<template>
  <fullscreen-overlay-frame :title="$t('faq-dialog.title').toString()"
                            icon="contact_support"
                            color="primary"
                            centered
                            closable
                            hide-actions-slot
                            @close="abort">

    <template v-slot:content>
      <v-skeleton-loader v-if="loading"
                         type="list-item"/>
      <v-expansion-panels v-else-if="items.length > 0">
        <v-expansion-panel v-for="item in items" v-bind:key="item.id">
          <v-expansion-panel-header class="font-weight-bold primary--text">
            {{item.title}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="item.body"/>
            <v-btn v-if="item.linkLabel && item.linkAddress"
                   depressed
                   color="primary"
                   class="mt-6"
                   :href="item.linkAddress"
                   target="_blank">
              {{ item.linkLabel }}
              <v-icon right>call_made</v-icon>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else
           v-html="$t('faq-dialog.empty-state')"/>
    </template>

  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: 'FaqDialog',

  components: {
    FullscreenOverlayFrame
  },

  data: () => ({
    loading: false,
    items: []
  }),

  methods: {
    getData() {
      this.loading = true
      this.$rhRequest.sendGet({
        endpoint: 'content-service/get-relevant',
        params: {type: 'FAQ'}
      }, (response) => {
        if (response.data.data) {
          this.items = response.data.data
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },

    abort() {
      this.$root.bisadialog.toggle('faq')
    }
  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(() => this.getData())
  }
};
</script>

