import CustomLogger from "@/plugins/bluetooth/deviceManager/Logger";
import BltCommand from "@/plugins/bluetooth/deviceManager/Command";
import { Buffer } from 'buffer';

const FLOW_AUTH_CHALLENGE = 0xAC;
const FLOW_AUTH_FINISH = 0xAF;

export default class {
    constructor(connection) {
        this.logger = new CustomLogger('flow: challenge');
        this.connection = connection;
        this.connection.onData(this.onData.bind(this));
        this.commandStack = [];
        this.sessionKey = null;
        this.callbacks = {
            onFinish: null,
            onSuccess: null,
            onError: null,
            onTimeout: null
        };
        this.timeout = null;
    }

    onFinish(callback) {
        this.callbacks.onFinish = callback;
    }

    onSuccess(callback) {
        this.callbacks.onSuccess = callback;
    }

    onError(callback) {
        this.callbacks.onError = callback;
    }

    onTimeout(callback) {
        this.callbacks.onTimeout = callback;
    }

    resetTimeout() {
        this.logger.debug('reset timeout');
        clearTimeout(this.timeout);
    }

    onData(data) {
        if (data.getType() === 0xFD) {
            if (this.callbacks.onError !== null) {
                this.callbacks.onError();
                this.resetTimeout();
            }
        } else if (data.getType() === FLOW_AUTH_CHALLENGE) {
            this.onFlowChallengeRepsonse(data);
        } else if (data.getType() === FLOW_AUTH_FINISH) {
            this.connection.setEncryptionKey(this.getSessionKey());

            if (this.callbacks.onSuccess !== null) {
                this.callbacks.onSuccess();
                this.resetTimeout();
            }
        }

        if (this.callbacks.onFinish !== null) {
            this.callbacks.onFinish();
            this.resetTimeout();
        }
    }

    onFlowChallengeRepsonse(response) {
        this.logger.debug('onACResponse', [response, Buffer.from(response.getData()).toString('hex')]);
        let challengeCmd = this.commandStack.slice(-1)[0];
        this.logger.debug('challengeCmd',Buffer.from(challengeCmd.getData()).toString('hex'));

        let i1, i2;
        for (i1=0; i1<challengeCmd.getLength(); i1++) {
            let c = (response.getData()[i1] ^ 0xFF);
            if (challengeCmd.getData()[i1] !== c) {
                this.logger.error('onACResponse: challenge failed', {last: challengeCmd.getData()[i1], new: c})
                return;
            }
        }

        let responseMask = [];
        for (i2=i1; i2 < (challengeCmd.getLength() + 4); i2++) {
            let c = (response.getData()[i2] ^ 0xFF);
            responseMask.push(c);
        }

        this.sessionKey = this.buildSessionKey(challengeCmd, response, responseMask);

        this.commandStack.push(
          new BltCommand(
              FLOW_AUTH_FINISH,
            new Uint8Array(responseMask)
          )
        );

        this.sendLatestCommandFromStack();
    }

    sendLatestCommandFromStack() {
        this.connection.send(this.commandStack.slice(-1)[0]);
    }

    onConnect() {
        let challengeData = [];

        for (let i = 0; i < 4; i++) {
            challengeData.push(this.getRandomByte());
        }

        this.commandStack.push(
            new BltCommand(
                FLOW_AUTH_CHALLENGE,
                new Uint8Array(challengeData)
            )
        );

        this.sendLatestCommandFromStack();

        let self = this;
        this.timeout = setTimeout(function () {
                if (self.callbacks.onTimeout !== null) {
                    self.callbacks.onTimeout();
                    self.logger.error('auth challenge timed out');
                }
            },
            10000
        );
    }

    getRandomByte() {
        return Math.floor(Math.random() * 255);
    }

    start() {
        let self = this;
        this.connection.isConnected().then(
          self.onConnect.bind(self)
        );
    }

    getSessionKey() {
        return this.sessionKey;
    }

    buildSessionKey(challenge, response, responseMask) {
        let sessionKeyData = [];
        sessionKeyData['challenge'] = Buffer.from(challenge.getData()).toString('hex');
        sessionKeyData['challengeMask'] = Buffer.from(response.getData().slice(0, challenge.getLength())).toString('hex');
        sessionKeyData['response'] = Buffer.from(response.getData().slice(challenge.getLength(), challenge.getLength()+4)).toString('hex');
        sessionKeyData['responseMask'] = Buffer.from(responseMask).toString('hex');
        this.logger.debug('sessionKeyData', sessionKeyData);


        //Challenge : Response : Challenge ^ FF : Response ^ FF
        return sessionKeyData['challenge'] + sessionKeyData['response'] + sessionKeyData['challengeMask'] + sessionKeyData['responseMask'];
    }
}
