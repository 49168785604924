<template>
  <fullscreen-overlay-frame :title="$t('language-dialog.title').toString()"
                icon="language"
                color="primary"
                centered
                closable
                @close="abort">
    <template v-slot:content>
      <v-radio-group v-model="language">
        <v-radio v-for="language in availableLanguages"
                 :key="language"
                 class="mt-2"
                 :label="languages[language]"
                 :value="language" />
      </v-radio-group>
    </template>

    <template v-slot:actions>
      <v-btn large depressed
             color="primary"
             class="font-weight-bold action-button"
             @click="save"
             :loading="loading">
        {{ $t('app.save') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import config from '@/config/config.app.json'
import i18n from "@/i18n";
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";

export default {
  name: 'LanguageDialog',

  components: {
    FullscreenOverlayFrame
  },

  data: () => ({
    loading: false,
    availableLanguages: [],
    language: ''
  }),

  methods: {
    save() {
      this.loading = true
      this.$rhRequest.sendPost({
        endpoint: 'language/set',
        data: {
          language: this.language,
          persist: true
        }
      }, () => {
        i18n.locale = this.language
        this.loading = false
        this.$vuetify.rtl = i18n.locale === 'ar'
        this.$root.bisadialog.toggle('language')
        localStorage.setItem('langSetting', this.language)
        this.$rhPushNotification.unsubscribeAllTopics()
      }, () => {
        this.loading = false
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
      })
    },
    abort() {
      this.$root.bisadialog.toggle('language')
    }
  },

  mounted() {
    this.language = i18n.locale
    this.availableLanguages = config.availableLanguages.split(",")
  },

  computed: {
    languages() {
      return {
        'de': this.$t('language-dialog.german'),
        'en': this.$t('language-dialog.english'),
        'it': this.$t('language-dialog.italian'),
        'ar': this.$t('language-dialog.arabic')
      }
    },
  }
};
</script>

