<template>
  <v-list color="transparent">
    <v-list-item class="px-0">
      <v-list-item-content>
        <div class="font-size-03">
          {{ $t('add-nuki-bridge-dialog.step-finish.description') }}
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "AddNukiBridgeStepFinish"
}
</script>

