<template>
  <entry-frame :title="$t('registration-page.title').toString()">
    <template v-slot:subheader>
      <div v-html="$t('registration-page.subtitle')"/>
      <v-btn text small
             color="primary"
             class="font-weight-bold"
             to="login">
          {{ $t('registration-page.login') }}
      </v-btn>
    </template>

    <template v-slot:form>
      <!-- Placeholder on page, gets implemented later -->
      <v-form v-model="valid" ref="form">
        {{ $t('registration-code-page.insert-code') }}
      </v-form>
    </template>
  </entry-frame>
</template>

<script>
import EntryFrame from "@/templates/components/EntryFrame";

export default {
  name: 'RegistrationCode',

  components: {
    EntryFrame
  },

  data: () => ({
    loading: false,
    valid: false,
    email: ''
  }),

  methods: {
    register: function () {
      if (this.$refs.form.validate()) {
        this.loading = true
        // TODO call api
        this.$root.bisatoast.info({
          message: 'not implemented yet',
          showCloseBtn: true
        })
      }
    }
  },

  computed: {
    emailRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
        v => /.+@.+\..+/.test(v) || this.$t('app.rules.email-invalid')
      ]
    }
  }
};
</script>
