<template>
  <fullscreen-overlay-frame :title="data?.item?.title"
                            icon="mail_outline"
                            color="primary"
                            centered
                            closable
                            @close="abort">

    <template v-slot:content>
      <div class="font-size-02"
           v-html="getFormattedTimestamp()"/>

      <div class="mt-6"
           v-html="getFormattedMessage()"/>

      <v-btn v-if="data?.item?.linkAddress && data?.item?.linkLabel"
             depressed dark
             type="button"
             color="primary"
             class="font-weight-bold mb-2 mt-6"
             :href="data?.item?.linkAddress"
             target="_blank">
        {{ data?.item?.linkLabel }}
        <v-icon right>chevron_right</v-icon>
      </v-btn>
    </template>

    <template v-slot:actions>

      <v-btn v-if="read"
             outlined
             color="primary"
             class="font-weight-bold action-button"
             @click="markAsUnread">
        <v-icon color="primary" left>mark_as_unread</v-icon>
        {{ $t('inbox-dialog.mark-as-unread') }}
      </v-btn>
      <v-btn v-else
             outlined
             color="primary"
             class="font-weight-bold action-button"
             @click="markAsRead">
        <v-icon color="primary" left>mark_email_read</v-icon>
        {{ $t('inbox-dialog.mark-as-read') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import formats from "@/scripts/formats";

export default {
  name: 'InboxDialog',

  components: {
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: () => ({
    read: true
  }),

  methods: {
    getFormattedMessage() {
      if (this.data?.item?.body) {
        return this.data?.item?.body.replace(/\n/g, '<br />')
      } else {
        return "-"
      }
    },

    getFormattedTimestamp() {
      return formats.formatDate(this.data?.item?.createdTimestamp, this.$t('app.date-time-format.long'))
    },

    markAsUnread() {
      localStorage.removeItem('inbox-' + this.data?.item?.id)
      this.read = false
      this.$root.$emit('updateInboxItems')
    },

    markAsRead() {
      localStorage.setItem('inbox-' + this.data?.item?.id, 'read')
      this.read = true
      this.$root.$emit('updateInboxItems')
    },

    abort() {
      this.read = true // reset read button status
      this.$root.bisadialog.toggle('inbox')
    }
  }
};
</script>

