import crc from "crc";
import CryptoJS from "crypto-js";
import CustomLogger from "@/plugins/bluetooth/deviceManager/Logger";
import { Buffer } from 'buffer';

const COMMAND_LOG_PREFIX = 'blt command';

export default class {
    constructor(type, data) {
        this.type = type;
        this.data = data;
        this.length = data.length;
        this.encryptionKey = null;
        this.encrypted = [];
        this.iv = null;
        this.nextIv = null;
        this.crc = null;
        this.logger = new CustomLogger(COMMAND_LOG_PREFIX);
        this.updateLogPrefix();

        this.fillRandomData();
        this.checksum = this.calcChecksum();
    }

    setEncryptionKey(encryptionKey) {
        this.encryptionKey = encryptionKey;
    }

    setIV(iv) {
        this.iv = iv;
        this.updateLogPrefix();
    }

    getNextIV() {
        return this.nextIv;
    }

    getEncryptionKey() {
        return this.encryptionKey;
    }

    getData() {
        return this.data.slice(0, this.getLength());
    }

    getLength() {
        return this.length;
    }

    encrypt(encryptionKey) {
        let hex = Buffer.from(this.data).toString('hex');
        let value =  CryptoJS.enc.Hex.parse(hex);
        let key  = CryptoJS.enc.Hex.parse(encryptionKey);

        // iv is always this value
        let iv   = CryptoJS.enc.Hex.parse(this.iv);

        let cipher = CryptoJS.AES.encrypt(
            value,
            key,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.NoPadding
        });

        this.nextIv = cipher.ciphertext.toString();

        for (var n = 0; n < this.nextIv.length; n += 2) {
            this.encrypted.push(parseInt(this.nextIv.substr(n, 2), 16));
        }

        this.logger.debug('encrypt',  {input: {data: this.data, hex: hex, length: hex.length/2, encrypted: this.encrypted}, iv: this.iv, nextIV: this.nextIv, key: encryptionKey});

        return new Uint8Array(this.encrypted);
    }

    build() {
        let encrypted = this.encrypt(this.encryptionKey);
        let d = new Uint8Array(19);
        d.set(new Uint8Array([this.type, this.getLength()]));
        d.set(encrypted, 2);
        d.set(new Uint8Array([this.checksum]), encrypted.length+2);

        this.logger.debug('build', {hex: Buffer.from(d).toString('hex')});

        return d;
    }

    calcChecksum() {
        let d = new Uint8Array(18);
        d.set(new Uint8Array([this.type, this.getLength()]));
        d.set(this.data, 2);

        this.crc = crc.crc8(d);
        this.logger.debug('checksum', {dec: this.crc, hex: this.crc.toString(16), data: { b: d, hex: Buffer.from(d).toString('hex')}});
        return this.crc;
    }

    fillRandomData() {
        let length = 16-this.getLength();
        let rng = [];
        for (let i=0; i<length; i++) {
            rng.push(this.getRandomByte());
        }

        this.logger.debug('rng', rng);

        let data = new Uint8Array(16);
        data.set(this.data);
        data.set(new Uint8Array(rng), this.length);

        this.data = data;
    }

    getRandomByte() {
        return Math.floor(Math.random() * 255);
    }

    updateLogPrefix() {
        this.logger.logPrefix = COMMAND_LOG_PREFIX + ' ' + this.iv;
    }
}

