export default {

  /**
   * This function calculates the electricity end price adding other charges and taxes to the current price.
   * @param price Current price
   * @returns {number} End price
   */
  calculateEndPrice(price) {
    // Börsenpreis + Netzentgelte + KWKG-Umlage + §19 StromNEV-Umlage + Offshore-Netzumlage + Stromsteuer
    let endPrice = price + (7 + 0.275 + 0.643 + 0.656 + 2.05)
    // Taxes
    endPrice = endPrice + (endPrice * 0.19)
    // Margin
    endPrice = Math.round(endPrice + 4)
    return endPrice
  },

}