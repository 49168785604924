<template>
  <v-container class="pa-5 page-content">

    <v-row class="mt-8">
      <v-col cols="12" sm="6" lg="4">
        <v-card>
          <v-list v-if="userInformation.informationLoaded">
            <v-list-item three-line>
              <v-list-item-icon>
                <v-btn fab depressed small>
                  <v-icon color="primary">person</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content :class="canChangeAddress ? '' : 'pb-0'">
                <v-list-item-title class="primary--text font-weight-bold font-size-06">
                  {{ userInformation.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="canChangeAddress">{{ userInformation.address }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>mdi-menu-down</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-skeleton-loader v-else
                             type="list-item-avatar-three-line"/>
          <v-divider></v-divider>
          <v-list class="py-0">

            <v-list-item class="list-item straight"
                         @click="changePassword">
              <v-list-item-icon class="ml-2">
                <v-icon class="material-icons-outlined mr-2" color="primary">password</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('app.change-password') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item class="list-item straight"
                         @click="changeUserData">
              <v-list-item-icon class="ml-2">
                <v-icon class="material-icons-outlined mr-2" color="primary">manage_accounts</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('profile-page.change-user-data') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item class="list-item straight"
                         @click="openConfirmDeleteAccountDialog">
              <v-list-item-icon class="ml-2">
                <v-icon class="material-icons-outlined mr-2" color="primary">person_remove</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('app.delete-account') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item to="logout">
              <v-list-item-icon class="ml-2">
                <v-icon class="material-icons-outlined mr-2" color="primary">logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('app.logout') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <!-- general settings/information -->
        <v-card>
          <v-list-item v-if="canChangeLanguage"
                       class="list-item straight"
                       @click="changeLanguage">
            <v-list-item-icon class="ml-2">
              <v-icon class="material-icons-outlined mr-2" color="primary">language</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('app.change-language') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">chevron_right</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item class="list-item straight"
                       @click="changeDarkMode"
                       v-if="$darkmode.changeable">
            <v-list-item-icon class="ml-2">
              <v-icon class="material-icons-outlined mr-2" color="primary">dark_mode</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('app.change-darkmode') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">chevron_right</v-icon>
            </v-list-item-action>
          </v-list-item>

          <!-- this item is only visible if the user is a site admin for the currently active site is allowed to invite users -->
          <v-list-item class="list-item straight"
                       v-if="isSiteAdmin && canInviteAdditionalUser"
                       to="userManagement">
            <v-list-item-icon class="ml-2">
              <v-icon class="material-icons-outlined mr-2" color="primary">group_add</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('profile-page.user-management') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">chevron_right</v-icon>
            </v-list-item-action>
          </v-list-item>

          <!-- item only visible if user is allowed to change site -->
          <v-list-item class="list-item straight"
                       @click="changeSite" v-if="canChangeSite">
            <v-list-item-icon class="ml-2">
              <v-icon class="material-icons-outlined mr-2" color="primary">home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('change-site-dialog.title') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">chevron_right</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item v-if="hasFaq" class="list-item straight"
                       @click="openFaq">
            <v-list-item-icon class="ml-2">
              <v-icon class="material-icons-outlined mr-2" color="primary">contact_support</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('profile-page.faq') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">chevron_right</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click="clearCache">
            <v-list-item-icon class="ml-2">
              <v-icon class="material-icons-outlined mr-2" color="primary">cleaning_services</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('app.clear-cache') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">chevron_right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4" v-if="hasEnode">
        <v-card>
          <v-list class="py-0">
            <v-list-item @click="openConnectedAccounts">
              <v-list-item-icon class="ml-2">
                <v-icon class="material-icons-outlined mr-2" color="primary">hub</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('app.connected-accounts') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>

          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4" v-if="config.clientKey !== 'habilio'"> <!-- Habilio does not want to have the legal links in the app settings -->
        <!-- links -->
        <v-card>
          <v-list class="py-0">
            <v-list-item class="list-item straight"
                         :href="config.imprintLink"
                         target="_blank">
              <v-list-item-icon class="ml-2">
                <v-icon class="material-icons-outlined mr-2" color="primary">description</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('app.imprint') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>call_made</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item class="list-item straight"
                         :href="config.privacyLink"
                         target="_blank">
              <v-list-item-icon class="ml-2">
                <v-icon class="material-icons-outlined mr-2" color="primary">phonelink_lock</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('app.privacy') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>call_made</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="config?.tosLink"
                         :href="config.tosLink"
                         target="_blank">
              <v-list-item-icon class="ml-2">
                <v-icon class="material-icons-outlined mr-2" color="primary">perm_device_information</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('profile-page.tos') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>call_made</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <div class="my-6 font-size-01 mx-auto text-center">
      {{ $t('profile-page.version', {version: config.configVersion}) }}
      <span v-if="config.environment !== 'production'">({{ config.environment }})</span>
    </div>

  </v-container>
</template>

<script>
import config from '@/config/config.app.json'
import requestHelper from "@/scripts/requestHelper";

export default {
  name: 'ProfilePage',

  data: () => ({
    config: config,
    informationLoaded: false,
    userInformation: {
      informationLoaded: false
    },
    isSiteAdmin: false,
    timeouts: {
      generalInfo: {
        handle: null,
        time: 3000
      }
    }
  }),
  methods: {
    getGeneralUserInformation() {
      this.userInformation.informationLoaded = false
      this.isSiteAdmin = this.$rhAuth.isSiteAdmin()
      this.$rhRequest.sendGet({
        endpoint: "site/get-general-information",
      }, (resp) => {

        this.userInformation = {
          informationLoaded: true,
          name: resp?.data?.data?.firstName + ' ' + resp?.data?.data?.lastName,
          address: this.buildAddressString(resp?.data?.data)
        }
        this.userInformation.informationLoaded = true
      },
        () => {
          this.timeouts.generalInfo.handle = setTimeout(() => {this.getGeneralUserInformation()}, this.timeouts.generalInfo.time)
        })
    },

    buildAddressString(rawAddress) {
      let address = null
      if (rawAddress?.street) {
        address = rawAddress.street
        address += rawAddress.zip ? ', ' + rawAddress.zip : ''
        address += rawAddress.city ? ' ' + rawAddress.city : ''
      }
      return address
    },

    openConfirmDeleteAccountDialog() {
      this.$root.bisadialog.toggle('profileConfirmDelete', true)
    },

    changeLanguage() {
      this.$root.bisadialog.toggle('language')
    },

    changePassword() {
      this.$root.bisadialog.toggle('changePassword')
    },

    changeDarkMode() {
      this.$root.bisadialog.toggle('darkMode')
      this.$root.bisadialog.callDialogInit('darkMode')
    },

    changeUserData() {
      this.$root.bisadialog.toggle('changeUserData')
      this.$root.bisadialog.callDialogInit('changeUserData')
      this.$root.$on('changed-data', () => {
        this.getGeneralUserInformation()
        this.$root.$off('changed-data')
      })
    },

    openConnectedAccounts() {
      this.$root.bisadialog.toggle('connectedAccounts')
      this.$root.bisadialog.callDialogInit('connectedAccounts')
    },

    clearCache() {
      this.$root.bisadialog.toggle('clearCache')
    },

    openFaq() {
      this.$root.bisadialog.toggle('faq')
    },
    changeSite() {
      this.$root.bisadialog.toggle('changeSite', true)
      this.$root.bisadialog.callDialogInit('changeSite')

      // listen for close on change site dialog to reload site information displayed at top of profile page
      this.$root.$on('close-change-site-dialog', () => {
        this.getGeneralUserInformation()
        this.$root.$off('close-change-site-dialog')
      })
    },
  },
  computed: {
    hasEnode() {
      return Object.hasOwn(config, 'enodeRedirect')
    },

    canInviteAdditionalUser() {
      return config.canInviteAdditionalUser !== 'false'
    },

    canChangeSite() {
      return config.canChangeSite !== 'false'
    },

    hasFaq() {
      return config.hasFaq !== 'false'
    },

    /**
     * returns true if the user is allowed to enter or change the site address
     * @returns {boolean}
     */
    canChangeAddress() {
      return config.canChangeAddress === 'true';
    },

    /**
     * returns true if the user is allowed to change app language
     * @returns {boolean}
     */
    canChangeLanguage() {
      return !(config.canChangeLanguage === 'false');
    }
  },
  mounted() {
    this.userInformation.informationLoaded = true
    requestHelper.startDelayedRequest(
        () => this.getGeneralUserInformation()
    )
  }
};
</script>
