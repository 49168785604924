<template>
  <v-stepper-content step="1"
                     class=" pb-2 pa-1">
    <content-card :title="$t('add-rule-dialog.content-card.title').toString()"
                  icon="info"
                  class="my-5">
      <template v-slot:content>
        <v-list class="py-0">
          <!-- scene name -->
          <v-list-item class="list-item straight">
            <v-list-item-icon class="material-icons-outlined">
              <v-icon>label</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-if="editNameMode">
              <v-text-field v-model="name"
                            dense outlined
                            hide-details
                            append-icon="check"
                            @click:append="editName(false)"
                            @blur="editName(false)"/>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>{{ name }}</v-list-item-title>
              <v-list-item-subtitle v-text="$t('add-scene-step-overview.name.subtitle')"/>
            </v-list-item-content>
            <v-list-item-action v-if="!editNameMode">
              <v-btn icon
                     :disabled="!isSiteAdmin"
                     class="float-right"
                     @click="editName">
                <v-icon color="primary">
                  edit
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <!-- scene delay -->
          <v-list-item class="list-item straight">
            <v-list-item-icon class="material-icons-outlined">
              <v-icon>timer</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-if="editDelayMode">
              <v-menu v-model="delayMenu"
                      ref="delayPickerMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="delay"
                                outlined dense hide-details
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                append-icon="check"
                                @click:append="editDelay(false)"/>
                </template>
                <v-time-picker v-model="delay"
                               format="24hr"
                               use-seconds scrollable
                               @click:second="setDelay(delay)"/>
              </v-menu>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title v-if="delay == null" v-text="$t('add-scene-step-overview.delay.none')"/>
              <v-list-item-title v-else v-text="delay"/>
              <v-list-item-subtitle v-text="$t('add-scene-step-overview.delay.subtitle')"/>
            </v-list-item-content>
            <v-list-item-action v-if="!editDelayMode">
              <v-btn icon
                     :disabled="!isSiteAdmin"
                     class="float-right"
                     @click="editDelay">
                <v-icon color="primary">
                  edit
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <!-- custom scene icon -->
          <v-list-item>
            <v-list-item-icon class="material-icons-outlined">
              <span class="material-symbols-outlined v-icon ma-0"
                    :class="$vuetify.theme.isDark ? 'theme--dark': 'theme--light'">{{ iconKey }}</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t('add-rule-step-overview.name.icon')"/>
            </v-list-item-content>
            <v-list-item-action class="align-self-start">
              <v-btn icon
                     :disabled="!isSiteAdmin"
                     class="float-right"
                     @click="editIcon">
                <v-icon v-if="editIconMode"
                        color="primary">
                  check
                </v-icon>
                <v-icon v-else
                        color="primary">
                  edit
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <icon-picker v-model="iconKey"
                       :visible="editIconMode"/>
        </v-list>
      </template>
    </content-card>

    <content-card v-if="!loadingActions"
                  class="angular-at-bottom"
                  :title="$t('action-summary-card.title.plural').toString()">
      <template v-slot:content>
        <v-list-item v-for="(action, idx) in actionList"
                     v-bind:key="idx"
                     class="list-item straight"
                     @click="editAction(idx)">
          <v-list-item-content>
            <v-list-item-title>
              <action-summary :action="action" :action-subject="getActionSubject(action)"/>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="ml-0">
            <!-- edit button -->
            <v-btn icon
                   :disabled="!isSiteAdmin"
                   class="float-right"
                   @click.native.stop="editAction(idx)">
              <v-icon color="primary">edit</v-icon>
            </v-btn>

            <!-- remove button -->
            <v-btn icon
                   :disabled="!isSiteAdmin"
                   class="float-right"
                   @click.native.stop="removeAction(idx)">
              <v-icon color="primary">close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </content-card>
    <v-skeleton-loader v-else
                       type="card" height="138"/>

    <v-card color="primary"
            class="angular-at-top">
      <v-list-item class="py-3"
                   @click="addAction">
        <v-list-item-content>
          <v-list-item-title class="white--text font-size-04 font-weight-bold"
                             v-text="$t('add-scene-step-overview.add-action')"/>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="white">add</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-card>

  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import ActionSummary from "@/templates/components/living/rules/ActionSummary.vue";
import automation from "@/scripts/automations";
import IconPicker from "@/templates/dialogs/automations/IconPicker.vue";

export default {
  name: 'AddSceneStepOverview',
  components: {
    IconPicker,
    ActionSummary,
    ContentCard
  },

  props: {
    value: {
      type: Object,
      default: null
    },
    actions: {
      type: Array
    },
    actionSubjects: {
      type: Array
    },
    loadingActions: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      name: this.value?.name,
      delay: this.value?.delay,
      iconKey: this.value?.iconKey,
      editNameMode: false,
      editDelayMode: false,
      editIconMode: false,
      delayMenu: false,
      actionList: this.actions,
      actionSubjectList: this.actionSubjects
    }
  },

  computed: {
    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    }
  },

  methods: {

    /**
     * initializes scene props from v-model
     * call this method, when using this component to edit an existing scene
     */
    init() {
      this.name = this.value?.name
      this.delay = this.value?.delay
      this.iconKey = this.value?.iconKey
    },

    /**
     * updates actions and actionSubjects with deep inspection to force display updates
     *
     * @param actions
     * @param actionSubjects
     */
    updateData(actions, actionSubjects = null) {
      // this is kind of a workaround to force Vue to rerender the actions when changed externally (no deep item inspection)
      this.actionList = []
      this.actionList = actions
      if (actionSubjects) {
        this.actionSubjectList = []
        this.actionSubjectList = actionSubjects
      }
    },

    /**
     * proceeds to action selection
     */
    addAction() {
      if (!this.isSiteAdmin) {
        return
      }
      this.$emit("add-action")
    },

    /**
     * proceeds to action editor
     */
    editAction(idx) {
      if (!this.isSiteAdmin) {
        return
      }
      this.$emit("edit-action", idx)
    },

    /**
     * removes action
     */
    removeAction(idx) {
      if (!this.isSiteAdmin) {
        return
      }
      this.$emit("remove-action", idx)
    },

    /**
     * activates the edit mode for the name of the automation
     */
    editName(mode) {
      if (!this.isSiteAdmin) {
        return
      }
      this.editNameMode = mode
      this.updateModel()
    },

    /**
     * activates the edit mode for the time delay when playing the scene
     */
    editDelay(mode) {
      if (!this.isSiteAdmin) {
        return
      }
      this.editDelayMode = mode
      this.updateModel()
    },

    /**
     * activates the edit mode for automation icon
     */
    editIcon() {
      if (!this.isSiteAdmin) {
        return
      }
      this.editIconMode = !this.editIconMode
      this.updateModel()
    },

    /**
     * sets the delay value and updates the v-model
     *
     * @param delay
     */
    setDelay(delay) {
      this.$refs.delayPickerMenu.save(delay)
      this.updateModel()
    },

    /**
     * returns the actionSubject (device) for the deviceId stored in action
     *
     * @param action
     * @returns {*}
     */
    getActionSubject(action) {
      if (action.type === automation.actionTypes.devices) {
        return this.actionSubjectList.find(item => item.device.id === action.deviceId)
      } else if (action.type === automation.actionTypes.sceneRefs) {
        return action?.name
      }
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', {
        name: this.name,
        delay: this.delay,
        iconKey: this.iconKey
      })
    }
  }
};
</script>

<style lang="scss">
.five-columns {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.angular-at-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.angular-at-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
</style>
