<template>
  <fullscreen-overlay-frame :title="data?.name"
                            icon="feed"
                            color="primary"
                            centered
                            closable
                            @close="closeOverlay">
    <template v-slot:content>
      <div class="meetings-iframe-container" :id="'meetings-iframe-container-' + data.id"/>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame.vue";

export default {
  components: {FullscreenOverlayFrame},
  props: ['data'],
  methods: {
    /**
     * close overlay function
     */
    closeOverlay() {
      this.$root.bisadialog.toggle('hubspotCalendar')
    }
  },
  mounted() {
    // set the calendar URL
    document.getElementById("meetings-iframe-container-" + this.data.id).setAttribute("data-src", this.data.calendarUrl)

    let hubspotCalendarScript = document.createElement("script")
    hubspotCalendarScript.id = "hubspot-calendar-script"
    hubspotCalendarScript.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
    document.body.appendChild(hubspotCalendarScript)
  },
  beforeDestroy() {
    // This is to avoid multiple script tags being added.
    // Also, removing the tag forces the calendar to be initialised,
    // otherwise the calendar will only be loaded when the overlay is first displayed.
    document.getElementById("hubspot-calendar-script")?.remove()
  }
}
</script>
