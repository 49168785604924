<template>
  <!-- No dense view: Nuki devices cannot be added to favorites. This is covered by AccessesCard.vue -->
  <device-card :flat="flat"
               :device="device"
               :title="$t('device-dialog.actuators.title').toString()"
               icon="tune">
    <sub-devices>
      <actuator-device hide-actions-slot
                       :device-id="device.id"
                       :actuator="device.actuators?.[0]">
        <template v-slot:content>
          <kaadas-lock :device="device"/>
        </template>
      </actuator-device>
    </sub-devices>
  </device-card>
</template>

<script>
import DeviceCard from "@/templates/components/devices/DeviceCard";
import SubDevices from "@/templates/components/devices/SubDevices";
import ActuatorDevice from "@/templates/components/devices/ActuatorDevice";
import KaadasLock from "@/templates/components/devices/access/KaadasLock";

export default {
  name: 'DeviceCardKaadasLock',

  components: {KaadasLock, ActuatorDevice, SubDevices, DeviceCard},

  props: {
    device: Object,
    flat: Boolean
  },
}

</script>
