import CustomLogger from "@/plugins/bluetooth/deviceManager/Logger";
import CryptoJS from "crypto-js";
import ByteBuffer from "bytebuffer";
import { Buffer } from 'buffer';

export default class {
    constructor(response) {
        this.response = response;
        this.logger = new CustomLogger('blt response');
        this.cmd = new Uint8Array(response)[0];
        this.length = new Uint8Array(response)[2];
        this.state = new Uint8Array(response)[3];
        this.crc = new Uint8Array(response)[19];
        this.cryptdata = response.slice(3, 19);
        this.mac = null;
        this.data = null;
    }

    setMac(mac) {
        this.mac = mac;
    }

    getMac() {
        return this.mac;
    }

    getType() {
        return this.cmd;
    }

    getData() {
        return new Uint8Array(this.data.buffer.slice(0, this.getLength() - 1));
    }

    getState() {
        return this.state;
    }

    getLength() {
        return this.length;
    }

    getNexIV() {
        return Buffer.from(this.cryptdata).toString('hex');
    }

    decrypt(iv, encryptionKey) {
        let civ = CryptoJS.enc.Hex.parse(iv);
        let hexValue = Buffer.from(this.cryptdata).toString('hex');
        let value =  CryptoJS.enc.Hex.parse(hexValue);
        let key  = CryptoJS.enc.Hex.parse(encryptionKey);

        let decrypted = CryptoJS.AES.decrypt(
            {
                ciphertext: value
            },
            key, {
                iv: civ,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.NoPadding
            });

        this.data = ByteBuffer.fromHex(decrypted.toString());
        this.logger.debug('decrypt', {decrypted: decrypted.toString(), buffer:this.data, iv: iv, key: encryptionKey});
    }
}
