<template>
  <entry-frame :title="$t(titleMap[step]).toString()">
    <template v-slot:form>
      <v-progress-linear class="ma-auto" :value="progressValue"></v-progress-linear>
      <v-stepper flat v-model="step" class="pt-1 transparent overflow-visible">
        <v-stepper-items class="overflow-visible">
          <v-stepper-content class="px-0" step="1">
            <registration-name @nextStep="nextStep"
                               @stepBack="cancel"/>
          </v-stepper-content>
          <v-stepper-content class="px-0" step="2">
            <registration-address @nextStep="nextStep"
                                  @stepBack="stepBack"/>
          </v-stepper-content>
          <v-stepper-content class="px-0" step="3">
            <registration-password @nextStep="nextStep" @stepBack="stepBack"></registration-password>
          </v-stepper-content>
          <v-stepper-content class="px-0 overflow-visible" step="4">
            <registration-overview @nextStep="invitationCode ? createInvitedUser() : registerUser()"
                                   @stepBack="stepBack"
                                   :user="user"
                                   :loading="loadingRegistering"/>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </entry-frame>
</template>
<script>

import EntryFrame from "@/templates/components/EntryFrame";
import RegistrationAddress from "@/templates/components/userRegistrationSteps/RegistrationAddress";
import RegistrationName from "@/templates/components/userRegistrationSteps/RegistrationName";
import RegistrationOverview from "@/templates/components/userRegistrationSteps/RegistrationOverview";
import RegistrationPassword from "@/templates/components/userRegistrationSteps/RegistrationPassword";
import config from '@/config/config.app.json'

import Sha1 from "sha1";

export default {
  name: 'RegistrationUser',
  components: {
    RegistrationAddress,
    EntryFrame,
    RegistrationName,
    RegistrationPassword,
    RegistrationOverview
  },

  data: () => ({
    loadingRegistering: false,
    valid: false,
    step: 1,
    user: {
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      zip: '',
      password: ''
    },
    titleMap: {
      1: 'registration-name-page.title',
      2: 'registration-address.title',
      3: 'registration-password.title',
      4: 'registration-overview.title'
    },
    invitationCode: null
  }),

  methods: {
    nextStep(values) {
      let self = this
      values.forEach(function (value) {
        self.user[Object.keys(value)[0]] = Object.values(value)[0]
      })
      if (!this.canChangeAddress
          && this.step < Object.keys(this.titleMap).length
          && this.titleMap[this.step + 1] === 'registration-address.title') {
        // skip registration-address if canChangeAddress is disabled in config
        this.step += 2
      } else {
        this.step += 1
      }
    },

    stepBack() {
      if (!this.canChangeAddress
          && Object.keys(this.titleMap).length > 1
          && this.titleMap[this.step - 1] === 'registration-address.title') {
        // skip registration-address if canChangeAddress is disabled in config
        this.step -= 2
      } else {
        this.step -= 1
      }
    },

    /**
     * closes the dialog without submitting any data
     */
    cancel() {
      this.$router.push('registration')
    },

    /**
     * registers a new user
     */
    registerUser() {
      this.loadingRegistering = true

      // encrypt password before send request
      this.user.password = Sha1(this.user.password)

      this.$rhRequest.sendPost({
        endpoint: "registration/register-user",
        data: this.user
      }, () => {
        this.$root.bisatoast.success({
          message: this.$t("registration-user.register-user.success", {email: this.user.email})
        })
        this.$router.push('login')
        this.loadingRegistering = false
      }, (e) => {
        console.error(e)
        if (e?.response?.status === 409) {
          this.$root.bisatoast.error({
            message: this.$t("registration-user.register-user.conflict")
          })
        } else {
          this.$root.bisatoast.error({
            message: this.$t("app.generic-error")
          })
        }
        this.loadingRegistering = false
      })
    },

    /**
     * Creates a new user for an invitation code
     */
    createInvitedUser() {
      this.loadingRegistering = true
      let formData = new FormData()
      formData.append('registrationCode', this.invitationCode)
      formData.append('email', this.user.email)
      formData.append('lastName', this.user.lastName)
      formData.append('firstName', this.user.firstName)
      formData.append('encryptedPw', Sha1(this.user.password))

      this.$rhRequest.sendPost({
        endpoint: "user/register",
        serializer: 'multipart',
        data: formData
      }, () => {
        this.$root.bisatoast.success({
          message: this.$t("registration-user.register-user.success", {email: this.user.email})
        })
        this.$router.push('login')
        this.loadingRegistering = false
      }, (e) => {
        console.error(e)
        if (e?.response?.status === 409) {
          this.$root.bisatoast.error({
            message: this.$t("registration-user.register-user.conflict")
          })
        } else {
          this.$root.bisatoast.error({
            message: this.$t("app.generic-error")
          })
        }
        this.loadingRegistering = false
      })
    }
  },

  computed: {
    nameRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
      ]
    },

    /**
     * calculates the progress bar value
     * @returns {number}
     */
    progressValue() {
      return this.step * 100 / (Object.keys(this.titleMap).length)
    },

    /**
     * returns true if the user is allowed to enter or change the site address
     * @returns {boolean}
     */
    canChangeAddress() {
      return config.canChangeAddress === 'true';
    }
  },

  mounted() {
    if (this.$route.params?.invitationCode) {
      this.invitationCode = this.$route.params.invitationCode
    }

    if (this.$route.params?.email) {
      this.user.email = this.$route.params.email
    } else {
      // the user is not allowed to skip the e-mail step
      this.$router.push('registrationEmail')
    }
  }
};
</script>
