import {Chart as ChartJS} from 'chart.js'
import moment from "moment";

var highlightedIndex = 0
var gradient
var offset1, offset2
var consumptionUnit

/*
 *   creates gradient for second xAxis which displays year under months for more clarity
 */
function getGradient(ctx, chartArea, updated) {

  if (!gradient || !updated) {
    // Create the gradient because this is either the first render
    gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    gradient.addColorStop(0, ChartJS.defaults.borderColor);
    // set previous color stop and next color start to same offset to prevent bleeding
    gradient.addColorStop(offset1, ChartJS.defaults.borderColor);
    gradient.addColorStop(offset1, '#2E3233');
    // true if more than one year change is visible
    if (offset2) {
      gradient.addColorStop(offset2, '#2E3233');
      gradient.addColorStop(offset2, ChartJS.defaults.borderColor);
      gradient.addColorStop(1, ChartJS.defaults.borderColor);
    } else {
      gradient.addColorStop(1, '#2E3233');
    }
  }

  return gradient
}

export default {
  setActiveIndex(index) {
    highlightedIndex = index
  },
  setLabelOffset(tickOffset1 = 0, tickOffset2 = 1) {
    offset1 = tickOffset1
    offset2 = tickOffset2
  },
  setConsumptionUnit(passedConsumptionUnit) {
    consumptionUnit = passedConsumptionUnit
  },

  chartOptions: {
    plugins: {
      tooltip: {
        backgroundColor: 'white',
        borderWidth: 0.2,
        caretSize: 3,
        cornerRadius: 4,
        padding: 10,
        caretPadding: 8,
        yAlign: 'bottom',
        borderColor: 'black',
        bodyColor: 'black',
        titleColor: 'black',
        displayColors: false,
        callbacks: {
          title: function (ctx) {
            var month = ctx[0].label.split(";")[0]
            var year = ctx[0].label.split(";")[1]
            return month + ' ' + year;
          },
          label: function (ctx) {
            return ctx.formattedValue + ' ' + consumptionUnit
          }
        }
      },
      legend: {
        display: false
      }
    },
    responsive: true,
    scales: {
      yAxis: {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6,
          font: {
            size: 10
          }
        },
        border: {
          display: false
        },
        position: 'right'
      },
      xAxis: {
        // hook to increase height to fit axis more nicely
        afterFit: (context) => {
          context.height += 5
        },
        gridLines: {
          drawTicks: false
        },
        grid: {
          color: '#EDF1F2',
          display: function (context) {
            return Object.values(context.scale.ticks).length < 13
          }
        },
        ticks: {
          padding: 3,
          callback: function (label) {
            let realLabel = this.getLabelForValue(label)
            var month = realLabel.split(";")[0];
            return month;
          },
          color: (c) => {
            if (c.tick.value === highlightedIndex) {
              return 'black';
            } else {
              return ChartJS.defaults.color
            }
          },
          minRotation: 0,
          maxRotation: 0,
          beginAtZero: true,
          font: {
            size: 10,
            weight: (w) => {
              if (w.tick.value === highlightedIndex) {
                return '900'
              }
            }
          }
        },
      },
      // second axis which shows year
      xAxis2: {
        // hook to increase height to fit axis more nicely
        afterFit: (context) => {
          context.height += 5
        },
        type: "category",
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
        },
        border: {
          width: 3,
          color: function (context) {
            const chart = context.chart;
            const {ctx, chartArea} = chart;
            if (!chartArea) {
              // This case happens on initial chart load
              return;
            }
            return getGradient(ctx, chartArea, false);
          },
        },
        ticks: {
          font: {
            size: 10
          },
          autoSkip: false,
          minRotation: 0,
          maxRotation: 0,
          // set value for tick as year if month is January, otherwise omit year for month
          callback: function (label) {
            let realLabel = this.getLabelForValue(label)
            let realLabelMonth = moment(realLabel, 'MMM;YYYY', moment.locale(localStorage.getItem('langSetting')))
            let year = realLabel.split(";")[1];
            if (realLabelMonth.format('M') === '1') {
              return year;
            } else {
              return null;
            }
          }
        }
      }
    }
  }
}
