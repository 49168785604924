<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="236.694" height="132.592" viewBox="0 0 236.694 132.592">
    <g id="Gruppe_1803" data-name="Gruppe 1803" transform="translate(-77 -256.407)">
      <circle id="Ellipse_526" data-name="Ellipse 526" cx="9.332" cy="9.332" r="9.332" transform="translate(114.799 301.644)" :fill="fillSecondary" opacity="0.2"/>
      <path id="Pfad_2909" data-name="Pfad 2909" d="M868.357,593.552c.424,3.323,1.984,6.364,3.648,9.292.055.1.112.2.17.3h17.293c.35-.091.695-.19,1.039-.3a18.9,18.9,0,0,0,5.46-2.686c6.362-4.584,8.81-13.245,7.7-21.006-.688-4.822-2.8-9.745-6.924-12.342a10.4,10.4,0,0,0-7.475-1.31c-.065.012-.131.027-.2.04a7.715,7.715,0,0,0-5.167,3.633c-1.964,3.49-.795,7.813.171,11.7s1.485,8.533-1.31,11.4A7.13,7.13,0,0,0,874.477,583c-.2.043-.4.092-.6.152a6.637,6.637,0,0,0-1.365.571C869.218,585.566,867.883,589.806,868.357,593.552Z" transform="translate(-630.562 -214.143)" :fill="fillSecondary"/>
      <path id="Pfad_2910" data-name="Pfad 2910" d="M885.5,603c-.479.112-.96.21-1.444.3h2.615c.342-.092.683-.191,1.021-.3q1.114-.348,2.194-.8a19.678,19.678,0,0,0,7.559-5.45,15.38,15.38,0,0,0,2.384-3.895,17.682,17.682,0,0,0,1.22-4.984,35.45,35.45,0,0,0-.7-10.571,44.115,44.115,0,0,0-3.447-10.339q-.283-.592-.586-1.176a.229.229,0,0,0-.153-.131.264.264,0,0,0-.2.04.313.313,0,0,0-.123.414,43.618,43.618,0,0,1,3.658,9.965,38.354,38.354,0,0,1,1.088,10.373,16.553,16.553,0,0,1-2.914,9.08,18.321,18.321,0,0,1-6.858,5.627A25.156,25.156,0,0,1,885.5,603Z" transform="translate(-637.446 -214.3)" fill="#fff"/>
      <path id="Pfad_2911" data-name="Pfad 2911" d="M876.436,616.838h.771c.086-.1.17-.2.252-.3a16.433,16.433,0,0,0,3.459-7.39,16.033,16.033,0,0,0-1.383-9.978,16.415,16.415,0,0,0-1.494-2.473c-.2.043-.4.092-.6.152a15.509,15.509,0,0,1-.74,19.69C876.617,616.64,876.527,616.74,876.436,616.838Z" transform="translate(-634.125 -227.838)" fill="#fff"/>
      <rect id="Rechteck_2815" data-name="Rechteck 2815" width="87.74" height="72.176" transform="translate(139.676 303.307)" fill="#fff"/>
      <path id="Pfad_2903" data-name="Pfad 2903" d="M726.722,261.453v-4.432H714.551V243.16h-3.305v13.861H689.576V243.16h-3.306v13.861H652.849V243.16h-3.305v13.861H638.983v4.432h10.561v17.234H638.983v4.432h10.561v18.465H638.983v4.432h28.374v9.321h3.305v-9.321h15.609v9.321h3.306v-9.321h37.146v-4.432H689.576V283.118h37.146v-4.432H714.551V261.453Zm-40.452,0v5.416H652.849v-5.416ZM652.849,271.3h33.422v7.386H652.849Zm0,30.282V283.118h14.507v18.465Zm33.422,0H670.662V283.118h15.609Zm24.975-22.9H689.576V261.453h21.669Z" transform="translate(-499.307 60.147)" fill="#e6e6e6"/>
      <path id="Pfad_2904" data-name="Pfad 2904" d="M800.6,165.85c0,8.754-15.85,28.254-15.85,28.254S768.9,174.6,768.9,165.85a15.85,15.85,0,0,1,31.7,0Z" transform="translate(-601.208 122.717)" :fill="fillPrimary"/>
      <path id="Pfad_2905" data-name="Pfad 2905" d="M817.078,188.231a7.246,7.246,0,1,1-7.246-7.246A7.246,7.246,0,0,1,817.078,188.231Z" transform="translate(-626.286 99.647)" fill="#fff" style="isolation: isolate"/>
      <circle id="Ellipse_525" data-name="Ellipse 525" cx="4.5" cy="4.5" r="4.5" transform="translate(179.046 319.715)" :fill="fillPrimary"/>
      <path id="Pfad_2906" data-name="Pfad 2906" d="M254.171,426.609c-.728,5.7-3.405,10.923-6.261,15.95-.094.171-.192.34-.291.511H217.935c-.6-.156-1.193-.327-1.783-.511a32.449,32.449,0,0,1-9.372-4.611c-10.92-7.868-15.122-22.735-13.224-36.056,1.18-8.276,4.813-16.727,11.886-21.184a17.845,17.845,0,0,1,12.831-2.248c.113.02.225.046.34.069a13.241,13.241,0,0,1,8.869,6.235c3.372,5.99,1.364,13.411-.294,20.078s-2.549,14.647,2.248,19.565A12.238,12.238,0,0,1,243.667,408.5c.347.074.69.158,1.027.261a11.392,11.392,0,0,1,2.342.981C252.694,412.9,254.985,420.179,254.171,426.609Z" transform="translate(-85.472 -54.07)" :fill="fillSecondary"/>
      <path id="Pfad_2907" data-name="Pfad 2907" d="M284.727,443.559q1.234.287,2.478.511h-4.488c-.588-.158-1.173-.327-1.752-.511q-1.912-.6-3.765-1.372a33.779,33.779,0,0,1-12.974-9.354,26.4,26.4,0,0,1-4.092-6.685,30.361,30.361,0,0,1-2.095-8.555,60.84,60.84,0,0,1,1.206-18.144,75.728,75.728,0,0,1,5.916-17.746q.487-1.015,1.006-2.018a.394.394,0,0,1,.263-.225.454.454,0,0,1,.34.069.538.538,0,0,1,.212.71,74.854,74.854,0,0,0-6.279,17.1,65.837,65.837,0,0,0-1.867,17.8,28.408,28.408,0,0,0,5,15.585,31.443,31.443,0,0,0,11.771,9.658A43.2,43.2,0,0,0,284.727,443.559Z" transform="translate(-133.628 -55.071)" fill="#fff"/>
      <path id="Pfad_2908" data-name="Pfad 2908" d="M378.048,531.654h-1.323c-.148-.169-.291-.34-.432-.511a28.2,28.2,0,0,1-5.937-12.685,27.52,27.52,0,0,1,2.373-17.128,28.152,28.152,0,0,1,2.565-4.246c.347.074.69.158,1.027.261a26.621,26.621,0,0,0,1.27,33.8C377.739,531.314,377.892,531.485,378.048,531.654Z" transform="translate(-217.099 -142.655)" fill="#fff"/>
      <path id="Pfad_2937" data-name="Pfad 2937" d="M422.194,630.67a.272.272,0,0,1-.285.255H185.785a.257.257,0,1,1,0-.51H421.909A.272.272,0,0,1,422.194,630.67Z" transform="translate(-108.5 -241.925)" fill="#ccc"/>
      <g id="undraw_in_real_life_v8fk" transform="translate(183.545 256.407)">
        <path id="Pfad_2865" data-name="Pfad 2865" d="M302.864,315.668a4,4,0,0,0-.082,5.632l24.576,25.1a3.994,3.994,0,0,0,5.69.055L408.8,269.58a3.994,3.994,0,0,0-.638-6.177l-28.181-18.685a3.97,3.97,0,0,0-4.97.489Z" transform="translate(-301.664 -215.28)" fill="#2d3233"/>
        <path id="Pfad_2866" data-name="Pfad 2866" d="M422.474,284.509l-3.142-2a2.994,2.994,0,0,1-4.308,3.93l-20.332-13.978a2.992,2.992,0,0,1,.791-5.319l-1.619-1.032a1.089,1.089,0,0,0-1.36.153l-68.4,67.216a1.089,1.089,0,0,0,.028,1.558l24.84,24.2a1.089,1.089,0,0,0,1.516-.021L422.657,286.2a1.089,1.089,0,0,0-.183-1.69Z" transform="translate(-319.775 -233.182)" fill="#fff"/>
        <path id="Pfad_2867" data-name="Pfad 2867" d="M130.873,263.282l20.982,19.783,49.908-49.308-25.329-16.486Z" transform="translate(-107.115 -177.829)" fill="#e6e6e6"/>
        <path id="Pfad_2868" data-name="Pfad 2868" d="M811.4,435.387a.744.744,0,0,0,1.027,0l4.364-4.364a.727.727,0,0,0,0-1.027.744.744,0,0,0-1.027,0L811.4,434.36A.727.727,0,0,0,811.4,435.387Z" transform="translate(-718.693 -367.29)" fill="#3f3d56"/>
        <path id="Pfad_2869" data-name="Pfad 2869" d="M770.9,475.855a.727.727,0,0,0,1.027,0l4.364-4.364a.726.726,0,0,0-1.027-1.027l-4.364,4.364a.727.727,0,0,0,0,1.027Z" transform="translate(-685.545 -400.406)" fill="#3f3d56"/>
        <circle id="Ellipse_521" data-name="Ellipse 521" cx="4.5" cy="4.5" r="4.5" transform="translate(16.341 101.532)" :fill="fillPrimary"/>
        <ellipse id="Ellipse_522" data-name="Ellipse 522" cx="13.524" cy="7.352" rx="13.524" ry="7.352" transform="translate(38.402 71.573)" fill="#fff"/>
        <path id="Pfad_2870" data-name="Pfad 2870" d="M436.728,150.055l-.162,1.468-.1.869L436,156.641s-.357.663-.995,1.591a15.946,15.946,0,0,1-5.194,5.023c-3.667,1.921-5.113-11.879-5.113-11.879s-.792-5.351,1.154-5.547,1.277,2.108,1.425,5.9a13.649,13.649,0,0,0,2.691,7,52.628,52.628,0,0,0,3.9-6.274,4.237,4.237,0,0,1,1.947-2.178h0a2.386,2.386,0,0,1,.6-.195A1.418,1.418,0,0,1,436.728,150.055Z" transform="translate(-402.235 -134.869)" fill="#c77f52"/>
        <path id="Pfad_2871" data-name="Pfad 2871" d="M458.492,175.88l5.969,3.47,3.25-5.791-.734-2.612h0a4.489,4.489,0,0,0-5.866.288Z" transform="translate(-430.023 -154.655)" :fill="fillPrimary"/>
        <path id="Pfad_2872" data-name="Pfad 2872" d="M452.424,86.04h0c-3.455,1.428-4.971,5.695-3.386,9.53l2.014,4.872,1.5-.619.121-2.164.529,1.895,9.631-3.981.11-1.967.481,1.723,1.084-.448-1.581-3.825C461.1,86.641,456.4,84.4,452.424,86.04Z" transform="translate(-421.77 -85.501)" fill="#2c3233"/>
        <path id="Pfad_2873" data-name="Pfad 2873" d="M489.448,211.678l.608,4.413,9.556-4.356-1.534-3.219S490.016,209.618,489.448,211.678Z" transform="translate(-455.359 -186.185)" fill="#a0616a"/>
        <path id="Pfad_2874" data-name="Pfad 2874" d="M172.549,235.7l5.981,5.639,1.441-4.854-4.429-3.847Z" transform="translate(-141.226 -190.404)" fill="#c77f52"/>
        <path id="Pfad_2875" data-name="Pfad 2875" d="M273.458,365.224l3.64,7.824,3.81-1.575-3.813-7.752Z" transform="translate(-223.816 -297.694)" fill="#c77f52"/>
        <path id="Pfad_2876" data-name="Pfad 2876" d="M475.775,256.307l4.095,5,14.053,26.637a7.269,7.269,0,0,0,4.085-1.891l-1.229-3.953-8.328-22.109s2.575-3.092,1.226-5.374S486.1,249.4,486.1,249.4Z" transform="translate(-444.168 -219.649)" fill="#2d3233"/>
        <path id="Pfad_2877" data-name="Pfad 2877" d="M565.818,482.151l-1.039.43s-1.656,4.335-2.552,5.111-5.048,5.94-.5,4.669a10.817,10.817,0,0,0,6.385-4.667,4.281,4.281,0,0,0,3.362-1.187c1.619-1.48-.28-3.129-.28-3.129s-2.276-2.768-2.3-2.728S568.469,482.678,565.818,482.151Z" transform="translate(-512.804 -408.917)" fill="#2d3233"/>
        <ellipse id="Ellipse_523" data-name="Ellipse 523" cx="4.31" cy="4.31" rx="4.31" ry="4.31" transform="translate(26.62 5.463) rotate(-22.459)" fill="#c77f52"/>
        <path id="Pfad_2878" data-name="Pfad 2878" d="M490.084,131.663l-1.2,2.285-.161.306.686,2.15-6.755,2.792s.591-1.179,1.105-2.359c.009-.026.02-.055.033-.083.069-.161.13-.307.185-.44.029-.073.057-.141.082-.2.04-.1.08-.2.12-.3a.007.007,0,0,0,0,0,7.325,7.325,0,0,0,.257-.792v0a2.167,2.167,0,0,0,.045-.219c.162-1.081-1.767-2.312-1.767-2.312l3.023-3.48a5.54,5.54,0,0,0,3.921,2.6C489.923,131.646,490.084,131.663,490.084,131.663Z" transform="translate(-449.797 -121.102)" fill="#c77f52"/>
        <path id="Pfad_2879" data-name="Pfad 2879" d="M531.61,165.757c-2.312,1.767-4.633-3.357-4.633-3.357l-6.477-5.626a17.747,17.747,0,0,1-3.494-4.126l-2.933-4.787-.125.4v0l-.353.406-4.5-3.212-1.26-.9-.573-1.386,2.1-2.286,1.039-.43s.021-.013.06-.033c.457-.248,3.482-1.742,4.9,1.229.555,1.166.958,2.155,1.246,2.943a24.743,24.743,0,0,0,3.239,6.126,8.483,8.483,0,0,0,1.309,1.514c1.194.926,7.556,9.449,7.556,9.449S533.922,163.991,531.61,165.757Z" transform="translate(-469.943 -129.966)" fill="#c77f52"/>
        <path id="Pfad_2880" data-name="Pfad 2880" d="M490.772,162.125a4.75,4.75,0,0,1-.377,1.1A9.051,9.051,0,0,0,490.772,162.125Z" transform="translate(-456.134 -148.215)" fill="#d0cde1"/>
        <path id="Pfad_2881" data-name="Pfad 2881" d="M489.308,150.664l-.074.278-.053,5.716s-2.265,4.1-4.833,4.754c-1.46.328-4.2-1.11-4.442.366l-1.018-4.677L478,156.01c-.1-.128-.22-.268-.349-.428-1.236-1.517-.118-4.21-.118-4.21l1.273-3.318h0a2.386,2.386,0,0,1,.6-.195c-.055.132-.116.279-.185.44-.013.028-.023.056-.033.083-.383.951.039,1,.37.92a1.65,1.65,0,0,0,.316-.113c.765-.195,2.084-1.482,3.026-2.492.641-.687,1.108-1.245,1.108-1.245l.939-2.011.141-.3c.264.051.425.068.425.068l.173-.072s.021-.013.059-.033l-.547.911a3.172,3.172,0,0,0-.129,3.027h0Z" transform="translate(-445.23 -132.646)" :fill="fillPrimary"/>
        <path id="Pfad_2882" data-name="Pfad 2882" d="M420.928,220.646l-2.123,2.035s-15.947.365-16.235,5.554,13.36,11.714,13.36,11.714l3.369-3.623-3.88-4.48s13.553,4.334,15.777-2.061-3.281-11.861-3.281-11.861S424.471,216.951,420.928,220.646Z" transform="translate(-384.248 -193.79)" fill="#2d3233"/>
        <path id="Pfad_2883" data-name="Pfad 2883" d="M501.871,331.506l-1.058,1.857s3.677,4.969,3.515,6.05-.2,3.935,1.635,2.974,2.695-4.764,2.438-5.875a40.836,40.836,0,0,1-.333-4.729s.074-5.709-1.557-5.237-4.161,1.382-4.161,1.382Z" transform="translate(-464.662 -282.765)" fill="#2d3233"/>
        <path id="Pfad_2884" data-name="Pfad 2884" d="M457.613,92.687a3.551,3.551,0,0,0-3.135-.251l-.123.051a4.932,4.932,0,0,0-2.309,6.559h0l.794-.328-.275-1.028.591.9,4.707-1.946.053-.988.243.866.926-.383a8.387,8.387,0,0,1,.8,7.207l1.577-.652.106-1.977.485,1.732,3.007-1.243-.978-4.041A6.942,6.942,0,0,0,457.613,92.687Z" transform="translate(-424.39 -90.971)" fill="#2c3233"/>
        <path id="Pfad_2885" data-name="Pfad 2885" d="M528.549,146.134l-9.1,3.762-2.919-7.061,1.2-2.883h0a5.164,5.164,0,0,1,6.661,1.132Z" transform="translate(-477.525 -129.455)" :fill="fillPrimary"/>
      </g>
      <circle id="Ellipse_528" data-name="Ellipse 528" cx="3.361" cy="3.361" r="3.361" transform="translate(133.463 290.586)" :fill="fillSecondary" opacity="0.2"/>
    </g>
  </svg>

</template>

<script>
export default {
  name: 'OffersEmptyStateIllustration',
  props: {
    fillPrimary: {
      type: String,
      default: "var(--v-primary-base)"
    },
    fillSecondary: {
      type: String,
      default: "var(--v-secondary-base)"
    }
  }
}
</script>

<style scoped>

</style>
