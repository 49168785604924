<template>
  <v-list-item class="px-0">
    <v-list-item-content>
      <i18n class="ma-0" path="remove-device-dialog.step-error.force-remove" tag="p">
        <template v-slot:buttonLabel>
          <v-btn text color="primary" class="ma-0 pa-0" @click="startForceRemove">{{ $t('remove-device-dialog.force-remove.button-text') }}</v-btn>
        </template>
      </i18n>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

export default {
  name: "RemoveDeviceForceComponent",
  props: ['device'],

  methods: {
    startForceRemove() {
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 400,
        title: this.$t('remove-device-dialog.force-remove-confirmation.title'),
        text: this.$t('remove-device-dialog.force-remove-confirmation.text'),
        confirmLabel: this.$t('remove-device-dialog.force-remove-confirmation.confirm-button'),
        device: this.device
      })
      this.$root.$on('dialogConfirmed', (data) => {
        this.$root.bisatoast.info({message: this.$t('device-card.force-remove-device.info')})
        this.$rhRequest.sendPost(
          {
            endpoint: 'gateway/force-device-remove',
            data: {
              deviceId: data.device.id
            }
          }
        )
        this.$root.$off('dialogConfirmed')
      })
    }
  }
}
</script>