<template>
  <fullscreen-overlay-frame :title="title"
                            icon="thermostat_auto"
                            :color="color"
                            centered
                            closable
                            @close="close">
    <template v-slot:content>
      <content-card :title="$t('heating-modes-card.title').toString()"
                    icon="thermostat_auto">
        <template v-slot:content>
          <v-list>
            <active-day-overview :weekdays="weekdays"/>
            <v-list-item v-for="(heatingMode, index) in heatingModes" v-bind:key="index" dense @click="openHeatingMode(heatingMode)">
              <v-list-item-icon class="ml-1 my-auto">
                <v-chip class="heating-scene-color-chip mt-1" :color="heatingMode.color"></v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text font-weight-bold">{{ heatingMode.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon class="material-icons-outlined" color="primary">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
      </content-card>

    </template>

  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import ContentCard from "@/templates/components/ContentCard";
import ActiveDayOverview from "@/templates/components/ActiveDayOverview";

export default {
  name: 'HeatingModesOverviewDialog',
  components: {
    ActiveDayOverview, ContentCard, FullscreenOverlayFrame
  },
  props: ['data'],
  data: function () {
    return {
      title: this.$t('heating-modes-overview.title'),
      color: 'primary',
      heatingModes: this.data.heatingModes,
      weekdays: this.data.weekdays
    }
  },
  methods: {
    openHeatingMode(heatingMode) {
      this.close()
      this.$root.bisadialog.toggle('addHeatingMode', true, {heatingMode: heatingMode, gatewayMac: this.data.gatewaymac})
      this.$root.bisadialog.callDialogInit('addHeatingMode')
    },
    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('heatingModesOverview')
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/living/heating-modes-dialog.scss';
</style>
