<template>
  <fullscreen-overlay-frame :title="data?.title"
                            icon="perm_contact_calendar"
                            color="primary"
                            centered
                            closable
                            @close="abort">

    <template v-slot:content>
      <v-list color="transparent">
        <!-- name -->
        <v-list-item v-if="data?.name"
                     class="px-1">
          <v-list-item-icon>
            <v-icon color="primary">person</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold primary--text font-size-05"
                             v-text="data?.name"/>
        </v-list-item>

        <!-- address -->
        <v-list-item v-if="data?.street || data?.city"
                     class="px-1">
          <v-list-item-icon>
            <v-icon color="primary">signpost</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="data?.street"/>
            <v-list-item-subtitle v-text="data?.zip + ' ' + data?.city"/>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="data?.email || data?.phoneNumber"
                    class="my-4"/>

        <!-- email -->
        <v-list-item v-if="data?.name"
                     class="px-1"
                     link
                     :href="'mailto:' + data?.email">
          <v-list-item-icon>
            <v-icon color="primary">email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold primary--text"
                               v-text="data?.email"/>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon color="primary">call_made</v-icon>
          </v-list-item-action>
        </v-list-item>

        <!-- phone -->
        <v-list-item v-if="data?.phoneNumber"
                     class="px-1"
                     link
                     :href="'tel:' + data?.phoneNumber">
          <v-list-item-icon>
            <v-icon color="primary">phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold primary--text"
                               v-text="data?.phoneNumber"/>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon color="primary">call_made</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-divider v-if="data?.description"
                   class="my-4"/>

        <!-- description -->
        <v-list-item v-if="data?.description"
                     class="px-1">
          <v-list-item-icon>
            <v-icon color="primary">info</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
          <v-list-item-title class="info-text"
                             v-text="data?.description"/>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";

export default {
  name: 'ContactDialog',

  components: {
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: () => ({
    //
  }),

  methods: {
    abort() {
      this.$root.bisadialog.toggle('contact')
    }
  }
};
</script>

<style scoped>
.info-text {
  white-space: normal;
}
</style>
