<template>
  <dialog-frame :title="$t('registration-code-valid-dialog.title').toString()"
                icon="check_circle_outline"
                color="success">
    <template v-slot:content>
      {{ $t('registration-code-valid-dialog.text', {email: data?.email}) }}
    </template>

    <template v-slot:actions>
      <v-btn plain block
             color="success"
             class="font-weight-bold d-block"
             @click="close">
        {{ $t('app.continue') }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>

import DialogFrame from "@/templates/dialogs/DialogFrame";
export default {
  name: 'RegistrationCodeValidDialog',

  components: {
    DialogFrame
  },

  props: ['data'],

  methods: {
    close() {
      this.$root.bisadialog.toggle('registrationCodeValid', false)
      this.$router.push({
        name: 'registrationUser',
        params: {email: this.data.email, invitationCode: this.data.invitationCode}
      })
    }
  }

};
</script>

