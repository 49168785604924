import moment from "moment/moment";

export default {
  
  plugins: [{
    id: 'noData',
    /* eslint-disable */
    afterDatasetsDraw: function(chart, args, options) {
      let ctx = chart.ctx
      if(chart.data.labels.length < 2) {
        ctx.save()
        ctx.fillStyle = 'transparent'
        ctx.textAlign = 'center'
        // hide y grid and labels on y axis
        chart.options.scales.y.grid.display = false
        chart.options.scales.y.ticks.color = 'transparent'
        // hide x axis grid
        chart.options.scales.x.grid.display = false
        chart.update()
        ctx.textBaseline = 'middle'
        ctx.fillRect(chart.chartArea.left, chart.chartArea.top, chart.chartArea.width, chart.chartArea.height)
        ctx.font = "20px \"Roboto\",Helvetica, Arial sans-serif"
        ctx.fillStyle = options.fontColor
        
        // get graph center
        let x = chart.chartArea.left + chart.chartArea.width / 2
        let y = chart.chartArea.top + chart.chartArea.height / 2
        let lineHeight = 24
        
        let words = options.text.split(' ')
        let lines = []
        let line = ''
        
        // make empty state text multiline
        // text automatically wraps if texts overflows available graph area
        for(var n = 0; n < words.length; n++) {
          let testLine = line + words[n] + ' '
          let metrics = ctx.measureText(testLine)
          let testWidth = metrics.width
          // text overflows area and therefore has to be multiline
          if (testWidth > chart.chartArea.width - 20 && n > 0) {
            lines.push(line)
            line = words[n] + ' '
          }
          else {
            line = testLine
          }
        }
        
        // push last remaining line in lines to display
        lines.push(line)
        
        // calculate y position depending on how many lines text is displayed
        let numberOfLines = lines.length
        y -= (numberOfLines - 1) * (lineHeight / 2)
        
        // set text on empty graph area
        lines.forEach(l => {
          ctx.fillText(l, x, y)
          y += lineHeight
        })
      }
    }
  }],
  
  options: {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0
      },
      line: {
        borderWidth: 2,
        borderCapStyle: 'square',
        borderJoinStyle: 'round'
      }
    },
    type: 'line',
    scales: {
      x: {
        title: {
          display: true
        },
        type: 'time',
        min: moment().hour(0).startOf('hour'),
        max: moment().hour(24).startOf('hour'), // Should calculate this dynamic, not best way but can use: new Date().setHours(23,59,59)
        time: {
          displayFormats: {hour: 'H'},
          unit: 'hour'
        },
        ticks: {
          minRotation: 0,
          maxRotation: 0,
        }
      },
      y: {
        ticks: {
          precision: 0,
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }
}