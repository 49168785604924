<template>
  <dialog-frame :title="$t('delete-enode-connection-dialog.title').toString()"
                icon="hub"
                color="primary"
                closable
                @close="abort">
    <template v-slot:content>
      {{ $t('delete-enode-connection-dialog.text', {manufacturer: data.brand}) }}
    </template>

    <template v-slot:actions>
      <v-btn plain block
             color="primary"
             class="font-weight-bold d-block"
             @click="deleteConnection">
        {{ $t('delete-enode-connection-dialog.button') }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>

import DialogFrame from "@/templates/dialogs/DialogFrame";
export default {
  name: 'DeleteEnodeConnection',

  props: ['data'],

  components: {
    DialogFrame
  },

  methods: {
    deleteConnection() {
      this.$root.$emit('connectionDelete')
      this.$root.bisadialog.toggle('deleteEnodeConnection')
    },
    abort() {
      this.$root.bisadialog.toggle('deleteEnodeConnection')
    }
  },

};
</script>

