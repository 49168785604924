<template>
    <fullscreen-overlay-frame :title="$t('app.change-darkmode')"
                              icon="dark_mode"
                              color="primary"
                              centered
                              closable
                              @close="$root.bisadialog.toggle('darkMode')">
        <template v-slot:content>
            <v-form v-model="valid" ref="form">
                <v-radio-group v-model="darkmode">
                    <v-radio class="mt-2" value="dark" :label="$t('darkmode.dark')" @click="change"></v-radio>
                    <v-radio class="mt-2" value="light" :label="$t('darkmode.light')" @click="change"></v-radio>
                    <v-radio class="mt-2" value="system" :label="$t('darkmode.system')" @click="change"></v-radio>
                </v-radio-group>
            </v-form>
        </template>
        <template v-slot:actions>
            <v-btn large depressed
                   color="primary"
                   class="font-weight-bold action-button"
                   @click="$root.bisadialog.toggle('darkMode')">
                {{ $t('app.close') }}
            </v-btn>
        </template>
    </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";

export default {
  name: "DarkMode",

  components: {
    FullscreenOverlayFrame
  },

  data: () => ({
    darkmode: null
  }),
  methods: {
    init() {
        this.darkmode = this.$darkmode.get();
    },
    change() {
        this.$darkmode.set(this.darkmode);
    }
  }
}
</script>

<style scoped>

</style>
