<template>
  <content-card :title="$t('feedback-card.title').toString()"
                icon="feedback">
    <template v-slot:content>
      <div class="pa-5"
           v-html="$t('first-launch-dialog.text')"/>
      <div class="px-5 pb-5">
        <v-btn plain block
               color="primary"
               class="font-weight-bold"
               to="support">
          {{ $t('feedback-card.button') }}
        </v-btn>
      </div>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";

export default {
  name: 'FeedbackCard',

  components: {
    ContentCard
  }
}
</script>
