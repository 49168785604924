<template>
  <v-card>
    <v-skeleton-loader v-if="loading"
                       type="list-item-avatar"/>
    <v-list v-else class="py-0">
      <!-- list of devices for which permission can be granted -->
      <div v-for="(device, deviceIndex) in passedDevices" v-bind:key="device.device_key">
        <v-list-item :class="device.selected ? '' : 'list-item straight'">
          <v-list-item-icon>
            <v-icon color="primary">{{ getIcon(device.type) }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="device.name"/>
            <v-list-item-subtitle v-text="device.type"/>
          </v-list-item-content>
          <v-list-item-action class="align-self-start">
            <v-checkbox v-model="device.selected"
                        @change="update"/>
          </v-list-item-action>
        </v-list-item>

          <v-card class="px-5 list-item" flat v-if="device.selected">
            <v-checkbox :label="$t('device-permission.restrict-permission.label')" v-model="device.hasDayRestriction"/>
            <div v-if="device.hasDayRestriction">
              <div class="d-flex justify-space-between">
                <v-chip class="mx-1 white--text weekday-chip font-size-02 font-weight-bold"
                        v-for="(day, key) in device.weekdays"
                        v-bind:key="device.device_key.toString() + key"
                        :color="device.weekdays[key] ? 'primary' : 'blue-grey lighten-4'"
                        @click="activateDay(key, deviceIndex)">
                  {{ $t(weekdayTranslation[key]) }}
                </v-chip>
              </div>
              <v-checkbox :label="$t('device-permission.restrict-period.label')" v-model="device.hasTimeRestriction"/>
              <div v-if="device.hasTimeRestriction" class="d-flex">
                <v-row class="mt-4">
                  <v-col cols="12" sm="6">
                    <v-menu
                        v-model="menuFrom[deviceIndex]"
                        :ref="'menufrom' + deviceIndex"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="device.restrictionTimeFrom"
                            :label="$t('device-permission.restrict-period.start')"
                            prepend-inner-icon="schedule"
                            readonly
                            clearable
                            outlined
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          :max="device.restrictionTimeTo"
                          v-model="device.restrictionTimeFrom"
                          format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text
                               color="primary"
                               @click="closeMenu('from', deviceIndex); device.restrictionTimeFrom = null">
                          {{ $t('app.cancel') }}
                        </v-btn>
                        <v-btn text
                               color="primary"
                               class="font-weight-bold"
                               @click="closeMenu('from', deviceIndex)">
                          {{ $t('app.save') }}
                        </v-btn>

                      </v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu v-model="menuTo[deviceIndex]"
                            :ref="'menuto' + deviceIndex"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="device.restrictionTimeTo"
                            prepend-inner-icon="schedule"
                            readonly
                            clearable
                            outlined
                            :label="$t('device-permission.restrict-period.end')"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          :min="device.restrictionTimeFrom"
                          v-model="device.restrictionTimeTo"
                          format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text
                               color="primary"
                               @click="closeMenu('to', deviceIndex); device.restrictionTimeTo = null">
                          {{ $t('app.cancel') }}
                        </v-btn>
                        <v-btn text
                               color="primary"
                               class="font-weight-bold"
                               @click="closeMenu('to', deviceIndex)">
                          {{ $t('app.save') }}
                        </v-btn>
                      </v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

          </v-card>
      </div>

      <!-- charging station (1:1 relation to site) -->
      <v-list-item v-if="compleoConnections?.length > 0">
        <v-list-item-icon>
          <v-icon color="primary">ev_station</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-html="$t('invite-user-dialog.charging-station-access')"/>
        </v-list-item-content>
        <v-list-item-action class="align-self-start">
          <v-checkbox v-model="selection.compleoConnection"
                      @change="update"/>
        </v-list-item-action>
      </v-list-item>

          <v-card class="px-5" flat v-if="selection.compleoConnection">
            <v-checkbox :label="$t('device-permission.restrict-permission.label')" v-model="passedCompleoConnection.hasDayRestriction"/>
            <div v-if="passedCompleoConnection.hasDayRestriction">
              <div class="d-flex justify-space-between">
                <v-chip class="mx-1 white--text font-size-02 weekday-chip font-weight-bold"
                        v-for="(day, key) in passedCompleoConnection.weekdays"
                        v-bind:key="key"
                        :color="passedCompleoConnection.weekdays[key] ? 'primary' : 'blue-grey lighten-4'"
                        @click="activateCompleoDay(key)">
                  {{ $t(weekdayTranslation[key]) }}
                </v-chip>
              </div>
              <v-checkbox :label="$t('device-permission.restrict-period.label')" v-model="passedCompleoConnection.hasTimeRestriction"/>
              <div v-if="passedCompleoConnection.hasTimeRestriction" class="d-flex">
                <v-row class="mt-4">
                  <v-col cols="12" sm="6">
                    <v-menu
                        v-model="menuFrom"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="passedCompleoConnection.restrictionTimeFrom"
                            :label="$t('device-permission.restrict-period.start')"
                            prepend-inner-icon="schedule"
                            readonly
                            clearable
                            outlined
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          :max="passedCompleoConnection.restrictionTimeTo"
                          v-model="passedCompleoConnection.restrictionTimeFrom"
                          format="24hr"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                        v-model="menuTo"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="passedCompleoConnection.restrictionTimeTo"
                            prepend-inner-icon="schedule"
                            readonly
                            clearable
                            outlined
                            :label="$t('device-permission.restrict-period.end')"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          :min="passedCompleoConnection.restrictionTimeFrom"
                          v-model="passedCompleoConnection.restrictionTimeTo"
                          format="24hr"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card>

      <!-- empty state -->
      <v-list-item v-else-if="devices.length === 0"
                   v-html="$t('invite-user-dialog.devices.empty-state')"/>
    </v-list>
  </v-card>
</template>

<script>

export default {
  name: 'DevicePermissionsCard',
  components: {},

  props: {
    value: Object, // for v-model
    loading: Boolean,
    devices: Array,
    compleoConnections: Array,
  },

  data: () => ({
    selection: null,
    menuFrom: [],
    menuTo: [],
    weekdayTranslation: [
      'heating-mode-dialog.weekdays.monday-shortcut',
      'heating-mode-dialog.weekdays.tuesday-shortcut',
      'heating-mode-dialog.weekdays.wednesday-shortcut',
      'heating-mode-dialog.weekdays.thursday-shortcut',
      'heating-mode-dialog.weekdays.friday-shortcut',
      'heating-mode-dialog.weekdays.saturday-shortcut',
      'heating-mode-dialog.weekdays.sunday-shortcut'
    ]
  }),

  methods: {
    /**
     * returns a matching icon for a device type
     *
     * @param type
     * @returns {string}
     */
    getIcon(type) {
      switch (type) {
        case 'Nuki Lock':
          return 'lock'
        case 'Nuki Box':
          return 'business'
        case 'Nuki Opener':
          return 'sensor_door'
        case 'On/Off-D':
          return 'sensor_door'
        case 'Wilka Lock':
          return 'door_front'
        default:
          return 'widgets'
      }
    },

    /**
     * updates v-model
     */
    update() {
      this.selection.devices = []
      let filteredDevices = this.passedDevices.filter(device => device.selected === true)
      filteredDevices.forEach(item => this.selection.devices.push(item))
      if(this.selection.compleoConnection) {
        this.selection.compleoRestriction = this.passedCompleoConnection
      }
      this.$emit('input', this.selection)
    },

    activateDay(key, deviceIndex) {
      this.passedDevices[deviceIndex].weekdays[key] = !this.passedDevices[deviceIndex].weekdays[key]
      this.$forceUpdate()
    },

    activateCompleoDay(key) {
      this.passedCompleoConnection.weekdays[key] = !this.passedCompleoConnection.weekdays[key]
      this.$forceUpdate()
    },
    /** closes menu on button click
     * @type string either from or to, depending on which menu should be closed
     */
    closeMenu (menuType, deviceIndex) {
      this.$refs['menu' + menuType + deviceIndex][0].save()
    }
  },

  mounted() {
    this.selection = this.value
  },
  computed: {
    passedDevices() {
      return this.devices
    },
    // because compleo to user relation is 1:1, use first element of from parent component
    // passed element.
    passedCompleoConnection () {
      if (this.compleoConnections && this.compleoConnections.length > 0) {
        return this.compleoConnections[0]
      } else {
        return []
      }
    },
  },

  watch: {
    value: function () {
      this.selection = this.value
    },
    passedDevices: function () {
      this.selection.devices = this.passedDevices.filter((item) => item.selected === true)
    },
    passedCompleoConnection: {
      handler(newValue) {
        this.selection.compleoRestriction = newValue
      },
      deep: true
    },
    'passedCompleoConnection.weekdays': {
      handler(newValue) {
        this.selection.compleoRestriction.weekdays = newValue
      },
      deep: true
    }
  }
}
</script>
<style lang="scss">
 .weekday-chip {
   padding: 3%;
 }
</style>
