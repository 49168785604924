<template>
  <div class="page-content">
    <v-speed-dial v-if="hasEnode"
                  class="pulse"
                  :right="true"
                  :bottom="true">
      <template v-slot:activator>
        <v-btn class="speed-dial-button"
               fab fixed bottom right
               color="primary"
               @click="openAddDialog">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>

    <div>
      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <real-time-energy-prices></real-time-energy-prices>
        </v-col>
      </v-row>
    </div>
    <v-skeleton-loader v-if="loading"
                       class="mt-3"
                       type="article"
                       height="300"/>
    <div v-else-if="!loading && (enodeConnections.length > 0)">
      <v-row v-if="enodeConnections.length > 0">
        <v-col cols="12" sm="6" lg="4" v-for="(enodeConnection, index) in enodeConnections"
               v-bind:key="index">
          <enode-connection-card @reloadConnectionDetails="getEnodeConnections(false)" :connectionDetails="enodeConnection"/>
        </v-col>
      </v-row>
    </div>

    <!-- Empty State -->
    <div v-else>
      <empty-state
        :empty-state-title="$t('emobility-page.emptyState.title').toString()"
        :empty-state-text="$t('emobility-page.emptyState.text').toString()">
        <template v-slot:illustration>
          <emobility-empty-state-illustration
            :fill-primary="'var(--v-primary-base)'"
            :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>
    </div>

  </div>
</template>

<script>
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import EmobilityEmptyStateIllustration from "@/templates/components/emptyStates/svg/EmobilityEmptyStateIllustration.vue";
import config from '@/config/config.app.json'
import requestHelper from "@/scripts/requestHelper";
import EnodeConnectionCard from "@/templates/components/emobility/EnodeConnectionCard.vue";
import RealTimeEnergyPrices from "@/templates/components/RealTimeEnergyPrices.vue";

export default {
  name: "HomeEnergyPage",

  components: {
    EnodeConnectionCard,
    EmptyState,
    EmobilityEmptyStateIllustration,
    RealTimeEnergyPrices
  },

  data() {
    return {
      refreshRate: config.updateInterval,
      loading: false,
      timer: null,
      enodeConnections: []
    }
  },

  methods: {
    openAddDialog() {
      this.$root.bisadialog.toggle('addEmobilityConnection', true)
    },
    /**
     * calls all get connection requests
     * @param showLoader
     */
    getVehicles(showLoader) {
      this.getEnodeConnections(showLoader)
    },
    /**
     * get all enodeConnections
     */
    getEnodeConnections (showLoader) {
      if (showLoader) {
        this.loading = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'enode/get-vehicles',
      }, (resp) => {
        if (resp?.data?.data) {
          this.enodeConnections = resp.data.data
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },

    /**
     * continuously refresh data
     */
    updateInterval() {
      this.timer = setInterval(() => {
        this.getVehicles(false)
      }, this.refreshRate)
    },
    checkForRedirect() {
      if (this.$route.path === '/enodeRedirect') {
        setTimeout(() => {
          if (Object.keys(this.$route.query).length === 0) {
            this.$root.bisatoast.success({
              message: this.$t("enode.toast.success"),
              showCloseBtn: true
            })
          } else if (Object.keys(this.$route.query).length > 0) {
            this.$root.bisatoast.error({
              message: this.$t('app.generic-error'),
              showCloseBtn: true
            })
          }
          this.$router.push('emobility')
        }, 500);
      }
    }
  },
  computed: {
    hasEnode() {
      return Object.hasOwn(config, 'enodeRedirect')
    }
  },
  mounted() {
    this.loading = true
    this.checkForRedirect()
    requestHelper.startDelayedRequest(
      () => this.getVehicles(true),
      () => this.updateInterval()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
