<template>
  <v-card height="240">
    <LineChart class="" :id="'device-history-chart-' + event.name" :style="style" :data="data" :options="deviceEventHistoryChartOptions.options" />
  </v-card>

</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement
} from 'chart.js'
import { Line as LineChart} from 'vue-chartjs'
import 'chartjs-adapter-moment';
import moment from "moment";
import deviceEventHistoryChartOptions from "@/scripts/deviceEventHistoryChartOptions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  deviceEventHistoryChartOptions.plugins
)

export default {
  components: {
    LineChart
  },

  props: {
    event: Object,
    events: Array,
    date: Number
  },

  name: "DeviceEventHistoryChart",
  data: () => ({
    deviceEventHistoryChartOptions: deviceEventHistoryChartOptions,
    data: {
      labels: [],
      datasets: [
        {
          data: []
        }
      ]
    },
    style: {}
  }),

  mounted() {
    let currentDay = moment.unix(this.date).isSame(moment(),"day")
    let firstDay = moment.unix(this.date).isSame(moment().subtract(1, 'week'), "day")
    this.events.forEach((item) => {
      let mom = moment.unix(item.dateTime)
      this.data.labels.push(moment().hour(mom.hour()).minute(mom.minute()))
      this.data.datasets[0].data.push(item.value)
    })

    if(!firstDay) {
      this.data.labels[0] = moment().hour(0).minute(0)
    }

    if(!currentDay) {
      this.data.labels[this.data.labels.length - 1] = moment().hour(23).minute(59).second(59)
    }

    let chart = ChartJS.getChart('device-history-chart-' + this.event.name)
    if (chart !== undefined) {
      let max = Math.max(...this.data.datasets[0].data)
      if(max < 1) {
        chart.options.scales.y.ticks.precision = 3
      }

      chart.options.scales.x.title.text = this.$t('device-event-history.chart.y-axis.label')
      chart.canvas.parentNode.style.height = '100%';
      chart.canvas.parentNode.style.width = '100%';
      chart.data.datasets[0].borderColor = this.$vuetify.theme.currentTheme.primary
      chart.options.scales.y.suggestedMax = max * 1.05

      if(this.data.labels.length < 2) {
        chart.options.plugins['noData'].text = this.$t('device-event-history.chart.no-data').toString()
        chart.options.plugins['noData'].fontColor = this.$vuetify.theme.currentTheme.primary
      }
      chart.update()
    }
  }
}
</script>

<style lang="scss">
 canvas {
   width: 100%;
 }
</style>

