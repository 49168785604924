<template>

  <div class="mode-switch-content">
    <!-- on/off switch -->
    <div class="py-4 px-5">
      <div class="d-flex justify-space-between">
        <span class="mt-1" :class="irControllerActive ? '' : 'font-weight-bold primary--text'">{{ $t('heating-mode-dialog.inactive') }}</span>
        <v-switch class="mt-0" :disabled="irController.online === false"
                  @change="$emit('set-controller-mode', irControllerActive ? setControllerMode(ControllerModes.RESUME) : setControllerMode(ControllerModes.OFF))"
                  v-model="irControllerActive"
                  inset hide-details/>
        <span class="mt-1" :class="irControllerActive ? 'primary--text font-weight-bold' : ''">{{ $t('heating-mode-dialog.active') }}</span>
      </div>
    </div>
    <v-divider/>
    <!-- mode buttons -->
    <div class="mode-buttons px-10 py-4 d-flex justify-space-between">
      <v-btn icon
             :disabled="!irControllerActive"
             :class="(irControllerActive && irController.mode === ControllerModes.HEAT ? 'error--text' : '')"
             @click="setControllerMode(ControllerModes.HEAT)">
        <span class="font-size-10 material-symbols-outlined">mode_heat</span>
        <span class="font-size-02 pt-1">{{ $t('ir-controller-card.mode-label.heat') }}</span>
      </v-btn>
      <v-btn icon
             :disabled="!irControllerActive"
             :class="(irControllerActive && irController.mode === ControllerModes.COOL ? 'info--text' : '')"
             @click="setControllerMode(ControllerModes.COOL)">
        <span class="font-size-10 material-symbols-outlined">mode_cool</span>
        <span class="font-size-02 pt-1">{{ $t('ir-controller-card.mode-label.cool') }}</span>
      </v-btn>
      <v-btn icon
             :disabled="!irControllerActive"
             :class="(irControllerActive && irController.mode === ControllerModes.DRY_AIR ? 'warning--text' : '')"
             @click="setControllerMode(ControllerModes.DRY_AIR)">
        <span class="font-size-10 material-symbols-outlined">cool_to_dry</span>
        <span class="font-size-02 pt-1">{{ $t('ir-controller-card.mode-label.dry-air') }}</span>
      </v-btn>
      <v-btn icon
             :disabled="!irControllerActive"
             :class="(irControllerActive && irController.mode === ControllerModes.FAN_ONLY ? 'success--text' : '')"
             @click="setControllerMode(ControllerModes.FAN_ONLY)">
        <span class="font-size-10 material-symbols-outlined">mode_fan</span>
        <span class="font-size-02 pt-1">{{ $t('ir-controller-card.mode-label.fan-only') }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>



export default {
  name: "ModeSwitch",

  props: ['irController', 'ControllerModes', 'loading'],

  data: function () {
    return {
      irControllerActive: false,
    }
  },

  methods: {
    setControllerMode(mode) {
      this.$emit('set-controller-mode', this.irController, mode)
    }
  },

  mounted() {
    this.irControllerActive = this.irController.mode !== this.ControllerModes.OFF
  },

  watch: {
    irController: {
      handler(newIrController, oldIrController) {
        if(oldIrController?.mode !== newIrController?.mode) {
          this.irControllerActive = newIrController.mode !== this.ControllerModes.OFF
        }
      },
      deep: true
    }
  }
}
</script>
