<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="241.622" height="112.429" viewBox="0 0 241.622 112.429">
    <g id="Gruppe_1650" data-name="Gruppe 1650" transform="translate(-74 -276.238)">
      <path id="Pfad_1209" data-name="Pfad 1209" d="M410.3,770.346a.332.332,0,0,1-.333.333H169.015a.333.333,0,0,1,0-.667H409.971a.332.332,0,0,1,.333.333Z" transform="translate(-94.682 -382.013)" fill="#ccc"/>
      <circle id="Ellipse_490" data-name="Ellipse 490" cx="3.361" cy="3.361" r="3.361" transform="translate(271.346 351.083)" :fill="fillSecondary" opacity="0.2"/>
      <ellipse id="Ellipse_495" data-name="Ellipse 495" cx="10.79" cy="10.639" rx="10.79" ry="10.639" transform="translate(251.292 360.609)" :fill="fillSecondary" opacity="0.2"/>
      <circle id="Ellipse_507" data-name="Ellipse 507" cx="6.722" cy="6.722" r="6.722" transform="translate(153.474 309.805)" :fill="fillSecondary" opacity="0.2"/>
      <circle id="Ellipse_494" data-name="Ellipse 494" cx="13.332" cy="13.332" r="13.332" transform="translate(123.592 330.336)" :fill="fillSecondary" opacity="0.2"/>
      <g id="Gruppe_1645" data-name="Gruppe 1645" transform="translate(0.071)">
        <path id="Pfad_1195" data-name="Pfad 1195" d="M680.126,394.68a7,7,0,0,0,4.594,2.211c.174.013.145.282-.029.269a7.276,7.276,0,0,1-4.776-2.31C679.8,394.721,680.008,394.552,680.126,394.68Z" transform="translate(-463.715 -91.128)" fill="#fff"/>
        <path id="Pfad_1021" data-name="Pfad 1021" d="M292.069,129.629a68.434,68.434,0,0,1-11.024,15.8c-.1.111-.2.221-.3.325l-10.832-5.121.221-.369c4.649-7.962,32.726-56.508,31.84-67.5C302.067,73.675,305.4,103.656,292.069,129.629Z" transform="translate(-65.444 237.417)" :fill="fillSecondary"/>
        <path id="Pfad_1022" data-name="Pfad 1022" d="M284.782,160.927l-.465.251-8.117-3.844.4-.28c2.487-1.786,9.888-7.15,17.127-12.913,7.785-6.191,15.385-12.839,16.507-16.02C310,128.851,302.875,150.987,284.782,160.927Z" transform="translate(-67.093 222.907)" :fill="fillSecondary"/>
        <path id="bc7f4772-a479-43ba-84b6-0038831d0625" d="M273.192,51.274h-.627V34.329a9.862,9.862,0,0,0-9.939-9.789H226.262a10,10,0,0,0-7.025,2.865,9.711,9.711,0,0,0-2.907,6.924h0v92.843a9.861,9.861,0,0,0,9.932,9.8h36.363a9.869,9.869,0,0,0,9.939-9.8h0V63.318h.627Z" transform="translate(-51.401 251.698)" fill="#2e3233"/>
        <path id="e7549b14-e146-4c1f-8b4f-7638ef62aaf0" d="M263.993,28.08h-4.752a3.456,3.456,0,0,1-1.926,4.528,3.664,3.664,0,0,1-1.336.254H235.149a3.542,3.542,0,0,1-2.927-1.534,3.429,3.429,0,0,1-.342-3.248h-4.427a7.475,7.475,0,0,0-5.268,2.126A7.249,7.249,0,0,0,220,35.384h0V128a7.361,7.361,0,0,0,7.416,7.3h36.548a7.361,7.361,0,0,0,7.416-7.3h0V35.384a7.361,7.361,0,0,0-7.416-7.3Z" transform="translate(-52.363 250.771)" fill="#fff"/>
        <ellipse id="f4a12a6a-3235-4aa9-87a4-fdfa38e5097e" cx="4.073" cy="4.073" rx="4.073" ry="4.073" transform="translate(189.346 372.677)" fill="#edf1f2"/>
        <path id="Pfad_1031" data-name="Pfad 1031" d="M232.293,111.92a.8.8,0,0,0,0,1.594h32.932a.8.8,0,0,0,0-1.594Z" transform="translate(-55.402 227.857)" fill="#edf1f2"/>
        <path id="Pfad_1032" data-name="Pfad 1032" d="M232.293,137.4a.8.8,0,0,0,0,1.594h32.932a.8.8,0,0,0,0-1.594Z" transform="translate(-55.402 220.906)" fill="#edf1f2"/>
        <path id="Pfad_1033" data-name="Pfad 1033" d="M232.293,118.4a.8.8,0,0,0,0,1.594H246.46a.8.8,0,0,0,0-1.594Z" transform="translate(-55.402 226.09)" fill="#edf1f2"/>
        <path id="Pfad_1034" data-name="Pfad 1034" d="M232.293,124.58a.8.8,0,0,0,0,1.594h32.932a.8.8,0,0,0,0-1.594Z" transform="translate(-55.402 224.403)" fill="#edf1f2"/>
        <path id="Pfad_1035" data-name="Pfad 1035" d="M232.293,131.06a.8.8,0,0,0,0,1.594H246.46a.8.8,0,0,0,0-1.594Z" transform="translate(-55.402 222.636)" fill="#edf1f2"/>
        <path id="Pfad_1037" data-name="Pfad 1037" d="M258.422,87.587a.583.583,0,0,0,0-1.159H246.66a.583.583,0,1,0,0,1.158Z" transform="translate(-59.18 234.816)" fill="#fff"/>
        <path id="Pfad_1038" data-name="Pfad 1038" d="M258.422,82.957a.583.583,0,0,0,0-1.158H246.66a.583.583,0,1,0,0,1.158Z" transform="translate(-59.18 236.079)" fill="#fff"/>
        <path id="Pfad_1039" data-name="Pfad 1039" d="M253.549,68.69a3.653,3.653,0,1,1-3.689,3.645h0A3.689,3.689,0,0,1,253.549,68.69Z" transform="translate(-60.189 239.627)" fill="#fff"/>
        <path id="Pfad_2464" data-name="Pfad 2464" d="M14.711,0A14.711,14.711,0,1,1,0,14.711,14.711,14.711,0,0,1,14.711,0Z" transform="translate(178.449 301.76)" :fill="fillPrimary"/>
        <path id="Pfad_2441" data-name="Pfad 2441" d="M212.252,699.586H200.467a1.966,1.966,0,0,0-1.964,1.964v8.418a1.966,1.966,0,0,0,1.964,1.964h11.785a1.966,1.966,0,0,0,1.964-1.964V701.55A1.966,1.966,0,0,0,212.252,699.586Zm0,1.122a.84.84,0,0,1,.208.027l-6.1,4.866-6.064-4.876a.842.842,0,0,1,.17-.017Zm0,10.1H200.467a.843.843,0,0,1-.842-.842v-8.342l6.383,5.132a.561.561,0,0,0,.7,0l6.384-5.093v8.3A.843.843,0,0,1,212.252,710.81Z" transform="translate(-13.2 -389.288)" fill="#fff"/>
      </g>
      <g id="Gruppe_1646" data-name="Gruppe 1646" transform="translate(3)">
        <path id="Pfad_2400" data-name="Pfad 2400" d="M352.947,586.891a9.083,9.083,0,0,0,3.259-7.83,7.343,7.343,0,0,0-5.459-6.234,6.18,6.18,0,0,0-7.038,3.911c-.481-5.424-1.036-11.075-3.92-15.694a16.621,16.621,0,0,0-12.035-7.71,14.913,14.913,0,0,0-13.119,5.355,13.534,13.534,0,0,0-1.664,13.934c1.577,3.272,4.483,5.707,7.527,7.688a48.58,48.58,0,0,0,33.226,7.269" transform="translate(-186.75 -200.144)" :fill="fillSecondary"/>
        <path id="Pfad_2401" data-name="Pfad 2401" d="M341.026,559.827a80.16,80.16,0,0,1,23.553,31.848c.225.55-.669.79-.891.246a79.335,79.335,0,0,0-23.315-31.44C339.911,560.105,340.568,559.455,341.026,559.827Z" transform="translate(-208.294 -205.037)" fill="#fff"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: 'SupportEmptyStateIllustration',
  props: {
    fillPrimary: {
      type: String,
      default: "var(--v-primary-base)"
    },
    fillSecondary: {
      type: String,
      default: "var(--v-secondary-base)"
    }
  }
}
</script>

<style scoped>

</style>
