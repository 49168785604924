<template>
  <v-form v-model="valid" ref="form" class="pt-1">
    <v-text-field outlined
                  tabindex="1"
                  v-model="password"
                  :rules="passwordRule"
                  :type="passwordVisible ? 'text' : 'password'"
                  required
                  :label="$t('registration-password.password.label')">
      <template v-slot:append>
        <v-icon v-if="!passwordVisible" @click="passwordVisible = !passwordVisible">visibility</v-icon>
        <v-icon v-else @click="passwordVisible = !passwordVisible">visibility_off</v-icon>
      </template>
    </v-text-field>

    <v-text-field outlined
                  tabindex="2"
                  v-model="confirmPassword"
                  :rules="confirmPasswordRule.concat(passwordRule)"
                  :type="confirmPasswordVisible ? 'text' : 'password'"
                  required
                  :label="$t('registration-password.password-confirm.label')">
      <template v-slot:append>
        <v-icon v-if="!confirmPasswordVisible" @click="confirmPasswordVisible = !confirmPasswordVisible">visibility</v-icon>
        <v-icon v-else @click="confirmPasswordVisible = !confirmPasswordVisible">visibility_off</v-icon>
      </template>
    </v-text-field>
    <div class="text-center mb-8 font-size-02" v-html="$t('registration-password.password-rules.explenation')"></div>
    <v-btn depressed x-large block
           class="mt-2"
           color="primary"
           @click="nextStep"
           v-html="$t('app.continue')"/>
    <v-btn depressed block
           class="mt-4"
           color="primary"
           text
           @click="stepBack"
           v-html="$t('app.step-back')"/>
  </v-form>
</template>

<script>

export default {
  name: 'RegistrationPassword',

  components: {
  },

  data: () => ({
    valid: false,
    password: '',
    passwordVisible: false,
    passwordMinLength: 8,
    confirmPassword: '',
    confirmPasswordVisible: false,
  }),

  methods: {
    nextStep() {
      if (this.$refs.form.validate()) {
        this.$emit('nextStep', [{'password': this.password}])
      }
    },
    stepBack() {
      this.$emit('stepBack')
    }
  },
  computed: {
    passwordRule() {
      return [
        v => !!v || this.$t('app.rules.required'),
        v => (v && v.length >= this.passwordMinLength) || this.$t('app.rules.password.lengthMessage', {passwordMinLength: this.passwordMinLength}),
        v => /[A-Z]/.test(v) || this.$t('app.rules.password.capitalLetter'),
        v => /[a-z]/.test(v) || this.$t('app.rules.password.smallLetter'),
        v => /[0-9]/.test(v) || this.$t('app.rules.password.number')
      ]
    },
    confirmPasswordRule () {
      return [
        this.confirmPassword === this.password || this.$t('app.rules.password-not-same')
      ]
    }
  }
};
</script>
