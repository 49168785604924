<template>
  <dialog-frame :title="$t('gateway-offline-help-dialog.title').toString()"
                icon="help"
                color="primary"
                closable
                @close="abort">
    <template v-slot:content>
      <ul :class="$vuetify.rtl ? 'text-right' : 'text-left'"
          v-html="$t('gateway-offline-help-dialog.text')"/>
    </template>

    <template v-slot:actions>
      <v-btn plain block
             color="primary"
             class="font-weight-bold d-block"
             @click="abort">
        {{ $t('app.close') }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>

import DialogFrame from "@/templates/dialogs/DialogFrame";

export default {
  name: 'GatewayOfflineHelpDialog',

  components: {
    DialogFrame
  },

  methods: {
    abort() {
      this.$root.bisadialog.toggle('gatewayOfflineHelp')
    }
  }
};
</script>

