<template>
  <dialog-frame :title="$t('app.clear-cache').toString()"
                icon="cleaning_services"
                color="primary"
                closable
                @close="abort">
    <template v-slot:content>
      {{ $t('clear-cache-dialog.text') }}
    </template>

    <template v-slot:actions>
      <v-btn plain block
             color="primary"
             class="font-weight-bold d-block"
             @click="clearCache">
        {{ $t('clear-cache-dialog.delete') }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>

import DialogFrame from "@/templates/dialogs/DialogFrame";
export default {
  name: 'ClearCacheDialog',

  components: {
    DialogFrame
  },

  methods: {
    clearCache() {
      localStorage.clear()
      this.$root.bisadialog.toggle('clearCache')
      this.$router.push({name: 'logout'})
    },
    abort() {
      this.$root.bisadialog.toggle('clearCache')
    }
  },

};
</script>

