<template>

  <v-skeleton-loader v-if="loading"
                     type="article"
                     height="300"/>
  <content-card v-else :title="$t('energy-data-visualization-card.title').toString()"
                icon="water_damage">
    <template v-slot:content>
      <v-container class="pa-5">
        <div class="pb-5"
             v-html="$t('energy-data-visualization-card.text')"/>

        <!-- consumption point selection -->
        <v-autocomplete :items="consumptionPointsData"
                        :disabled="!hasContractSelected || consumptionPointsData.length < 1"
                        :label="dropdownLabel"
                        :item-text="item => item?.attributes?.displayName"
                        :item-value="item => item?.id"
                        v-model="consumptionPointSelection"
                        :no-data-text="$t('energy-page.emptyState.has-linked.title')"
                        clear-icon="close"
                        clearable
                        single-line
                        outlined>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('energy-data-visualization-card.consumption-point-selection.no-data') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="data">
            <v-list-item class="ma-0 px-0 overflow-hidden">
              <v-list-item-avatar class="ma-0">
                <v-icon>{{ iconMap[data.item?.attributes?.consumptionType] }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="overflow-hidden">
                <v-list-item-title>{{ data.item?.attributes?.displayName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <v-icon>{{ iconMap[data.item?.attributes?.consumptionType] }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item?.attributes?.displayName }}</v-list-item-title>
                <v-list-item-subtitle>{{ data.item?.attributes?.externalId }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

        <!-- consumption trend display -->
        <div class="mb-5 trend-container" v-if="consumptionTrendData.lastMonth && consumptionTrendData.compareMonth">
          <v-row no-gutters>
            <v-col sm="3"
                   class="font-size-06 font-weight-medium info--text vertical_align_center ma-auto text-center">
              {{ activeDatasetIndex === 0 ? formatConsumptionValue(consumptionTrendData.compareMonth.consumptionValue) : formatConsumptionValue(consumptionTrendData.compareMonth.co2Factor) }}
            </v-col>
            <v-col sm="3"
                   class="text-center">
              <v-icon v-if="consumptionTrendData.trend > 0" class="pa-2 trend upwards">
                north_east
              </v-icon>
              <v-icon v-else class="pa-2 trend downwards">
                south_east
              </v-icon>
            </v-col>
            <v-col sm="3"
                   :class="consumptionTrendData.trend > 0 ? 'error--text' : 'success--text'"
                   class="font-size-06 font-weight-medium vertical_align_center ma-auto text-center">
              {{ activeDatasetIndex === 0 ? formatConsumptionValue(consumptionTrendData.lastMonth.consumptionValue) : formatConsumptionValue(consumptionTrendData.lastMonth.co2Factor) }}
            </v-col>
            <v-col sm="3"
                   class="font-size-06 font-weight-medium black--text vertical_align_center ma-auto text-center">
              {{ activeDatasetIndex === 0 ? consumptionTrendData.lastMonth.consumptionUnit : co2Unit }}
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-1">
            <v-col sm="3"
                   class="font-size-02 text-center">
              {{ formatDateForTrend(consumptionTrendData.compareMonth.periodStart) }}
            </v-col>
            <v-col sm="3"
                   class="font-size-02 text-center"
                   :class="consumptionTrendData.trend > 0 ? 'error--text' : 'success--text'">
              {{ activeDatasetIndex === 0 ? formatConsumptionValue(consumptionTrendData.trend) : formatConsumptionValue(consumptionTrendData.co2Trend) }}
              {{ activeDatasetIndex === 0 ? consumptionTrendData.lastMonth.consumptionUnit : consumptionTrendData.lastMonth.co2FactorUnit }}
            </v-col>
            <v-col sm="3"
                   class="font-size-02 text-center">
              {{ formatDateForTrend(consumptionTrendData.lastMonth.periodStart) }}
            </v-col>
            <v-col sm="3"/>
          </v-row>
        </div>
        <v-card v-else-if="showConsumptionGraph && consumptionPointSelection"
                flat class="mb-5" height="70">
          <!-- placeholder to avoid jumping content -->
        </v-card>

        <!-- title for consumption chart -->
        <div v-if="showConsumptionGraph && consumptionPointSelection"
             class="py-4">
          <v-icon left
                  class="material-icons-outlined mb-1"
                  color="primary">
            bar_chart
          </v-icon>
          <span class="font-weight-bold font-size-04 mr-4 primary--text"
                v-html="$t('energy-data-visualization-card.chart.title')"/>
          <div v-text="$t('energy-data-visualization-card.chart.info-text')"/>
        </div>

        <!-- Buttons to switch between kg CO2 and kWh -->
        <div v-if="showConsumptionGraph && consumptionPointSelection">
          <v-btn class="my-4 mr-2 text-none" :outlined="activeDatasetIndex !== 0" small depressed @click="toggleGraphData(0)" color="primary">{{ consumptionUnit }}</v-btn>
          <v-btn class="my-4 ml-2 text-none" :outlined="activeDatasetIndex !== 1" small depressed @click="toggleGraphData(1)" color="primary">{{ co2Unit }} CO<sub>2</sub></v-btn>
        </div>

        <!-- graph -->
        <Bar v-if="showConsumptionGraph && consumptionPointSelection"
             type="bar"
             id="consumption-chart"
             :options="consumptionGraphOptions.chartOptions"
             :data="chartData"/>

        <div class="graph-shift-graph-buttons d-flex justify-space-between" v-if="showConsumptionGraph && consumptionPointSelection">
          <v-btn
              :disabled="highlightedMonthIndexInCompleteData < 13 || completeConsumptionData.length < 13"
              icon x-small color="primary" @click="highlightedMonthIndexInCompleteData -= 1">
            <v-icon>chevron_left</v-icon>
          </v-btn>
          <v-btn
              class=""
              :disabled="highlightedMonthIndexInCompleteData >= completeDatasets[0].data.length - 1"
              icon x-small color="primary" @click="highlightedMonthIndexInCompleteData += 1">
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </div>

        <div class="consumption-chart-legend mt-4" v-if="showConsumptionGraph && consumptionPointSelection">
          <consumption-graph-legend-item :bar-color="$vuetify.theme.currentTheme.primary.toString()"
                                         :label="$t('energy-data-visualization.graph-legend.primary').toString()"/>
          <consumption-graph-legend-item v-if="hasCompareMonth" :bar-color="$vuetify.theme.currentTheme.info.toString()"
                                         :label="$t('energy-data-visualization.graph-legend.compare-month').toString()"/>
          <consumption-graph-legend-item v-if="hasSelectedMonth && consumptionTrendData.trend > 0 && hasCompareMonth"
                                         :bar-color="$vuetify.theme.currentTheme.error.toString()"
                                         :label="activeDatasetIndex === 0 ? $t('energy-data-visualization.graph-legend.higher-consumption').toString() : $t('energy-data-visualization.graph-legend.higher-co2-consumption').toString()"/>
          <consumption-graph-legend-item v-else-if="hasSelectedMonth & hasCompareMonth"
                                         :bar-color="$vuetify.theme.currentTheme.success.toString()"
                                         :label="activeDatasetIndex === 0 ? $t('energy-data-visualization.graph-legend.lower-consumption').toString() : $t('energy-data-visualization.graph-legend.lower-co2-consumption').toString()"/>
        </div>

        <!-- loading indicator -->
        <v-progress-linear v-if="loadingConsumptionData"
                           indeterminate
                           color="primary"
                           class="mb-0"/>

        <!-- empty state -->
        <div v-else-if="consumptionPointSelection && !showConsumptionGraph && !hasCompareMonth && !hasSelectedMonth">
          {{ $t('energy-data-visualization.empty-state') }}
        </div>

      </v-container>

    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard.vue";
import moment from "moment";
import {Bar} from 'vue-chartjs'
import {BarElement, CategoryScale, Chart as ChartJS, LinearScale, Tooltip} from 'chart.js'
import consumptionGraphOptions from "@/scripts/consumptionGraphOptions";
import ConsumptionGraphLegendItem from "@/templates/components/energy/ConsumptionGraphLegendItem.vue";

ChartJS.register(Tooltip, BarElement, LinearScale, CategoryScale)

export default {
  name: "EnergyDataVisualizationCard",
  components: {ConsumptionGraphLegendItem, ContentCard, Bar},

  props: {
    loading: Boolean,
    hasContractSelected: Boolean,
    consumptionPointsData: Array
  },

  data: () => ({
    activeDatasetIndex: 0,
    co2Unit: '',
    consumptionUnit: '',
    iconMap: {
      'HEAT': 'thermostat',
      'HOT_WATER': 'hot_tub',
      'COLD_WATER': 'water_drop',
    },
    consumptionPointSelection: null,
    consumptionGraphOptions: consumptionGraphOptions,
    consumptionTrendData: {
      lastMonth: null,
      compareMonth: null,
      trend: null
    },
    completeConsumptionData: null,
    completeFormattedLabels: [],
    completeMomentObjectLabels: [],
    completeDatasets: [
      {
        data: [],
        backgroundColor: []
      },
      {
        data: [],
        backgroundColor: []
      },
    ],
    chartData: {
      labels: [],
      datasets: [
        {
          yAxisID: 'yAxis',
          xAxisID: 'xAxis',
          data: [],
          backgroundColor: [],
          borderRadius: 5,
          barThickness: 5
        },
        {
          yAxisID: 'yAxis',
          xAxisID: 'xAxis',
          data: [],
          backgroundColor: [],
          borderRadius: 5,
          barThickness: 5,
          hidden: true

        }
      ]
    },
    highlightedMonthIndexInCompleteData: 0,
    highlightedMonthIndexInGraph: 0,
    loadingConsumptionData: false
  }),

  computed: {
    dropdownLabel() {
      if (!this.hasContractSelected) {
        return this.$t('energy-data-visualization-card.consumption-point-selection.no-contract-selected').toString()
      }
      return this.consumptionPointsData.length > 0 ?
          this.$t('energy-data-visualization-card.consumption-point-selection.label-text').toString() :
          this.$t('energy-data-visualization-card.consumption-point-selection.no-data').toString()
    },
    showConsumptionGraph() {
      return this.chartData.datasets[0].data.length > 0
    },
    hasCompareMonth() {
      return this.consumptionTrendData.compareMonth !== null
    },
    hasSelectedMonth() {
      return this.consumptionTrendData.lastMonth !== null
    }
  },

  watch: {
    /**
     * auto-selects either the first consumption point or the last selected consumption-point (as stored in localStorage)
     */
    consumptionPointsData: function () {
      let storedConsumptionPointId = localStorage.getItem("energy:selected-cp:" + localStorage.user)
      if (storedConsumptionPointId) {
        this.consumptionPointSelection = storedConsumptionPointId
      } else {
        this.consumptionPointSelection = Array.isArray(this.consumptionPointsData) && this.consumptionPointsData.length > 0
            ? this.consumptionPointsData[1].id // the header is an item, too -> select idx 1
            : null
      }
    },

    highlightedMonthIndexInCompleteData: function () {
      this.highlightedMonthIndexInGraph = 12
      consumptionGraphOptions.setActiveIndex(this.highlightedMonthIndexInGraph)
      let datasetIndex = this.activeDatasetIndex
      this.resetGraphData()
      this.resetTrendData()
      this.activeDatasetIndex = datasetIndex
      this.setDisplayedData()
    },

    consumptionPointSelection: function () {
      if (!this.consumptionPointSelection) {
        this.resetTrendData()
        this.resetGraphData()
      } else {
        this.getConsumptionData()
      }
    }
  },

  methods: {
    getConsumptionData() {
      this.loadingConsumptionData = true

      this.resetGraphData()
      this.resetTrendData()

      localStorage.setItem("energy:selected-cp:" + localStorage.user, this.consumptionPointSelection)

      this.$rhRequest.sendGet({
        endpoint: 'consumption-data-service/consumptions',
        params: {
          "filter[consumptionPoint.id]": this.consumptionPointSelection,
          "sort": 'periodStart'
        }
      }, (response) => {
        this.completeConsumptionData = response?.data?.data?.data
        if (this.completeConsumptionData.length > 0) {
          this.prepareGraphData()
        }
        this.loadingConsumptionData = false
      }, (error) => {
        console.error(error)
        this.loadingConsumptionData = false
      })
    },

    formatDateForTrend(date) {
      moment.locale(localStorage.getItem('langSetting'))
      return moment(date).format("MMM YYYY")
    },

    formatConsumptionValue(value) {
      return parseFloat(value).toLocaleString(
          undefined, // use client locale
          {minimumFractionDigits: 1, maximumFractionDigits: 1}
      )
    },
    prepareGraphData() {
      this.completeFormattedLabels = []
      this.completeMomentObjectLabels = []
      this.completeDatasets = [
        {
          data: [],
          backgroundColor: []
        },
        {
          data: [],
          backgroundColor: []
        }
      ]

      // calculate the amount of month between the first and last available consumption data item
      // (the amount of items is not sufficient as there might be gaps in the data set)
      let firstAvailableMonth = moment(this.completeConsumptionData[0]?.attributes?.periodStart).startOf('month')
      let latestAvailableMonth = moment(this.completeConsumptionData[this.completeConsumptionData.length-1]?.attributes?.periodStart).startOf('month')
      let numberOfMonths = latestAvailableMonth.diff(firstAvailableMonth, 'months')

      this.highlightedMonthIndexInCompleteData = numberOfMonths - 1

      let firstMonth = null

      if (numberOfMonths <= 12) {
        numberOfMonths = 13
        firstMonth = moment(this.completeConsumptionData[this.completeConsumptionData.length - 1]?.attributes?.periodStart).subtract(12, 'months').startOf('month')
      } else {
        firstMonth = moment(this.completeConsumptionData[0]?.attributes?.periodStart).startOf('month')
      }
      firstMonth.subtract(1, 'month')

      // graph is going to display last 13 months
      for (let i = 0; i < numberOfMonths; i++) {
        // in each iteration, add one month to previous month
        let month = firstMonth.add(1, 'months')
        // check if consumption response has data for current month
        let monthInResponse = this.completeConsumptionData.find(consumption => moment(consumption?.attributes?.periodStart).startOf('month').diff(month) === 0)
        // if no data for current month is found
        if (monthInResponse === undefined) {
          // set data for current month to null, therefore display no data for month
          this.completeDatasets[0].data.push(null)
          this.completeDatasets[1].data.push(null)
        }
        // data for current month is found
        else {
          // set data for current month to returned consumptionValue for current month
          this.completeDatasets[0].data.push(monthInResponse?.attributes?.consumptionValue)
          this.completeDatasets[1].data.push(monthInResponse?.attributes?.co2Factor)

          // set units for data set buttons
          this.consumptionUnit = monthInResponse?.attributes?.consumptionUnit
          this.co2Unit = monthInResponse?.attributes?.co2FactorUnit
        }
        // label for current month should be displayed regardless of having data for month or not
        this.completeFormattedLabels.push(month.locale(localStorage.getItem('langSetting')).format('MMM;YYYY').toString())
        this.completeMomentObjectLabels.push(month.clone())
        this.completeDatasets[0].backgroundColor.push(this.$vuetify.theme.currentTheme.primary)
        this.completeDatasets[1].backgroundColor.push(this.$vuetify.theme.currentTheme.primary)
      }
      this.highlightedMonthIndexInCompleteData = this.completeDatasets[0].data.length - 1

      this.setDisplayedData()
    },
    // determine what data is displayed, depending on which month is selected
    setDisplayedData() {
      let sliceStart = 0
      let sliceEnd = this.completeDatasets[0].data.length

      if (this.highlightedMonthIndexInCompleteData < 13) {
        sliceStart = 0
      } else {
        sliceStart = this.highlightedMonthIndexInCompleteData - 12
      }

      if (this.highlightedMonthIndexInCompleteData < 13) {
        sliceEnd = 13
      } else {
        // + 1 because slice() doesn't inlcude upper index
        sliceEnd = this.highlightedMonthIndexInCompleteData + 1
      }

      this.chartData.datasets[0].data = this.completeDatasets[0].data.slice(sliceStart, sliceEnd)
      this.chartData.datasets[0].backgroundColor = this.completeDatasets[0].backgroundColor.slice(sliceStart, sliceEnd)
      this.chartData.datasets[1].data = this.completeDatasets[1].data.slice(sliceStart, sliceEnd)
      this.chartData.datasets[1].backgroundColor = this.completeDatasets[1].backgroundColor.slice(sliceStart, sliceEnd)

      let slicedLabels = this.completeFormattedLabels.slice(sliceStart, sliceEnd)
      this.chartData.labels = slicedLabels

      this.recolorBars()

      this.setConsumptionUnitForTooltip()

      // set trend data
      this.setTrendData()
    },

    setTrendData() {
      let selectedMonthIndex = this.completeConsumptionData.findIndex(consumption => moment(consumption?.attributes?.periodStart)
          .format('YYYY-MM-DD') === moment(this.completeMomentObjectLabels[this.highlightedMonthIndexInCompleteData]).format('YYYY-MM-DD'))
      this.consumptionTrendData.lastMonth = this.completeConsumptionData[selectedMonthIndex]?.attributes

      let compareMonthIndex = this.completeConsumptionData.findIndex(consumption => moment(consumption?.attributes?.periodStart)
          .format('YYYY-MM-DD') === moment(this.completeMomentObjectLabels[this.highlightedMonthIndexInCompleteData - 12]).format('YYYY-MM-DD'))
      this.consumptionTrendData.compareMonth = this.completeConsumptionData[compareMonthIndex]?.attributes

      this.consumptionTrendData.trend = this.consumptionTrendData.lastMonth?.consumptionValue - this.consumptionTrendData.compareMonth?.consumptionValue
      this.consumptionTrendData.co2Trend = this.consumptionTrendData.lastMonth?.co2Factor - this.consumptionTrendData.compareMonth?.co2Factor
    },

    updateGraph() {
      let chart = ChartJS.getChart('consumption-chart')
      if (chart !== undefined) {
        chart.data.labels = this.chartData.labels
        chart.data.datasets[0] = this.chartData.datasets[0]
        chart.data.datasets[1] = this.chartData.datasets[1]
        chart.update()
      }
    },

    setConsumptionUnitForTooltip() {
      let consumptionUnit
      if (this.activeDatasetIndex === 0) {
        consumptionUnit = this.consumptionUnit
      } else {
        consumptionUnit = this.co2Unit
      }
      consumptionGraphOptions.setConsumptionUnit(consumptionUnit)
    },

    toggleGraphData(index) {
      let chart = ChartJS.getChart('consumption-chart')
      this.activeDatasetIndex = index
      this.setConsumptionUnitForTooltip()
      chart.show(index)
      switch (index) {
        case 0:
          chart.hide(1)
          break;
        case 1:
          chart.hide(0)
          break;
        default:
          this.activeDatasetIndex = 0
          chart.show(0)
          chart.hide(1)
      }
    },

    recolorBars() {
      // color compare month blue
      this.chartData.datasets[0].backgroundColor[this.highlightedMonthIndexInGraph - 12] = this.$vuetify.theme.currentTheme.info
      this.chartData.datasets[1].backgroundColor[this.highlightedMonthIndexInGraph - 12] = this.$vuetify.theme.currentTheme.info

      if (this.chartData.datasets[0].data[this.highlightedMonthIndexInGraph - 12] > this.chartData.datasets[0].data[12]) {
        this.chartData.datasets[0].backgroundColor[12] = this.$vuetify.theme.currentTheme.success
        this.chartData.datasets[1].backgroundColor[12] = this.$vuetify.theme.currentTheme.success
      } else if (this.chartData.datasets[0].data[this.highlightedMonthIndexInGraph - 12]) {
        this.chartData.datasets[0].backgroundColor[12] = this.$vuetify.theme.currentTheme.error
        this.chartData.datasets[1].backgroundColor[12] = this.$vuetify.theme.currentTheme.error
      }

      this.updateGraph()
    },
    resetGraphData() {
      this.chartData.labels = []
      this.chartData.datasets[0].data = []
      this.chartData.datasets[1].data = []
      this.chartData.datasets[0].backgroundColor = []
      this.chartData.datasets[1].backgroundColor = []
      // reset data selection (consumption, co2)
      this.activeDatasetIndex = 0
      this.setConsumptionUnitForTooltip()
    },
    resetTrendData() {
      this.consumptionTrendData = {
        lastMonth: null,
        compareMonth: null,
        trend: null,
        co2Trend: null
      }
    }
  },
  mounted() {
    ChartJS.register(
        {
          id: 'recolorYearAxis',
          beforeRender(chart) {
            if (chart.scales['xAxis2'].getTicks().length > 1) {
              consumptionGraphOptions.setLabelOffset(
                  chart.scales['xAxis2'].getPixelForTick(0) / chart.scales['xAxis2'].right,
                  chart.scales['xAxis2'].getPixelForTick(1) / chart.scales['xAxis2'].right
              )
            } else {
              consumptionGraphOptions.setLabelOffset(
                  chart.scales['xAxis2'].getPixelForTick(0) / chart.scales['xAxis2'].right,
              )
            }

          }
        },
        {
          id: 'xAxis2Padding',
          beforeDatasetsDraw(chart) {
            var labelItems = chart.scales['xAxis2']._labelItems
            labelItems.forEach((label) => {
              label.textBaseline = 'bottom'
              label.textOffset = 10
            })
          }
        }
    )
  }
}
</script>

<style lang="scss">
@import '~@/styles/energy/energy-data-visualization-card.scss';
</style>
