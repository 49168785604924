<template>
  <div>
    <div class="mb-8 text-center font-size-02" v-html="$t('registration-overview.hint')"/>

    <v-card elevation="1">
      <v-card-text class="pa-5">
        <div class="mb-3 font-weight-bold">{{ user.firstName }} {{ user.lastName }}</div>
        <div class="font-weight-bold">{{ user.email }}</div>
        <div class="font-weight-bold" v-if="canChangeAddress">{{ user.address }}, {{ user.zip }} {{ user.city }}</div>
      </v-card-text>
    </v-card>

    <v-btn depressed x-large block
           class="mt-10"
           color="primary"
           :loading="loading"
           :disabled="loading"
           @click="$emit('nextStep')"
           v-html="$t('registration-overview.create-account')"/>
    <v-btn depressed block
           class="mt-4"
           color="primary"
           text
           @click="$emit('stepBack')"
           v-html="$t('app.step-back')"/>
  </div>
</template>

<script>

import config from "@/config/config.app.json";

export default {
  name: 'RegistrationOverview',
  props: ['user', 'loading'],
  computed: {
    /**
     * returns true if the user is allowed to enter or change the site address
     * @returns {boolean}
     */
    canChangeAddress() {
      return config.canChangeAddress === 'true';
    }
  }
};
</script>
