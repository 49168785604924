<template>
  <v-expansion-panels flat
                      :value="visible ? 0 : null"
                      class="list-item">
    <v-expansion-panel>
      <v-expansion-panel-content>
        <v-container class="pa-4">
          <v-row dense class="five-columns">
            <v-col v-for="(item, i) in icons"
                   v-bind:key="i"
                   class="text-center">
              <v-btn icon
                     @click="clickIcon(item.icon)">
                <v-icon v-if="item.type === 'md_icon'"
                        class="material-icons-outlined">
                  {{ item.icon }}
                </v-icon>
                <span v-else
                      class="material-symbols-outlined v-icon ma-0"
                      :class="iconKey === item.icon ? 'primary--text' : ''">
                  {{ item?.icon }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ruleIcons from "@/config/ruleIcons.json";

export default {
  name: 'IconPicker',

  props: {
    /**
     * v-model
     */
    value: {
      type: String
    },
    /**
     * controls the visibility of the component
     */
    visible: {
      type: Boolean
    },
  },

  data: function () {
    return {
      icons: ruleIcons,
      iconKey: null
    }
  },

  methods: {
    clickIcon(iconKey) {
      this.iconKey = iconKey
      this.updateModel()
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', this.iconKey)
    }
  }
}
</script>
