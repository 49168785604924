<template>
  <fullscreen-overlay-frame :title="title"
                            icon="delete_forever"
                            :color="color"
                            centered
                            closable
                            @close="close">

    <template v-slot:content>
      <v-progress-linear :value="progress"
                         color="primary accent-4"
                         height="5"
                         :buffer-value="bufferValue"
                         stream/>
      <v-stepper v-model="stepper"
                 flat class="transparent">

        <v-stepper-items ref="stepper">
          <v-stepper-content step="start"
                             class="pa-0 pt-4">
            <remove-device-step-start/>
          </v-stepper-content>

          <v-stepper-content step="removing-mode-requested"
                             class="pa-0 pt-4">
            <remove-device-step-removing-mode-requested/>
          </v-stepper-content>

          <v-stepper-content step="removing"
                             class="pa-0 pt-4">
            <remove-device-step-removing/>
          </v-stepper-content>

          <v-stepper-content step="success"
                             class="pa-0 pt-4">
            <remove-device-step-success :device="data?.device"/>
          </v-stepper-content>

          <v-stepper-content step="error"
                             class="pa-0 pt-4">
            <remove-device-step-error :device="data?.device"/>
          </v-stepper-content>

          <v-stepper-content step="gateway-error"
                             class="pa-0 pt-4">
            <remove-device-step-gateway-error :device="data?.device"/>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>

    </template>

    <template v-slot:actions>
      <v-stepper v-model="stepper"
                 width="100%"
                 flat class="transparent">
        <v-stepper-items>
          <v-stepper-content step="start"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   :loading="loading"
                   :disabled="loading"
                   class="font-weight-bold action-button"
                   v-text="$t('remove-device-dialog.start-removing')"
                   @click="startRemoving">
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="success"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   class="font-weight-bold action-button"
                   v-text="$t('app.close')"
                   @click="close">
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="error"
                             class="pa-0">
            <v-btn depressed large
                   class="font-weight-bold action-button"
                   v-text="$t('app.close')"
                   @click="close">
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="gateway-error"
                             class="pa-0">
            <v-btn depressed large
                   class="action-button"
                   @click="close"
                   v-text="$t('app.close')"/>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>

    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import RemoveDeviceStepStart from "@/templates/dialogs/removeDevice/RemoveDeviceStepStart";
import RemoveDeviceStepRemovingModeRequested
  from "@/templates/dialogs/removeDevice/RemoveDeviceStepRemovingModeRequested";
import RemoveDeviceStepRemoving from "@/templates/dialogs/removeDevice/RemoveDeviceStepRemoving";
import RemoveDeviceStepError from "@/templates/dialogs/removeDevice/RemoveDeviceStepError";
import RemoveDeviceStepSuccess from "@/templates/dialogs/removeDevice/RemoveDeviceStepSuccess";
import RemoveDeviceStepGatewayError from "@/templates/dialogs/removeDevice/RemoveDeviceStepGatewayError";


export default {
  name: 'RemoveDeviceDialog',
  components: {
    RemoveDeviceStepGatewayError,
    RemoveDeviceStepSuccess,
    RemoveDeviceStepError,
    RemoveDeviceStepRemoving,
    RemoveDeviceStepRemovingModeRequested,
    RemoveDeviceStepStart,
    FullscreenOverlayFrame
  },
  props: ['data'],
  data: function () {
    return {
      loading: false,
      intervals: {
        init: {
          handle: null,
          time: 2000
        },
        controllerMode: {
          handle: null,
          time: 5000,
          count: 0,
          maxRetries: 7
        }
      },
      timeouts: {
        controllerMode: {
          handle: null,
          time: 30000
        }
      },
      title: this.$t('remove-device-dialog.title'),
      color: 'primary',
      stepper: 'start',
      progress: 0,
      bufferValue: 100,
      controllerModes: Object.freeze({
        NORMAL: 'NORMAL',
        REMOVING: 'REMOVING'
      }),
      deviceList: null,
    }
  },
  methods: {
    /**
     * init
     */
    init() {
      this.stepper = 'start'
      this.loading = true
      this.progress = 0
      this.bufferValue = 100
      this.getDevices()
          .then((devices) => {
            this.deviceList = devices
          })

      this.intervals.init.handle = setInterval(() => {
        this.getControllerMode()
            .then((mode) => {
              this.loading = mode !== this.controllerModes.NORMAL
              if (this.loading === false) {
                clearInterval(this.intervals.init.handle)
                this.intervals.init.handle = undefined
              }
            })
            .catch(() => {
              this.showGatewayError()
            })
      }, this.intervals.init.time)
    },

    /**
     * start removing mode
     */
    startRemoving() {
      this.loading = true
      this.getControllerMode()
          .then((mode) => {
            if (mode === this.controllerModes.NORMAL) {
              this.setControllerMode(this.controllerModes.REMOVING)
                  .then(() => {
                    this.progress = 33
                    this.bufferValue = 0
                    this.stepper = 'removing-mode-requested'
                    this.startControllerModeWatching()
                  })
                  .catch(() => {
                    this.showGatewayError()
                  })

            } else {
              this.showGatewayError()
            }
          })
          .catch(() => {
            this.showError()
          })
    },

    /**
     * start controller mode watching
     */
    startControllerModeWatching() {
      // if controller does not enter removing mode
      this.timeouts.controllerMode.handle = setTimeout(
          () => {
            this.showGatewayError()
          },
          this.timeouts.controllerMode.time
      )

      this.intervals.controllerMode.handle = setInterval(() => {
        this.intervals.controllerMode.count++

        if(this.intervals.controllerMode.count >= this.intervals.controllerMode.maxRetries) {
          clearInterval(this.intervals.controllerMode.handle)
          this.showError()
          return
        }

          this.getControllerMode()
              .then((mode) => {

                if (mode === this.controllerModes.REMOVING) {
                  this.showStep('removing', 66)
                  clearTimeout(this.timeouts.controllerMode.handle)
                  this.timeouts.controllerMode.handle = undefined
                }

                if (mode === this.controllerModes.NORMAL) {
                  setTimeout(() => {
                    this.loading = false
                    this.showStep('success', 100)
                    this.stopWatching()
                    clearTimeout(this.timeouts.controllerMode.handle)
                  }, 5000)
                }

              })
              .catch(() => {
                if (this.intervals.controllerMode.count >= this.intervals.controllerMode.maxRetries) {
                  this.showGatewayError()
                }
              })
          }, this.intervals.controllerMode.time
      )
    },

    /**
     * get the controller mode
     * @returns {Promise<unknown>}
     */
    getControllerMode() {
      return new Promise((resolve, reject) => {
        this.$rhRequest.sendGet(
            {
              endpoint: 'gateway/get-mode'
            },
            (response) => {
              if (response.data.data?.data?.mode) {
                resolve(response.data.data.data.mode)
              } else {
                reject(new Error('invalid response body'))
              }
            },
            (err) => {
              reject(err)
            }
        )
      })
    },

    /**
     * set the controller mode
     * @param controllerMode
     * @returns {Promise<unknown>}
     */
    setControllerMode(controllerMode) {
      return new Promise((resolve, reject) => {
        this.$rhRequest.sendPost(
            {
              endpoint: 'gateway/set-mode?mode=' + controllerMode,
              timeout: 10000,
              data: {}
            },
            (response) => {
              if (response.data?.data) {
                resolve(response.data.data)
              } else {
                reject(new Error('invalid response body'))
              }
            },
            (err) => {
              reject(err)
            }
        )
      })
    },

    /**
     * get devices from gateway
     * @returns {Promise<unknown>}
     */
    getDevices() {
      return new Promise((resolve, reject) => {
        this.$rhRequest.sendGet(
            {
              endpoint: 'devices/get',
            },
            (response) => {
              if (response.data?.data) {
                resolve(response.data.data)
              } else {
                reject(new Error('invalid response body'))
              }
            },
            (err) => {
              reject(err)
            }
        )
      })
    },

    /**
     * wrapper to set current step and progress
     * @param step
     * @param progress
     */
    showStep(step, progress) {
      this.progress = progress
      this.stepper = step
    },

    /**
     * show device removing error
     */
    showError() {
      this.loading = false
      this.stopWatching()
      // set controller mode back to normal, because the gateway controller keeps the previous mode longer than the UI
      this.setControllerMode(this.controllerModes.NORMAL)
      this.showStep('error', 100)
    },

    /**
     * show gateway error
     */
    showGatewayError() {
      this.loading = false
      this.stopWatching()
      this.showStep('gateway-error', 100)
    },

    /**
     * clear controller mode watching interval
     */
    stopWatching() {
      clearInterval(this.intervals.controllerMode.handle)
      this.intervals.controllerMode.handle = undefined
    },

    /**
     * clear interval handles
     */
    clearIntervals() {
      Object.keys(this.intervals).forEach((k) => {
        clearInterval(this.intervals[k].handle)
        this.intervals[k].handle = undefined
      })
    },

    /**
     * clear timeout handles
     */
    clearTimeouts() {
      Object.keys(this.timeouts).forEach((k) => {
        clearInterval(this.timeouts[k].handle)
        this.timeouts[k].handle = undefined
      })
    },

    /**
     * close dialog
     */
    close() {
      this.clearIntervals()
      this.clearTimeouts()

      this.$root.$emit('updateDeviceList')
      this.$root.bisadialog.toggle('removeDevice')
    }
  }
}

</script>
