<template>
  <div class="legend-item-container ma-2">
    <div class="legend-bar mr-2" :style="{'background-color': barColor}"></div>
    <div v-html="label" class="legend-label ma-auto font-size-00"></div>
  </div>
</template>

<script>

export default {
  name: "ConsumptionGraphLegendItem",
  components: {},
  props: {
    barColor: String,
    label: String
  }
}
</script>
