<template>
  <fullscreen-overlay-frame :title="$t('enode-time-settings.title').toString()"
                            icon="settings_suggest"
                            color="primary"
                            centered
                            closable
                            @close="abort">
    <template v-slot:content>
      <div v-html="$t('enode-time-settings.info-text')"/>


      <content-card :title="$t('add-rule-dialog.daily-timer.title').toString()"
                    icon="event_repeat"
                    class="my-5">
        <template v-slot:content>
          <div class="px-5 pt-5">
            <div v-html="$t('enode-time-settings.daily-timer.text')"/>

            <v-row>
              <v-col>
                <v-menu v-model="timeMenu"
                        ref="timePickerMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="triggerTime"
                                  outlined dense hide-details
                                  prepend-icon="schedule"
                                  class="my-5"
                                  :placeholder="$t('add-rule-step-timer-definition.trigger-time.label')"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"/>
                  </template>
                  <v-time-picker v-model="triggerTime"
                                 format="24hr"
                                 scrollable
                                 @click:minute="setTriggerTime(triggerTime)"/>
                </v-menu>
              </v-col>

              <v-col></v-col>
            </v-row>
          </div>

        </template>
      </content-card>



    </template>

    <template v-slot:actions>
      <v-btn large depressed
             color="primary"
             class="font-weight-bold action-button"
             @click="save">
        {{ $t('app.save') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame.vue";
import ContentCard from "@/templates/components/ContentCard.vue";

export default {
  name: "EnodeTimeSettingDialog",

  components: {
    ContentCard,
    FullscreenOverlayFrame
  },
  props: ['data'],

  data: () => ({
    timeMenu: false,
    triggerTime: null
  }),

  methods: {
    init() {
      this.triggerTime = this.data.triggerTime
    },
    setTriggerTime(time) {
      this.triggerTime = time
      this.$refs.timePickerMenu.save(time)
    },
    save() {
      let ref = 'triggerTimeChange-' + this.data.vehicleId
      this.$root.$emit(ref, true, this.triggerTime + ":00")
      this.close()
    },
    close() {
      this.$root.bisadialog.toggle('enodeTimeSetting')
    },
    abort() {
      this.$root.bisadialog.toggle('enodeTimeSetting')
    }
  },
  computed: {}
}
</script>
