import Vue from 'vue'

export default [
    {
        path: '/discover',
        name: 'discover',
        component: () => import("@/templates/pages/discover/DiscoverPage"),
        meta: {
            bottomNavigation: true,
            topNavigation: true,
            topNavLogoColor: 'secondary',
            bottomNavigationActive: 'discover'
        },
        beforeEnter: requireAuth
    },
    {
        path: '/sigo',
        name: 'sigo',
        component: () => import("@/templates/pages/discover/SigoPage"),
        meta: {
            bottomNavigation: true,
            topNavigation: true,
            topNavLogoColor: 'secondary',
            showBackArrow: true,
            bottomNavigationActive: 'discover'
        },
        beforeEnter: requireAuth
    },
    {
        path: '/pflegix',
        name: 'pflegix',
        component: () => import("@/templates/pages/discover/PflegixPage"),
        meta: {
            bottomNavigation: true,
            topNavigation: true,
            topNavLogoColor: 'secondary',
            showBackArrow: true,
            bottomNavigationActive: 'discover'
        },
        beforeEnter: requireAuth
    },
    {
        path: '/yeply',
        name: 'yeply',
        component: () => import("@/templates/pages/discover/YeplyPage"),
        meta: {
            bottomNavigation: true,
            topNavigation: true,
            topNavLogoColor: 'secondary',
            showBackArrow: true,
            bottomNavigationActive: 'discover'
        },
        beforeEnter: requireAuth
    },
    {
        path: '/energySaving',
        name: 'energySaving',
        component: () => import("@/templates/pages/discover/EnergySavingPage"),
        meta: {
            bottomNavigation: true,
            topNavigation: true,
            topNavLogoColor: 'secondary',
            showBackArrow: true,
            bottomNavigationActive: 'discover'
        },
        beforeEnter: requireAuth
    },
    {
        path: '/movingPartner',
        name: 'movingPartner',
        component: () => import("@/templates/pages/discover/MyMovingPartnerPage"),
        meta: {
            bottomNavigation: true,
            topNavigation: true,
            topNavLogoColor: 'secondary',
            showBackArrow: true,
            bottomNavigationActive: 'discover'
        },
        beforeEnter: requireAuth
    },
    {
        path: '/mivo',
        name: 'mivo',
        component: () => import("@/templates/pages/discover/MiVoPage"),
        meta: {
            bottomNavigation: true,
            topNavigation: true,
            topNavLogoColor: 'secondary',
            showBackArrow: true,
            bottomNavigationActive: 'discover'
        },
        beforeEnter: requireAuth
    }
]

function requireAuth(to, from, next) {
    if (!Vue.prototype.$rhAuth.loggedIn()) {
        next({
            path: '/welcome',
            query: {redirect: to.fullPath}
        })
    } else {
        next()
    }
}
