<template>
  <v-skeleton-loader v-if="loading"
                     type="card"/>
  <content-card v-else
                :title="$t('device-favorites-card.title').toString()"
                icon="favorite">
    <template v-slot:content>
      <div v-for="device in favoriteDevices" v-bind:key="device.id">
        <generic-device-card :device="device"
                             class="list-item"
                             dense flat/>
      </div>
    </template>
  </content-card>
</template>

<script>

import ContentCard from "@/templates/components/ContentCard";
import GenericDeviceCard from "@/templates/components/devices/GenericDeviceCard.vue";

export default {
  name: 'DeviceFavoritesCard',

  components: {
    GenericDeviceCard,
    ContentCard
  },

  data: () => ({
        loading: false,
        favoriteDevices: []
      }
  ),

  methods: {
    removeIrControllerFromFavorites () {
      this.favoriteDevices = this.favoriteDevices.filter(device => device.deviceWhitelistKey !== "RMT_ZXT800")
    },

    getFavoriteDevices(showLoader) {
      if (showLoader) {
        this.loading = true
      }
      this.$rhRequest.sendGet({
        endpoint: "devices/get-app-favorites-device-ids"
      }, (resp) => {
        let ids = resp?.data?.data
        if (resp?.data?.code === -1 || !ids || ids?.length === 0) {
          this.$emit('hide-favorites', true)
          this.loading = false
        } else {
          this.$rhRequest.sendGet({
            endpoint: 'devices/get',
            params: {
              deviceIds: ids.join(',')
            }
          }, (resp) => {
            this.favoriteDevices = Object.values(resp?.data?.data)
            this.removeIrControllerFromFavorites()
            this.loading = false
            if (this.favoriteDevices.length === 0) {
              this.$emit('hide-favorites', true)
            } else {
              this.$emit('hide-favorites', false)
            }
          }, (err) => {
            console.error(err)
          })
        }
      }, (err) => {
        console.error(err)
        if (showLoader) { // do not spam
          this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
        }
        this.loading = false
      })
    }
  }

}
</script>
