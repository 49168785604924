<!--
  Reset button for subdevices with name "meter-total"
-->
<template>
  <v-list-item :disabled="disabled"
               class="list-item">
    <v-list-item-icon>
      <v-icon color="primary">
        date_range
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="font-size-03">
        {{ lastResetTimestamp }}
      </v-list-item-title>
      <v-list-item-subtitle class="font-size-02">
        {{ $t('meter-total-reset.last-reset') }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-btn dense depressed small
           color="primary"
           @click="reset">
      Reset
    </v-btn>
  </v-list-item>
</template>

<script>

import formats from "@/scripts/formats";
import moment from "moment";

export default {
  name: 'MeterTotalReset',

  props: {
    deviceID: String,
    disabled: Boolean,
    lastChanged: Number,
    lastReset: Number
  },

  computed: {
    lastResetTimestamp () {
      if (moment(this.lastReset).isBefore(Date.now())) {
        return formats.formatDate(this.lastReset, this.$t('app.date-time-format.long'))
      } else if (this.lastReset != null) {
        return moment().format(this.$t('app.date-time-format.long').toString())
      } else {
        return '-'
      }
    }
  },

  methods: {
    /**
     * reset total
     */
    reset() {
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 350,
        title: this.$t('confirmation-dialog.title'),
        text: this.$t('device.sensor-reset.confirm'),
        confirmLabel: this.$t('device.sensor-reset.confirm.button')
      })
      this.$root.$on('dialogConfirmed', () => {
        this.$rhRequest.sendGet({
              endpoint: `device/${this.deviceID}/reset-total`
            },
            () => {
              this.lastResetTimestamp = formats.formatDate(moment().unix(), this.$t('app.date-time-format.long'))
              this.$forceUpdate();
            },
            () => {
              this.$root.bisatoast.error({
                message: this.$t('device.sensor-reset.toast.error'),
                showCloseBtn: true
              });
            }
        );
        this.$root.$off('dialogConfirmed')
      })
    }
  }
}
</script>
