<template>
  <v-container class="pa-5 page-content">

    <content-card :title="$t('profile-page.user-management').toString()"
                  icon="manage_accounts"
                  class="mt-8">
      <template v-slot:content>
        <div class="pa-5" v-html="$t('user-management-page.description')"/>
        <div class="px-5 pb-5">
          <v-btn depressed
                 color="primary"
                 max-width="400"
                 class="font-weight-bold block-sm mx-auto d-block"
                 @click="openInviteUserDialog">
            <v-icon left>person_add</v-icon>
            {{ $t('user-management-page.invite-user') }}
          </v-btn>
        </div>
      </template>
    </content-card>

    <v-row class="mt-4 mb-8">

      <!-- skeleton loader -->
      <v-col cols="12" sm="6" lg="4"
             v-if="loading">
        <v-card height="185">
          <v-skeleton-loader type="article"/>
        </v-card>
      </v-col>

      <!-- current invitations -->
      <v-col cols="12" sm="6" lg="4"
             v-else
             v-for="user in invitedUsers" v-bind:key="user.id">
        <invited-user-card :user="user" :timezone="timezone"/>
      </v-col>

      <!-- pending invitations -->
      <v-col cols="12" sm="6" lg="4"
             v-for="user in pendingInvitations" v-bind:key="user.id">
        <invited-user-card :user="user"
                           :timezone="timezone"
                           pending
                           @update-data="getData(false)"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import ContentCard from "@/templates/components/ContentCard";
import InvitedUserCard from "@/templates/components/profile/InvitedUserCard";
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: 'UserManagementPage',
  components: {InvitedUserCard, ContentCard},

  data: () => ({
    loading: false,
    invitedUsers: [],
    pendingInvitations: [],
    timezone: null,
    timer: null,
    timeouts: {
      getRegisteredUser: {
        handle: null,
        time: 3000
      },
      getInvitedUser: {
        handle: null,
        time: 3000
      }
    }
  }),

  methods: {
    /**
     * gets invited users from the API
     *
     * @param showLoader
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'settings/get-registered-users',
      }, (resp) => {
        this.invitedUsers = resp?.data?.data?.registered
        this.timezone = resp?.data?.data?.timezone
        this.loading = false
      },
          () => {
            this.timeouts.getRegisteredUser.handle = setTimeout(() => {this.getGeneralUserInformation()}, this.timeouts.getRegisteredUser.time)
          })

      this.$rhRequest.sendGet({
        endpoint: 'settings/get-invited-users',
      }, (resp) => {
        this.pendingInvitations = resp?.data?.data?.invited
        this.timezone = resp?.data?.data?.timezone
        this.loading = false
      },
          () => {
            this.timeouts.getInvitedUser.handle = setTimeout(() => {this.getGeneralUserInformation()}, this.timeouts.getInvitedUser.time)
          })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, config.updateInterval)
    },

    openInviteUserDialog() {
      this.$root.bisadialog.toggle('inviteUser')
      this.$root.bisadialog.callDialogInit('inviteUser')
    }
  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
        () => this.getData(true),
        () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
