<template>
  <v-list color="transparent">
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          error_outline
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-04 mb-2 primary--text font-weight-bold">
          {{ $t('add-nuki-bridge-dialog.step-no-bridge-found.title') }}
        </div>
        <div class="font-size-03 red--text">
          {{ $t('add-nuki-bridge-dialog.step-no-bridge-found.description') }}
        </div>
        <div class="font-size-03">
          {{ $t('add-nuki-bridge-dialog.step-no-bridge-found.text1') }}
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          check_circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-03 font-weight-bold">
          {{ $t('add-nuki-bridge-dialog.step-no-bridge-found.text2') }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          check_circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-03 font-weight-bold">
          {{ $t('add-nuki-bridge-dialog.step-no-bridge-found.text3') }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          check_circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-03 font-weight-bold">
          {{ $t('add-nuki-bridge-dialog.step-no-bridge-found.text4') }}
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "AddNukiBrigdeStepNoBridgeFound"
}
</script>

