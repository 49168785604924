<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="241.572" height="156.909" viewBox="0 0 241.572 156.909">
    <g id="Gruppe_1855" data-name="Gruppe 1855" transform="translate(-74 -232.091)">
      <path id="Pfad_1209" data-name="Pfad 1209" d="M410.207,770.582a.332.332,0,0,1-.236.1H169.015a.333.333,0,0,1,0-.667H409.971a.332.332,0,0,1,.236.1A.617.617,0,0,1,410.207,770.582Z" transform="translate(-94.682 -381.68)" fill="#adb1b3"/>
      <g id="Gruppe_1854" data-name="Gruppe 1854" transform="translate(31.333 9.091)">
        <path id="Path_944" data-name="Path 944" d="M376.522,792.357H189.644a.228.228,0,1,1,0-.456H376.521a.228.228,0,1,1,0,.456Z" transform="translate(-119.416 -437.783)" fill="#e6e6e6"/>
        <path id="Pfad_2912" data-name="Pfad 2912" d="M742.029,638.056h-2.8l-1.33-10.788h4.128Z" transform="translate(-499.564 -261.169)" fill="#9e616a"/>
        <path id="Pfad_2913" data-name="Pfad 2913" d="M918.024,780.276h-9.018v-.114a3.51,3.51,0,0,1,3.51-3.51h5.508Z" transform="translate(-674.846 -400.678)" fill="#2e3233"/>
        <path id="Pfad_2914" data-name="Pfad 2914" d="M695.029,638.056h-2.8l-1.33-10.788h4.128Z" transform="translate(-463.286 -261.169)" fill="#9e616a"/>
        <path id="Pfad_2915" data-name="Pfad 2915" d="M871.024,780.276h-9.018v-.114a3.51,3.51,0,0,1,3.51-3.51h5.508Z" transform="translate(-638.568 -400.678)" fill="#2e3233"/>
        <path id="Pfad_2916" data-name="Pfad 2916" d="M265.432,198.789a.684.684,0,0,1-.509-1.141l30.023-33.488,29.547,16.592,29.723-51.215L384.5,154.459a.684.684,0,0,1-.87,1.057l-29.035-23.9L325,182.606l-29.767-16.715-29.291,32.67A.683.683,0,0,1,265.432,198.789Z" transform="translate(-177.562 98.81)" fill="#adb1b3"/>
        <circle id="Ellipse_300" data-name="Ellipse 300" cx="6.388" cy="6.388" r="6.388" transform="translate(81.368 290.527)" :fill="fillPrimary"/>
        <circle id="Ellipse_301" data-name="Ellipse 301" cx="6.388" cy="6.388" r="6.388" transform="translate(111.025 257.448)" :fill="fillPrimary"/>
        <circle id="Ellipse_302" data-name="Ellipse 302" cx="6.388" cy="6.388" r="6.388" transform="translate(140.682 274.101)" :fill="fillPrimary"/>
        <circle id="Ellipse_303" data-name="Ellipse 303" cx="6.388" cy="6.388" r="6.388" transform="translate(170.339 223)" :fill="fillSecondary"/>
        <circle id="Ellipse_304" data-name="Ellipse 304" cx="6.388" cy="6.388" r="6.388" transform="translate(227.372 270.573)" :fill="fillPrimary"/>
        <path id="Path_1509" data-name="Path 1509" d="M915.773,390.173a12.6,12.6,0,0,1,.741-4.741,4.915,4.915,0,0,0,.436-1.791,4.392,4.392,0,0,0-.552-1.622,41.618,41.618,0,0,1-2.053-5.582l-1.141-3.57c-.372-.273,1.8-.367,2.263.274a55.432,55.432,0,0,1,3.862,6.816,11.538,11.538,0,0,1,.78,1.709,9.4,9.4,0,0,1,.411,2.952,40.969,40.969,0,0,1-.5,5.582,4.992,4.992,0,0,1-1.252,3.194A18.081,18.081,0,0,0,915.773,390.173Z" transform="translate(-678.053 -88.848)" fill="#9e616a"/>
        <circle id="Ellipse_307" data-name="Ellipse 307" cx="4.734" cy="4.734" r="4.734" transform="translate(226.954 290.01)" fill="#9e616a"/>
        <path id="Path_1512" data-name="Path 1512" d="M881.127,391.085a.822.822,0,0,1,.173-.849c.118-.093.275-.128.4-.217a1.734,1.734,0,0,0,.433-.548,2.353,2.353,0,0,1,2.945-.748c.782.409,1.255,1.229,1.95,1.781.353.286.763.5,1.118.784a4.6,4.6,0,0,1,1.45,2.345,13.305,13.305,0,0,1,.4,2.766,2.489,2.489,0,0,1-.032.841,2.351,2.351,0,0,1-.319.65,7.24,7.24,0,0,1-2.839,2.626,4.385,4.385,0,0,1-3.783.065.65.65,0,0,1-.29-.232.835.835,0,0,1-.046-.5,3.425,3.425,0,0,0-.377-1.918c-.217-.433-.542-.806-.715-1.255a4.368,4.368,0,0,1-.2-1.556A16.9,16.9,0,0,0,881.127,391.085Z" transform="translate(-653.287 -101.064)" fill="#2e3233"/>
        <path id="Path_944-2" data-name="Path 944" d="M376.522,792.357H189.644a.228.228,0,1,1,0-.456H376.521a.228.228,0,1,1,0,.456Z" transform="translate(-119.416 -463.143)" fill="#e6e6e6"/>
        <path id="Path_944-3" data-name="Path 944" d="M376.522,792.357H189.644a.228.228,0,1,1,0-.456H376.521a.228.228,0,1,1,0,.456Z" transform="translate(-119.416 -486.91)" fill="#e6e6e6"/>
        <circle id="Ellipse_582" data-name="Ellipse 582" cx="2.053" cy="2.053" r="2.053" transform="translate(233.839 280.572)" fill="#a0616a"/>
        <path id="Rechteck_2880" data-name="Rechteck 2880" d="M6.708,0h0a6.708,6.708,0,0,1,6.708,6.708V115.551a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V6.708A6.708,6.708,0,0,1,6.708,0Z" transform="translate(169.698 264.358)" :fill="fillSecondary"/>
        <path id="Rechteck_3061" data-name="Rechteck 3061" d="M6.756,0h0a6.756,6.756,0,0,1,6.756,6.756v94.416a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V6.756A6.756,6.756,0,0,1,6.756,0Z" transform="translate(199.208 278.737)" :fill="fillPrimary"/>
        <path id="Rechteck_3058" data-name="Rechteck 3058" d="M6.756,0h0a6.756,6.756,0,0,1,6.756,6.756v65.7a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V6.756A6.756,6.756,0,0,1,6.756,0Z" transform="translate(140.093 307.457)" :fill="fillPrimary"/>
        <path id="Rechteck_3059" data-name="Rechteck 3059" d="M6.756,0h0a6.756,6.756,0,0,1,6.756,6.756v83.43a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V6.756A6.756,6.756,0,0,1,6.756,0Z" transform="translate(110.536 289.723)" :fill="fillPrimary"/>
        <path id="Rechteck_3060" data-name="Rechteck 3060" d="M6.756,0h0a6.756,6.756,0,0,1,6.756,6.756v40.36a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V6.756A6.756,6.756,0,0,1,6.756,0Z" transform="translate(80.978 332.792)" :fill="fillPrimary"/>
        <path id="Path_1508" data-name="Path 1508" d="M827.094,385.571a29.811,29.811,0,0,0-3.493-5.514,8.732,8.732,0,0,1-.776-1.059,14.683,14.683,0,0,1-.947-2.781c-.867-2.61-3.03-4.706-3.557-7.405-.192-.981-1.81,1.87-1.385,3.087.76,2.2.858,4.533,1.825,6.659s2.1,4.166,2.938,6.34a21.853,21.853,0,0,0,1.549,3.65,4.493,4.493,0,0,0,3.093,2.268,7.631,7.631,0,0,1,.372-2.436C827,387.48,827.507,386.422,827.094,385.571Z" transform="translate(-603.726 -85.724)" fill="#9e616a"/>
        <circle id="Ellipse_581" data-name="Ellipse 581" cx="2.053" cy="2.053" r="2.053" transform="translate(211.026 281.485)" fill="#a0616a"/>
        <path id="Path_1507" data-name="Path 1507" d="M871.466,557.984c2.391.288,8.5-1.893,8.836,1.425,3.147,9.263,3.053,44.772,3.053,44.772l-4.791.228s-3.828-28.214-5.638-34.25c-.908,7.336-.749,35.39-.749,35.39l-4.334.228s-3.888-23.889-3.91-30.9c-.472-5.136.686-12.747.19-17.712a18.985,18.985,0,0,0,7.344.819Z" transform="translate(-639.965 -231.263)" :fill="fillSecondary"/>
        <path id="Pfad_2917" data-name="Pfad 2917" d="M858.961,435.166c.2-.183,1.834-9.735,1.824-9.95-.025-1.177-3.616-2.521-4.334-2.053-.678.441-1.859,5.5-2.618,6.425l-.8-1.406h-6.388l-.387.719c-.838-.5-1.592-.971-1.861-1.125a1.206,1.206,0,0,0-.3-.147,4.634,4.634,0,0,1-1.825-.858c-.525-.4-2.96-4.633-3.612-4.521-.737.123-3.222,2.272-3.194,4.334.313-.007,4.383,8.132,4.44,8.436.313,1.282,1.345,19.98,1.678,20.851,1.534-.148.323-.413,1.808,0,1.6.445,3.287-.036,4.937-.214a18.97,18.97,0,0,1,2.067-.091,39.723,39.723,0,0,1,8.521.773c.014-1.592-1.385-5.037-1.9-6.54a4.392,4.392,0,0,1,.007-1.355C857.084,447.894,858.42,435.774,858.961,435.166Z" transform="translate(-618.08 -127.125)" fill="#e6e6e6"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EnergyEmptyStateIllustration',
  props: {
    fillPrimary: {
      type: String,
      default: "var(--v-primary-base)"
    },
    fillSecondary: {
      type: String,
      default: "var(--v-secondary-base)"
    },
  }
}
</script>