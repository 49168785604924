<template>
  <fullscreen-overlay-frame :title="$t('add-rule-dialog.condition.title').toString()"
                            icon="settings_suggest"
                            color="primary"
                            centered
                            closable
                            @close="abort">
    <template v-slot:content>
      <v-stepper flat
                 v-model="step">
        <v-stepper-items class="overflow-visible">
          <v-stepper-content step="1"
                             class="pb-2 pa-1">
            <div v-html="$t('enode-green-charging-settings.select-device.info-text')"/>
            <device-selection v-if="step === 1"
                              :items="meterList"
                              :loading="loadingMeterList"
                              v-model="itemSelection"/>
          </v-stepper-content>

          <v-stepper-content step="2"
                             class="pb-2 pa-1">
            <div v-html="$t('enode-green-charging-settings.select-condition.info-text')"/>
            <condition-selection v-if="step === 2"
                                 v-model="condition"
                                 :device="selectedDevice"/>

          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>


    </template>

    <template v-slot:actions>
      <v-btn v-if="step === 2"
             large depressed
             color="primary"
             :disabled="disableSave"
             class="font-weight-bold action-button"
             @click="save">
        {{ $t('app.save') }}
      </v-btn>

      <!-- next button -->
      <v-btn v-if="step === 1"
             large depressed
             :disabled="buttonDisabled"
             color="primary"
             class="font-weight-bold action-button"
             @click="nextButtonClick">
        {{ $t('app.continue') }}
      </v-btn>

      <!-- back button -->
      <v-btn v-if="step === 2 && meterList.length > 1"
             large depressed outlined
             color="primary"
             class="mt-4 ml-0 action-button"
             @click="previousStep">
        {{ $t('app.step-back') }}
      </v-btn>

    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame.vue";
import DeviceSelection from "@/templates/dialogs/automations/DeviceSelection.vue";
import ConditionSelection from "@/templates/dialogs/enode/ConditionSelection.vue";


export default {
  name: "EnodeGreenChargingSettingDialog",

  components: {
    ConditionSelection,
    DeviceSelection,
    FullscreenOverlayFrame
  },
  props: ['data'],

  data: () => ({
    /**
     * Step IDs:
     * 1 - device condition property selection
     * 2 - device property condition definition
     */
    step: 1,
    condition: true,
    meterList: [],
    loadingMeterList: false,
    buttonDisabled: false,
    selectedMeter: null,
    itemSelection: 0
  }),

  methods: {
    init() {
      this.meterList = this.data.meterList
      this.getMeterDevices()
    },
    /**
     * action for the next button
     */
    nextButtonClick() {
      this.step++
    },
    /**
     * action for the next button
     */
    previousStep() {
      this.step--
    },
    /**
     * returns true if the device is online
     *
     * @param device
     * @returns {boolean}
     */
    isOnline(device) {
      return device?.online
    },
    getMeterDevices() {
      this.manageLoading(true)

      this.$rhRequest.sendGet(
          {
            endpoint: "rule/device-service-items",
          },
          (resp) => {
            this.fillMeterList(resp?.data?.data)
            this.checkToContinue()
            this.manageLoading(false)
          },
          (err) => {
            this.manageLoading(false)
            console.error(err);
          }
      );
    },
    fillMeterList(respMeterList) {
      this.meterList = []
      respMeterList.forEach((meter) => {
        if (meter.energyDataType === 'meter' || meter.energyDataType === 'photovoltaic_inverter') {
          this.meterList.push(meter)
        }
      })
    },
    manageLoading(status) {
      this.loadingMeterList = status
      this.buttonDisabled = status
    },
    /**
     * if meterList includes only one device this method selects it and continues to the next step
     */
    checkToContinue() {
      if (this.meterList.length === 1) {
        this.itemSelection = 0
        this.nextButtonClick()
      }
    },
    setTriggerTime(time) {
      this.triggerTime = time
      this.$refs.timePickerMenu.save(time)
    },
    save() {
      let ref = 'triggerGreenCharging-' + this.data.vehicleId
      this.$root.$emit(ref, this.condition)
      this.close()
    },
    close() {
      this.$root.bisadialog.toggle('enodeGreenChargingSetting')
    },
    abort() {
      this.$root.bisadialog.toggle('enodeGreenChargingSetting')
    }
  },
  computed: {
    disableSave() {
      let disabled = true
      if (this.condition?.value > 0) {
        disabled = false
      }
      return disabled
    },
    selectedDevice() {
      return this.meterList[this.itemSelection]
    }
  }
}
</script>
