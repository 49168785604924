<template>
  <v-snackbar :color="color"
              :timeout="timeout"
              v-model="visible"
              multi-line
              app
              bottom>
    <v-layout align-center>
      <v-icon class="px-3 non-flip" dark>{{ icon }}</v-icon>
      <v-layout column>
        <div v-if="title">
          <strong v-html="title" />
        </div>
        <div v-html="message" />
      </v-layout>
    </v-layout>

    <template v-slot:action="{ attrs }">
      <v-btn v-if="timeout <= 0 || showCloseBtn"
             icon
             v-bind="attrs"
             @click="visible = false">
        <v-icon>clear</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "BisaToast",
  data() {
    return {
      visible: false,
      title: null,
      message: '',
      color: 'success',
      icon: null,
      timeout: 5000,
      showCloseBtn: false
    }
  },
  methods:{
    show(data) {
      this.title = data.title || null
      this.message = data.message || '(Missing message)'
      this.color = data.color || 'info'
      this.timeout = data.timeout || 5000
      this.icon = data.icon || null
      this.showCloseBtn = data.showCloseBtn || false
      this.visible = true
    },
    error(data) {
      data.color = data.color || 'error'
      data.icon = data.icon || 'error'
      this.show(data)
    },
    warning(data) {
      data.color = data.color || 'warning'
      data.icon = data.icon || 'warning'
      this.show(data)
    },
    success(data) {
      data.color = data.color || 'success'
      data.icon = data.icon || 'check_circle'
      this.show(data)
    },
    info(data) {
      data.color = data.color || 'info'
      data.icon = data.icon || 'info'
      this.show(data)
    }
  }
}
</script>
