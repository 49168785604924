<template>
  <fullscreen-overlay-frame :title="$t('change-user-data-dialog.title').toString()"
                            icon="manage_accounts"
                            color="primary"
                            centered
                            closable
                            @close="abort">
    <template v-slot:content>
      <v-form v-model="valid" ref="form">

        <div class="mb-4" v-html="$t('change-user-data-dialog.user-data')"/>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <v-text-field class="mt-1 mr-sm-6"
                          outlined
                          v-model="firstname"
                          :loading="loading.data"
                          :rules="required"
                          required
                          :label="$t('change-user-data-dialog.firstname.label')"/>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field class="mt-1"
                          outlined
                          v-model="lastname"
                          :loading="loading.data"
                          :rules="required"
                          required
                          :label="$t('change-user-data-dialog.lastname.label')"/>
          </v-col>
        </v-row>

        <v-text-field v-if="isSiteAdmin"
                      class="mt-1"
                      outlined
                      v-model="siteName"
                      :loading="loading.data"
                      :rules="required"
                      required
                      :label="$t('change-user-data-dialog.site.label')"/>

        <div class="font-size-02 mb-2"
             v-if="canChangeEmail"
             v-html="$t('change-user-data-dialog.email.info-text')"/>
        <v-text-field class="mt-4"
                      v-if="canChangeEmail"
                      outlined
                      append-icon="undo"
                      @click:append="resetMail"
                      v-model="email"
                      :loading="loading.data"
                      :rules="emailRules"
                      required
                      :label="$t('change-user-data-dialog.email.label')"/>

        <div v-if="isSiteAdmin && canChangeAddress"
             class="mb-4"
             v-html="$t('change-user-data-dialog.address-data')"/>
        <v-text-field v-if="isSiteAdmin && canChangeAddress"
                      class="mt-1"
                      outlined
                      v-model="street"
                      :loading="loading.data"
                      :rules="required"
                      required
                      :label="$t('change-user-data-dialog.street.label')"/>
        <v-row>
          <v-col cols="4">
            <v-text-field v-if="isSiteAdmin && canChangeAddress"
                          class="mt-1"
                          outlined
                          v-model="zip"
                          :loading="loading.data"
                          :rules="required"
                          required
                          :label="$t('change-user-data-dialog.zip.label')"/>
          </v-col>
          <v-col cols="8">
            <v-text-field v-if="isSiteAdmin && canChangeAddress"
                          class="mt-1"
                          outlined
                          v-model="city"
                          :loading="loading.data"
                          :rules="required"
                          required
                          :label="$t('change-user-data-dialog.city.label')"/>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn large depressed
             color="primary"
             class="font-weight-bold action-button"
             @click="save"
             :loading="loading.save"
             :disabled="!valid || loading.save">
        {{ $t('app.save') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame"
import config from '@/config/config.app.json'

export default {
  name: "ChangeUserDataDialog",

  components: {
    FullscreenOverlayFrame
  },

  data: () => ({
    isSiteAdmin: null,
    loading: {
      data: false,
      save: false
    },
    valid: false,
    siteName: null,
    firstname: null,
    lastname: null,
    street: null,
    zip: null,
    city: null,
    email: localStorage.user
  }),

  computed: {
    required() {
      return [
        v => !!v || this.$t('app.rules.required'),
      ]
    },

    emailRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
        v => (v && v.length <= 100) || this.$t('app.rules.too-long', {maxLength: 100})
      ]
    },

    /**
     * returns true if the user is allowed to enter or change the site address
     * @returns {boolean}
     */
    canChangeAddress() {
      return config.canChangeAddress === 'true';
    },

    /**
     * returns true if the user is allowed to enter or change the e-mail address
     * @returns {boolean}
     */
    canChangeEmail() {
      return config.canChangeEmail === 'true';
    }
  },

  methods: {
    init() {
      this.isSiteAdmin = this.$rhAuth.isSiteAdmin()
      this.getData()
    },

    getData() {
      this.loading.data = true
      this.$rhRequest.sendGet({
        endpoint: "site/get-general-information",
      }, (response) => {
        this.firstname = response.data?.data?.firstName
        this.lastname = response.data?.data?.lastName
        this.siteName = response.data?.data?.siteName
        this.street = response.data?.data?.street
        this.zip = response.data?.data?.zip
        this.city = response.data?.data?.city
        this.loading.data = false
      }, (error) => {
        console.error(error)
        this.$root.bisadialog.error({message: this.$t('app.generic-error')})
        this.loading.data = false
      })
    },

    abort() {
      this.$refs.form.reset()
      this.$root.bisadialog.toggle('changeUserData')
    },

    save() {
      this.loading.save = true
      this.$rhRequest.sendPost(
          {
            endpoint: "site/update-data",
            data: {
              firstname: this.firstname,
              lastname: this.lastname,
              siteName: this.siteName,
              street: this.street,
              zip: this.zip,
              city: this.city,
              email: this.email
            }
          }, (response) => {
            if (response?.data?.code > -1) {
              this.$root.bisatoast.success({
                message: this.$t("change-user-data-dialog.save.success"),
                showCloseBtn: true
              })
              if (response?.data?.data?.emailChangedTokenSent) {
                this.$root.bisadialog.toggle('changeMail')
                this.$root.$emit('changed-data')
              }
            } else {
              this.$root.bisatoast.error({
                message: this.$t("app.generic-error"),
                showCloseBtn: true
              })
            }

            this.$refs.form.reset()
            this.loading.save = false
            this.$root.bisadialog.toggle('changeUserData')
          }, (e) => {
            console.error(e)
            this.$root.bisatoast.error({
              message: this.$t("app.generic-error"),
              showCloseBtn: true
            })
            this.loading.save = false
          }
      )
    },

    resetMail() {
      this.email = localStorage.user
    }
  }
}
</script>
