<template>
  <v-card class="water-control-animation mt-5">
    <div class="water-case-background-offline-icon px-2 pt-1" v-if="!isOnline">
      <v-icon color="red" class="material-icons-outlined" left>wifi_off</v-icon>
    </div>
    <div class="water-control-background-graphic">
      <div class="water-case-background-upper-tank" :class="{'full': upperTankFull}"/>
      <div class="water-case-background-pipe"></div>
      <div class="water-case-background-drop-group-1" :class="{'animated': waterPumpRunning}">
        <div class="water-case-background-drop water-case-background-drop-1"></div>
        <div class="water-case-background-drop water-case-background-drop-2"></div>
        <div class="water-case-background-drop water-case-background-drop-3"></div>
        <div class="water-case-background-drop water-case-background-drop-4"></div>
      </div>
      <div class="water-case-background-drop-group-2" :class="{'animated': waterPumpRunning}">
        <div class="water-case-background-drop water-case-background-drop-1"></div>
        <div class="water-case-background-drop water-case-background-drop-2"></div>
        <div class="water-case-background-drop water-case-background-drop-3"></div>
        <div class="water-case-background-drop water-case-background-drop-4"></div>
      </div>
      <div class="water-case-background-drop-group-3" :class="{'animated': waterPumpRunning}">
        <div class="water-case-background-drop water-case-background-drop-1"></div>
        <div class="water-case-background-drop water-case-background-drop-2"></div>
      </div>
      <div class="water-case-background-drop-icon"></div>
      <div class="water-case-background-lower-tank" :class="{'full': lowerTankFull}">
      </div>
      <div class="water-case-background-pump" :class="{'animated': waterPumpRunning}"></div>
    </div>
  </v-card>
</template>

<script>

export default {
  name: "WaterControlAnimation",
  props: ["waterControlStatus"],

  data() {
    return {}
  },

  methods: {},

  mounted() {
  },

  computed: {
    isOnline() {
      return this.waterControlStatus?.online
    },
    upperTankFull() {
      return this.waterControlStatus?.uppertankFull
    },
    lowerTankFull() {
      return !this.waterControlStatus?.lowertankEmpty
    },
    isManualMode() {
      return this.waterControlStatus?.isManualMode
    },
    pumpIsRunning() {
      return this.waterControlStatus?.meterCurrent > 0
    },
    waterPumpRunning() {
      // tanks: 0 empty, 1 full
      // pump: 0 manual, 1 auto

      // pump on if:
      // - Lower tank not empty AND manual mode
      // - Lower tank not empty AND auto mode && upper tank not full
      // - OR meter-current (power consumption from pump) higher than 0 AND lower tank not empty
      return ((this.lowerTankFull && this.isManualMode) || (this.lowerTankFull && !this.isManualMode && !this.upperTankFull) || this.pumpIsRunning) && this.isOnline
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/water-control.scss';
</style>
