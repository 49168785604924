import Vue from 'vue'
import CustomLogger from "@/plugins/bluetooth/deviceManager/Logger";

const MODULE_NAME_BLUETOOTH_DEVICE_MANAGER = 'blt discoverer';
export default class {
    constructor(uuid) {
        this.callbacks= {};
        this.logger = new CustomLogger(MODULE_NAME_BLUETOOTH_DEVICE_MANAGER);
        this.bluetoothEnabled = false;
        this.pause = false;             // true = app in background
        this.serviceUUID = uuid;
        this.active = false;            // scanning active and restart scanning after opening
    }

    addEventListener(eventname, callback) {
        if( this.callbacks[eventname] === undefined ) {
            this.callbacks[eventname] = [];
        }

        this.callbacks[eventname].push(callback);
    }

    notifyEventListener(eventname, ...args) {
        if( this.callbacks[eventname] === undefined ) {
            return;
        }

        this.callbacks[eventname].forEach(callback => {
            callback(...args);
        });
    }

    setActive() {
        this.active = true;
    }

    setInactive() {
        this.active = false;
        this.stop();
    }

    discover() {
        if (!this.isReady()) {
            this.logger.debug('is not ready', {bluetoothEnabled: this.bluetoothEnabled, pause: this.pause, active: this.active});
            return;
        }

        this.logger.debug('discover');

        if (window?.cordova?.platformId.toLowerCase() === 'ios') {
            // eslint-disable-next-line
            ble.startScanWithOptions([this.serviceUUID], {reportDuplicates: true, scanMode: "lowPower", callbackType: "all"}, this.onDiscover.bind(this), this.onError.bind(this));
        } else {
            // eslint-disable-next-line
            ble.startScanWithOptions([this.serviceUUID], {reportDuplicates: true, scanMode: "balanced", callbackType: "all"}, this.onDiscover.bind(this), this.onError.bind(this));
        }
    }

    onDiscover(device) {
        this.logger.debug('device discovered', device);
        if (window?.cordova?.platformId.toLowerCase() === 'ios') {
             this.onDiscoverDeviceIOS(device);
        } else {
            this.onDiscoverDeviceAndroid(device);
        }
    }

    stop() {
        // eslint-disable-next-line
        ble.stopScan(
            this.onStopSuccess.bind(this),
            this.onStopError.bind(this)
        );
    }

    onDiscoverDeviceIOS(device) {
        if (typeof device == 'undefined') return;
        let adbuf = device.advertising;
        let advertising = new Uint8Array(adbuf.kCBAdvDataManufacturerData);
        this.notifyEventListener('discoveredDevice', { mac: this.convertMacToString(advertising.slice(7, 13)), uuid: device.id, batteryState: advertising[6]});
    }

    onDiscoverDeviceAndroid(device) {
        if (typeof device == 'undefined') return;
        let adbuf = device.advertising;
        let advertising = new Uint8Array(adbuf, 0, adbuf.byteLength / Uint8Array.BYTES_PER_ELEMENT );
        let advertisingStruct = this.getAdvertisingADStruct3(advertising);
        let scanStruct = this.getScanADStruct2(advertising);
        this.notifyEventListener('discoveredDevice', { mac: this.convertMacToString(scanStruct.slice(4, 10)), uuid: this.convertMacToString(scanStruct.slice(4, 10), true), batteryState: advertisingStruct[8]});
    }

    getScanADStruct2(advertising) {
        let index = this.indexOfSubArray(advertising, new Uint8Array([0x09, 0xFF]));
        this.logger.log('AD3Index', index);

        return advertising.slice(index, index + 10);
    }

    getAdvertisingADStruct3(advertising) {
        let index = this.indexOfSubArray(advertising, new Uint8Array([0x08, 0xFF]));
        this.logger.log('AD3Index', index);

        return advertising.slice(index, index + 9);
    }

    indexOfSubArray(main, sub) {
        let i;
        for (i = 0; i <= main.length; i++) {
            let x = main.slice(i, i+sub.length);
            if (JSON.stringify(x) === JSON.stringify(sub)) return i;
        }

        return false;
    }

    onStopSuccess() {
        this.logger.debug('onStopSuccess');
    }

    onStopError(...args) {
        this.logger.error(...args);
    }

    onError(...args) {
        this.logger.error(...args);
    }

    onPause() {
        this.pause = true;
        this.stop();
    }

    onResume() {
        this.pause = false;
        this.discover();
    }

    onBluetoothEnabled() {
        this.bluetoothEnabled = true;
        this.discover();
    }

    onBluetoothDisabled() {
        this.bluetoothEnabled = false;
        this.stop();
    }

    isReady() {
        if (!this.bluetoothEnabled) return false;
        if (this.pause) return false;
        if (!this.active) return false;
        if (!Vue.prototype.$rhAuth.loggedIn()) return false;
        return true;
    }

    convertMacToString(mac) {
        let macString = '';
        mac.forEach(element => {
            let me = element.toString(16).toUpperCase();
            macString += me.length % 2 ? '0' + me : me;
            macString += ':';
        });

        macString = macString.replace(/:$/, '');
        return macString;
    }
}
