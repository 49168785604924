import CustomLogger from "@/plugins/bluetooth/deviceManager/Logger";
import BltResponse from "@/plugins/bluetooth/deviceManager/Response";
import { Buffer } from 'buffer';

export default class {
  constructor(deviceUUID, serviceUUID, characteristicUUID) {
    this.deviceUUID = deviceUUID;
    this.serviceUUID = serviceUUID;
    this.characteristicUUID = characteristicUUID;
    this.logger = new CustomLogger('blt connection');
    this.iv = null;
    this.onDataCallback = null;
    this.onNotificationErrorCallback = null;
    this.onConnectCallback = null;
    this.isListenForNotifcations = false;
    this.isConnecting = false;

    this.reset();
  }

  getDeviceUUID() {
    return this.deviceUUID;
  }

  setEncryptionKey(encryptionKey) {
    this.encryptionKey = encryptionKey;
  }

  setIV(iv) {
    this.iv = iv;
  }

  onData(callback) {
    this.onDataCallback = callback;
  }

  onNotificationError(callback) {
    this.onNotificationErrorCallback = callback;
  }

  onConnect(callback) {
    this.onConnectCallback = callback;
  }

  connect() {
    this.logger.debug('connecting', { deviceUUID: this.deviceUUID });
    let self = this;
    if (self.isConnecting) return;
    self.isConnecting = true;
    // eslint-disable-next-line
    ble.connect(
      this.deviceUUID,
      () => { this.onConnectSuccess() },
      () => { this.onConnectFailure() },
    );
  }

  onConnectSuccess() {
    this.isConnecting = false;
    this.logger.debug('connect success', this.deviceUUID);
    if (this.onConnectCallback !== null) {
      this.onConnectCallback(true);
    }
  }

  onConnectFailure() {
    this.isConnecting = false;
    this.logger.error('connect failure', this.deviceUUID);
    this.disconnect();

    if (this.onConnect !== null) {
      this.onConnectCallback(false);
    }
  }

  isConnected() {
    this.logger.log("Checking connecting to '" + this.deviceUUID + "'", { deviceUUID: this.deviceUUID, isConnecting: this.isConnecting });
    let self = this;

    return new Promise((resolve, reject) => {
      if (this.isConnecting) {
        reject(self.isConnecting);
        return;
      }

      // eslint-disable-next-line
      ble.isConnected(
        self.deviceUUID,
        (...args) => {
          self.isConnecting = false;
          self.onIsConnectedSuccess(...args);
          resolve(...args);
        },
        (...args) => {
          self.isConnecting = false;
          args.unshift(self.isConnecting)
          self.isListenForNotifcations = false;
          self.logger.error("Connection check not successfull (" + self.deviceUUID + ")", ...args);
          reject(...args);
        }
      );
    });
  }

  onIsConnectedSuccess() {
    this.logger.log("Connection to '" + this.deviceUUID + "' successfull", this.deviceUUID);
    if (!this.isListenForNotifcations) {
      this.listenNotifications();
    }
  }

  listenNotifications() {
    this.logger.debug('start listening for notifications', this.deviceUUID);
    this.isListenForNotifcations = true;
    let self = this;
    // eslint-disable-next-line
    ble.startNotification(
      self.deviceUUID,
      self.serviceUUID,
      self.characteristicUUID,
      (data) => {
        let response = new BltResponse(data, true);
        response.setMac(self.deviceUUID);

        if (response.getType() === 0xFD) {
          self.logger.error(
            'Failure',
            {
              data: data,
              response: response,
              hex: Buffer.from(data).toString('hex'),
              iv: self.iv,
              key: self.encryptionKey
            });
          self.disconnect();
          if (self.onNotificationErrorCallback !== null) {
            self.onNotificationErrorCallback(response);
          }
        } else {
          response.decrypt(self.iv, self.encryptionKey);
          self.iv = response.getNexIV();
        }

        self.logger.debug(
          'notification data',
          {
            data: data,
            response: response,
            hex: Buffer.from(data).toString('hex'),
            iv: self.iv,
            key: self.encryptionKey
          });

        if (self.onDataCallback !== null) {
          self.onDataCallback(response);
        }
      },
      (...args) => {
        self.isListenForNotifcations = false;
        self.logger.error("listen for data not successfull", ...args);
      }
    );
  }

  send(command) {
    this.logger.debug('sending command', { iv: this.iv, key: this.encryptionKey, cmd: command });

    if (!this.isListenForNotifcations) {
      this.logger.error("sending error (not listen for notifications)", command);
      return;
    }

    command.setEncryptionKey(this.encryptionKey);
    command.setIV(this.iv);
    let data = command.build();
    this.iv = command.getNextIV();
    let self = this;
    // eslint-disable-next-line
    ble.isConnected(
      self.deviceUUID,
      () => {
        self.logger.debug('is connected for sending');
        // eslint-disable-next-line
        ble.write(
          self.deviceUUID,
          self.serviceUUID,
          self.characteristicUUID,
          data.buffer,
          () => {
          },
          (...args) => {
            self.logger.error("sending error", ...args);
          }
        );
      },
      (...args) => {
        self.isConnecting = false;
        self.logger.error("sending error (not connected)", ...args);
      }
    );
  }

  disconnect() {
    this.logger.debug('disconnect', this.deviceUUID);
    this.isConnecting = false;
    // eslint-disable-next-line
    ble.disconnect(this.deviceUUID);
  }

  reset() {
    this.iv = '00000000000000000000000000000000';
  }
}
