<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="220" height="220" viewBox="0 0 349.999 349.999">
    <g id="Gruppe_2547" data-name="Gruppe 2547" transform="translate(-20 -177)">
      <g id="Gruppe_2543" data-name="Gruppe 2543" transform="translate(277.088 -1621)">
        <path id="Differenzmenge_1" data-name="Differenzmenge 1" d="M175,350a176.287,176.287,0,0,1-35.268-3.555,174.041,174.041,0,0,1-62.575-26.332,175.512,175.512,0,0,1-63.4-76.995,174.117,174.117,0,0,1-10.2-32.849,176.414,176.414,0,0,1,2.95-82.7H52.089V218.73a14.118,14.118,0,0,0,.09,1.59,14.109,14.109,0,0,0-.09,1.589,13.836,13.836,0,0,0,13.836,13.836H339.169A174.814,174.814,0,0,1,175,350ZM346.878,208.075H79.76V113.73A13.835,13.835,0,0,0,65.925,99.9H16.891A175.058,175.058,0,0,1,346.878,208.075Z" transform="translate(-257.088 1798)" fill="#121212" opacity="0.87"/>
        <circle id="Ellipse_901" data-name="Ellipse 901" cx="5.953" cy="5.953" r="5.953" transform="translate(2.501 1812.939) rotate(60)" fill="#e6e6e6"/>
        <circle id="Ellipse_903" data-name="Ellipse 903" cx="5.953" cy="5.953" r="5.953" transform="translate(-40.341 2131.735) rotate(60)" fill="#e6e6e6"/>
        <circle id="Ellipse_902" data-name="Ellipse 902" cx="3.606" cy="3.606" r="3.606" transform="translate(49.438 1895.61) rotate(-120)" fill="#e6e6e6"/>
        <path id="Pfad_4309" data-name="Pfad 4309" d="M2.8,0l-.87,1.935L0,2.8l1.935.87L2.8,5.61l.87-1.935L5.61,2.8l-1.935-.87Z" transform="translate(-89.705 1836.449) rotate(180)" fill="#fff"/>
        <path id="Pfad_4310" data-name="Pfad 4310" d="M1.7,0,1.175,1.175,0,1.7l1.175.528L1.7,3.407l.528-1.175L3.407,1.7,2.232,1.175Z" transform="translate(-119.351 1821.4) rotate(180)" fill="#fff"/>
        <path id="Pfad_4311" data-name="Pfad 4311" d="M5.879,0,4.057,4.057,0,5.879,4.057,7.7l1.823,4.057L7.7,7.7l4.057-1.823L7.7,4.057Z" transform="translate(-122.759 2119.295) rotate(180)" fill="#fff"/>
        <path id="Pfad_4312" data-name="Pfad 4312" d="M2.8,0l-.87,1.935L0,2.8l1.935.87L2.8,5.61l.87-1.935L5.61,2.8l-1.935-.87Z" transform="translate(-102.552 2088.443) rotate(180)" fill="#fff"/>
        <path id="Pfad_4313" data-name="Pfad 4313" d="M2.8,0l-.87,1.935L0,2.8l1.935.87L2.8,5.61l.87-1.935L5.61,2.8l-1.935-.87Z" transform="translate(47.656 1987.645) rotate(180)" fill="#fff"/>
        <g id="Gruppe_2025" data-name="Gruppe 2025" transform="translate(-144.103 1848.63)">
          <path id="Path_22" data-name="Path 22" d="M645.032,160.268h-1.367V122.828A21.67,21.67,0,0,0,622,101.159H542.672A21.67,21.67,0,0,0,521,122.828v205.4a21.67,21.67,0,0,0,21.67,21.67H622a21.67,21.67,0,0,0,21.669-21.669V186.918h1.367Z" transform="translate(-521.003 -101.159)" fill="#e6e6e6"/>
          <path id="Path_23" data-name="Path 23" d="M633.61,116.917H623.257a7.688,7.688,0,0,1-7.119,10.592H570.694a7.688,7.688,0,0,1-7.119-10.592H553.9A16.183,16.183,0,0,0,537.722,133.1V338.2A16.183,16.183,0,0,0,553.9,354.384H633.61A16.183,16.183,0,0,0,649.793,338.2h0V133.1a16.183,16.183,0,0,0-16.183-16.182Z" transform="translate(-531.742 -111.282)" fill="#fff"/>
          <rect id="Rechteck_3514" data-name="Rechteck 3514" width="50.399" height="3.459" rx="1.729" transform="translate(36.816 232.523)" fill="#e6e6e6"/>
          <rect id="Rechteck_3515" data-name="Rechteck 3515" width="90.837" height="71.374" rx="4" transform="translate(16.63 40.09)" fill="#f2f2f2"/>
          <rect id="Rechteck_3516" data-name="Rechteck 3516" width="81.81" height="62.501" rx="4" transform="translate(21.143 44.526)" fill="#fff"/>
          <rect id="Rechteck_3517" data-name="Rechteck 3517" width="90.837" height="71.374" rx="4" transform="translate(15.673 120.409)" fill="#f2f2f2"/>
          <rect id="Rechteck_3518" data-name="Rechteck 3518" width="81.81" height="62.501" rx="4" transform="translate(20.187 124.846)" fill="#fff"/>
          <rect id="Rechteck_3519" data-name="Rechteck 3519" width="60.387" height="19.29" rx="4" transform="translate(31.855 81.526)" fill="#006cb7"/>
          <rect id="Rechteck_3520" data-name="Rechteck 3520" width="60.387" height="19.29" rx="4" transform="translate(31.855 161.571)" fill="#006cb7"/>
          <rect id="Rechteck_3521" data-name="Rechteck 3521" width="27.67" height="10.87" rx="4" transform="translate(64.535 56.821)" fill="#e6e6e6"/>
          <rect id="Rechteck_3522" data-name="Rechteck 3522" width="27.67" height="10.87" rx="4" transform="translate(64.535 136.865)" fill="#e6e6e6"/>
          <g id="water_drop_black_24dp" transform="translate(31.535 50.022)">
            <rect id="Rechteck_3135" data-name="Rechteck 3135" width="23.717" height="23.717" fill="none"/>
            <path id="Pfad_3079" data-name="Pfad 3079" d="M11.906,2Q4,8.745,4,13.661a7.908,7.908,0,1,0,15.811,0Q19.811,8.74,11.906,2Zm0,17.788a5.884,5.884,0,0,1-5.929-6.127c0-2.312,1.927-5.376,5.929-9.032,4,3.656,5.929,6.71,5.929,9.032A5.884,5.884,0,0,1,11.906,19.788ZM7.785,13.859a.742.742,0,0,1,.731.613,3.348,3.348,0,0,0,3.6,2.836.742.742,0,1,1,.069,1.482,4.815,4.815,0,0,1-5.129-4.071A.742.742,0,0,1,7.785,13.859Z" transform="translate(-0.047 -0.024)" fill="#006cb7"/>
          </g>
          <g id="settings_black_24dp" transform="translate(31.535 130.066)">
            <path id="Pfad_3156" data-name="Pfad 3156" d="M0,0H23.717V23.717H0Z" fill="none"/>
            <path id="Pfad_3157" data-name="Pfad 3157" d="M19.228,12.851a7.7,7.7,0,0,0,.069-.968,7.7,7.7,0,0,0-.069-.968l2.085-1.631a.5.5,0,0,0,.119-.632L19.455,5.231a.494.494,0,0,0-.435-.247.462.462,0,0,0-.168.03L16.392,6a7.22,7.22,0,0,0-1.67-.968l-.376-2.619A.482.482,0,0,0,13.862,2H9.909a.482.482,0,0,0-.484.415L9.049,5.034A7.592,7.592,0,0,0,7.379,6L4.918,5.014a.56.56,0,0,0-.178-.03.489.489,0,0,0-.425.247L2.339,8.651a.487.487,0,0,0,.119.632l2.085,1.631a7.837,7.837,0,0,0-.069.968,7.837,7.837,0,0,0,.069.968L2.458,14.481a.5.5,0,0,0-.119.632l1.976,3.419a.494.494,0,0,0,.435.247.462.462,0,0,0,.168-.03l2.461-.988a7.22,7.22,0,0,0,1.67.968l.376,2.619a.482.482,0,0,0,.484.415h3.953a.482.482,0,0,0,.484-.415l.376-2.619a7.592,7.592,0,0,0,1.67-.968l2.461.988a.56.56,0,0,0,.178.03.489.489,0,0,0,.425-.247l1.976-3.419a.5.5,0,0,0-.119-.632Zm-1.957-1.69a5.28,5.28,0,0,1,.049.721c0,.208-.02.425-.049.721l-.138,1.117.88.692,1.067.83-.692,1.2-1.255-.5L16.1,15.519l-.889.672a5.788,5.788,0,0,1-1.235.721l-1.048.425-.158,1.117-.2,1.334H11.194l-.188-1.334-.158-1.117L9.8,16.912a5.607,5.607,0,0,1-1.215-.7l-.9-.692-1.048.425-1.255.5-.692-1.2,1.067-.83.88-.692L6.5,12.613c-.03-.306-.049-.534-.049-.731s.02-.425.049-.721l.138-1.117-.88-.692-1.067-.83.692-1.2,1.255.5,1.028.415.889-.672A5.788,5.788,0,0,1,9.79,6.852l1.048-.425L11,5.311l.2-1.334h1.374l.188,1.334.158,1.117,1.048.425a5.607,5.607,0,0,1,1.215.7l.9.692,1.048-.425,1.255-.5.692,1.2-1.057.84-.88.692.138,1.117ZM11.885,7.929a3.953,3.953,0,1,0,3.953,3.953A3.952,3.952,0,0,0,11.885,7.929Zm0,5.929a1.976,1.976,0,1,1,1.976-1.976A1.982,1.982,0,0,1,11.885,13.859Z" transform="translate(-0.027 -0.024)" fill="#0067ae"/>
          </g>
        </g>
      </g>
      <path id="Pfad_4318" data-name="Pfad 4318" d="M2.8,0l-.87,1.935L0,2.8l1.935.87L2.8,5.61l.87-1.935L5.61,2.8l-1.935-.87Z" transform="translate(90.744 446.646) rotate(180)" fill="#fff"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'WaterControlEmptyStateIllustration',
  props: {
    fillPrimary: {
      type: String,
      default: "var(--v-primary-base)"
    },
    fillSecondary: {
      type: String,
      default: "var(--v-secondary-base)"
    }
  }
}
</script>
