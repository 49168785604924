<template>
  <div class="day-chips-wrapper px-5 py-3">
    <div class="mx-auto day-chips" v-for="(day, index) in days" v-bind:key="index">
      <div class="wrapper" v-if="day.length > 0">
        <div v-for="(color, i) in day" v-bind:key="i"
             class="color"
             :class="{'single-mode' : isOnlyMode(day)}"
             :style="{backgroundColor: color}">
        </div>
      </div>
      <div class="wrapper" v-else>
        <div class="color no-heating-plan"></div>
      </div>
      <div class="weekday-label">
        <span class="font-size-01 font-weight-bold">{{$t(weekdayNames[index])}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveDayOverview",
  props: ['weekdays'],
  data() {
    return {
      weekdayNames: ['heating-mode-dialog.weekdays.monday-shortcut',
        'heating-mode-dialog.weekdays.tuesday-shortcut',
        'heating-mode-dialog.weekdays.wednesday-shortcut',
        'heating-mode-dialog.weekdays.thursday-shortcut',
        'heating-mode-dialog.weekdays.friday-shortcut',
        'heating-mode-dialog.weekdays.saturday-shortcut',
        'heating-mode-dialog.weekdays.sunday-shortcut']
    }
  },
  methods: {
    isOnlyMode(day) {
      return day.length === 1
    },
  },
  computed: {
    days () {
      return this.weekdays
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/active-day-overview.scss';
</style>
