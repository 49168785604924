<!-- frame for entry pages (login, register, reset password, ...) -->
<template>
  <v-card flat
          max-width="960"
          class="entry-page px-8 ma-md-auto transparent">

    <div class="pt-14 pt-md-0 pb-11">
      <slot name="logo">
        <main-logo :fill-color="'var(--v-primary-base)'" class="mx-auto d-block logo"/>
      </slot>
    </div>

    <div v-html="title"
         class="font-weight-bold mx-auto text-center mb-6 font-size-05 primary--text" />

    <div class="text-center mb-8 font-size-02">
      <slot name="subheader" />
    </div>

    <div class="mx-auto mb-14">
      <slot name="form" />
    </div>
  </v-card>
</template>

<script>
import mainLogo from "@/assets/custom/images/mainLogo";

export default {
  name: 'EntryFrame',

  components: {
    mainLogo
  },

  props: {
    title: String
  }
};
</script>

<style lang="scss">
@import '~@/styles/entry/entry-frame.scss';
</style>
