<template>
  <div>
    <div v-for="chargingPoint in chargingPoints"
         v-bind:key="chargingPoint.id"
         class="pa-5">
      <div class="pb-5 d-flex justify-space-between">
        <div class="d-inline-block">
          <v-icon class="material-icons-outlined" :color="isCompleoConnectionActive ? 'primary' : ''">ev_station</v-icon>
          <span class="pl-1 fill-height d-inline-block">
                {{ isCompleoConnectionActive ? chargingPoint.attributes.name : $t('app.additional-permission.no-permission') }}
              </span>
        </div>
        <div class="d-inline-block" v-if="isCompleoConnectionActive">
          <v-icon class="material-icons-outlined" :color="getStateColor(chargingPoint.attributes.status)">{{ getStateIcon(chargingPoint.attributes.status) }}</v-icon>
          <span class="ml-1 d-inline-block" v-html="$t(getStateLabels(chargingPoint.attributes.status))"></span>
        </div>
      </div>
      <div class="d-block justify-space-between d-flex">
        <v-btn :disabled="buttonIsDisabled(chargingPoint.attributes.status) || disabledButton || !isCompleoConnectionActive"
               @click="executeOperation(chargingPoint.id, chargingPoint.attributes.status)"
               depressed large color="primary" class="chargingpoint-button">
          {{ chargingPoint.attributes.status === 'CHARGING' ? $t('chargingstation-home-card.charging.stop') : $t('chargingstation-home-card.charging.start') }}
        </v-btn>
      </div>
      <div>
        <v-checkbox v-model="overviewChargingpoints" :value="chargingPoint.id" :label="$t('chargingstation-card.favorite-checkbox.label')"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChargingstationCard",

  props: [
    'chargingstationId',
    'chargingPoints',
    'compleoConnectionId',
    'enabledConnectionIds'
  ],

  data() {
    return {
      overviewChargingpoints: [],
      disabledButton: false
    }
  },

  methods: {
    /**
     * execute operations on chargingpoint (start charging, stop charging)
     */
    executeOperation(id, status) {
      this.disabledButton = true
      this.$chargingPointOperations.setCompleoConnectionId(this.compleoConnectionId)

      this.$chargingPointOperations.executeOperation(id, status,
        () => {
          this.disabledButton = false
          this.$chargingPointOperations.executeOperationSuccessCallback(this.$root, status)
        },
        (error) => {
          this.disabledButton = false
          this.$chargingPointOperations.executeOperationErrorCallback(this.$root, error, status)
        },
      )
    },

    /**
     * multiple functions for display purposes
     */
    getStateColor(state) {
      return this.$chargingPointOperations.getStateColor(state)
    },

    getStateLabels(state) {
      return this.$chargingPointOperations.getStateLabels(state)
    },

    getStateIcon(state) {
      return this.$chargingPointOperations.getStateIcon(state)
    },

    buttonIsDisabled(state) {
      return this.$chargingPointOperations.buttonIsDisabled(state)
    }
  },

  computed: {
    isCompleoConnectionActive() {
      if(this.enabledConnectionIds && Object.keys(this.enabledConnectionIds).includes(this.compleoConnectionId)) {
        return this.enabledConnectionIds[this.compleoConnectionId]
      } else {
        return true
      }
    }
  },

  mounted() {
    // get favorite chargingpoints from localStorage to determine which checkbox should be checked
    let localStorageChargingpoints = localStorage.getItem('emobility:favoriteChargingpoints:' + this.chargingstationId)
    if (localStorageChargingpoints) {
      this.overviewChargingpoints = localStorageChargingpoints.split(',')
    }
  },
  watch: {
    /**
     * if favorite chargingpoints get changed, write new or remove chargingpoint in localStorage
     */
    overviewChargingpoints(newValue) {
      if (newValue !== null) {
        localStorage.setItem('emobility:favoriteChargingpoints:' + this.chargingstationId, newValue)
      }
    }
  }
}
</script>

<style lang="scss">
.chargingpoint-button {
  width: 100%;
}
</style>