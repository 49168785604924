<template>
  <v-stepper-content step="12"
                     class="pa-1">

    <div v-html="$t('add-rule-step-set-point-definition.info-text')"/>

    <!-- switch devices -->
    <content-card :title="$t('add-rule-step-set-point-definition.set-point.title').toString()"
                  v-if="item?.actuator?.dataType === 'boolean'"
                  icon="tune"
                  class="my-5">
      <template v-slot:content>
        <v-list class="pa-0">
          <v-radio-group v-model="switchOperation"
                         class="pa-0 ma-0 overflow-visible"
                         hide-details
                         @change="updateSwitchOperation">

            <v-list-item class="list-item straight">
              <v-list-item-content class="overflow-visible">
                <v-radio value="TURN_ON"
                         class="font-weight-bold"
                         :label="$t('add-rule-step-set-point-definition.turn-on')"/>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="list-item straight">
              <v-list-item-content class="overflow-visible">
                <v-radio value="TURN_OFF"
                         class="font-weight-bold"
                         :label="$t('add-rule-step-set-point-definition.turn-off')"/>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="list-item straight">
              <v-list-item-content class="overflow-visible">
                <v-radio value="TOGGLE"
                         class="font-weight-bold"
                         :label="$t('add-rule-step-set-point-definition.toggle')"/>
              </v-list-item-content>
            </v-list-item>
          </v-radio-group>
        </v-list>
      </template>
    </content-card>

    <!-- other data types -->
    <content-card :title="$t('add-rule-step-set-point-definition.set-point.title').toString()"
                  v-else
                  icon="tune"
                  class="my-5">
      <template v-slot:content>
        <v-slider v-model="setPoint"
                  :min="item?.actuator?.minValue"
                  :max="item?.actuator?.maxValue"
                  :label="$t('add-rule-step-set-point-definition.set-point.label', {unit: item?.actuator?.unit})"
                  class="align-center mx-5 mt-10"
                  thumb-label="always"
                  @change="updateSetPoint"/>
      </template>
    </content-card>
  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import automations from "@/scripts/automations";

export default {
  name: 'AddRuleStepSetPointDefinition',
  components: {
    ContentCard
  },

  props: {
    item: {
      type: Object,
      default: null
    },
    value: {
      type: Object,
      default: null
    }
  },

  data: function () {
    return {
      setPoint: null,
      switchOperation: null
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      if (this.switchOperation !== null
          && this.item?.actuator?.dataType === 'boolean') {
        this.updateModel() // refresh v-model to current value
        return true
      } else if (this.setPoint !== null
          && this.setPoint >= this.item?.actuator?.minValue
          && this.setPoint <= this.item?.actuator?.maxValue
          && this.item?.actuator?.dataType !== 'boolean') {
        this.updateModel() // refresh v-model to current value
        return true
      }
      return false
    }
  },

  methods: {
    setSetPoint(value) {
      this.setPoint = value
      this.updateSetPoint()
    },

    setSwitchOperation(value) {
      this.switchOperation = value
      this.updateSwitchOperation()
    },

    updateSetPoint() {
      this.switchOperation = null
      this.updateModel()
    },

    updateSwitchOperation() {
      this.setPoint = null
      this.updateModel()
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', {
        deviceId: this.item?.device?.id,
        setPoint: this.setPoint,
        switchOperation: this.switchOperation,
        property: this.item?.actuator?.name,
        type: automations.actionTypes.devices // JSON:API Resource type
      })
    }
  }
};
</script>
