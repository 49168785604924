<template>
  <fullscreen-overlay-frame :title="data?.name"
                            icon="feed"
                            color="primary"
                            centered
                            closable
                            @close="closeOverlay">
    <template v-slot:content>
      <!-- Height must be calculated, because the fullscreen-overlay-frame has a header with 140px height.
      Otherwise the bottom content of the hubspot iframe will be cut off -->
      <div style="height: calc(100% - 140px)" :id="'hubspot-form-' + data.formId"/>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";

export default {
  components: {FullscreenOverlayFrame},
  props: ['data'],
  methods: {
    /**
     * close overlay function
     */
    closeOverlay() {
      this.$root.bisadialog.toggle('hubspotForm')
    }
  },
  mounted() {
    // include the external js file and initialize the form on 'load' event
    const script = document.createElement("script")
    script.id = "hubspot-form-script"
    script.src = "https://js.hsforms.net/forms/shell.js"
    script.addEventListener("load", () => {
      // create form
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: this.data.portalId,
          formId: this.data.formId,
          target: "#hubspot-form-" + this.data.formId
        })
      }
    })
    document.body.appendChild(script)
  },
  beforeDestroy() {
    // This is to avoid multiple script tags being added.
    document.getElementById("hubspot-form-script")?.remove()
  }
}
</script>