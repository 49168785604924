
export default {

    /**
     * This method should be used to start requests in mounted()
     * The request is delayed by the value passed for delay (default 300ms) which is meant as a fix for RH-3906.
     *
     * @param request method to execute to start a request
     * @param update optional - additional method to execute; should be used for continuously updating the data
     * @param delay request delay in ms (default: 300)
     */
    startDelayedRequest(request, update = () => {}, delay = 300) {
        update()
        setTimeout(() => {
            request()
        }, delay)
    }
}
