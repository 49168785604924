import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import theme from '@/config/theme'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    rtl: false,
    icons: {
        iconfont: 'md'
    },
    theme: {
        default: 'light',
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
            light: theme.light,
            dark: theme.dark
        }
    }
});
