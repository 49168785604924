<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="241.622" height="124.917" viewBox="0 0 241.622 124.917">
    <g id="Gruppe_1651" data-name="Gruppe 1651" transform="translate(-74 -263.75)">
      <circle id="Ellipse_492" data-name="Ellipse 492" cx="6.722" cy="6.722" r="6.722" transform="translate(106 263.75)" :fill="fillSecondary" opacity="0.2"/>
      <ellipse id="Ellipse_495" data-name="Ellipse 495" cx="10.79" cy="10.639" rx="10.79" ry="10.639" transform="translate(287.463 361.858)" :fill="fillSecondary" opacity="0.2"/>
      <path id="Pfad_1209" data-name="Pfad 1209" d="M410.3,770.346a.332.332,0,0,1-.333.333H169.015a.333.333,0,0,1,0-.667H409.971a.332.332,0,0,1,.333.333Z" transform="translate(-94.682 -382.013)" fill="#ccc"/>
      <g id="Gruppe_1644" data-name="Gruppe 1644" transform="translate(78.785 -114.352)">
        <path id="Pfad_2424" data-name="Pfad 2424" d="M718.494,375.327c-1.3,8.238-5.312,16.234-11.939,21.294-6.522,4.984-14.878,6.754-22.944,8.276q-14.512,2.742-29.1,5.012-2.068.33-4.14.637H593.711c-.057-.077-.118-.156-.175-.236-.092-.134-.185-.268-.274-.4-.051-.076-.1-.156-.153-.236h35.539a3.809,3.809,0,0,0,3.805-3.8V358.437c.229-.72.443-1.443.637-2.175a57.33,57.33,0,0,0,1.761-10.416c.191-2.452.249-4.9.207-7.359a163.241,163.241,0,0,0-1.958-20.2c-.07-.494-.143-.99-.213-1.484-.675-4.656-1.29-9.722,1.057-13.8,2.685-4.668,8.643-6.614,14.009-6.136,9.652.863,17.782,8.254,22.008,16.976s5.168,18.635,5.554,28.316a89.014,89.014,0,0,1-.535,15.645q3.63-8.3,7.267-16.6c1.293-2.952,2.653-6,5.047-8.159,5.006-4.519,13.286-3.608,18.632.506s8.273,10.623,10.222,17.078C718.561,358.612,719.793,367.089,718.494,375.327Z" transform="translate(-510.342 92.472)" :fill="fillSecondary"/>
        <path id="Pfad_2425" data-name="Pfad 2425" d="M810.182,401.378a41.044,41.044,0,0,1,5.068,20.4,49.387,49.387,0,0,1-4.564,20.358,51.145,51.145,0,0,1-10.976,15.38,46.6,46.6,0,0,1-15.773,10.076,41.218,41.218,0,0,1-21.4,2.034q-1.5-.257-2.974-.624c-.6-.147-.852.774-.254.921a42.282,42.282,0,0,0,22.687-.652,46.739,46.739,0,0,0,16.617-9.453,51.879,51.879,0,0,0,11.918-15.194,50.792,50.792,0,0,0,5.6-20.337,43.228,43.228,0,0,0-3.9-21.061q-.566-1.187-1.215-2.332a.478.478,0,0,0-.825.482Z" transform="translate(-623.382 21.675)" fill="#fff"/>
        <path id="Pfad_2426" data-name="Pfad 2426" d="M726.751,295.084a103.6,103.6,0,0,1,9.011,22.6,109.172,109.172,0,0,1,1.857,48.264,103.213,103.213,0,0,1-7.257,23.242c-3.318,7.371-8.095,15.138-16.254,17.718a17.022,17.022,0,0,1-2.822.631c-.606.083-.348,1,.254.921,8.44-1.158,14.305-8.17,17.983-15.318a98.073,98.073,0,0,0,8.318-23.536,110.029,110.029,0,0,0-.376-49.079,105.219,105.219,0,0,0-8.448-23.182q-.7-1.384-1.442-2.746C727.282,294.062,726.457,294.544,726.751,295.084Z" transform="translate(-590.65 94.13)" fill="#fff"/>
        <path id="Pfad_2427" data-name="Pfad 2427" d="M503.361,292.259H446.168a4.384,4.384,0,0,0-4.379,4.379v28.657h8.8a3.783,3.783,0,0,1,3.219,1.78l3.051,4.856,2.917,4.64.548.873,3.337,5.309.038.061H507.74V296.638a4.384,4.384,0,0,0-4.379-4.379Z" transform="translate(-407.209 95.565)" fill="#ccc"/>
        <path id="Pfad_2428" data-name="Pfad 2428" d="M497.236,319.769H468.9a2.833,2.833,0,1,0,0,5.665h28.339a2.833,2.833,0,1,0,0-5.665Z" transform="translate(-423.754 76.816)" fill="#fff"/>
        <path id="Pfad_2429" data-name="Pfad 2429" d="M512.947,363.449h-43.8a3.092,3.092,0,1,0,0,6.184h43.8a3.092,3.092,0,0,0,0-6.184Z" transform="translate(-423.75 47.045)" fill="#fff"/>
        <path id="Pfad_2430" data-name="Pfad 2430" d="M521.939,399.209a3.09,3.09,0,0,0-2.8-1.78h-43.8c-.067,0-.131,0-.2.006a3.8,3.8,0,0,1,1.4,1.318l3.051,4.856h39.544a3.088,3.088,0,0,0,2.8-4.4Z" transform="translate(-429.946 23.886)" fill="#fff"/>
        <path id="Pfad_2431" data-name="Pfad 2431" d="M537.968,434.084a3.092,3.092,0,0,0-3.06-2.675H498.28l.548.873,3.337,5.308h32.742a3.056,3.056,0,0,0,1.793-.576A3.088,3.088,0,0,0,538,434.5a2.955,2.955,0,0,0-.029-.414Z" transform="translate(-445.71 0.727)" fill="#fff"/>
        <path id="Pfad_2432" data-name="Pfad 2432" d="M574.1,324.615l-4.458-1.742L547.413,314.2,520.82,303.821a4.386,4.386,0,0,0-5.672,2.49l-.93,2.385-2.213,5.665-3.216,8.245-2.414,6.184-1.809,4.637-2.414,6.181-1.812,4.64-.051.134-.29.739,3.337,5.309.038.061h61.813a3.794,3.794,0,0,1,2.958,1.414l.261-.662,3.191-8.171,4.987-12.779a4.387,4.387,0,0,0-2.487-5.675Z" transform="translate(-446.882 87.889)" :fill="fillPrimary"/>
        <path id="Pfad_2433" data-name="Pfad 2433" d="M591.6,347.039l-2.382-.93L565.2,336.737a2.834,2.834,0,1,0-2.06,5.28l7.932,3.1,9.811,3.828,8.334,3.255.322.124a2.834,2.834,0,1,0,2.06-5.28Z" transform="translate(-488.686 65.382)" fill="#fff"/>
        <path id="Pfad_2434" data-name="Pfad 2434" d="M590.113,393.607l-11.961-4.668-8.538-3.331-4.56-1.78-11.878-4.637-3.859-1.506a3.092,3.092,0,0,0-3.895,1.506c-.038.08-.076.162-.108.248a3.09,3.09,0,0,0,1.755,4l.987.385,15.833,6.181,14.263,5.566,9.713,3.793a3.092,3.092,0,1,0,2.248-5.761Z" transform="translate(-477.622 37.488)" fill="#fff"/>
        <path id="Pfad_2435" data-name="Pfad 2435" d="M569.73,422.125l-.768-.3-7.506-2.93-6.856-2.675-11.891-4.64-5.751-2.245a3.091,3.091,0,0,0-2.245,5.761l2.879,1.124,14.362,5.6,1.478.576.153.061h17.008Z" transform="translate(-469.2 15.916)" fill="#fff"/>
        <path id="Pfad_2436" data-name="Pfad 2436" d="M532.26,443.971l-1.478-.576-6.175-2.411a3.087,3.087,0,0,0-4.178,2.411,2.934,2.934,0,0,0-.035.576.364.364,0,0,0,0,.06h12.018Z" transform="translate(-460.781 -5.654)" fill="#fff"/>
        <path id="Pfad_2437" data-name="Pfad 2437" d="M469.257,412.093l-1.478-.576H444.673a2.939,2.939,0,0,0-.035.576h-3.159l.038.06H469.41Zm0,0H441.479l.038.06H469.41Zm0,0H441.479l.038.06H469.41Zm.153.06-.153-.06H441.479l.038.06Zm-55.731,63.769a3.808,3.808,0,0,1-3.8-3.8V398.442a3.809,3.809,0,0,1,3.8-3.805h5.926V394h-5.926a4.447,4.447,0,0,0-4.439,4.442V472.12a4.443,4.443,0,0,0,2.6,4.038h56.1c-.051-.076-.1-.156-.153-.236Zm92.871-63.017a4.419,4.419,0,0,0-3.219-1.388h-61.46l-3.439-5.471-.083-.134-2.917-4.64-3.267-5.2A4.451,4.451,0,0,0,428.407,394H413.679a4.448,4.448,0,0,0-4.439,4.442V472.12a4.443,4.443,0,0,0,2.6,4.038,4.379,4.379,0,0,0,1.837.4h89.652a4.394,4.394,0,0,0,1.841-.4,4.443,4.443,0,0,0,2.6-4.038V415.959A4.417,4.417,0,0,0,506.55,412.905Zm.586,59.215a3.809,3.809,0,0,1-3.806,3.8H413.679a3.808,3.808,0,0,1-3.8-3.8V398.442a3.809,3.809,0,0,1,3.8-3.805h14.728a3.783,3.783,0,0,1,3.219,1.78l3.051,4.856,2.917,4.64.548.873,3.337,5.308.038.06h61.813a3.809,3.809,0,0,1,3.805,3.805Zm-37.879-60.027H441.479l.038.06H469.41Zm0,0H441.479l.038.06H469.41Zm0,0H441.479l.038.06H469.41Zm0,0-1.478-.576H441.871l-3.439-5.471-.29.739,3.337,5.308.038.061H469.41Z" transform="translate(-385.024 26.224)" fill="#2e3233"/>
      </g>
      <circle id="Ellipse_490" data-name="Ellipse 490" cx="3.361" cy="3.361" r="3.361" transform="translate(96.278 283.971)" :fill="fillSecondary" opacity="0.2"/>
      <circle id="Ellipse_507" data-name="Ellipse 507" cx="6.722" cy="6.722" r="6.722" transform="translate(248.463 290.692)" :fill="fillSecondary" opacity="0.2"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DocumentsEmptyStateIllustration',
  props: {
    fillPrimary: {
      type: String,
      default: "var(--v-primary-base)"
    },
    fillSecondary: {
      type: String,
      default: "var(--v-secondary-base)"
    }
  }
}
</script>
