import Vue from "vue";

export default class {
  
  compleoConnectionId = null
  
  constructor() {
  }
  
  /**
   * sets compleoConnectionId for later use in API calls
   * @param compleoConnectionId
   */
  setCompleoConnectionId(compleoConnectionId) {
    this.compleoConnectionId = compleoConnectionId
  }
  
  /**
   * returns true if button should be enabled, false if button disabled
   * @param state state in which chargingPoint currently is
   * @returns boolean
   */
  buttonIsDisabled(state) {
    if (state === 'ERROR' || state === 'SERVICE' || state === 'OCCUPIED' || state === 'INITIALIZING') {
      return true
    } else {
      return false
    }
  }
  
  /**
   * checks which operation should be executed, start or stop charging
   * @param id id of chargingPoint on which operation should be executed
   * @param state state in which chargingPoint currently is
   * @param successCallback success callback function
   * @param errorCallback error callback function
   */
  executeOperation(id, state, successCallback, errorCallback) {
    if (state === 'CHARGING') {
      this.stopCharging(id, successCallback, errorCallback)
    } else if (state === 'AVAILABLE') {
      this.startCharging(id, successCallback, errorCallback)
    }
  }
  
  /**
   * default success callback, shows success toast if start or stop was successful
   * @param root used because otherwise bisatoast can't be triggered
   * @param status state in which chargingPoint was set
   */
  executeOperationSuccessCallback(root, status) {
    let successMessage = ''
    if (status === 'AVAILABLE') {
      // eslint-disable-next-line no-unused-vars
      successMessage = 'chargingstation-home-card.operation.starting.success'
    } else if (status === 'CHARGING') {
      // eslint-disable-next-line no-unused-vars
      successMessage = 'chargingstation-home-card.operation.stopping.error'
    }
    
    root.bisatoast.success({
      message: root.$t(successMessage),
      showCloseBtn: true
    })
  }
  
  /**
   * default error callback, shows error toast if start or stop was not successful
   * @param root used because otherwise bisatoast can't be triggered
   * @param error error which was thrown, needed to distinguish between error toasts
   * @param status state in which chargingPoint was set
   */
  executeOperationErrorCallback(root, error, status) {
    let errorMessage = ''
    if (error.response?.data?.data?.message === "PROCESS_STARTED_BY_OTHER_COMPLEO_CONNECTION") {
      root.bisatoast.error({
        message: 'chargingstation-home-card.operation.error.started_by_other_user',
        showCloseBtn: true
      })
    } else if (error.response?.data?.data?.message === "NOT_ALLOWED") {
      root.bisatoast.error({
        message: 'chargingstation-home-card.operation.error.no_permission',
        showCloseBtn: true
      })
    } else {
      if (status === 'AVAILABLE') {
        errorMessage = 'chargingstation-home-card.operation.error.start'
      } else {
        errorMessage = 'chargingstation-home-card.operation.error.stop'
      }
      
      root.bisatoast.error({
        message: root.$t(errorMessage),
        showCloseBtn: true
      })
    }
  }
  
  /**
   * executes stop operation
   * @param id id of chargingPoint on which operation should be executed
   * @param successCallback success callback function
   * @param errorCallback error callback function
   */
  stopCharging(id, successCallback, errorCallback) {
    let data = this.getRequestData(id, 'STOP')
    this.sendRequest(data, successCallback, errorCallback)
  }
  
  /**
   * executes start operation
   * @param id id of chargingPoint on which operation should be executed
   * @param successCallback success callback function
   * @param errorCallback error callback function
   */
  startCharging(id, successCallback, errorCallback) {
    let data = this.getRequestData(id, 'START')
    this.sendRequest(data, successCallback, errorCallback)
  }
  
  /**
   * creates data for request
   * @param id id of chargingPoint on which operation should be executed
   * @param task operation which should be executed, either 'START' or 'STOP'
   * @return {data} data object with set id and task
   */
  getRequestData(id, task) {
    return {
      data: {
        type: "compleoConnections",
        attributes: {
          chargingPointOperation: {
            chargingPointId: id,
            task: task
          }
        }
      }
    }
  }
  
  /**
   * creates data for request
   * @param data data object which contains required data for request
   * @param successCallback success callback function
   * @param errorCallback error callback function
   */
  sendRequest(data, successCallback, errorCallback) {
    data["data"]["id"] = this.compleoConnectionId
    
    let options = {
      endpoint: 'chargingstations/compleoConnections',
      method: 'PATCH',
      data: data
    }
    
    Vue.prototype.$rhRequest.sendPatch(
      options,
      successCallback,
      errorCallback
    )
  }
  
  /**
   * returns icon color for given state
   * @param state state in which chargingPoint currently is
   * @returns string
   */
  getStateColor(state) {
    let stateColor = ''
    switch (state) {
      case 'AVAILABLE':
        stateColor = 'success'
        break
      case 'CHARGING':
        stateColor = 'success'
        break
      case 'ERROR':
        stateColor = 'error'
        break
      case 'OCCUPIED':
        stateColor = 'yellow'
        break
      case 'SERVICE':
        stateColor = 'warning'
        break
      default:
        stateColor = ''
    }
    return stateColor
  }
  
  /**
   * returns label for given state
   * @param state state in which chargingPoint currently is
   * @returns string
   */
  getStateLabels(state) {
    let stateText = ''
    switch (state) {
      case 'AVAILABLE':
        stateText = 'chargingstations.status.available'
        break
      case 'CHARGING':
        stateText = 'chargingstations.status.charging'
        break
      case 'ERROR':
        stateText = 'chargingstations.status.error'
        break
      case 'OCCUPIED':
        stateText = 'chargingstations.status.occupied'
        break
      case 'SERVICE':
        stateText = 'chargingstations.status.service'
        break
      case 'INITIALIZING':
        stateText = 'chargingstations.status.initializing'
        break
      case 'STOPPING':
        stateText = 'chargingstations.status.stopping'
        break
      default:
        stateText = ''
    }
    return stateText
  }
  
  /**
   * returns icon name for given state
   * @param state state in which chargingPoint currently is
   * @returns string
   */
  getStateIcon(state) {
    let iconName = ''
    switch (state) {
      case 'AVAILABLE':
        iconName = 'contactless'
        break
      case 'CHARGING':
        iconName = 'offline_bolt'
        break
      case 'ERROR':
        iconName = 'offline_bolt'
        break
      case 'OCCUPIED':
        iconName = 'remove_circle_outline'
        break
      case 'SERVICE':
        iconName = 'build_circle'
        break
    }
    return iconName
  }
}
