<template>
  <dialog-frame :title="data?.title"
                :icon="data?.icon"
                color="primary"
                closable
                @close="close">
    <template v-slot:content>
      <span v-if="data?.html" v-html="data?.html" />
      <span v-else>{{ data?.text }}</span>
    </template>

    <template v-slot:actions>
      <v-btn plain block
             color="primary"
             class="font-weight-bold d-block"
             @click="close">
        {{ $t('app.close') }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>
import DialogFrame from "@/templates/dialogs/DialogFrame";

export default {
  name: 'InfoTextDialog',

  components: {
    DialogFrame
  },

  props: ['data'],

  methods: {
    close() {
      this.$root.bisadialog.toggle('infoText')
    }
  },

};
</script>

