import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n.js'
import router from './router'

import onboardingDialog from "@/scripts/onboardingDialog";

import econda from './plugins/econda/plugin'
import config from '@/config/config.app.json'

import rhRequestPlugin from './plugins/rhRequest/plugin'
import rhPushNotification from './plugins/rhPushNotification/plugin'
import rhAuthPlugin from './plugins/rhAuth/plugin'
import chargingPointsOperations from './plugins/chargingPointsOperations/plugin'
import irControllerOperations from './plugins/irControllerOperations/plugin'
import BluetoothDeviceManagerPlugin from './plugins/bluetooth/deviceManager/Plugin'
import Darkmode from "@/plugins/darkmode/plugin";

Vue.config.productionTip = false

Vue.use(rhPushNotification,
  {
    environment: config.environment,
    clientKey: config.clientKey,
    channels: JSON.parse(config.pushNotificationChannels)
  }
)

Vue.use(rhRequestPlugin)
Vue.use(rhAuthPlugin)
Vue.use(chargingPointsOperations)
Vue.use(irControllerOperations)
Vue.use(BluetoothDeviceManagerPlugin)


let econdaEnabled = JSON.parse(config.econda_enabled)
Vue.use(econda,  {
  siteId: (econdaEnabled ? config.econda_siteId : null),
  clientKey: (econdaEnabled ? config.econda_clientKey : null),
  containerId: (econdaEnabled ? config.econda_containerId : null),
  enabled: econdaEnabled
})

Vue.prototype.$showFirstLaunchDialog = onboardingDialog.showFirstLaunchDialog

/**
 * returns true if the user can add devices with deviceType to devices favourites
 *
 * @param deviceType
 * @returns {boolean}
 */
Vue.prototype.$isFavourable = (deviceType) => {
    return ![
        'nuki-bridge',
        'nuki-lock',
        'nuki-opener',
        'aeo-range-ext',
        'net-watermodule',
        'kaadas-lock'
    ].includes(deviceType);
}

Vue.use(Darkmode, {
  default: (config.darkmode_theme ? config.darkmode_theme : 'system'),
  changeable: (config.darkmode_changeable ? JSON.parse(config.darkmode_changeable) : true)
})

new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
