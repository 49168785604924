<template>
  <v-color-picker
      v-model="colorPicker"
      class="swatch-color-picker"
      :swatches="colorSwatches"
      update:modelValue="changeColor"
      hide-canvas
      show-swatches
  ></v-color-picker>
</template>

<script>
export default {
  name: "SwatchColorPicker",
  props: {
    color: {
      type: String,
      default: '#FFB000'
    }
  },
  data() {
    return {
      colorSwatches: [
        ['#FFB000', '#FF7D2B'],
        ['#E871AD', '#A36EAF'],
        ['#506586', '#84A6DD'],
        ['#416E65', '#82D1C3'],
        ['#7C954C', '#C5E26B']
      ],
      colorPicker: this.color
    }
  },
  watch: {
    colorPicker: function (e) {
      this.$emit('changeColor', e)
    }
  }
}
</script>

<style lang="scss">
  @import '~@/styles/swatch-color-picker.scss';
</style>
