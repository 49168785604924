<template>
  <fullscreen-overlay-frame :title="title"
                            icon="control_point_duplicate"
                            :color="color"
                            centered
                            closable
                            @close="close">

    <template v-slot:content>
      <v-stepper v-model="stepper"
                 flat class="transparent">

        <v-stepper-items>
          <v-stepper-content step="1"
                             class="pa-0 pt-4">
            <add-gateway-step1/>
          </v-stepper-content>

          <v-stepper-content step="2"
                             class="pa-0 pt-4">
            <add-gateway-step2 v-model="gwId" @valid="setGwIdValid"/>
          </v-stepper-content>

          <v-stepper-content step="success"
                             class="pa-0 pt-4">
            <add-gateway-success :updatedStarted="updatedStarted" :firmwareVersion="firmwareVersion"/>
            <div v-if="!devicesSynced" class="mt-5">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              <span class="ml-3">{{ $t("add-gateway-dialog.syncDevices")}}</span>
            </div>
            <div v-else class="font-size-03" v-html="$t('add-gateway-dialog.number-devices-synced', {numDevicesSynced: numDevicesSynced})"></div>
            <v-alert
                class="mt-5"
                v-if="showErrorSyncDevicesFailed"
                dense
                outlined
                type="error"
            >
              {{ $t("add-gateway-dialog.syncDevicesError") }}
            </v-alert>
          </v-stepper-content>

          <v-stepper-content step="not-found"
                             class="pa-0 pt-4">
            <add-gateway-not-found :gwId="gwId"/>
          </v-stepper-content>

          <v-stepper-content step="already-in-use"
                             class="pa-0 pt-4">
            <add-gateway-already-in-use :gwId="gwId"/>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

    </template>

    <template v-slot:actions>
      <v-stepper v-model="stepper"
                 width="100%"
                 flat class="transparent">
        <v-stepper-items>
          <v-stepper-content step="1"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   class="font-weight-bold action-button"
                   v-text="$t('app.continue')"
                   @click="stepper++">
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   class="font-weight-bold action-button"
                   :disabled="!gwIdValid || loading"
                   :loading="loading"
                   v-text="$t('add-gateway-dialog.add')"
                   @click="addGateway"/>
            <v-btn depressed large
                   class="mt-4 action-button"
                   @click="stepper--"
                   v-text="$t('app.go-back')"/>
          </v-stepper-content>

          <v-stepper-content step="success"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   class="font-weight-bold action-button"
                   :disabled="!gwIdValid || !devicesSynced"
                   v-text="$t('device-list.add-device')"
                   @click="addDevices"/>
            <v-btn depressed large
                   class="mt-4 action-button"
                   @click="close"
                   :disabled="!devicesSynced"
                   v-text="$t('add-gateway-dialog.add-device-later')"/>
          </v-stepper-content>

          <v-stepper-content step="not-found"
                             class="pa-0">
            <v-btn depressed large
                   class="mt-4 action-button"
                   @click="resetFromError()"
                   v-text="$t('app.go-back')"/>
          </v-stepper-content>

          <v-stepper-content step="already-in-use"
                             class="pa-0">
            <v-btn depressed large
                   class="mt-4 action-button"
                   @click="stepper=2"
                   v-text="$t('app.go-back')"/>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import AddGatewayStep1 from "@/templates/dialogs/addGateway/AddGatewayStep1";
import AddGatewayStep2 from "@/templates/dialogs/addGateway/AddGatewayStep2";
import AddGatewayNotFound from "@/templates/dialogs/addGateway/AddGatewayNotFound";
import AddGatewayAlreadyInUse from "@/templates/dialogs/addGateway/AddGatewayAlreadyInUse";
import AddGatewaySuccess from "@/templates/dialogs/addGateway/AddGatewaySuccess";

export default {
  name: 'AddGatewayDialog',

  components: {
    AddGatewaySuccess,
    AddGatewayAlreadyInUse,
    AddGatewayNotFound,
    AddGatewayStep2,
    AddGatewayStep1,
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: function () {
    return {
      title: this.$t('add-gateway-dialog.title'),
      color: 'primary',
      stepper: 1,
      gwId: null,
      gwIdValid: null,
      loading: false,
      updatedStarted: null,
      firmwareVersion: null,
      devicesSynced: false,
      showErrorSyncDevicesFailed: false,
      numDevicesSynced: 0
    }
  },

  methods: {
    /**
     * initialize dialog data, parameter stepperStep (default = 1) for resetting to specific step
     * after error case
     */
    init(stepperStep = 1) {
      this.title = this.$t('add-gateway-dialog.title')
      this.color = 'primary'
      this.stepper = stepperStep
      this.gwId = null
      this.gwIdValid = null
      this.devicesSynced = false
      this.showErrorSyncDevicesFailed = false
      this.numDevicesSynced = 0
    },

    /**
     * updates the gwIdValid variable
     *
     * @param valid
     */
    setGwIdValid(valid) {
      this.gwIdValid = valid
    },

    /**
     * adds a gateway to the currently active site
     */
    addGateway() {
      this.loading = true
      let self = this
      this.$rhRequest.sendPost({
            endpoint: 'gateway-registration/add-gw-id',
            data: {
              gwId: this.gwId.toUpperCase()
            }
          },
          (response) => {
            if (response?.data?.code === 0) {
              self.updatedStarted = response?.data?.data?.updatedStarted
              self.firmwareVersion = response?.data?.data?.firmwareVersion
              self.color = 'success'
              self.title = this.$t('add-gateway-dialog.title.success')
              self.stepper = 'success'
              self.syncDevices()
            } else {
              self.$root.bisatoast.error({message: self.$t('app.generic-error')})
            }
            self.loading = false
          },
          (err) => {
            self.title = this.$t('add-gateway-dialog.title.error')
            self.color = 'error'
            self.loading = false
            if (err.response.status === 404) {
              self.stepper = 'not-found'
            } else if(err.response.status === 409) {
              self.stepper = 'already-in-use'
            } else {
              // unexpected error
              self.$root.bisatoast.error({message: self.$t('app.generic-error')})
              console.error(err)
            }
          })
    },

    syncDevices() {
      this.devicesSynced = false
      this.showErrorSyncDevicesFailed = false
      this.numDevicesSynced = 0
      this.$rhRequest.sendGet({
          endpoint: 'devices/sync',
          timeout: 300000
        },
        (response) => {
          this.devicesSynced = true
          // assign number of added devices (notice: the controller has to be subtracted from the number)
          this.numDevicesSynced = response?.data?.data?.add?.new > 0 ? response?.data?.data?.add?.new - 1 : 0
        },
        () => {
          this.devicesSynced = true
          this.showErrorSyncDevicesFailed = true
        }
      );
    },

    /**
     * opens the add device dialog
     */
    addDevices() {
      this.$root.bisadialog.toggle('addGateway')
      this.$root.bisadialog.toggle('addDevice', true, {})
      this.$root.bisadialog.callDialogInit('addDevice')
    },

    /**
     * resets dialog title and color after error occured
     */
    resetFromError() {
      this.init(2)
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('addGateway')
    }
  }

};
</script>
