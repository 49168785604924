<template>
  <fullscreen-overlay-frame :title="$t('edit-device-permissions-dialog.title').toString()"
                            icon="supervisor_account"
                            color="primary"
                            centered
                            closable
                            @close="abort">

    <template v-slot:content>
      <div class="font-size-03 mb-6"
           v-html="$t('edit-device-permissions-dialog.description', {name: data?.userName})"/>

      <device-permissions-card v-model="devicePermissions"
                               :devices="devices"
                               :compleo-connections="compleoConnection"/>
    </template>

    <template v-slot:actions>
      <v-btn depressed large
             :loading="loading.save"
             :disabled="loading.save"
             color="primary"
             class="font-weight-bold action-button"
             @click="submit">
        {{ $t('app.save') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import DevicePermissionsCard from "@/templates/components/profile/DevicePermissionsCard";
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";

export default {
  name: 'EditDevicePermissionsDialog',

  components: {
    FullscreenOverlayFrame,
    DevicePermissionsCard,
  },

  props: ['data'],

  data: function () {
    return {
      loading: {
        get: false,
        save: false
      },
      devices: [],
      compleoConnection: null,
      devicePermissions: {
        devices: [],
        compleoConnection: false
      },
      weekdaysInit: [
        true,
        true,
        true,
        true,
        true,
        false,
        false
      ]
    }
  },

  methods: {
    /**
     * dialog initialization
     */
    init() {
      this.devices = []
      this.compleoConnection = null
      this.devicePermissions = {
        devices: [],
        compleoConnection: false
      }
      this.getData()
    },

    /**
     * get devices
     */
    getData() {
      this.loading.get = true // the first response may set loading to false as there is now data to display
      let userId = this.data.userId

      // Nukis and BurgWaechter devices
      this.$rhRequest.sendGet({
        endpoint: 'settings/get-nuki-devices',
        params: {
          invitedUserId: userId
        }
      }, (response) => {
        response?.data?.data?.forEach(device => {

          if(device.permissions?.active_days) {
            device['weekdays'] = {}
            let activeDays = device.permissions.active_days.split(',')
            for (let i = 0; i < 7; i++) {
              device.weekdays[i] = activeDays.includes(i.toString())
            }
            device.hasDayRestriction = true
          } else {
            device['weekdays'] = Object.assign({}, this.weekdaysInit)
          }
          if(device.permissions?.time_end || device.permissions?.time_start) {
            device.hasTimeRestriction = true
            device.restrictionTimeFrom = device.permissions?.time_start ? new Date(device.permissions.time_start.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : null
            device.restrictionTimeTo = device.permissions?.time_end ? new Date(device.permissions.time_end.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : null
          }
          if (device.selected) {
            this.devicePermissions.devices.push(device.device_key)
          }
          this.devices.push(device)
        })
        this.loading.get = false
      }, (error) => {
        console.error(error)
        this.loading.get = false
      })

      // Wilkas
      this.$rhRequest.sendGet({
        endpoint: 'settings/get-wilka-devices',
        params: {
          invitedUserId: userId
        }
      }, (response) => {
        response?.data?.data?.forEach(device => {
          if(device.permissions.active_days) {
            device['weekdays'] = {}
            let activeDays = device.permissions.active_days.split(',')
            for (let i = 0; i < 7; i++) {
              device.weekdays[i] = activeDays.includes(i.toString())
            }
            device.hasDayRestriction = true
          } else {
            device['weekdays'] = Object.assign({}, this.weekdaysInit)
          }
          if(device.permissions?.time_end || device.permissions?.time_start) {
            device.hasTimeRestriction = true
            device.restrictionTimeFrom = device.permissions?.time_start ? new Date(device.permissions.time_start.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : null
            device.restrictionTimeTo = device.permissions?.time_end ? new Date(device.permissions.time_end.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : null
          }
          this.devices.push(device)
          if (device.selected) {
            this.devicePermissions.devices.push(device.device_key)
          }
        })
        this.loading.get = false
      }, (error) => {
        console.error(error)
        this.loading.get = false
      })

      // Compleo connection
      this.$rhRequest.sendGet({
        endpoint: 'settings/get-charging-devices',
        params: {
          invitedUserId: userId
        }
      }, (response) => {
        if (response.data.data) {
          this.compleoConnection = response.data.data
          this.devicePermissions.compleoConnection = !!response.data?.data[0]?.isWhitelisted;
          this.compleoConnection = []
          response.data.data.forEach(item => {
            if(item.permissions.active_days) {
              item['weekdays'] = {}
              let activeDays = item.permissions.active_days.split(',')
              for (let i = 0; i < 7; i++) {
                item.weekdays[i] = activeDays.includes(i.toString())
              }
              item.hasDayRestriction = true
            } else {
              // assign object without reference to original variable
              item['weekdays'] = Object.assign({}, this.weekdaysInit)
            }
            if(item.permissions?.time_end || item.permissions?.time_start) {
              item.hasTimeRestriction = true
              item.restrictionTimeFrom = item.permissions?.time_start ? new Date(item.permissions.time_start.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : null
              item.restrictionTimeTo = item.permissions?.time_start ? new Date(item.permissions.time_end.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : null
            }
            this.compleoConnection.push(item)
          })
          this.loading.get = false
        }
      }, (error) => {
        console.error(error)
        this.loading.get = false
      })
    },

    /**
     * submits the user selection
     */
    submit() {
      this.loading.save = true
      let deviceList = []
      this.devices.forEach((device) => {
        deviceList.push({
          device: device,
          selected: device.selected,
        })
      })

      let postBody = {
        userId: this.data.userId,
        deviceList: deviceList
      }
      if (this.compleoConnection?.length > 0) {
        postBody.chargingConnections = this.compleoConnection
        postBody.shareChargingConnection = this.devicePermissions.compleoConnection
      }

      if(this.devicePermissions.compleoConnection && this.devicePermissions.compleoRestriction.hasDayRestriction) {
        postBody.compleoPermissions = this.devicePermissions.compleoRestriction
      }

      this.$rhRequest.sendPost({
        endpoint: "settings/set-access-devices",
        data: postBody
      }, () => {
        this.loading.save = false
        this.$root.bisatoast.success({message: this.$t('edit-device-permissions-dialog.success')})
        this.$root.bisadialog.toggle('editDevicePermissions')
      }, (error) => {
        console.error(error)
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        this.loading.save = false
      })
    },

    /**
     * close dialog without submitting data
     */
    abort() {
      this.$root.bisadialog.toggle('editDevicePermissions')
    }
  }
}

</script>

