<template>
  <div class="device-icon">
    <icon-frame :icon-name="getIcon(deviceType)"
                :icon-type="getIconType(deviceType)"
                :icon-color="getIconColor(isColorized)"/>
  </div>
</template>

<script>

import deviceTypeIcons from "@/config/deviceTypeIcons.json";
import IconFrame from "@/templates/components/IconFrame.vue";

export default {
  name: 'DeviceIcon',
  components: {IconFrame},
  props: {
    deviceType: {
      type: String,
      default: ''
    },
    isColorized: {
      type: Boolean,
      required: false,
      default: false
    },

  },
  methods: {
    /**
     * Checks if device type is listed in deviceTypeIcons.json and returns icon-type for a passed device type
     *
     * @param type
     * @returns {string}
     */
    getIconType(type) {
      if (Object.hasOwn(deviceTypeIcons, type)) {
        return deviceTypeIcons[type].type
      }
    },
    /**
     * Checks if a device type is listed in deviceTypeIcons.json and returns a matching icon for a passed device type
     *
     * @param type
     * @returns {string}
     */
    getIcon(type) {
      if (Object.hasOwn(deviceTypeIcons, type)) {
        return deviceTypeIcons[type].icon ?? 'widgets'
      }
      return 'widgets'
    },
    /**
     * Checks if a device is active and returns color string
     *
     * @param type
     * @returns {string}
     */
    getIconColor(isColorized) {
      let color = this.$vuetify.theme.isDark ? 'theme--dark' : 'theme--light'
      if (isColorized) {
        color = 'primary'
      }
      return color
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/living/device-icon.scss';
</style>
