<template>
  <!-- No dense view: Nuki devices cannot be added to favorites. This is covered by AccessesCard.vue -->
  <device-card :flat="flat"
               :device="device"
               :title="$t('device-dialog.actuators.title').toString()"
               icon="tune">
    <sub-devices>
      <actuator-device hide-actions-slot
                       :device-id="device.id"
                       :actuator="device.sensors?.[0]"> <!-- special case nuki lock: pass first sensor for device event history on actuator -->
        <template v-slot:content>
          <nuki-lock :device="device"/>
        </template>
      </actuator-device>
    </sub-devices>
  </device-card>
</template>

<script>
import DeviceCard from "@/templates/components/devices/DeviceCard";
import SubDevices from "@/templates/components/devices/SubDevices";
import ActuatorDevice from "@/templates/components/devices/ActuatorDevice";
import NukiLock from "@/templates/components/devices/access/NukiLock";

export default {
  name: 'DeviceCardNukiLock',

  components: {NukiLock, ActuatorDevice, SubDevices, DeviceCard},

  props: {
    device: Object,
    flat: Boolean
  },
}

</script>
