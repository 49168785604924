<template>
  <v-card class="enode-card">

    <v-card-title class="pa-0 enode-card-header text-no-wrap">
      <car-brand-icon :carBrand="connectionDetails.brand"/>
      <div class="pt-5 pb-6 line-height-02 text-informatiion">
        <span class="font-weight-bold font-size-04 mr-4" v-html="title"/><br>
        <span class="font-size-02">{{ $t('enode-connection-tile.range') }} {{ connectionDetails.range }}{{ $t('app.unit.km') }}</span>
      </div>

      <div class="mx-2 additional-car-information d-flex">
        <div v-if="connectionDetails.pluggedIn && connectionDetails.reachable" class="connection-information" :style="{backgroundColor: connectionInformationBackground}">
          <v-icon color="primary" center>electric_car</v-icon>
        </div>
        <div v-else-if="!connectionDetails.pluggedIn && connectionDetails.reachable" class="connection-information">
          <v-icon center>power_off</v-icon>
        </div>
        <div v-else class="connection-information">
          <v-icon center>bolt</v-icon>
        </div>
      </div>

    </v-card-title>

    <v-card-text>

      <div class="additional-battery-information mb-1">
        <span class="battery-capacity">{{ connectionDetails.batteryCapacity }} {{ $t('app.unit.kwh') }}</span>
      </div>

      <v-progress-linear v-if="connectionDetails.batteryLevel"
                         :color="progressBarColor"
                         :value="connectionDetails.batteryLevel"
                         class="mb-0 charging-progress-bar"
                         :buffer-value="progressVarBufferValue"
                         :stream="connectionDetails.charging"/>


      <div class="additional-charging-information mt-1">
        <span v-if="connectionDetails.batteryLevel" class="battery-level">{{ connectionDetails.batteryLevel }}%</span>
        <span v-else class="battery-level">N/A</span>
        <span v-if="connectionDetails.charging" class="charge-time-remaining">{{ timeRemaining }}</span>
      </div>

      <enode-advanced-settings :vehicle-id="connectionDetails.id"/>

    </v-card-text>

    <v-card-actions>
      <v-btn block large depressed
             color="primary"
             class="font-weight-bold action-button"
             @click="requestCharging(connectionDetails.id, connectionDetails.charging)"
             :disabled="triggerStateChange || !connectionDetails.reachable || !connectionDetails.pluggedIn || connectionDetails.fullyCharged">
        <v-progress-circular
            v-if="triggerStateChange"
            indeterminate
            color="grey lighten-5"
        ></v-progress-circular>
        <span v-else-if="connectionDetails.charging">
          <v-icon small left>pause</v-icon>
          {{ $t('enode-connection-tile.stop-charging') }}
        </span>
        <span v-else>
          <v-icon small left>play_circle</v-icon>
          {{ $t('enode-connection-tile.start-charging') }}
        </span>
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import CarBrandIcon from "@/templates/components/CarBrandIcon";
import EnodeAdvancedSettings from "@/templates/components/emobility/EnodeAdvancedSettings.vue";

export default {
  name: "EnodeConnectionCard",
  components: {EnodeAdvancedSettings, CarBrandIcon},
  props: ['connectionDetails'],

  data() {
    return {
      greenCharging: false,
      timeBasedCharging: false,
      triggerStateChange: false,
      timeStamp: null,
      connectionInformationBackground: this.$vuetify.theme.currentTheme.primary + '33'
    }
  },
  methods: {
    requestCharging(vehicleId, isLoading) {
      let action = isLoading ? 'STOP' : 'START'

      this.triggerStateChange = true

      this.$rhRequest.sendPost({
            endpoint: 'enode/charge',
            data: {
              vehicleId: vehicleId,
              action: action
            }
          }, (resp) => {
            this.checkChargingState(resp?.data?.data?.id)
          },
          (err) => {
            this.$root.bisatoast.error({message: this.$t('app.generic-error')})
            this.triggerStateChange = false
            console.error(err)
          }
      )
    },
    checkChargingState(id) {
      this.timeStamp = this.timeStamp ?? Date.now()

      this.$rhRequest.sendGet({
        endpoint: 'enode/get-charge-action',
        params: {
          actionId: id
        }
      }, (resp) => {
        let successMsg = resp?.data?.data?.action === 'START' ? 'enode.success.start-charging' : 'enode.success.stop-charging'

        switch (resp?.data?.data?.state) {
          case 'PENDING':
            if ((Date.now() - this.timeStamp) < 60000) {
              setTimeout(() => {
                this.checkChargingState(id)
              }, 2000);
            } else {
              this.triggerStateChange = false
              this.$root.bisatoast.error({message: this.$t('app.generic-error')})
            }
            break;
          case 'FAILED':
          case 'CANCELLED':
            this.triggerStateChange = false
            this.timeStamp = null
            this.$root.bisatoast.error({message: this.$t('app.generic-error')})
            break;
          default:
            this.$root.bisatoast.success({message: this.$t(successMsg)})
            this.$emit('reloadConnectionDetails')
            this.triggerStateChange = false
            this.timeStamp = null
        }
      }, (err) => {
        this.timeStamp = null
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        console.error(err)
      })
    }
  },
  computed: {
    timeRemaining() {
      let timeRemainingString = 'N/A'
      let chargeTimeRemaining = this.connectionDetails?.chargeTimeRemaining
      let realMinutes
      let hours

      if (this.connectionDetails?.fullyCharged) {
        timeRemainingString = this.$t('enode-connection-tile.isFullyCharged')
      } else if (Number.isInteger(chargeTimeRemaining)) {
        realMinutes = this.connectionDetails.chargeTimeRemaining % 60

        if (chargeTimeRemaining > 60) {
          hours = (chargeTimeRemaining - realMinutes) / 60
          timeRemainingString = hours + this.$t('app.unit.hours') + ' ' + realMinutes + this.$t('app.unit.minutes') + ' ' + this.$t('enode-connection-tile.remaining-time') + ' ' + this.connectionDetails.chargeLimit + this.$t(
              'app.unit.percent')
        } else if (chargeTimeRemaining < 60) {
          timeRemainingString = realMinutes + this.$t('app.unit.minutes') + ' ' + this.$t('enode-connection-tile.remaining-time') + ' ' + this.connectionDetails.chargeLimit + this.$t(
              'app.unit.percent')
        }
      }

      return timeRemainingString
    },
    progressVarBufferValue() {
      return this.connectionDetails.charging ? 0 : 100
    },
    title() {
      return this.connectionDetails?.brand + ' ' + this.connectionDetails?.model
    },
    progressBarColor() {
      let color = '#E0E0E0'

      if (this.connectionDetails.pluggedIn && this.connectionDetails.reachable) {
        color = '#FCB662'
        if (this.connectionDetails.batteryLevel >= 80) {
          color = '#39D9BA'
        } else if (this.connectionDetails.batteryLevel <= 20) {
          color = '#FC627A'
        }
      }

      return color
    }
  },
  watch: {}
}
</script>

<style lang="scss">
@import '~@/styles/living/e-mobility.scss';
</style>
