<template>
  <content-card :title="device?.name"
                class="my-5">
    <template v-slot:content>
      <v-row class="px-5 pt-5">
        <v-col xs="12" sm="6" cols="12">
          <v-select outlined
                    disabled
                    v-model="propertySelection"
                    :items="properties"
                    return-object
                    single-line
                    hide-details
                    item-value="name"
                    @change="updateModel">
            <template v-slot:selection="data">
              <!-- HTML that describe how select should render selected items -->
              {{ itemTitle(data.item.name) }}
            </template>
            <template v-slot:item="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ itemTitle(data.item.name) }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="3" sm="2">
          <v-btn depressed small
                 disabled
                 class="font-size-04 font-weight-bold mx-auto operator-btn"
                 @change="updateModel">
            <v-icon large>chevron_right</v-icon>
          </v-btn>
        </v-col>

        <v-col xs="8" sm="4">
          <v-text-field outlined
                        v-model="valueWrapper"
                        type="number"
                        :hint="valueRangeHint"
                        :suffix="propertySelection?.unit"
                        @change="updateModel"/>
        </v-col>
      </v-row>

      <v-divider v-if="properties?.length > 0"/>

      <div class="pa-5"
           v-if="properties?.length > 0">
        {{ $t('add-rule-step-property-definition.condition-summary-label') }}<br/>
        <condition-summary :condition="value"/>
      </div>

    </template>
  </content-card>

</template>

<script>

import ContentCard from "@/templates/components/ContentCard.vue";
import ConditionSummary from "@/templates/components/living/rules/ConditionSummary.vue";
import deviceProperties from "@/config/deviceProperties.json";

export default {
  name: "ConditionSelection",
  components: {ConditionSummary, ContentCard},
  props: ['device', 'value'],
  data: () => ({
    propertySelection: null,
    compareValue: 0,
    valueInverted: false
  }),

  methods: {
    init() {
      if (this.device?.energyDataType === 'meter') this.propertySelection = this.properties[2]
      else if (this.device?.energyDataType === 'photovoltaic_inverter') this.propertySelection = this.properties[0]
    },
    updateModel() {
      let data = {
        type: "properties",
        deviceId: this.device.id,
        property: this.propertySelection.name,
        constraint: '>',
        value: this.compareValue,
        metaData: {
          device: this.device
        }
      }

      this.$emit('input', data)
    },
    /**
     * returns a translated label for the passed property name
     * @param name
     * @returns {string}
     */
    itemTitle(name) {
      return this.$t(deviceProperties[name]?.label.replace('{mec-meter-type}', this.device?.energyDataType))
    },
  },
  computed: {

    valueWrapper: {
      set(value) {
        if (this.valueInverted === true) {
          this.compareValue = Number(this.propertySelection?.maxValue) - value;
        } else {
          this.compareValue = value
        }
      },

      get() {
        if (this.valueInverted === true) {
          return Number(this.propertySelection?.maxValue) - this.compareValue;
        } else {
          return this.compareValue
        }
      }
    },

    /**
     * returns a list of selectable device properties
     * @returns {*|*[]}
     */
    properties() {
      let properties = this.device?.sensors
      if (properties == null) {
        return []
      }
      // remove properties marked as unsupported for use in property conditions
      return properties.filter(function (obj) {
        return deviceProperties[obj.name]?.automation?.selectableInConditions !== false
      })
    },

    /**
     * returns a text describing the allowed value range
     * @returns {null|string}
     */
    valueRangeHint() {
      let min = this.propertySelection?.minValue
      let max = this.propertySelection?.maxValue
      if (min && max) {
        return this.$t('app-rule-step-property-definition.value-range-hint.min-max', {min: min, max: max, unit: this.propertySelection?.unit})
      } else if (min) {
        return this.$t('app-rule-step-property-definition.value-range-hint.min', {min: min, unit: this.propertySelection?.unit})
      } else if (max) {
        return this.$t('app-rule-step-property-definition.value-range-hint.max', {max: max, unit: this.propertySelection?.unit})
      } else {
        return null
      }
    },
  },
  mounted() {
    this.init()
  },
  watch: {
    valueWrapper() {
      this.updateModel()
    }
  }
}
</script>
