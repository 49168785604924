<template>
  <v-container class="pa-0 ma-0">

    <!-- DIALOGS OF TYPE 'POPUP' -->
    <v-dialog v-model="dialogs.registrationCodeInput.visible"
              persistent :max-width="maxWidth">
      <registration-code-dialog/>
    </v-dialog>

    <v-dialog v-model="dialogs.registrationCodeValid.visible"
              persistent :max-width="maxWidth">
      <registration-code-valid-dialog :data="data"/>
    </v-dialog>

    <v-dialog v-model="dialogs.registrationCodeError.visible"
              persistent :max-width="maxWidth">
      <registration-code-error-dialog/>
    </v-dialog>

    <v-dialog v-model="dialogs.registrationMailError.visible"
              persistent :max-width="maxWidth">
      <registration-mail-error-dialog/>
    </v-dialog>

    <v-dialog v-model="dialogs.invitationCodeInput.visible"
              persistent :max-width="maxWidth">
      <invitation-code-dialog/>
    </v-dialog>

    <v-dialog v-model="dialogs.invitationCodeError.visible"
              persistent :max-width="maxWidth">
      <invitation-code-error-dialog/>
    </v-dialog>

    <v-dialog v-model="dialogs.profileConfirmDelete.visible"
              persistent max-width="550">
      <profile-confirm-delete-dialog/>
    </v-dialog>

    <v-dialog v-model="dialogs.clearCache.visible"
              persistent max-width="550">
      <clear-cache-dialog/>
    </v-dialog>

    <v-dialog v-model="dialogs.gatewayOfflineHelp.visible"
              persistent max-width="550">
      <gateway-offline-help-dialog/>
    </v-dialog>

    <v-dialog v-model="dialogs.firstLaunch.visible"
              persistent max-width="550">
      <first-launch-dialog/>
    </v-dialog>

    <v-dialog v-model="dialogs.infoText.visible"
              persistent max-width="550">
      <info-text-dialog :data="data"/>
    </v-dialog>

    <v-dialog v-model="dialogs.deleteEnodeConnection.visible"
              persistent max-width="550">
      <delete-enode-connection-dialog :data="data"/>
    </v-dialog>

    <v-dialog v-model="dialogs.unlinkEnodeUser.visible"
              persistent max-width="550">
      <unlink-enode-user-dialog/>
    </v-dialog>

    <v-dialog v-model="dialogs.confirmation.visible"
              persistent :max-width="maxWidth">
      <confirmation-dialog :data="data"/>
    </v-dialog>

    <v-dialog v-model="dialogs.energylineAccountLinking.visible"
              persistent width="90%" max-width="550">
      <account-linking-dialog :data="data"/>
    </v-dialog>

    <v-dialog v-model="dialogs.changeMail.visible"
              persistent :max-width="maxWidth">
      <change-mail-dialog :data="data"/>
    </v-dialog>

    <!-- DIALOGS OF TYPE 'OVERLAY' -->
    <!-- v-navigation-drawers load content even if v-model is false. Add v-if to content if it should only be executed
         when the corresponding overlay is visible. -->
    <v-navigation-drawer v-model="dialogs.language.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <language-dialog v-if="dialogs.language.visible"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.changePassword.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <change-password-dialog v-if="dialogs.changePassword.visible"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.darkMode.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <dark-mode-dialog v-if="dialogs.darkMode.visible"
                        ref="darkMode"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.inbox.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <inbox-dialog v-if="dialogs.inbox.visible"
                    :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.contact.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <contact-dialog v-if="dialogs.contact.visible"
                      :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.form.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <form-dialog v-if="dialogs.form.visible"
                   :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.hubspotForm.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <hubspot-form-dialog v-if="dialogs.hubspotForm.visible"
                           :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.hubspotCalendar.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <hubspot-calendar-dialog v-if="dialogs.hubspotCalendar.visible"
                               :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.device.visible"
                         fixed right :temporary="!dialogs.confirmation.visible"
                         :class="maximized ? 'width-large' : 'width-small'"
                         v-click-outside="clickOutside">
      <device-dialog v-if="dialogs.device.visible"
                     @maximize="maximized = true"
                     @minimize="maximized = false"
                     ref="device"
                     :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.addGateway.visible"
                         fixed right temporary
                         class="width-medium"
                         v-click-outside="clickOutside">
      <add-gateway-dialog v-if="dialogs.addGateway.visible"
                          ref="addGateway"
                          :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.addDevice.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <add-device-dialog v-if="dialogs.addDevice.visible"
                         ref="addDevice"
                         :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.newPassword.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <new-password-dialog v-if="dialogs.newPassword.visible"
                           :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.removeDevice.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <remove-device-dialog v-if="dialogs.removeDevice.visible"
                            ref="removeDevice"
                            :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.changeSite.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <change-site-dialog v-if="dialogs.changeSite.visible"
                          ref="changeSite"
                          :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.faq.visible"
                         fixed right temporary
                         class="width-medium"
                         v-click-outside="clickOutside">
      <faq-dialog v-if="dialogs.faq.visible"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.inviteUser.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <invite-user-dialog v-if="dialogs.inviteUser.visible"
                          ref="inviteUser"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.editDevicePermissions.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <edit-device-permissions-dialog v-if="dialogs.editDevicePermissions.visible"
                                      ref="editDevicePermissions"
                                      :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.changeUserData.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <change-user-data-dialog v-if="dialogs.changeUserData.visible"
                               ref="changeUserData"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.connectedAccounts.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <connected-accounts-dialog v-if="dialogs.connectedAccounts.visible"
                               ref="connectedAccounts"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.energylineDataSupport.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <energyline-data-support-dialog v-if="dialogs.energylineDataSupport.visible"
                                      :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.contentDetailPage.visible"
                         fixed right temporary
                         class="width-small px-0"
                         v-click-outside="clickOutside">
      <content-detail-page-dialog v-if="dialogs.contentDetailPage.visible"
                                  :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.pflegixCallback.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <pflegix-callback-dialog v-if="dialogs.pflegixCallback.visible"
                               :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.addSmarthomeComponent.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <add-smarthome-component-dialog v-if="dialogs.addSmarthomeComponent.visible"
                                      ref="addSmarthomeComponent"
                                      :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.addEmobilityConnection.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <add-emobility-connection-dialog v-if="dialogs.addEmobilityConnection.visible"
                                      ref="addEmobilityConnection"
                                      :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.addHeatingMode.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <add-heating-mode-dialog v-if="dialogs.addHeatingMode.visible"
                               ref="addHeatingMode"
                               :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.enodeTimeSetting.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <enode-time-setting-dialog v-if="dialogs.enodeTimeSetting.visible"
                               ref="enodeTimeSetting"
                               :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.enodeGreenChargingSetting.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <enode-green-charging-setting-dialog v-if="dialogs.enodeGreenChargingSetting.visible"
                               ref="enodeGreenChargingSetting"
                               :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.heatingModesOverview.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <heating-modes-overview-dialog v-if="dialogs.heatingModesOverview.visible"
                                     ref="heatingModesOverview"
                                     :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.addRule.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <add-rule-dialog v-if="dialogs.addRule.visible"
                       ref="addRule"
                       :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.addScene.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <add-scene-dialog v-if="dialogs.addScene.visible"
                       ref="addScene"
                       :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.addNukiBridge.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <add-nuki-bridge-dialog v-if="dialogs.addNukiBridge.visible"
                              ref="addNukiBridge"
                              :data="data"/>
    </v-navigation-drawer>

    <!-- individual dialog types -->
    <v-dialog v-model="dialogs.onboarding.visible"
              persistent width="90%" :max-width="750">
      <onboarding-dialog :data="data"/>
    </v-dialog>

    <v-navigation-drawer v-model="dialogs.userPermission.visible"
                         fixed right temporary
                         class="width-small"
                         v-click-outside="clickOutside">
      <user-permission-dialog v-if="dialogs.userPermission.visible"
                              ref="userPermission"
                              :data="data"/>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="dialogs.realTimeEnergyPrices.visible"
                         fixed right temporary
                         class="width-medium"
                         v-click-outside="clickOutside">
      <real-time-energy-prices-overlay v-if="dialogs.realTimeEnergyPrices.visible"
                                       ref="realTimeEnergyPrices"
                                       :data="data"
      />
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import RegistrationCodeDialog from "@/templates/dialogs/RegistrationCodeDialog";
import RegistrationCodeValidDialog from "@/templates/dialogs/RegistrationCodeValidDialog";
import RegistrationCodeErrorDialog from "@/templates/dialogs/RegistrationCodeErrorDialog";
import RegistrationMailErrorDialog from "@/templates/dialogs/RegistrationMailErrorDialog";
import ProfileConfirmDeleteDialog from "@/templates/dialogs/ProfileConfirmDeleteDialog";
import LanguageDialog from "@/templates/dialogs/LanguageDialog";
import ChangePasswordDialog from "@/templates/dialogs/ChangePasswordDialog";
import DarkModeDialog from "@/templates/dialogs/DarkModeDialog";
import InboxDialog from "@/templates/dialogs/InboxDialog";
import ContactDialog from "@/templates/dialogs/ContactDialog";
import FormDialog from "@/templates/dialogs/FormDialog";
import HubspotFormDialog from "@/templates/dialogs/HubspotFormDialog";
import HubspotCalendarDialog from "@/templates/dialogs/HubspotCalendarDialog";
import ClearCacheDialog from "@/templates/dialogs/ClearCacheDialog";
import GatewayOfflineHelpDialog from "@/templates/dialogs/GatewayOfflineHelpDialog";
import FirstLaunchDialog from "@/templates/dialogs/FirstLaunchDialog";
import DeviceDialog from "@/templates/dialogs/devices/DeviceDialog";
import AddGatewayDialog from "@/templates/dialogs/addGateway/AddGatewayDialog";
import AddDeviceDialog from "@/templates/dialogs/addDevice/AddDeviceDialog";
import InfoTextDialog from "@/templates/dialogs/InfoTextDialog";
import DeleteEnodeConnectionDialog from "@/templates/dialogs/DeleteEnodeConnectionDialog";
import UnlinkEnodeUserDialog from "@/templates/dialogs/UnlinkEnodeUserDialog";
import RemoveDeviceDialog from "@/templates/dialogs/removeDevice/RemoveDeviceDialog";
import NewPasswordDialog from "@/templates/dialogs/NewPasswordDialog";
import ChangeSiteDialog from "@/templates/dialogs/ChangeSiteDialog";
import FaqDialog from "@/templates/dialogs/FaqDialog";
import EnodeTimeSettingDialog from "@/templates/dialogs/enode/EnodeTimeSettingDialog.vue";
import EnodeGreenChargingSettingDialog from "@/templates/dialogs/enode/EnodeGreenChargingSettingDialog.vue";
import InviteUserDialog from "@/templates/dialogs/inviteUsers/InviteUserDialog";
import ConfirmationDialog from "@/templates/dialogs/ConfirmationDialog";
import EditDevicePermissionsDialog from "@/templates/dialogs/inviteUsers/EditDevicePermissionsDialog";
import InvitationCodeDialog from "@/templates/dialogs/registration/InvitationCodeDialog";
import InvitationCodeErrorDialog from "@/templates/dialogs/inviteUsers/InvitationCodeErrorDialog";
import ChangeUserDataDialog from "@/templates/dialogs/ChangeUserDataDialog";
import ConnectedAccountsDialog from "@/templates/dialogs/ConnectedAccountsDialog";
import OnboardingDialog from "@/templates/dialogs/OnboardingDialog";
import AccountLinkingDialog from "@/templates/dialogs/energy/AccountLinkingDialog.vue";
import EnergylineDataSupportDialog from "@/templates/dialogs/energy/EnergylineDataSupportDialog.vue";
import AddHeatingModeDialog from "@/templates/dialogs/addAutomation/AddHeatingModeDialog";
import HeatingModesOverviewDialog from "@/templates/dialogs/HeatingModesOverviewDialog";
import ChangeMailDialog from "@/templates/dialogs/ChangeMailDialog.vue";
import PflegixCallbackDialog from "@/templates/dialogs/discover/PflegixCallbackDialog.vue";
import ContentDetailPageDialog from "@/templates/dialogs/ContentDetailPageDialog.vue";
import AddSmarthomeComponentDialog from "@/templates/dialogs/AddSmarthomeComponentDialog.vue";
import AddEmobilityConnectionDialog from "@/templates/dialogs/AddEmobilityConnectionDialog.vue";
import AddRuleDialog from "@/templates/dialogs/automations/AddRuleDialog.vue";
import AddNukiBridgeDialog from "@/templates/dialogs/addNukiBridge/AddNukiBridgeDialog.vue";
import AddSceneDialog from "@/templates/dialogs/automations/AddSceneDialog.vue";
import UserPermissionDialog from "@/templates/dialogs/userPermission/UserPermissionDialog.vue";

export const DialogTypes = Object.freeze({
  POPUP: "POPUP",
  OVERLAY: "OVERLAY"
});

export default {
  name: 'BisaDialog',

  components: {
    AddSceneDialog,
    AddRuleDialog,
    ContentDetailPageDialog,
    ChangeMailDialog,
    EnergylineDataSupportDialog,
    AccountLinkingDialog,
    OnboardingDialog,
    ChangeUserDataDialog,
    ConnectedAccountsDialog,
    InvitationCodeErrorDialog,
    InvitationCodeDialog,
    EditDevicePermissionsDialog,
    ConfirmationDialog,
    InviteUserDialog,
    ChangeSiteDialog,
    FaqDialog,
    EnodeTimeSettingDialog,
    EnodeGreenChargingSettingDialog,
    RemoveDeviceDialog,
    InfoTextDialog,
    DeleteEnodeConnectionDialog,
    UnlinkEnodeUserDialog,
    AddDeviceDialog,
    AddGatewayDialog,
    DeviceDialog,
    FirstLaunchDialog,
    GatewayOfflineHelpDialog,
    ClearCacheDialog,
    FormDialog,
    HubspotFormDialog,
    HubspotCalendarDialog,
    ContactDialog,
    InboxDialog,
    ChangePasswordDialog,
    LanguageDialog,
    ProfileConfirmDeleteDialog,
    RegistrationMailErrorDialog,
    RegistrationCodeErrorDialog,
    RegistrationCodeValidDialog,
    RegistrationCodeDialog,
    NewPasswordDialog,
    PflegixCallbackDialog,
    DarkModeDialog,
    AddSmarthomeComponentDialog,
    AddEmobilityConnectionDialog,
    AddHeatingModeDialog,
    HeatingModesOverviewDialog,
    AddNukiBridgeDialog,
    UserPermissionDialog
  },

  data: function () {
    return {
      maxWidth: 960,
      data: {},
      maximized: false,
      dialogs: {
        registrationCodeInput: {
          visible: false,
          type: DialogTypes.POPUP
        },
        'registrationCodeValid': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'registrationCodeError': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'registrationMailError': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'invitationCodeInput': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'invitationCodeError': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'profileConfirmDelete': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'language': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'changePassword': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'darkMode': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'inbox': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'contact': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'form': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'hubspotForm': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'hubspotCalendar': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'clearCache': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'gatewayOfflineHelp': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'firstLaunch': {
          visible: false,
          type: DialogTypes.POPUP
        },
        device: {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'addDevice': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'removeDevice': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'addGateway': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'infoText': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'deleteEnodeConnection': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'unlinkEnodeUser': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'confirmation': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'newPassword': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'changeSite': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'faq': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'inviteUser': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'editDevicePermissions': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'changeUserData': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'connectedAccounts': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'onboarding': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'energylineAccountLinking': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'energylineDataSupport': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'changeMail': {
          visible: false,
          type: DialogTypes.POPUP
        },
        'loadComponent': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'pflegixCallback': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'contentDetailPage': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'addSmarthomeComponent': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'addEmobilityConnection': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'addHeatingMode': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'enodeTimeSetting': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'enodeGreenChargingSetting': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'heatingModesOverview': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'addRule': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'addScene': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'addNukiBridge': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'userPermission': {
          visible: false,
          type: DialogTypes.OVERLAY
        },
        'realTimeEnergyPrices': {
          visible: false,
          type: DialogTypes.OVERLAY
        }
      }
    }
  },

  methods: {
    /**
     * Calls the method init() on the dialog.
     * Don't forget to add a ref with the dialog key to the dialog component
     * @see https://rockethome.atlassian.net/wiki/spaces/DEV/pages/2491678721/Bisaknosp#Bisa-Dialog
     *
     * @param key
     */
    callDialogInit(key) {
      this.$nextTick(() => { // wait until refs are defined
        this.$refs[key].init()
      })
    },

    /**
     * toggles the dialog
     * @see https://rockethome.atlassian.net/wiki/spaces/DEV/pages/2491678721/Bisaknosp#Bisa-Dialog
     *
     * @param key
     * @param visible
     * @param data
     */
    toggle(key, visible = null, data = null) {
      if (!(key in this.dialogs)) {
        console.error("no dialog with key " + key + " registered!")
        return
      }

      this.data = data
      data?.maxWidth ? this.maxWidth = data.maxWidth : 960

      this.dialogs[key].visible = visible ? visible : !this.dialogs[key].visible

      if (this.dialogs[key].type === DialogTypes.POPUP) {
        this.$emit('blur')
      }

      if (this.dialogs[key].visible === false) {
        this.emitClose()
      } else {
        // Hide vertical scroll bars on <html> when a dialog is opened
        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = 'hidden'
      }
    },

    /**
     * emits the dialog close event for v-navigation-drawers when clicked outside
     *
     * @param e
     */
    clickOutside(e) {
      if (e.target.className === "v-overlay__scrim") {
        this.emitClose()
      }
    },

    emitClose() {
      // reset fullscreen setting on close
      this.maximized = false

      this.$emit('close')

      // Reset vertical scroll bars on <html> when a dialog is closed
      let elHtml = document.getElementsByTagName('html')[0]
      elHtml.style.overflowY = 'auto'
    }
  },

};
</script>

<style lang="scss">
@import '~@/styles/app.scss';
@import '~@/styles/dialog.scss';
</style>
