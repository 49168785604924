export default {
  install (Vue, options) {
    
    Vue.prototype.$econda = {
      enabled: options.enabled,
      send: function (payload) {
        if (typeof window.emos3 === 'undefined') return
        if (this.enabled) {
          window.emos3.send(payload);
        }
      },
      sendDiscover: function (name) {
        let payload = {Target : ['discover', name, 1, 'a']};
        this.send(payload);
      }
    }
    
    if (!options.enabled) return;
  
    // ===================================
    // Econda defaults
    // ===================================
    window.econda = {
      onReady:[],
      ready: function(f) {
        this.onReady.push(f);
      }
    };
    window.emos3 = window.emos3 || {
      defaults : {
        siteid : options.siteId,
        countryid: 'DE',
        langid : 'de'
      },
      stored:[],
      send: function(p){
        this.stored.push(p);
      }
    };
    // ===================================

    let scriptEl = document.createElement('script');
    scriptEl.setAttribute('defer', 'defer');
    scriptEl.setAttribute('type', 'text/javascript');
    scriptEl.setAttribute('src', 'https://l.ecn-ldr.de/loader/loader.js');
    scriptEl.setAttribute('client-key', options.clientKey);
    scriptEl.setAttribute('container-id', options.containerId);
    document.body.appendChild(scriptEl);
  }
}
