<template>
  <div class="page-content">
    <v-skeleton-loader v-if="loading"
                       type="article"
                       height="300"/>

    <div v-else-if="!loading && hasWaterControlDevices"
         v-for="(waterControl, index) in waterControls"
         v-bind:key="index">

      <water-control-tile :waterControlStatus="waterControl"/>
      <!-- Added a class for bottom distance -->
      <water-control-animation :waterControlStatus="waterControl" class="mb-14"/>

    </div>

    <!-- Empty State -->
    <div v-else>
      <empty-state
          :empty-state-title="$t('water-control-page.emptyState.title').toString()"
          :empty-state-text="emptyStateText">
        <template v-slot:illustration>
          <water-control-empty-state-illustration
              :fill-primary="'var(--v-primary-base)'"
              :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>
    </div>
  </div>
</template>

<script>
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import WaterControlEmptyStateIllustration from "@/templates/components/emptyStates/svg/WaterControlEmptyStateIllustration";
import config from '@/config/config.app.json'
import requestHelper from "@/scripts/requestHelper";
import WaterControlTile from "@/templates/components/waterControl/WaterControlTile";
import WaterControlAnimation from "@/templates/components/waterControl/WaterControlAnimation";

export default {
  name: "WaterControlPage",

  components: {WaterControlTile, WaterControlAnimation, EmptyState, WaterControlEmptyStateIllustration},

  data() {
    return {
      waterControls: {},
      hasWaterControlDevices: false,
      emptyStateText: this.$t("water-control-page.emptyState.text", {portal_url: config.portalUrl}),
      refreshRate: config.dataRefreshRate,
      loading: false
    }
  },

  methods: {
    getWaterControlStatus: function () {
      let self = this
      this.$rhRequest.sendGet({
        endpoint: 'devices/get',
        params: {
          deviceType: 'net-watermodule'
        }
      }, (resp) => {
        if(resp?.data?.code !== -1) {
          this.waterControls = resp?.data?.data
          this.hasWaterControlDevices = !(Array.isArray(this.waterControls) && this.waterControls?.length === 0)
        } else {
          this.hasWaterControlDevices = false
        }

        self.loading = false
      }, (error) => {
        console.error(error)
        self.loading = false
      })
    },

    /**
     * continuously refresh data
     */
    updateInterval () {
      this.timer = setInterval(() => {
        this.getWaterControlStatus()
      }, this.refreshRate)
    }
  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
        () => this.getWaterControlStatus(),
        () => this.updateInterval()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
