<template>
    <div class="brand-icon mx-2">
      <v-img class="icon" v-if="carIcon === 'audi'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/audi.png"/>
      <v-img class="icon" v-if="carIcon === 'bmw'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/bmw.png"/>
      <v-img class="icon" v-if="carIcon === 'cadillac'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/cadillac.png"/>
      <v-img class="icon" v-if="carIcon === 'chevrolet'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/chevrolet.png"/>
      <v-img class="icon" v-if="carIcon === 'citroen'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/citroen.png"/>

      <v-img class="icon" v-if="carIcon === 'cupra'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/cupra.png"/>
      <v-img class="icon" v-if="carIcon === 'ds'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/ds.png"/>
      <v-img class="icon" v-if="carIcon === 'fiat'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/fiat.png"/>
      <v-img class="icon" v-if="carIcon === 'ford'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/ford.png"/>
      <v-img class="icon" v-if="carIcon === 'hyundai'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/hyundai.png"/>

      <v-img class="icon" v-if="carIcon === 'jaguar'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/jaguar.png"/>
      <v-img class="icon" v-if="carIcon === 'kia'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/kia.png"/>
      <v-img class="icon" v-if="carIcon === 'landrover'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/landrover.png"/>
      <v-img class="icon" v-if="carIcon === 'mazda'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/mazda.png"/>
      <v-img class="icon" v-if="carIcon === 'mercedes'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/mercedes.png"/>

      <v-img class="icon" v-if="carIcon === 'mini'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/mini.png"/>
      <v-img class="icon" v-if="carIcon === 'nio'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/nio.png"/>
      <v-img class="icon" v-if="carIcon === 'nissan'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/nissan.png"/>
      <v-img class="icon" v-if="carIcon === 'opel'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/opel.png"/>
      <v-img class="icon" v-if="carIcon === 'peugeot'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/peugeot.png"/>

      <v-img class="icon" v-if="carIcon === 'porsche'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/porsche.png"/>
      <v-img class="icon" v-if="carIcon === 'renault'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/renault.png"/>
      <v-img class="icon" v-if="carIcon === 'rivian'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/rivian.png"/>
      <v-img class="icon" v-if="carIcon === 'seat'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/seat.png"/>
      <v-img class="icon" v-if="carIcon === 'skoda'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/skoda.png"/>

      <v-img class="icon" v-if="carIcon === 'tesla'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/tesla.png"/>
      <v-img class="icon" v-if="carIcon === 'toyota'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/toyota.png"/>
      <v-img class="icon" v-if="carIcon === 'vauxhall'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/vauxhall.png"/>
      <v-img class="icon" v-if="carIcon === 'volkswagen'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/volkswagen.png"/>
      <v-img class="icon" v-if="carIcon === 'volvo'" width="34px" centered contain src="~@/assets/images/emobilityCarBrands/volvo.png"/>
    </div>
</template>

<script>
export default {
  name: "CarBrandIcon",
  props: {
    carBrand: {
      default: 'audi',
      type: String
    }
  },
  data() {
    return {
      iconName: "",
    }
  },
  methods: {},
  computed: {
    carIcon() {
      return this.carBrand.toLowerCase()
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/e-mobility.scss';
</style>
