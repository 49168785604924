<!-- summary card for a user configured HAM condition -->

<template>
  <content-card :title="$t('condition-summary-card.title').toString()">
    <template v-slot:content>
      <v-list-item :three-line="condition.endTime != null || condition.repeatCount != null"
                   @click="editCondition">
        <v-list-item-content>
          <v-list-item-title>
            <condition-summary :condition="condition"/>
          </v-list-item-title>
          <v-list-item-subtitle v-if="condition.endTime && condition.repeatCount"
                                v-text="$t('condition-summary-card.end-time-and-repeat-count', {endTime: formatDate(condition.endTime), repeatCount: condition.repeatCount})"/>
          <v-list-item-subtitle v-else-if="condition.endTime"
                                v-text="$t('condition-summary-card.end-time', {endTime: formatDate(condition.endTime)})"/>
          <v-list-item-subtitle v-else-if="condition.repeatCount"
                                v-text="$t('condition-summary-card.repeat-count', {repeatCount: condition.repeatCount})"/>
        </v-list-item-content>
        <v-list-item-action class="ml-0">
          <v-btn icon
                 :disabled="!isSiteAdmin"
                 class="float-right"
                 @click="editCondition">
            <v-icon color="primary">edit</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard.vue";
import ConditionSummary from "@/templates/components/living/rules/ConditionSummary.vue";
import formats from "@/scripts/formats";

export default {
  name: 'ConditionSummaryCard',
  components: {
    ConditionSummary,
    ContentCard
  },

  props: ['condition'],

  computed: {
    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    }
  },

  methods: {
    editCondition() {
      if (!this.isSiteAdmin) {
        return
      }
      this.$emit('edit')
    },

    /**
     * returns a formatted string representation for date
     * @param date
     * @returns {string}
     */
    formatDate(date) {
      return formats.formatDateString(date, this.$t('app.date-time-format.date-only'))
    }
  }
}
</script>
