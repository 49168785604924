<template>
  <v-stepper-content step="13"
                     class="pb-2 pa-1">

    <div v-html="$t('add-rule-step-scene-selection.info-text')"/>

    <content-card :title="$t('add-rule-step-scene-selection.select-scene.title').toString()"
                  icon="widgets"
                  class="my-5">
      <template v-slot:content>
        <v-skeleton-loader v-if="loading"
                           type="table-tbody"/>
        <v-list v-else
                class="pa-0">
          <v-list-item v-if="items.length < 1">
            <!-- no actuator devices available -->
            <v-list-item-content v-text="$t('add-rule-step-scene-selection.empty-state')"/>
          </v-list-item>
          <v-radio-group v-model="itemSelection"
                         class="pa-0 ma-0"
                         @change="updateModel"
                         hide-details>
            <v-list-item class="list-item straight"
                         v-for="(item, itemId) in items" v-bind:key="item?.id">
              <v-list-item-icon class="mr-2">
                <v-radio :value="itemId"
                         class="pt-2 mr-2"/>
                <span v-if="item.attributes.iconKey"
                      class="material-symbols-outlined v-icon ma-0"
                      :class="$vuetify.theme.isDark ? 'theme--dark': 'theme--light'">
                  {{ item.attributes.iconKey }}
                </span>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold font-size-03">
                  {{ item.attributes.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="font-size-02">
                  <span>{{ $t("scenes-card.subtitle.actions-count", {n: item.attributes.actions.length}) }} </span>
                  <span v-if="item.attributes.delay">{{
                      $t("scenes-card.subtitle.delay", {delay: item.attributes.delay})
                    }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-radio-group>
        </v-list>
      </template>
    </content-card>

  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import automations, {actionTypes} from "@/scripts/automations";

export default {
  name: 'AddRuleStepSceneSelection',
  components: {
    ContentCard
  },

  props: {
    /**
     * v-model
     */
    value: {
      type: Object,
      default: null
    },
    /**
     * list of devices which should be excluded in the device selection
     */
    excludedItems: {
      type: Array
    },
  },

  data: function () {
    return {
      itemSelection: null,
      loading: false,
      items: []
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      return this.itemSelection !== null
    }
  },

  methods: {
    /**
     * call init, when the user is editing an action in a list of actions (as used in scenes) to pre-select the right
     * item in the device list
     *
     * @param item
     */
    init(item) {
      if (item == null) {
        this.getItems(null)
      } else {
        this.getItems(item)
      }
    },

    /**
     * get all devices which are available for rule actions
     */
    getItems(selectedItem) {
      this.loading = true
      this.$rhRequest.sendGet({
        endpoint: 'scenes/get'
      }, (resp) => {
        if (resp?.data?.data === null) {
          this.items = []
          this.loading = false
          return
        }

        let items = Object.values(resp?.data?.data)

        if (selectedItem === null) {
          // the user is adding a new action
          this.itemSelection = null
          // remove already assigned scenes from selection list
          this.excludedItems?.forEach(excludedItem => {
            if (excludedItem.type === automations.actionTypes.sceneRefs && items.filter(obj => {
              return obj.id === excludedItem.id
            })) {
              items.splice(items.findIndex(item => item.id === excludedItem.sceneRef.id), 1);
            }
          })
          this.items = items
        } else {
          // the user wants to edit an existing action: pre-select the previously selected scene
          this.itemSelection = items.findIndex(value => value?.id === selectedItem?.sceneRef?.id)
          this.items = items
          this.updateModel()
        }
        this.loading = false
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
        this.loading = false
      })
    },

    /**
     * updates v-model
     */
    updateModel() {
      if (this.items[this.itemSelection] == null) {
        return
      }

      this.$emit('input', {
        sceneRef: this.items[this.itemSelection].id,
        /*
         * The field 'name' is only included here so that we can display the name of the newly added scene in
         * ActionSummary before the rule or scene is saved. The field is not taken into account in the data service,
         * as a reference to the SceneCollection is created via the ID.
         */
        name: this.items[this.itemSelection].attributes.name,
        type: actionTypes.sceneRefs
      })
    }
  },

  mounted() {
    this.getItems()
  }
};
</script>
