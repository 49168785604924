<template>
    <fullscreen-overlay-frame :title="title"
                              icon="volunteer_activism"
                              :color="color"
                              centered
                              closable
                              @close="close">

        <template v-slot:content>

            <v-stepper v-model="stepper"
                       flat class="transparent">

                <div class="mb-5 primary--text font-weight-bold">{{ subtitle[stepper - 1] }}</div>

                <v-progress-linear
                        :value="progress"
                        color="primary accent-4"
                        height="5"
                        rounded
                        class="mt-10"
                        stream
                ></v-progress-linear>
                <div class="mt-12 mb-10">
                    {{ $t('discover.pflegix-callback-dialog.description') }}
                </div>


                <v-stepper-items>
                    <!-- first form page, dropdowns -->
                    <v-stepper-content step="1"
                                       class="pa-0 pt-4">
                        <v-form ref="form-step1">
                            <div class="font-weight-bold primary--text mb-5">{{ $t('discover.pflegix-callback-dialog.dropdown-family-label') }}</div>
                            <v-select v-model="beziehung" :label="$t('discover.pflegix-callback-dialog.dropdown-label')" :items="items.relationship" :rules="requiredRule" outlined/>
                            <div class="font-weight-bold primary--text mt-5 mb-5">{{ $t('discover.pflegix-callback-dialog.dropdown-pflegegrad-label') }}</div>
                            <v-select item-text="name" v-model="selectedPflegegrad" return-object :label="$t('discover.pflegix-callback-dialog.dropdown-label')" :items="items.pflegegrad"
                                      :rules="requiredRule" outlined/>
                        </v-form>
                    </v-stepper-content>

                    <!-- second page, contact data -->
                    <v-stepper-content step="2"
                                       class="pa-0 pt-4">
                        <v-form ref="form-step2">
                            <div class="font-weight-bold primary--text mb-5">{{ $t('discover.pflegix-callback-dialog.contact-data-label') }}</div>
                            <v-row>
                                <v-col class="pb-0" cols="6" sm="6">
                                    <v-text-field :label="$t('registration-name-page.first-name.label')"
                                                  :rules="requiredRule"
                                                  v-model="requestBody.address.firstname" outlined/>
                                </v-col>
                                <v-col class="pb-0" cols="6" sm="6">
                                    <v-text-field :label="$t('registration-name-page.last-name.label')"
                                                  :rules="requiredRule"
                                                  v-model="requestBody.address.lastname" outlined/>
                                </v-col>
                                <v-col class="py-0" cols="6" sm="6">
                                    <v-text-field :label="$t('registration-address-page.post-code.label')"
                                                  :rules="requiredRule"
                                                  v-model="zip" outlined/>
                                </v-col>
                                <v-col class="py-0" cols="6">
                                    <v-text-field :label="$t('discover.pflegix-callback-dialog.phone-label')"
                                                  :rules="eitherMailOrPhone.concat(phoneRule)"
                                                  persistent-hint
                                                  :hint="$t('discover.pflegix-callback-dialog.phone-hint')"
                                                  v-model="requestBody.address.mobile" outlined/>
                                </v-col>
                                <v-col class="pt-0" cols="12">
                                    <v-text-field :label="$t('login-page.e-mail.label')" :rules="emailRule.concat(eitherMailOrPhone)" v-model="requestBody.address.email" outlined/>
                                </v-col>
                            </v-row>
                        </v-form>

                    </v-stepper-content>

                    <v-stepper-content step="3"
                                       class="pa-0 pt-4">
                        <div class="font-weight-bold primary--text mb-5">{{ $t('discover.pflegix-callback-dialog.time-selection-label') }}</div>
                        <v-form ref="form-step3">
                            <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    class="mt"
                                    min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            :rules="requiredRule"
                                            v-model="dateFormatted"
                                            :label="$t('discover.pflegix-callback-dialog.time-selection.textfield-label')"
                                            readonly
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        v-model="date"
                                        :min="datePickerMin"
                                        :locale="langSetting"
                                        scrollable
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            text
                                            color="primary"
                                            @click="menu = false"
                                    >
                                        {{ $t('app.cancel') }}
                                    </v-btn>
                                    <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(date)"
                                    >
                                        {{ $t('app.select') }}
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                            <v-checkbox hide-details :rules="atLeastOneChecked" class="mt-0" v-model="timeslot.morning"
                                        :label="$t('discover.pflegix-callback-dialog.time-selection.slot-morning')"/>
                            <v-checkbox hide-details :rules="atLeastOneChecked" v-model="timeslot.midday" class="mt-0"
                                        :label="$t('discover.pflegix-callback-dialog.time-selection.slot-midday')"/>
                            <v-checkbox :rules="atLeastOneChecked" v-model="timeslot.afternoon" class="mt-0"
                                        :label="$t('discover.pflegix-callback-dialog.time-selection.slot-afternoon')"/>
                        </v-form>

                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </template>

        <template v-slot:actions>
            <v-stepper v-model="stepper"
                       width="100%"
                       flat class="transparent">
                <v-stepper-items>
                    <v-stepper-content step="1"
                                       class="pa-0">
                        <v-btn depressed large
                               color="primary"
                               class="font-weight-bold action-button"
                               v-text="$t('app.continue')"
                               @click="nextStep()">
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2"
                                       class="pa-0">
                        <v-btn depressed large
                               color="primary"
                               class="font-weight-bold action-button"
                               v-text="$t('app.continue')"
                               @click="nextStep()"/>
                        <v-btn depressed large
                               class="mt-4 action-button"
                               @click="stepper--"
                               v-text="$t('app.go-back')"/>
                    </v-stepper-content>

                    <v-stepper-content step="3"
                                       class="pa-0">
                        <v-btn depressed large
                               color="primary"
                               v-text="$t('app.send')"
                               class="font-weight-bold action-button"
                               @click="sendForm()"/>
                        <v-btn depressed large
                               class="mt-4 action-button"
                               @click="stepper--"
                               v-text="$t('app.go-back')"/>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>

        </template>
    </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import moment from "moment/moment";

export default {
  name: 'PflegixCallbackDialog',
  components: {
    FullscreenOverlayFrame
  },
  data: function () {
    return {
      title: this.$t('discover.pflegix-callback-dialog.title'),
      color: 'primary',
      stepper: 1,
      loading: false,
      subtitle: [
        this.$t('discover.pflegix-callback-dialog.title-page-1'),
        this.$t('discover.pflegix-callback-dialog.title-page-2'),
        this.$t('discover.pflegix-callback-dialog.title-page-3'),
      ],
      items: {
        relationship: [
          'Mich selbst',
          'Meine Mutter',
          'Meinen Vater',
          'Meine Ehefrau',
          'Meinen Ehemann',
          'Meine Tochter',
          'Meinen Sohn',
          'Meine Schwester',
          'Meinen Bruder',
          'Meine Großmutter',
          'Meinen Großvater',
          'Meine/n Partner/in',
          'Meine/n Nachbar/in',
          'Einen Patienten',
          'Andere'
        ],
        pflegegrad: [
          {name: 'Keiner', key: -1},
          {name: 'Beantragung erwünscht', key: -1},
          {name: 'Antrag wurde gestellt', key: -1},
          {name: 'Pflegegrad 1', key: 1},
          {name: 'Pflegegrad 2', key: 2},
          {name: 'Pflegegrad 3', key: 3},
          {name: 'Pflegegrad 4', key: 4},
          {name: 'Pflegegrad 5', key: 5},
        ]
      },
      selectedPflegegrad: null,
      requestBody: {
        address: {
          firstname: '',
          lastname: '',
          email: '',
          mobile: ''
        },
        careLevel: null,
        notes: null,
        campaign: "CARE_CONSULTATION",
        referrerUrl: "Habilio/App/pflegix"
      },
      date: null,
      dateFormatted: null,
      menu: false,
      valid: false,
      zip: null,
      beziehung: null,
      timeslot: {
        morning: false,
        midday: false,
        afternoon: false
      },
    }
  },
  methods: {
    /**
     * formats date in date-picker
     */
    formatDate(date) {
      if (!date) return null
      moment.locale(localStorage.getItem('langSetting'))
      return moment(date).format("DD.MM.YYYY")
    },
    /**
     * send form data to pflegix api
     */
    sendForm() {
      if (this.validateStep()) {
        this.concatNote()
        this.requestBody.careLevel = this.selectedPflegegrad.key
        this.$rhRequest.sendPost({
          endpoint: "discover/send-callback-form",
          data: this.requestBody
        }, () => {
          this.$root.bisatoast.success({
            message: this.$t("discover.pflegix-callback-dialog.success")
          })
          this.close()
          this.loading = false
        }, (e) => {
          window.console.log(e)
          this.$root.bisatoast.error({message: this.$t('app.generic-error')})
          this.close()
        })

      } else {
        this.$root.bisatoast.error({
          message: this.$t("discover.pflegix-callback-dialog.error")
        })
      }

    },
    /**
     * go to next step in stepper and validate current step
     */
    nextStep() {
      if (this.validateStep()) {
        this.stepper++
      }

    },
    /**
     * go to next step in stepper and validate current step
     */
    validateStep() {
      let step = "form-step" + this.stepper
      return this.$refs[step.toString()].validate()
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('pflegixCallback')
    },

    /**
     * sets note property of request body to include relevant data
     */
    concatNote() {
      let note = 'Pflegegrad: ' + this.selectedPflegegrad.name + '\nBeziehung: ' + this.beziehung + '\nPLZ: ' + this.zip + '\nGewünschtes Rückrufdatum: ' + this.date + '\nBevorzugte Zeit des Anrufs: '
      Object.entries(this.timeslot).forEach(([key, value]) => {
        if (value) {
          note += this.$t('discover.pflegix-callback-dialog.time-selection.slot-' + key) + '; '
        }
      })
      this.requestBody.notes = note
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
    }
  },
  computed: {
    progress() {
      if (this.stepper === 3) {
        return 100
      } else {
        return this.stepper * 33
      }
    },
    langSetting() {
      return localStorage.getItem('langSetting')
    },
    datePickerMin() {
      moment.locale(localStorage.getItem('langSetting'))
      return moment().format('YYYY-MM-DD')
    },
    requiredRule() {
      return [
        v => !!v || this.$t('app.rules.required'),
      ]
    },
    phoneRule() {
      return [
        v => (!v || /^\+/.test(v)) || this.$t('app.rules.phone-missing-prefix'),
        v => (!v || v.replace(/\s/g, '').length > 12) || this.$t('app.rules.phone-invalid')
      ]
    },
    emailRule() {
      return [
        v => !v || /.+@.+\..+/.test(v) || this.$t('app.rules.email-invalid')
      ]
    },
    eitherMailOrPhone() {
      return [
        (this.requestBody.address?.email.length > 0 || this.requestBody.address?.mobile.length > 0) || this.$t('discover.pflegix-callback-dialog.phone-or-email')
      ]
    },
    atLeastOneChecked() {
      return [
        !!(this.timeslot.midday || this.timeslot.afternoon || this.timeslot.morning) || this.$t('discover.pflegix-callback-dialog.checkbox-validation')
      ]
    }
  }
};
</script>
