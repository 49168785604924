<template>
  <div>
    <v-skeleton-loader v-if="loadingChargingstations"
                       type="card"
                       class="mb-5"
                       height="160"/>

    <content-card v-else-if="hasChargingPoints"
                  :title="$t('chargingstation-home-card.title').toString()"
                  icon="ev_station">
      <template v-slot:content>
        <div v-for="chargingPoint in shownChargingPoints" v-bind:key="chargingPoint.id" class="pa-5">
          <div class="pb-5 d-flex justify-space-between">
            <div class="d-inline-block">
              <v-icon class="material-icons-outlined" :color="chargingPoint.currentlyEnabled || currentlyEnabledPoints.length === 0 ?  'primary' : ''">ev_station</v-icon>
              <span class="pl-1 fill-height d-inline-block">
                {{ currentlyEnabledPoints.length === 0 ||  chargingPoint.currentlyEnabled ? chargingPoint.attributes.name : $t('app.additional-permission.no-permission') }}
              </span>
            </div>
            <div class="d-inline-block" v-if="chargingPoint.currentlyEnabled">
              <v-icon class="material-icons-outlined" :color="getStateColor(chargingPoint.attributes.status)">{{ getStateIcon(chargingPoint.attributes.status) }}</v-icon>
              <span class="ml-1 d-inline-block" v-html="$t(getStateLabels(chargingPoint.attributes.status))"></span>
            </div>
          </div>

          <div class="d-block justify-space-between d-flex">
            <v-btn :disabled="buttonIsDisabled(chargingPoint.attributes.status) || disabledButton || !chargingPoint.currentlyEnabled"
                   @click="executeOperation(chargingPoint.id, chargingPoint.attributes.status)"
                   depressed large color="primary" class="chargingpoint-button">
              {{ chargingPoint.attributes.status === 'CHARGING' ? $t('chargingstation-home-card.charging.stop') : $t('chargingstation-home-card.charging.start') }}
            </v-btn>
          </div>
        </div>
      </template>
    </content-card>
  </div>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: "ChargingstationHomeCard",
  components: {ContentCard},

  data() {
    return {
      refreshRate: config.updateInterval,
      timer: null,
      loadingChargingstations: false,
      compleoconnections: [],
      chargingPoints: [],
      shownChargingPoints: [],
      currentlyEnabledPoints: [],
      hasChargingPoints: true,
      disabledButton: false
    }
  },

  methods: {
    /**
     * get all compleoConnections
     */
    getCompleoConnections: function (showLoader) {
      if (showLoader) {
        this.loadingChargingstations = true
      }
      this.$rhRequest.sendGet({
        endpoint: "chargingstations/compleoConnections",
        params: {
          include: "chargingPoints",
        }
      }, (response) => {
        this.loadingChargingstations = false
        this.compleoconnections = response.data.data.data
        this.chargingPoints = response.data.data.included
        if(response.data.data.currentlyEnabled) {
          this.currentlyEnabledPoints = response.data.data.currentlyEnabled
        }
        if (this.chargingPoints) {
          this.getLocalstorageChargingpoints()
          this.shownChargingPoints.forEach(item => {
            let parentCompleoConnection = this.compleoconnections.find(connection => connection.relationships.chargingPoints.data.find((element) => element.id = connection.id))
            if(Object.keys(this.currentlyEnabledPoints).includes(parentCompleoConnection.id)) {
              item.currentlyEnabled = this.currentlyEnabledPoints[parentCompleoConnection.id]
            } else {
              item.currentlyEnabled = true
            }
          })
          this.$chargingPointOperations.setCompleoConnectionId(response.data.data.data[0].id)
        } else {
          this.$emit('hide-chargingstations', true)
        }

      }, (error) => {
        console.error(error)
      })
    },

    /**
     * get all chargingpoints which are marked as favorite from localstorage
     */
    getLocalstorageChargingpoints: function () {
      const keys = Object.keys(localStorage)
      let keysToGet = []
      let storedChargingpoints = []
      // get all entries for favorite chargingpoints
      keys.forEach(key => {
        if (key.startsWith('emobility:favoriteChargingpoints')) {
          keysToGet.push(key)
        }
      })
      if (keysToGet.length > 0) {
        keysToGet.forEach(key => {
          let items = localStorage.getItem(key)
          items.split(',').forEach(item => {
            storedChargingpoints.push(item)
          })
        })
      }
      // no chargingpoints found for home page found, hide component
      if (!storedChargingpoints) {
        this.$emit('hide-chargingstations', true)
        this.hasChargingPoints = false
        return
      }

      this.shownChargingPoints = this.chargingPoints?.filter(item => storedChargingpoints.includes(item.id))
      if (this.shownChargingPoints.length > 0) {
        this.$emit('hide-chargingstations', false)
        this.hasChargingPoints = true
      } else {
        this.$emit('hide-chargingstations', true)
      }
    },

    /**
     * execute operations on chargingpoint (start charging, stop charging)
     */
    executeOperation(id, status) {
      this.disabledButton = true
      this.$chargingPointOperations.executeOperation(id, status,
        () => {
          this.disabledButton = false
          this.$chargingPointOperations.executeOperationSuccessCallback(this.$root, status)
        },
        (error) => {
          this.disabledButton = false
          this.$chargingPointOperations.executeOperationErrorCallback(this.$root, error, status)
        },
      )
    },

    /**
     * multiple functions for display purposes
     */
    getStateColor(state) {
      return this.$chargingPointOperations.getStateColor(state)
    },

    getStateLabels(state) {
      return this.$chargingPointOperations.getStateLabels(state)
    },

    getStateIcon(state) {
      return this.$chargingPointOperations.getStateIcon(state)
    },

    buttonIsDisabled(state) {
      return this.$chargingPointOperations.buttonIsDisabled(state)
    },
    updateInterval() {
      this.timer = setInterval(() => {
        this.getCompleoConnections(false)
      }, this.refreshRate)
    }
  },

  mounted() {
    requestHelper.startDelayedRequest(
      () => this.getCompleoConnections(true),
      () => this.updateInterval()
    )
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
.chargingpoint-button {
  width: 100%;
}
</style>