<template>
  <v-stepper-content step="5"
                     class="pb-2 pa-1">
    <div v-html="$t('add-rule-step-when-device-selection.info-text')"/>
    <device-selection :items="items"
                      :loading="loading"
                      v-model="itemSelection"
                      @change="updateModel"/>
  </v-stepper-content>
</template>

<script>
import DeviceSelection from "@/templates/dialogs/automations/DeviceSelection.vue";


export default {
  name: 'AddRuleStepConditionDeviceSelection',
  components: {DeviceSelection},

  data: function () {
    return {
      itemSelection: null,
      loading: false,
      items: []
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      return this.itemSelection !== null
    }
  },

  methods: {
    /**
     * get all devices which are available for rule conditions
     */
    getItems() {
      this.loading = true
      this.$rhRequest.sendGet(
        {
          endpoint: "rule/device-service-items",
        },
        (resp) => {
          this.items = Object.values(resp?.data?.data)
          this.loading = false
        },
        (err) => {
          console.error(err);
          this.$root.bisatoast.error({
            message: this.$t("app.generic-error"),
            showCloseBtn: true,
          })
          this.loading = false
        }
      );
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', this.items[this.itemSelection])
    }
  },
  mounted() {
    this.getItems()
  }
};
</script>
