<template>
  <div>
    <v-list-item inactive
                 :disabled="device.currentlyEnabled === false"
                 :ripple="false"
                 class="px-0 mb-4">
      <v-list-item-icon class="ml-0 mr-3">
        <v-icon :color="device.currentlyEnabled === false ? '' : 'primary'">lock</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class=" font-size-03"
                           :class="device.currentlyEnabled === false ? '' : 'primary--text font-weight-bold'"
                           v-text="device.name" />
        <v-list-item-subtitle class="font-size-02" v-if="device.currentlyEnabled === false">
          {{$t('app.additional-permission.no-permission')}}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="font-size-02" v-else-if="lockState === SimulatedStates.LOCKING">
          {{$t("accesses-card.nukistate.locking")}}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="font-size-02" v-else-if="lockState === SimulatedStates.UNLATCHING">
          {{ $t("accesses-card.nukistate.unlatching") }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="font-size-02" v-else-if="lockState === SimulatedStates.ACTUATED">
          {{ $t("accesses-card.nuki-opener-action.actuated") }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="font-size-02" v-else-if="device.online && locked">
          {{ $t("accesses-card.nukistate.locked") }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="font-size-02" v-else-if="device.online && !locked">
          {{ $t("accesses-card.nukistate.unlocked") }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else class="font-size-02">
          {{ $t("accesses-card.offline") }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <div class="nuki-lock-buttons">
      <v-btn depressed large
             class="font-weight-bold nuki-lock-button"
             color="primary"
             :disabled="activeLockAction || locked || device.currentlyEnabled === false"
             @click="executeNukiLockOperation(device.id, 'lock')"
             v-html="$t('accesses-card.lock-button')"/>
      <v-btn depressed large
             class="font-weight-bold nuki-lock-button"
             color="primary"
             :disabled="activeLockAction || device.currentlyEnabled === false"
             @click="executeNukiLockOperation(device.id, 'unlatch')"
             v-html="$t('accesses-card.execute-button')"/>
    </div>
  </div>
</template>

<script>
import config from '@/config/config.app.json'

export const SimulatedStates = Object.freeze({
  IDLE: "idle",
  UNLATCHING: "unlatching",
  LOCKING: "locking",
  ACTUATED: "actuated"
})

export default {
  name: "NukiLock",

  props: {
    device: Object
  },

  data() {
    return {
      simulatedStatusDuration: 5000,
      SimulatedStates: SimulatedStates,
      lockState: null,
      activeLockAction: false,
      mayUpdateStatus: true,
      locked: this.device.sensorValue === 1
    }
  },

  computed: {
    sensorValue() {
      return this.device.sensorValue;
    }
  },

  methods: {
    executeNukiLockOperation(id, operation) {
      if (operation === 'lock') {
        this.lockState = SimulatedStates.LOCKING
      } else if (operation === 'unlatch') {
        this.lockState = SimulatedStates.UNLATCHING
      }

      this.activeLockAction = true

      this.$rhRequest.sendPost({
        endpoint: "devices/execute-nuki-operation",
        timeout: 15000, // increased time-out - requests wait for the Nuki Lock to perform the operation before sending a response
        data: {
          deviceId: id,
          operation: operation
        }
      }, (response) => {
        if (response?.data?.code !== 0 || response?.data?.data?.success === false) {
          this.$root.bisatoast.error({message: this.$t('accesses-card.nuki-operation.failed')})
          this.lockState = SimulatedStates.IDLE
        } else {
          this.lockState = SimulatedStates.ACTUATED

          // wait a few seconds before updating the status the next time (the device may not have updated its status yet)
          this.mayUpdateStatus = false
          setTimeout(() => {
            this.mayUpdateStatus = true
          }, config.updateInterval)

          // override lock status
          if (operation === 'lock') {
            this.locked = true
          } else if (operation === 'unlatch') {
            this.locked = false
          }

          // reset simulated status
          setTimeout(() => {
            this.lockState = SimulatedStates.IDLE
          }, this.simulatedStatusDuration)
        }

        this.activeLockAction = false
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('accesses-card.nuki-operation.failed')})
        this.lockState = SimulatedStates.IDLE
        this.activeLockAction = false
      })
    }
  },

  watch: {
    sensorValue() {
      if (this.mayUpdateStatus) {
        this.locked = this.sensorValue === 1
      }
    }
  }
}
</script>
