const CUSTOM_LOGGER_DEFAULT_VERBOSE_BEHAVIOR = false;

export default class {

    constructor(logPrefix, verbose = null) {
        if (verbose === null) {
            verbose = CUSTOM_LOGGER_DEFAULT_VERBOSE_BEHAVIOR;
        }

        this.verbose = verbose;
        this.logPrefix = logPrefix;
    }

    log(...args) {
        if (!this?.verbose) return;

        if (typeof args[0] == 'string')
            args[0] = this.logPrefix + ': ' + args[0];

        console.log(...args);
    }

    debug(...args) {
        if (!this?.verbose) return;

        if (typeof args[0] == 'string')
            args[0] = this.logPrefix + ': ' + args[0];
        console.debug(...args);
    }

    error(...args) {
        if (!this?.verbose) return;

        if (typeof args[0] == 'string')
            args[0] = this.logPrefix + ': ' + args[0];

        console.error(...args);
    }
}
