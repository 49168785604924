<!-- renders a list of devices-->
<template>
  <content-card :title="$t('add-rule-dialog.select-device.title').toString()"
                icon="widgets"
                class="my-5">
    <template v-slot:content>
      <v-skeleton-loader v-if="loading"
                         type="table-tbody"/>
      <v-list v-else
              class="pa-0">
        <v-list-item v-if="items.length < 1">
          <!-- no actuator devices available -->
          <v-list-item-content v-text="$t('add-rule-step-device-selection.empty-state')"/>
        </v-list-item>
        <v-radio-group v-model="itemSelection"
                       class="pa-0 ma-0"
                       @change="updateModel"
                       hide-details>
          <v-list-item class="list-item straight"
                       v-for="(item, itemId) in items" v-bind:key="item.id">
            <v-list-item-icon class="mr-2">
              <v-radio :value="itemId"
                       class="pt-2 mr-2"/>
              <device-icon :device-type="item?.type" :is-colorized="isOnline(item)" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold font-size-03">
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-size-02">
                {{ item.typeLabel}}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon v-if="isOnline(item)"
                      class="material-icons-outlined"
                      color="success">
                contactless
              </v-icon>
              <v-icon v-else
                      class="material-icons-outlined"
                      color="error">
                offline_bolt
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-radio-group>
      </v-list>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import DeviceIcon from "@/templates/components/devices/DeviceIcon.vue";

export default {
  name: 'DeviceSelection',
  components: {
    DeviceIcon,
    ContentCard
  },

  props: {
    /**
     * v-model
     */
    value: {
      type: Number
    },
    /**
     * list of devices which should be shown in the device selection
     */
    items: {
      type: Array
    },

    /**
     * loading indicator
     */
    loading: {
      type: Boolean
    }
  },

  data: function () {
    return {
      itemSelection: null
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      return this.itemSelection !== null
    }
  },

  methods: {
    /**
     * returns true if the device is online
     *
     * @param device
     * @returns {boolean}
     */
    isOnline(device) {
      return device?.online
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', this.itemSelection)
      this.$emit('change')
    }
  },

  watch: {
    value(newVal) {
      this.itemSelection = newVal
    }
  }
};
</script>
