<template>
  <v-list color="transparent">
    <v-list-item class="px-0">
      <v-list-item-content>
        <div class="font-size-04 mb-2 primary--text font-weight-bold">
          {{ $t('add-nuki-bridge-dialog.step-authorize.title') }}
        </div>
        <div class="font-size-03">
          {{ $t('add-nuki-bridge-dialog.step-list-bridges.description') }}
        </div>

        <v-data-table :headers="tableHeaders"
                      :items="dataProp"
                      item-value="bridgeId"
                      hide-default-footer
                      class="row-pointer"
                      @click:row="authorizeBridge">
          <!-- eslint-disable-next-line -->
          <template v-slot:item.chevron="{ item }">
            <v-icon color="primary">chevron_right</v-icon>
          </template>
        </v-data-table>

      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "AddNukiBridgeStepListBridges",
  props: ['data'],
  data: function () {
    return {
      dataProp: [],
      authorizeInProgress: false,
      tableHeaders: [
        {
          text: this.$t('add-nuki-bridge-dialog.step-authorize.table.bridge-id'),
          value: 'bridgeId',
          sortable: false,
          align: 'start'
        },
        {
          text: this.$t('add-nuki-bridge-dialog.step-authorize.table.name'),
          value: 'name',
          sortable: false,
          align: 'left'
        },
        {
          text: this.$t('add-nuki-bridge-dialog.step-authorize.table.ip'),
          value: 'ip',
          sortable: false,
          align: 'right'
        },
        {
          text: this.$t('add-nuki-bridge-dialog.step-authorize.table.mode'),
          value: 'mode',
          sortable: false,
          align: 'right'
        },
        {
          text: '',
          value: 'chevron',
          key: 'chevron',
          sortable: false,
          align: 'right'
        }
      ]
    }
  },
  methods: {
    authorizeBridge(bridgeItem) {
      this.$emit('authorizeBridge', bridgeItem)
    },
    returnBridgeModeTranslation(mode = 0) {
      let translation = ''
      switch(mode) {
        case 1:
          translation = this.$t('add-nuki-bridge-dialog.step-authorize.authorizing')
          break;
        case 2:
          translation = this.$t('add-nuki-bridge-dialog.step-authorize.authorized')
          break;
        default:
          translation = this.$t('add-nuki-bridge-dialog.step-authorize.unauthorized')
      }
      return translation
    }
  },
  watch: {
    data: function(newVal) {
      newVal.forEach((val) => {
        val.mode = this.returnBridgeModeTranslation(val?.mode)
      })
      this.dataProp = newVal
    }
  }
}
</script>

