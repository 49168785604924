import config from "@/config/config.app.json";

export default {
    
    /**
     * shows the configured type of the First Launch Dialog (if not seen already)
     * @param data
     */
    showFirstLaunchDialog(data) {
        if (localStorage.firstLaunchDialogSeen !== 'true') {
            if (config.firstLaunchDialogType === 'carousel') {
                this.$root.bisadialog.toggle('onboarding', true, data)
                localStorage.setItem('firstLaunchDialogSeen', 'true')
            } else if (config.firstLaunchDialogType === 'popup') {
                this.$root.bisadialog.toggle('firstLaunch')
                localStorage.setItem('firstLaunchDialogSeen', 'true')
            }
        }
    },
}
