<template>
  <div>
    <v-card flat height="250"
            color="appBackground">
      <div class="onboarding-header-circle" :class="secondaryColor ? 'secondary-color' : ''">
        <div class="inner">
          <v-icon :class="isNonFlipIcon(icon) ? 'icon material-icons-outlined non-flip' : 'icon material-icons-outlined'">{{ icon }}</v-icon>
        </div>
      </div>
    </v-card>
    <div :class="secondaryColor ? 'secondary--text' : 'primary--text'"
         class="font-weight-bold font-size-09 text-center mt-7">
      {{ title }}
    </div>
    <div class="scroll-container">
      <div class="font-size-03 text-center mt-4 mx-6 mx-md-16">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>

import {isNonFlipIcon} from "@/i18n";

export default {
  name: "OnboardingCard",
  methods: {isNonFlipIcon},
  props: {
    icon: String,
    title: String,
    secondaryColor: {
      default: false,
      type: Boolean
    }
  },
}
</script>
