<template>

  <v-skeleton-loader v-if="loading"
                     type="article"
                     height="300"/>
  <content-card v-else :title="$t('contract-selection-card.title').toString()"
                icon="manage_search">
    <template v-slot:content>
      <div class="pa-5"
           v-html="$t('contract-selection-card.text')"/>
      <v-autocomplete :items="contractsData"
                      v-model="selectedContract"
                      :filter="contractFilter"
                      clearable
                      clear-icon="close"
                      :item-text="item => item?.attributes.displayName"
                      :item-value="item => item?.id"
                      :no-data-text="$t('energy-page.emptyState.has-linked.title')"
                      return-object
                      outlined class="mx-5">
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title>{{ data.item.attributes.displayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('contract-selection-card.contract-number') }}: {{ data.item.attributes.contractNumber }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-divider/>

      <!-- TODO Preview - Angelina will provide a proper design -->
      <v-expansion-panels flat accordion multiple
                          class="contract-info">
        <v-expansion-panel>
          <v-expansion-panel-header class="primary--text font-weight-bold">
            {{ $t('contract-selection-card.contract-info.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-list-item class="pa-0">
                <v-list-item-icon>
                  <v-icon>tag</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-size-03" v-text="$t('contract-selection-card.contract-number')"/>
                <v-list-item-action-text v-text="selectedContract?.attributes?.contractNumber"/>
              </v-list-item>
              <v-list-item class="pa-0">
                <v-list-item-icon>
                  <v-icon>perm_identity</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-size-03" v-text="$t('contract-selection-card.customer-id')"/>
                <v-list-item-action-text v-text="selectedContract?.attributes?.customerId"/>
              </v-list-item>
              <v-list-item class="pa-0" v-if="selectedContract?.attributes?.start">
                <v-list-item-icon>
                  <v-icon>today</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-size-03" v-text="$t('contract-selection-card.contract-start')"/>
                <v-list-item-action-text v-text="formatDate(selectedContract?.attributes?.start)"/>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="primary--text font-weight-bold">
            {{ $t('contract-selection-card.support.title') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="$t('contract-selection-card.support.text')"/>
            <v-btn depressed
                   color="primary"
                   class="mt-5 mb-1 float-right"
                   @click="openSupportDialog">
              <v-icon left>support_agent</v-icon>
              {{ $t('contract-selection-card.support.button-label') }}
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard.vue";
import moment from "moment/moment";

export default {
  name: "ContractSelectionCard",
  components: {ContentCard},
  props: {
    loading: Boolean,
    contractsData: Array
  },

  data: () => ({
    selectedContract: null
  }),

  watch: {
    selectedContract: function (newValue) {
      if (newValue?.id !== localStorage.getItem("energy:selected-contract:" + localStorage.user)) {
        localStorage.removeItem("energy:selected-cp:" + localStorage.user)
      }
      this.$emit('change-selected-contract', newValue)
      localStorage.setItem("energy:selected-contract:" + localStorage.user, newValue?.id)
    },

    /**
     * auto-selects either the first contract or the last selected contract (as stored in localStorage)
     */
    contractsData: function () {
      this.preselectContract()
    }
  },

  mounted() {
    this.preselectContract()
  },

  methods: {
    // filters through displayName and externalId (ContractId)
    contractFilter(item, queryText) {
      return (item.attributes.displayName?.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.attributes.externalId?.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
    },

    formatDate(date) {
      moment.locale(localStorage.getItem('langSetting'))
      return moment(date).format("DD.MM.YYYY")
    },

    openSupportDialog() {
      this.$root.bisadialog.toggle('energylineDataSupport', true, {
        contractNumber: this.selectedContract.attributes.contractNumber,
        customerId: this.selectedContract.attributes.customerId
      })
    },

    preselectContract() {
      let storedContractId = localStorage.getItem("energy:selected-contract:" + localStorage.user)
      if (storedContractId) {
        this.selectedContract = this.contractsData.find(contract => contract.id === storedContractId)
      } else {
        this.selectedContract = Array.isArray(this.contractsData) && this.contractsData[0] ? this.contractsData[0] : null
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/energy/contract-selection-card.scss';
</style>
