<template>
  <div>
    <v-form v-model="valid" ref="form">
      <v-text-field outlined
                    class="mt-4"
                    :rules="rules"
                    @keyup="validatePaste"
                    @mouseup="validatePaste"
                    :label="$t('add-gateway-step2.device-id.label')"
                    v-model="gwId"
                    @input="handleInput"/>
    </v-form>

    <div class="mt-8 mb-2 primary--text font-weight-bold" v-html="$t('add-gateway-step2.tips.headline')"/>
    <v-list color="transparent">
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3">
          <v-icon color="primary"
                  class="material-icons-outlined">
            tips_and_updates
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap"
                             v-html="$t('add-gateway-step2.tips.0')"/>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3">
          <v-icon color="primary"
                  class="material-icons-outlined">
            tips_and_updates
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap"
                             v-html="$t('add-gateway-step2.tips.1')"/>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3"><!-- no icon --></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap font-weight-bold"
                             v-html="$t('add-gateway-step2.tips.hint')"/>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3">
          <v-icon color="primary"
                  class="material-icons-outlined">
            tips_and_updates
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap"
                             v-html="$t('add-gateway-step2.tips.type-a')"/>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3">
          <v-icon color="primary"
                  class="material-icons-outlined">
            tips_and_updates
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap"
                             v-html="$t('add-gateway-step2.tips.type-b')"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

export default {
  name: "AddGatewayStep2",

  props: ['value'],

  data: function () {
    return {
      gwId: this.value,
      valid: true,
      rules: [
        v => !!v || this.$t('app.rules.required'),
        v => (v && v.length <= 16) || this.$t('app-gateway-step2.rules.too-long')
      ]
    }
  },

  methods: {
    handleInput() {
      this.$emit('input', this.gwId)
      this.$emit('valid', this.valid)
    },
    validatePaste() {
      this.$refs.form.validate();
      this.handleInput();
    }
  }
}
</script>
