<template>
  <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 675.099 253.246" enable-background="new 0 0 675.099 253.246" xml:space="preserve">
<g>
	<g>
		<path :fill="fontColor" d="M183.467,56.532h26.772v36.156h34.361V56.532h26.772v96.601h-26.772v-36.708h-34.361v36.708h-26.772
			V56.532z"/>
    <path :fill="fontColor" d="M282.866,131.743v-0.276c0-15.456,11.729-23.46,29.256-23.46c6.899,0,13.662,1.38,17.939,2.897v-1.241
			c0-7.729-4.83-12.145-14.903-12.145c-7.866,0-13.938,1.519-20.562,4.14l-5.382-18.491c8.418-3.45,17.388-5.797,30.222-5.797
			c13.387,0,22.494,3.175,28.429,9.108c5.658,5.521,8.004,13.248,8.004,23.736v42.918h-25.944v-7.729
			c-5.106,5.658-12.144,9.385-21.942,9.385C293.768,154.789,282.866,146.646,282.866,131.743z M330.338,126.084v-3.45
			c-2.622-1.104-6.348-1.932-10.212-1.932c-7.59,0-12.006,3.726-12.006,9.246v0.275c0,5.106,3.863,8.143,9.246,8.143
			C324.956,138.367,330.338,133.537,330.338,126.084z"/>
    <path :fill="fontColor" d="M398.717,144.024v9.108h-26.22V52.392h26.22V88.41c5.106-6.21,11.454-11.592,22.633-11.592
			c17.802,0,33.12,14.767,33.12,38.641v0.275c0,24.288-15.318,39.055-32.982,39.055
			C410.171,154.789,403.547,149.682,398.717,144.024z M428.663,115.873v-0.276c0-9.798-6.762-16.836-15.318-16.836
			c-8.418,0-15.18,7.038-15.18,16.836v0.276c0,9.936,6.762,16.974,15.18,16.974C421.901,132.846,428.663,125.946,428.663,115.873z"
    />
    <path :fill="fontColor" d="M465.219,52.392h27.324v19.32h-27.324V52.392z M465.771,78.474h26.221v74.658h-26.221V78.474z"/>
    <path :fill="fontColor" d="M509.501,52.392h26.221v100.74h-26.221V52.392z"/>
    <path :fill="fontColor" d="M552.68,52.392h27.324v19.32H552.68V52.392z M553.231,78.474h26.221v74.658h-26.221V78.474z"/>
    <path :fill="fontColor" d="M592.574,116.148v-0.275c0-21.252,17.388-39.055,41.4-39.055c23.874,0,41.124,17.526,41.124,38.778v0.276
			c0,21.252-17.388,39.054-41.4,39.054C609.824,154.926,592.574,137.4,592.574,116.148z M649.568,116.148v-0.275
			c0-9.108-6.624-16.836-15.87-16.836c-9.521,0-15.594,7.59-15.594,16.56v0.276c0,9.107,6.624,16.836,15.87,16.836
			C643.496,132.708,649.568,125.119,649.568,116.148z"/>
	</g>
  <path :fill="fillColor" d="M76.034,253.246C34.107,253.246,0,219.138,0,177.215h26.8c0,27.146,22.085,49.229,49.23,49.229
		c27.149,0,49.234-22.084,49.234-49.229h26.8C152.064,219.138,117.957,253.246,76.034,253.246z M152.053,50.089L75.68,0
		L0.068,50.132V153.24h26.8V64.519L75.76,32.102l49.494,32.46v88.678h26.8V50.089z M73.003,111.829h-26.8v41.411h26.8V111.829z"/>
</g>
</svg>
</template>

<script>
export default {
  name: 'MainLogo',
  props: {
    fillColor: {
      type: String,
    },
    fontColor: {
      type: String,
    }
  }
}
</script>

<style scoped>

</style>
