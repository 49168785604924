<template>
  <fullscreen-overlay-frame :title="$t('invited-user-card.edit-release-period').toString()"
                            icon="manage_accounts"
                            color="primary"
                            centered
                            closable
                            @close="abort">

    <template v-slot:content>
      <v-form ref="form-restriction-period" v-model="periodRestrictionValid">
        <div>
          <v-icon color="primary" class="mr-1">person</v-icon>
          {{ userInfo.userFirstname }} {{ userInfo.userLastname }}
        </div>

        <v-checkbox v-model="restrictPeriod"
                    class="mt-8"
                    :label="$t('invite-user-dialog.restrict-period.label')"/>
        <v-row class="mt-2">
          <v-col cols="12" sm="6">
            <date-time-picker v-model="userInfo.userValidFrom"
                              :label="$t('invite-user-dialog.valid-from.label').toString()"
                              :disabled="!restrictPeriod"/>
          </v-col>
          <v-col cols="12" sm="6">
            <date-time-picker v-model="userInfo.userValidTo"
                              :label="$t('invite-user-dialog.valid-to.label').toString()"
                              :rules="rules.periodRestriction"
                              :disabled="!restrictPeriod"/>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn depressed large
             color="primary"
             class="font-weight-bold action-button"
             @click="updateUserPermissions"
             :disabled="!periodRestrictionValid">
        {{ $t('app.save') }}
      </v-btn>
    </template>

  </fullscreen-overlay-frame>
</template>

<script>

import FullscreenOverlayFrame from '@/templates/dialogs/FullscreenOverlayFrame'
import DateTimePicker from '@/templates/components/DateTimePicker.vue'
import moment from 'moment'

export default {
  name: 'UserPermissionDialog',

  components: {
    DateTimePicker,
    FullscreenOverlayFrame,
  },

  props: ['data'],

  data: function () {
    return {
      restrictPeriod: this.data?.userValidFrom || this.data?.userValidTo,
      periodRestrictionValid: false,
      userInfo: {
        userId: this.data?.userId,
        userValidFrom: this.data?.userValidFrom,
        userValidTo: this.data?.userValidTo,
        userFirstname: this.data?.userFirstname,
        userLastname: this.data?.userLastname,
        userEmail: this.data?.userEmail
      },
      rules: {
        periodRestriction: [
          v => this.validatePeriod(v) || this.$t('app.rules.period-restriction-invalid')
        ]
      },
    }
  },

  methods: {
    /**
     * validates the user's period restriction input
     *
     * @param input
     * @returns {boolean}
     */
    validatePeriod(input) {
      if (this.restrictPeriod && this.userInfo.userValidFrom && input) {
        let parseFormat = 'YYYY-MM-DD HH:mm:ss'
        let validTo = moment.utc(input, parseFormat)
        if (validTo.isBefore(moment.utc(this.userInfo.userValidFrom, parseFormat))) {
          // end date must not be before start date
          return false
        }
      }
      return true
    },

    /**
     * updates the user permission
     */
    updateUserPermissions() {
      if (!this.restrictPeriod) {
        this.userInfo.userValidTo = null
        this.userInfo.userValidFrom = null
      }

      if (this.$refs["form-restriction-period"].validate()) {
        this.$rhRequest.sendPost({
          endpoint: 'permission/edit-invited-user',
          data: {
            userId: this.userInfo.userId,
            validFrom: this.userInfo.userValidFrom,
            validTo: this.userInfo.userValidTo
          }
        }, () => {
          this.$root.bisatoast.success({
            message: this.$t('change-invited-user-data-dialog.save.success'),
            showCloseBtn: true
          })
          this.$root.bisadialog.toggle('userPermission')
        }, () => {
          this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          this.$root.bisadialog.toggle('userPermission')
        })
      }
    },

    /**
     * closes UserPermissionDialog
     */
    abort() {
      this.$root.bisadialog.toggle('userPermission')
    }

  }
}

</script>

