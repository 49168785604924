<template>
  <v-stepper-content step="1"
                     class="pb-2 pa-1">
    <content-card :title="$t('add-rule-dialog.content-card.title').toString()"
                  icon="info"
                  class="my-5">
      <template v-slot:content>
        <v-list class="py-0">
          <!-- rule name -->
          <v-list-item class="list-item straight">
            <v-list-item-icon class="material-icons-outlined">
              <v-icon>label</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-if="editNameMode">
              <v-text-field v-model="name"
                            dense outlined
                            hide-details
                            append-icon="check"
                            @click:append="editName(false)"
                            @blur="editName(false)"/>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>{{ name }}</v-list-item-title>
              <v-list-item-subtitle v-text="$t('add-rule-step-overview.name.subtitle')"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="!editNameMode">
              <v-btn icon
                     :disabled="!isSiteAdmin"
                     class="float-right"
                     @click="editName(true)">
                <v-icon color="primary">
                  edit
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <!-- rule icon -->
          <v-list-item>
            <v-list-item-icon class="material-icons-outlined">
              <span class="material-symbols-outlined v-icon ma-0" :class="$vuetify.theme.isDark ? 'theme--dark': 'theme--light'">{{ iconKey }}</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t('add-rule-step-overview.name.icon')"/>
            </v-list-item-content>
            <v-list-item-action class="align-self-start">
              <v-btn icon
                     :disabled="!isSiteAdmin"
                     class="float-right"
                     @click="editIcon">
                <v-icon v-if="editIconMode"
                        color="primary">
                  check
                </v-icon>
                <v-icon v-else
                        color="primary">
                  edit
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <icon-picker v-model="iconKey"
                       :visible="editIconMode"/>

          <!-- active state -->
          <v-list-item>
            <v-list-item-icon class="material-icons-outlined">
              <v-icon>
                power_settings_new
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="active" v-text="$t('add-rule-step-overview.rule-active')"/>
              <v-list-item-title v-else v-text="$t('add-rule-step-overview.rule-deactivated')"/>
              <v-list-item-subtitle v-text="$t('add-rule-step-overview.status.subtitle')"/>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="active"
                        inset
                        @change="updateModel"/>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </content-card>

    <!-- condition -->
    <div class="font-weight-bold text-break font-size-05 primary--text mt-10 mb-4 ml-5"
         v-text="$t('add-rule-step-overview.condition.title')"/>
    <v-card v-if="condition === null"
            color="primary">
      <v-list-item class="py-3"
                   @click="selectCondition">
        <v-list-item-content>
          <v-list-item-title class="white--text font-size-04 font-weight-bold"
                             v-text="$t('add-rule-dialog.condition.title')"/>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="white">chevron_right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-card>
    <condition-summary-card v-else-if="!loadingCondition"
                            :condition="condition"
                            @edit="selectCondition"/>
    <v-skeleton-loader v-else
                       type="card" height="138"/>

    <!-- action -->
    <div class="font-weight-bold text-break font-size-05 primary--text mt-10 mb-4 ml-5"
         v-text="$t('add-rule-step-overview.action.title')"/>
    <v-card v-if="action === null"
            color="primary">
      <v-list-item class="py-3"
                   @click="selectAction">
        <v-list-item-content>
          <v-list-item-title class="white--text font-size-04 font-weight-bold"
                             v-text="$t('add-rule-dialog.action.title')"/>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="white">chevron_right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-card>
    <action-summary-card v-else-if="!loadingAction"
                         :action="action"
                         :actionSubject="actionSubject"
                         @edit="selectAction"/>
    <v-skeleton-loader v-else
                       type="card" height="138"/>

  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import ConditionSummaryCard from "@/templates/dialogs/automations/ConditionSummaryCard.vue";
import ActionSummaryCard from "@/templates/dialogs/automations/ActionSummaryCard.vue";
import IconPicker from "@/templates/dialogs/automations/IconPicker.vue";

export default {
  name: 'AddRuleStepOverview',
  components: {
    IconPicker,
    ActionSummaryCard,
    ConditionSummaryCard,
    ContentCard
  },

  props: {
    value: {
      type: Object,
      default: null
    },
    condition: {
      type: Object,
      default: null
    },
    action: {
      type: Object,
      default: null
    },
    actionSubject: {
      type: Object,
      default: null
    },
    loadingAction: {
      type: Boolean,
      default: false
    },
    loadingCondition: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      name: this.value?.name,
      active: !this.value?.deactivated,
      iconKey: this.value?.iconKey,
      editNameMode: false,
      editIconMode: false
    }
  },

  computed: {
    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    }
  },

  methods: {
    /**
     * proceeds to condition selection
     */
    selectCondition() {
      if (!this.isSiteAdmin) {
        return
      }
      this.$emit("select-condition")
    },

    /**
     * proceeds to action selection
     */
    selectAction() {
      if (!this.isSiteAdmin) {
        return
      }
      this.$emit("select-action")
    },

    /**
     * activates the edit mode for the name of the automation
     */
    editName(mode) {
      if (!this.isSiteAdmin) {
        return
      }
      this.editNameMode = mode
      this.updateModel()
    },

    /**
     * activates the edit mode for automation icon
     */
    editIcon() {
      if (!this.isSiteAdmin) {
        return
      }
      this.editIconMode = !this.editIconMode
      this.updateModel()
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', {
        name: this.name,
        iconKey: this.iconKey,
        deactivated: !this.active
      })
    }
  }
};
</script>

<style lang="scss">
  .five-columns {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
</style>
