<template>
  <v-list color="transparent">
    <v-list-item class="px-0" three-line>
      <v-list-item-icon class="mr-3">
        <v-icon color="success"
                class="material-icons-outlined">
          sensors
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-04 mb-2 success--text font-weight-bold">
          {{ $t('remove-device-dialog.step-removing.title') }}
        </div>
        <div class="font-size-03"
             v-html="$t('remove-device-dialog.step-removing.description')"/>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "RemoveDeviceStepRemoving"
}
</script>

