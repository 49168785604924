import AndroidPushNotifications from "@/plugins/rhPushNotification/AndroidPushNotifications";
import IOSPushNotifications from "@/plugins/rhPushNotification/IOSPushNotifications";
import Vue from 'vue'

export default class RHPushNotification {
  
  static LSKEY_SUBSCRIPED_TOPICS = 'subscribedTopics';
  
  devicePushNotifications = window?.cordova?.platformId.toLowerCase() === 'ios' ? new IOSPushNotifications() : new AndroidPushNotifications();
  onMessage = null;

  constructor() {
    document.addEventListener("deviceready", this.onDeviceReady.bind(this), false);
    document.addEventListener("resume", this.onResume.bind(this), false);
    document.addEventListener("pause", this.onPause.bind(this), false);
  }

  /**
   * Initialise the plugin on device ready
   */
  onDeviceReady() {
    if (window.usingCordova) {
      window.FirebasePlugin.onMessageReceived(
        (message) => {
          this.onMessageReceived(message);
        },
        (error) => {
          this.onError("Failed to received message", error);
        }
      );

      this.checkNotificationPermission(false);
      this.isAutoInitEnabled();
      this.checkRelatedSites();
    }
  }
  
  /**
   * Checks subscription on app resume
   */
  onResume() {
    this.checkRelatedSites();
  }
  
  /**
   * Checks subscription on app pause
   */
  onPause() {
    this.checkRelatedSites();
  }
  
  /**
   * Check related sites
   */
  checkRelatedSites() {
    if (window.usingCordova && Vue.prototype.$rhAuth.loggedIn()) {
      Vue.prototype.$rhRequest.sendGet({
        endpoint: 'multisite/get-related-sites'
      }, (response) => {
        if (response?.data?.data) {
          this.cleanSurplusSubcriptions(response?.data?.data);
        }
      }, (error) => {
        console.error(error);
      })
    }
  }
  
  cleanSurplusSubcriptions(releatedSites) {
    let subscribedTopics = JSON.parse(localStorage.getItem(RHPushNotification.LSKEY_SUBSCRIPED_TOPICS));
    let pattern = /-s\d+-/;
    let siteSpecificTopics = [];
    
    subscribedTopics.forEach((topic) => {
      if (topic.match(pattern)) siteSpecificTopics.push(topic);
    })
    
    releatedSites.forEach((releatedSite) => {
      for (let index = 0; siteSpecificTopics.length > index; index++) {
        if (siteSpecificTopics[index].includes(releatedSite.siteId)) {
          siteSpecificTopics.splice(index, 1);
          index--;
        }
      }
    })
    
    this.deleteSurplusSubcriptions(siteSpecificTopics);
  }
  
  deleteSurplusSubcriptions(surplusSubcriptions) {
    let subscribedTopics = JSON.parse(localStorage.getItem(RHPushNotification.LSKEY_SUBSCRIPED_TOPICS));
    
    surplusSubcriptions.forEach((surplusTopic) => {
      this.unsubscribe(subscribedTopics);
      subscribedTopics = subscribedTopics.filter(topic => topic !== surplusTopic);
    })
    
    localStorage[RHPushNotification.LSKEY_SUBSCRIPED_TOPICS] = JSON.stringify(subscribedTopics);
  }
  
  /**
   * Subscribe a topic
   *
   * @param topic
   * @returns {Promise<unknown>}
   */
  subscribe(topic) {
    if (window.usingCordova) {
      return new Promise((resolve, reject) => {
        window.FirebasePlugin.subscribe(
          topic,
          () => {
            resolve(topic);
          },
          (error) => {
            this.onError("Failed to subscribe to topic", error);
            reject(topic, error);
          });
      });
    }
  }

  /**
   * Unsubscribe from a topic
   *
   * @param topic
   * @returns {Promise<unknown>}
   */
  unsubscribe(topic) {
    if (window.usingCordova) {
      return new Promise((resolve, reject) => {
        window.FirebasePlugin.unsubscribe(
          topic,
          () => {
            resolve(topic);
          },
          (error) => {
            this.onError("Failed to unsubscribe from topic", error);
            reject(topic, error);
          });
      });
    }
  }

  /**
   *
   * @param message
   */
  onMessageReceived(message) {
    if (this.onMessage !== null) this.onMessage(message);
  }

  /**
   *
   * @param requested
   */
  checkNotificationPermission(requested) {
    if (window.usingCordova) {
      window.FirebasePlugin.hasPermission((hasPermission) => {
          if (hasPermission) {
            this.devicePushNotifications.getToken();
            return;
          }

          if (!requested) {
            window.FirebasePlugin.grantPermission(
              this.checkNotificationPermission.bind(this, true)
            );
          }
        },
        (error) => {
          this.onError("Failed to grant permission", error);
        });
    }
  }

  /**
   * Check if auto init enabled
   * !!! Required for initialisation !!!
   */
  isAutoInitEnabled() {
    if (window.usingCordova) {
      window.FirebasePlugin.isAutoInitEnabled((enabled) => {
        console.info("Auto init is " + (enabled ? "enabled" : "disabled"))
      }, (error) => {
          this.onError("Failed to check auto init", error);
        });
    }
  }

  /**
   * Unregister device from firebase to avoid getting push notifications for old topics.
   * This deletes the firebase token for the device.
   */
  unregister() {
    if(window.usingCordova){
      window.FirebasePlugin.unregister();
    }
  }

  /**
   * Error handler
   *
   * @param message
   * @param err
   */
  onError(message, err) {
    console.error(message, err);
  }
}

