<template>
  <v-card class="appBackground">
    <div class="title-icon" :class="color">
      <v-icon dark large
              :class="isNonFlipIcon(icon) ? 'material-icons-outlined non-flip' : 'material-icons-outlined'">
        {{ icon }}
      </v-icon>
    </div>

    <v-btn icon
           v-if="closable"
           color="black"
           class="mt-1 mx-1 float-end"
           @click="$emit('close')">
      <v-icon>close</v-icon>
    </v-btn>

    <v-card-title class="text-center d-block font-weight-bold text-break mb-3 pt-12 font-size-05"
                  :class="color + '--text'"
                  v-html="title" />

    <v-card-text class="text-center d-block font-weight-medium font-size-03">
      <slot name="content"/>
    </v-card-text>

    <v-card-actions>
      <slot name="actions"/>
    </v-card-actions>
  </v-card>
</template>

<script>

import {isNonFlipIcon} from "@/i18n";

export default {
  name: 'DialogFrame',
  methods: {isNonFlipIcon},

  props: {
    color: String,
    title: String,
    icon: String,
    closable: Boolean
  }
};
</script>

