<template>
  <v-list color="transparent">
    <v-list-item class="px-0">
      <v-list-item-content>
        <div class="font-size-04 mb-2 primary--text font-weight-bold">
          {{ $t('remove-device-dialog.step-success.title') }}
        </div>
        <div class="font-size-03">
          {{ $t('remove-device-dialog.step-success.description', {name: data?.name}) }}
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>

export default {
  name: "RemoveDeviceStepSuccess",
  props: ['device'],

  data: function () {
    return {
      data: this.device
    }
  }
}
</script>

