<template>
  <div>
    <div class="font-size-03" v-html="$t('add-gateway-success.text')"/>

    <gateway-status-card class="mt-8"/>

    <div class="mt-8 mb-2 success--text font-weight-bold" v-html="$t('add-gateway-success.summary-headline')"/>
    <div class="font-size-03" v-html="$t('add-gateway-success.installed-firmware', {firmwareVersion: firmwareVersion})"/>
    <div v-if="updateStarted" class="font-size-03" v-html="$t('add-gateway-success.update-started')"/>
    <div v-else class="font-size-03" v-html="$t('add-gateway-success.no-updated-neccessary')"/>
  </div>
</template>

<script>

import GatewayStatusCard from "@/templates/components/living/GatewayStatusCard";

export default {
  name: "AddGatewaySuccess",
  components: {GatewayStatusCard},
  props: ['updateStarted', 'firmwareVersion']
}
</script>
