<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 152.064 253.246"
  >
  <path :fill="color" d="M76.034,253.246C34.107,253.246,0,219.138,0,177.215h26.8c0,27.146,22.085,49.229,49.23,49.229
    c27.149,0,49.234-22.084,49.234-49.229h26.8C152.064,219.138,117.957,253.246,76.034,253.246z M152.053,50.089L75.68,0L0.068,50.132
    V153.24h26.8V64.519L75.76,32.102l49.494,32.46v88.678h26.8V50.089z M73.003,111.829h-26.8v41.411h26.8V111.829z"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoNoText',
  props: {
    color: {
      type: String,
      required: true
    },
    width: {
      required: false,
      default: ''
    },
    height: {
      required: false,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
