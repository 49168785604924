<template>
  <v-list color="transparent">
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          check_circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-03 font-weight-bold">
          {{ $t('remove-device-dialog.step-start.text1') }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          check_circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-03 font-weight-bold">
          {{ $t('remove-device-dialog.step-start.text2') }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          check_circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-03 font-weight-bold">
          {{ $t('remove-device-dialog.step-start.text3') }}
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "RemoveDeviceStepStart"
}
</script>

