<!--
  This template renders a specific device card including a title and a slot which can be used to add actor or sensor
  subdevices.
-->
<template>
  <!-- dense view -->
  <v-card v-if="dense"
          :flat="flat">
    <v-card-subtitle class="pa-0">
      <v-list>
        <v-list-item>
          <v-list-item-icon class="mr-2">
            <device-icon :device-type="device?.type" :is-colorized="isOnline(device)" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="font-size-03 primary--text font-weight-bold"
                               v-text="device?.name"/>
            <v-list-item-subtitle class="font-size-02"
                                  v-text="isOnline(device) ? $t('app.online') : $t('app.offline')"/>
          </v-list-item-content>

          <v-list-item-action class="align-baseline">
            <!-- maximize button -->
            <v-btn icon
                   @click="openDeviceDialog">
              <v-icon color="primary" small>open_in_full</v-icon>
            </v-btn>
            <!-- device offline indicator -->
            <v-icon v-if="!isOnline(device)"
                    class="material-icons-outlined mx-2 non-flip"
                    color="error" small>offline_bolt</v-icon>
            <!-- battery warning -->
            <v-icon v-if="device['battery-low']"
                    class="mx-2"
                    color="error" small>battery_alert</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-subtitle>

    <v-card-text class="px-0">
      <slot :dense="true"/>
    </v-card-text>
  </v-card>

  <!-- large view -->
  <content-card v-else
                :title="title"
                :icon="icon">
    <template v-slot:content>
      <slot :dense="false"/>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import DeviceIcon from "@/templates/components/devices/DeviceIcon";

export default {
  name: 'DeviceCard',

  components: {DeviceIcon, ContentCard},

  props: {
    dense: Boolean,
    flat: Boolean,
    device: Object,
    title: String,
    icon: {
      type: String,
      default: 'sensors'
    }
  },

  methods: {
    /**
     * opens the device dialog for the current device
     */
    openDeviceDialog() {
      this.$root.bisadialog.toggle('device', true, {device: this.device})
      this.$root.bisadialog.callDialogInit('device')
    },

    /**
     * returns true if the device is online
     *
     * @param device
     * @returns {boolean}
     */
    isOnline(device) {
      return device?.online && device?.gatewayOnline
    },
  },

}
</script>
