<template>
  <v-form v-model="valid" ref="form" class="pt-1" lazy-validation>
    <v-container class="ma-0 pa-0 mt-2">
      <v-row class="ma-0">
        <v-col :cols="8" class="pl-0 pr-1">
          <v-text-field outlined
                        v-model="streetName"
                        required
                        :rules="rules"
                        hide-details
                        :label="$t('registration-address-page.street-name.label')"/>
        </v-col>
        <v-col :cols="4" class="pr-0 pl-1">
          <v-text-field outlined
                        v-model="houseNumber"
                        required
                        :rules="rules"
                        hide-details
                        :label="$t('registration-address-page.house-number.label')"/>
        </v-col>
      </v-row>
      <v-row class="ma-0 pb-0">
        <v-col :cols="4" class="pl-0 pr-1 pb-0">
          <v-text-field outlined
                        v-model="postCode"
                        required
                        :rules="rules"
                        hide-details
                        :label="$t('registration-address-page.post-code.label')"/>
        </v-col>
        <v-col :cols="8" class="pr-0 pl-1 pb-0">
          <v-text-field outlined
                        v-model="city"
                        required
                        :rules="rules"
                        hide-details
                        :label="$t('registration-address-page.city.label')"/>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="pa-0 pt-2" :cols="12" v-if="!valid">
          <div class="hint-text error--text">{{ $t('registration-address-page.form-not-valid') }}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-btn depressed x-large block
                         class="mt-10"
                         color="primary"
                         @click="nextStep"
                         v-html="$t('app.continue')"/>
    <v-btn depressed block
           class="mt-4"
           color="primary"
           text
           @click="$emit('stepBack')"
           v-html="$t('app.step-back')"/>
  </v-form>
</template>

<script>
export default {
  name: "RegistrationAddress",

  components: {
  },

  data: () => ({
    valid: true,
    streetName: '',
    houseNumber: '',
    postCode: '',
    city: ''
  }),

  methods: {
    nextStep() {
      if (this.$refs.form.validate()) {
        this.$emit('nextStep', [{'address': this.streetName + ' ' +  this.houseNumber}, {'zip': this.postCode}, {'city': this.city}])
      }
    },
  },

  computed: {
    rules() {
      return [
        v => !!v || false,
      ]
    }
  }
}
</script>

<style scoped>
</style>
