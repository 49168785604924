<template>
  <fullscreen-overlay-frame :title="title"
                            icon="control_point_duplicate"
                            :color="color"
                            centered
                            closable
                            @close="abort">

    <template v-slot:content>

      <v-progress-linear :value="progress"
                         color="primary accent-4"
                         height="5"
                         :buffer-value="bufferValue"
                         stream/>
      <v-stepper v-model="stepper"
                 flat class="transparent">

        <v-stepper-items ref="stepper">
          <v-stepper-content step="start"
                             class="pa-0 pt-4">
            <add-nuki-bridge-step-start/>
          </v-stepper-content>
          <v-stepper-content step="searching"
                             class="pa-0 pt-4">
            <add-nuki-bridge-step-searching/>
          </v-stepper-content>
          <v-stepper-content step="listBridges"
                             class="pa-0 pt-4">
            <add-nuki-bridge-step-list-bridges :data="availableBridges" @authorizeBridge="authorizeBridge"/>
          </v-stepper-content>
          <v-stepper-content step="authorize"
                             class="pa-0 pt-4">
            <add-nuki-bridge-step-authorize/>
          </v-stepper-content>
          <v-stepper-content step="finish"
                             class="pa-0 pt-4">
            <add-nuki-bridge-step-finish/>
          </v-stepper-content>
          <!-- Error cases -->
          <v-stepper-content step="noBridgeFound"
                             class="pa-0 pt-4">
            <add-nuki-bridge-step-no-bridge-found/>
          </v-stepper-content>
          <v-stepper-content step="showGwOffline"
                             class="pa-0 pt-4">
            <add-nuki-bridge-step-gw-offline/>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

    </template>

    <template v-slot:actions>
      <v-stepper v-model="stepper"
                 width="100%"
                 flat class="transparent">
        <v-stepper-items>
          <v-stepper-content step="start"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   class="font-weight-bold action-button"
                   v-text="$t('add-nuki-bridge-dialog.start-adding')"
                   @click="startAdding">
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="noBridgeFound"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   class="font-weight-bold action-button"
                   v-text="$t('add-nuki-bridge-dialog.restart-adding')"
                   @click="startAdding">
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="finish"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   class="font-weight-bold action-button"
                   v-text="$t('add-nuki-bridge-dialog.finish')"
                   @click="abort">
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="showGwOffline"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   class="font-weight-bold action-button"
                   v-text="$t('add-nuki-bridge-dialog.finish')"
                   @click="abort">
            </v-btn>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>

    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import AddNukiBridgeStepStart from "@/templates/dialogs/addNukiBridge/AddNukiBridgeStepStart";
import AddNukiBridgeStepSearching from "@/templates/dialogs/addNukiBridge/AddNukiBridgeStepSearching";
import AddNukiBridgeStepListBridges from "@/templates/dialogs/addNukiBridge/AddNukiBridgeStepListBridges";
import AddNukiBridgeStepAuthorize from "@/templates/dialogs/addNukiBridge/AddNukiBridgeStepAuthorize";
import AddNukiBridgeStepFinish from "@/templates/dialogs/addNukiBridge/AddNukiBridgeStepFinish";
import AddNukiBridgeStepGwOffline from "@/templates/dialogs/addNukiBridge/AddNukiBridgeStepGwOffline";
import AddNukiBridgeStepNoBridgeFound from "@/templates/dialogs/addNukiBridge/AddNukiBridgeStepNoBridgeFound";

export default {
  name: 'AddNukiBridgeDialog',
  components: {
    AddNukiBridgeStepStart,
    AddNukiBridgeStepSearching,
    AddNukiBridgeStepListBridges,
    AddNukiBridgeStepAuthorize,
    AddNukiBridgeStepFinish,
    AddNukiBridgeStepNoBridgeFound,
    AddNukiBridgeStepGwOffline,
    FullscreenOverlayFrame},
  props: ['data'],
  data: function () {
    return {
      title: this.$t('add-nuki-bridge-dialog.title'),
      color: 'primary',
      stepper: 'start',
      steps: 5,
      progress: 0,
      bufferValue: 100,
      availableBridges: [],
      bridge: null,
      bridgeTimerRunning: false,
      bridgeTimer: null
    }
  },
  methods: {

    /**
     * triggers search for nuki bridges in the network of the gateway
     */
    triggerBridgeSearch() {
      this.$rhRequest.sendGet({
          endpoint: 'nuki/trigger-bridges-search'
      }, () => {}, (err) => {
        this.checkGwStatus()
        console.error(err)
      })
    },

    /**
     * check gw status
     */
    checkGwStatus() {
      this.$rhRequest.sendGet({
        endpoint: 'gateway'
      }, (resp) => {
        if(!resp?.data?.data[0]?.online) {
          this.showGwOffline()
        } else {
          this.showNoBridgeFound()
        }
      }, (err) => {
        console.error(err)
        this.showNoBridgeFound()
      })
    },

    /**
     * show gateway offline error page
     */
    showGwOffline() {
      this.setProgress('end')
      this.bufferValue = 0
      this.stepper = 'showGwOffline'
    },

    /**
     * start adding mode
     */
    startAdding() {
      this.setProgress(1)
      this.bufferValue = 0
      this.stepper = 'searching'
      this.triggerBridgeSearch()
      this.returnBridges()
    },

    /**
     * return nuki bridges in network
     */

    returnBridges() {
      this.$rhRequest.sendGet({
          endpoint: 'nuki/get-bridges'
        },
        (resp) => {
          let returnedBridges = resp?.data?.data?.devices
          if (resp?.data?.data?.devices.length > 0) {
            this.availableBridges = returnedBridges
            this.listBridges()
          } else {
            this.showNoBridgeFound()
          }
        },
        (err) => {
          this.checkGwStatus()
          console.error(err)
       }
      )
    },

    /**
     * show no bridges
     */
    showNoBridgeFound() {
      this.setProgress('end')
      this.bufferValue = 0
      this.stepper = 'noBridgeFound'
    },

    /**
     * show bridges
     */
    listBridges() {
      this.setProgress(2)
      this.stepper = 'listBridges'
    },

    /**
     * start bridge authorization process
     */
    authorizeBridge(bridge) {
      this.bridge = bridge
      this.setProgress(3)
      this.stepper = 'authorize'

      this.$rhRequest.sendPost({
        endpoint: 'nuki/authorize-bridge',
        data: {
          deviceId: bridge.id,
        }
      }, () => {
        this.bridgeTimerRunning = true
        this.bridgeTimer = setTimeout(() => {
            this.bridgeTimerRunning = false
          }, 30000);
        this.checkBridgeStatus()
      }, (err) => {
        console.error(err)
        this.listBridges()
        this.$root.bisatoast.error({
          message: this.$t('add-nuki-bridge-dialog.step-authorize.error'),
          showCloseBtn: true
        })
      })
    },

    checkBridgeStatus() {
      if (this.bridgeTimerRunning) {
        this.$rhRequest.sendGet({
          endpoint: 'nuki/get-bridges'
        }, (resp) => {
          let returnedBridge = resp?.data?.data?.devices.find(({id}) => id === this.bridge.id)
          if (returnedBridge.mode < 2) {
            this.checkBridgeStatus()
          } else {
            this.finishAuthorization()
          }
        })
      } else {
        this.$root.bisatoast.error({
          message: this.$t('add-nuki-bridge-dialog.step-authorize.error'),
          showCloseBtn: true
        })
        this.listBridges()
      }
    },

    /**
     * show finish page
     */
    finishAuthorization() {
      clearTimeout(this.bridgeTimer)
      this.setProgress('end')
      this.bufferValue = 0
      this.stepper = 'finish'
    },

    /**
     * calculates and sets progress for progressbar
     * @param step
     */
    setProgress(step) {
      if (step === 'end') {
        this.progress = 100
      } else if (typeof step === 'number') {
        this.progress = step / this.steps * 100
      } else {
        this.progress = 0
      }
    },

    /**
     * abort adding process
     */
    abort() {
      this.close()
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('addNukiBridge')
    }
  },
  mounted() {
    this.triggerBridgeSearch()
  },
  beforeDestroy() {
    clearTimeout(this.bridgeTimer)
  }
};
</script>

