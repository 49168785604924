<template>
  <dialog-frame :title="$t('first-launch-dialog.title').toString()"
                icon="waving_hand"
                color="primary"
                closable
                @close="close">
    <template v-slot:content>
      {{ $t('first-launch-dialog.text') }}
    </template>

    <template v-slot:actions>
      <v-btn plain block
             color="primary"
             class="font-weight-bold d-block"
             @click="close">
        {{ $t('first-launch-dialog.close') }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>

import DialogFrame from "@/templates/dialogs/DialogFrame";
export default {
  name: 'FirstLaunchDialog',

  components: {
    DialogFrame
  },

  methods: {
    close() {
      localStorage.setItem('firstLaunchDialogSeen', 'true')
      this.$root.bisadialog.toggle('firstLaunch')
    }
  },

};
</script>

