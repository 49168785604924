<template>
  <div class="water-control-tile">
    <content-card :title="waterControlStatus.name"
                  icon="settings"
                  color="primary">
      <template v-slot:content>
        <v-radio-group class="px-5" v-model="isManualMode">
          <v-radio disabled :label="$t('water-control-page.mode-selection.auto').toString()" :value="false"></v-radio>
          <v-radio disabled :label="$t('water-control-page.mode-selection.manual').toString()" :value="true"></v-radio>
        </v-radio-group>

        <div class="mx-6 pb-6">
          <v-btn v-if="awaitModeSwitchResponse"
                 large depressed
                 color="primary"
                 class="font-weight-bold block-sm d-block mx-auto"
                 :disabled="true">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
          </v-btn>
          <v-btn v-else
                 large depressed
                 color="primary"
                 class="font-weight-bold block-sm d-block mx-auto"
                 :disabled="!isOnline"
                 @click="changeMode">
            <v-icon class="material-icons-outlined non-flip" left>published_with_changes</v-icon>
            {{ $t('water-control-page.button.change-mode') }}
          </v-btn>
        </div>
      </template>
    </content-card>
  </div>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";

export default {
  name: "WaterControlTile",
  components: {ContentCard},
  props: ["waterControlStatus"],

  data() {
    return {
      awaitModeSwitchResponse: false
    }
  },

  methods: {
    changeMode() {
      this.awaitModeSwitchResponse = true

      this.$rhRequest.sendPost({
        endpoint: 'devices/update-property',
        data: {
          deviceId: this.waterControlStatus.id,
          state: !this.waterControlStatus.state
        }
      }, (resp) => {
        console.log(resp)
        this.awaitModeSwitchResponse = false
      }, (error) => {
        console.error(error)
        this.awaitModeSwitchResponse = false
      })
    }
  },

  mounted() {
  },

  computed: {
    isManualMode() {
      return this.waterControlStatus?.isManualMode
    },
    isOnline() {
      return this.waterControlStatus?.online
    }
  }
}
</script>
