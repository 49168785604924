<template>
  <div class="page-content">

    <!-- skeleton loader -->
    <v-row v-if="loading">
      <v-col cols="12" sm="6" lg="4">
        <v-skeleton-loader type="article"
                           height="300"/>
      </v-col>
    </v-row>

    <!-- offers content -->
    <v-row v-else-if="!showEmptyState">
      <v-col cols="12" sm="6" lg="4"
             v-for="offer in offers" v-bind:key="offer.id">

        <offer-card :content="offer"/>

      </v-col>
    </v-row>

    <!-- Emptystate -->
    <div v-else>
      <empty-state
              :empty-state-title="$t('offers-page.empty-state.title').toString()"
              :empty-state-text="$t('offers-page.empty-state.text').toString()">
        <template v-slot:illustration>
          <OffersEmptyStateIllustration/>
        </template>
      </empty-state>
    </div>

  </div>
</template>

<script>
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";
import OffersEmptyStateIllustration from "@/templates/components/emptyStates/svg/OffersEmptyStateIllustration";
import OfferCard from "@/templates/components/living/OfferCard.vue";

export default {
  name: "OffersPage",

  components: {OfferCard, OffersEmptyStateIllustration, EmptyState},

  data() {
    return {
      timer: null,
      refreshRate: config.dataRefreshRate,
      loading: false,
      offers: []
    }
  },

  methods: {
    /**
     * gets offers from the API
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: "content-service/get-relevant",
        params: {
          type: "MARKET",
          filter: "SECTION" // only includes entries which are flagged for display on the overview page
        }
      }, (response) => {
        this.offers = response?.data?.data
        this.loading = false
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },
  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
      () => this.getData(true),
      () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },

  computed: {
    showEmptyState() {
      return !this.loading && this.hasNoContent
    },
    hasNoContent() {
      return !this.offers?.length > 0
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/offers-page.scss';
</style>
