<template>
  <v-list color="transparent">
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          error_outline
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-04 mb-2 primary--text font-weight-bold">
          {{ $t('add-nuki-bridge-dialog.step-gw-offline.title') }}
        </div>
        <div class="font-size-03 red--text">
          {{ $t('add-nuki-bridge-dialog.step-gw-offline.description') }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          check_circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-03 font-weight-bold">
          {{ $t('add-nuki-bridge-dialog.step-gw-offline.text1') }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          check_circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-03 font-weight-bold">
          {{ $t('add-nuki-bridge-dialog.step-gw-offline.text2') }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          check_circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-03 font-weight-bold">
          {{ $t('add-nuki-bridge-dialog.step-gw-offline.text3') }}
        </div>
      </v-list-item-content>
    </v-list-item>

  </v-list>
</template>

<script>
export default {
  name: "AddNukiBrigdeStepGwOffline"
}
</script>

