<template>
  <v-menu v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field outlined readonly
                    :disabled="disabled"
                    :label="label"
                    :rules="rules"
                    v-model="dateTime"
                    prepend-inner-icon="calendar_month"
                    validate-on-blur
                    v-bind="attrs"
                    v-on="on"/>
    </template>
    <v-date-picker v-model="date"
                   v-if="pickDate"
                   color="primary"
                   :locale="locale"
                   scrollable>
      <v-spacer></v-spacer>
      <v-btn text
             color="primary"
             @click="abort">
        {{ $t('app.cancel') }}
      </v-btn>
      <v-btn text
             color="primary"
             class="font-weight-bold"
             :disabled="!date"
             @click="pickDate=false">
        {{ $t('app.continue') }}
      </v-btn>
    </v-date-picker>
    <v-time-picker format="24hr"
                   v-model="time"
                   v-else>
      <v-spacer></v-spacer>
      <v-btn text
             color="primary"
             @click="abort">
        {{ $t('app.cancel') }}
      </v-btn>
      <v-btn text
             color="primary"
             class="font-weight-bold"
             :disabled="!time"
             @click="setDateTime">
        {{ $t('app.continue') }}
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
import i18n from "@/i18n";

export default {
  name: 'DateTimePicker',

  props: {
    value: String, // for v-model
    label: String,
    disabled: Boolean,
    rules: Array
  },

  data: () => ({
    dateTime: null,
    menu: false,
    date: null,
    time: null,
    pickDate: true
  }),

  computed: {
    locale() {
      return i18n.locale
    }
  },

  methods: {
    /**
     * updates v-model and resets inputs
     */
    setDateTime() {
      this.menu = false
      this.pickDate = true
      this.dateTime = this.date + " " + this.time
      this.$emit('input', this.dateTime)
    },

    /**
     * resets inputs and closes dialog
     */
    abort() {
      this.menu = false
      this.pickDate = true
    }
  },

  mounted() {
    if (this.value) {
      this.dateTime = this.value
    }
  }
}
</script>
