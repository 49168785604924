<template>
  <div>
    <v-list-item inactive
                 :disabled="device.currentlyEnabled === false"
                 :ripple="false"
                 class="px-0 mb-4">
      <v-list-item-icon class="ml-0 mr-3">
        <v-icon :color="device.currentlyEnabled === false ? '' : 'primary'">lock</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="font-size-03"
                           :class="device.currentlyEnabled === false ? '' : 'primary--text font-weight-bold'"
                           v-text="device.name" />
        <v-list-item-subtitle v-if="device.currentlyEnabled === false" class="font-size-02">{{ $t('app.additional-permission.no-permission') }}</v-list-item-subtitle>
        <v-list-item-subtitle v-else class="font-size-02">{{ deviceStatus }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-btn depressed block large
           class="font-weight-bold"
           color="primary"
           :disabled="lockState === SimulatedStates.REQUESTING || device.currentlyEnabled === false"
           @click="executeLockOperation(device.id)"
           v-html="$t('accesses-card.execute-button')"/>
  </div>
</template>

<script>

export const SimulatedStates = Object.freeze({
  IDLE: "idle",
  REQUESTING: "requesting",
  ACTUATED: "actuated"
})

export default {
  name: "KaadasLock",

  props: {
    device: Object
  },

  data() {
    return {
      simulatedStatusDuration: 5000,
      SimulatedStates: SimulatedStates,
      lockState: null
    }
  },

  computed: {
    deviceStatus() {
      switch (this.lockState) {
        case SimulatedStates.REQUESTING:
          return this.$t("accesses-card.device-status.actuate")
        default:
          return this.$t("accesses-card.device-status.reachable")
      }
    }
  },

  methods: {
    executeLockOperation: function (deviceId) {
      this.lockState = SimulatedStates.REQUESTING;
      this.$rhRequest.sendPost({
        endpoint: "lock/unlock",
        data: {
          deviceId: deviceId
        }
      }, (response) => {
        if (response?.data?.code !== 0 || response?.data?.data?.success === false) {
          this.$root.bisatoast.error({message: this.$t('accesses-card.nuki-operation.failed')})
          this.lockState = SimulatedStates.IDLE
        } else {
          this.lockState = SimulatedStates.ACTUATED
          setInterval(() => {
            this.lockState = SimulatedStates.IDLE
          }, this.simulatedStatusDuration)
        }
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('accesses-card.nuki-operation.failed')})
        this.lockState = SimulatedStates.IDLE
      })
    }
  }
}
</script>
