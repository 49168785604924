<template>
  <v-stepper-content step="6"
                     class="pb-2 pa-1">
    <div v-html="$t('add-rule-step-when-condition-selection.info-text')"/>

    <content-card :title="device?.name"
                  class="my-5">
      <template v-slot:content>
        <v-alert v-if="properties?.length === 0"
                 text
                 class="font-size-03 mb-5"
                 border="left"
                 icon="warning"
                 type="warning"
                 v-html="$t('add-rule-step-property-definition.no-properties')"/>

        <v-row class="px-5 pt-5"
               v-else>
          <v-col xs="12" sm="6" cols="12">
            <v-select outlined
                      v-model="propertySelection"
                      :items="properties"
                      return-object
                      single-line
                      hide-details
                      item-value="name"
                      @change="updateModel">
              <template v-slot:selection="data">
                <!-- HTML that describe how select should render selected items -->
                {{ itemTitle(data.item.name) }}
              </template>
              <template v-slot:item="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ itemTitle(data.item.name) }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="3" sm="2">
            <v-btn depressed small
                   :disabled="disallowOperatorChange"
                   class="font-size-04 font-weight-bold mx-auto operator-btn"
                   @click="switchOperator"
                   @change="updateModel">
              <v-icon v-if="compareOperator==='<'" large>chevron_left</v-icon>
              <v-icon v-else-if="compareOperator==='>'" large>chevron_right</v-icon>
              <v-icon v-else-if="compareOperator==='='" large>drag_handle</v-icon>
            </v-btn>
          </v-col>

          <v-col xs="8" sm="4">
            <v-switch v-model="compareValue"
                      v-if="propertySelection?.dataType === 'boolean'"
                      inset
                      :label="stateLabel(propertySelection.name, compareValue)"
                      @change="updateModel"/>
            <div v-else-if="valueMapping != null">
              <v-select outlined
                        v-model="compareValue"
                        :items="valueMapping"
                        item-value="value"
                        item-text="label"
                        @change="updateModel"/>
            </div>
            <v-text-field outlined
                          v-else
                          v-model="valueWrapper"
                          type="number"
                          :hint="valueRangeHint"
                          :suffix="propertySelection?.unit"
                          @change="updateModel"/>
          </v-col>
        </v-row>

        <v-divider v-if="properties?.length > 0"/>

        <div class="pa-5"
             v-if="properties?.length > 0">
          {{ $t('add-rule-step-property-definition.condition-summary-label') }}<br/>
          <condition-summary :condition="value"/>
        </div>

      </template>
    </content-card>
  </v-stepper-content>
</template>

<script>

import ContentCard from "@/templates/components/ContentCard.vue";
import deviceProperties from "@/config/deviceProperties.json";
import ConditionSummary from "@/templates/components/living/rules/ConditionSummary.vue";

export default {
  name: 'AddRuleStepPropertyDefinition',
  components: {ConditionSummary, ContentCard},

  props: ['device', 'value'],

  data: function () {
    return {
      propertySelection: null,
      compareOperator: '>',
      compareValue: null,
      valueInverted: false
    }
  },

  computed: {

    valueWrapper: {
      set(value) {
        if (this.valueInverted === true) {
          this.compareValue =  Number(this.propertySelection?.maxValue) - value;
        } else {
          this.compareValue = value
        }
      },

      get() {
        if (this.valueInverted === true) {
          return Number(this.propertySelection?.maxValue) - this.compareValue;
        } else {
          return this.compareValue
        }
      }
    },

    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      return this.propertySelection != null
          && this.compareOperator != null
          && this.compareValue != null
    },

    /**
     * returns a list of selectable device properties
     * @returns {*|*[]}
     */
    properties() {
      let properties = this.device?.actuators?.concat(this.device?.sensors)
      if (properties == null) {
        return []
      }
      // remove properties marked as unsupported for use in property conditions
      return properties.filter(function (obj) {
        return deviceProperties[obj.name]?.automation?.selectableInConditions !== false
      })
    },

    /**
     * returns a text describing the allowed value range
     * @returns {null|string}
     */
    valueRangeHint() {
      let min = this.propertySelection?.minValue
      let max = this.propertySelection?.maxValue
      if (min && max) {
        return this.$t('app-rule-step-property-definition.value-range-hint.min-max', {min: min, max: max, unit: this.propertySelection?.unit})
      } else if (min) {
        return this.$t('app-rule-step-property-definition.value-range-hint.min', {min: min, unit: this.propertySelection?.unit})
      } else if (max) {
        return this.$t('app-rule-step-property-definition.value-range-hint.max', {max: max, unit: this.propertySelection?.unit})
      } else {
        return null
      }
    },

    /**
     * returns an array with available property values and matching labels for devices which have propertyLabelMapping
     * configured (e.g. Nuki Locks)
     * @returns {*[]|null}
     */
    valueMapping() {
      let valueMapping = []
      let obj = deviceProperties[this.propertySelection?.name]?.valueLabelMapping
      if (obj == null) {
        return null
      }
      for (const [key, value] of Object.entries(obj)) {
        valueMapping.push({
          value: key,
          label: this.$t(value.toString())
        })
      }
      return valueMapping
    },

    /**
     * returns true if the user must not change the compare operator for the currently selected property
     * @returns {boolean}
     */
    disallowOperatorChange() {
      return this.propertySelection?.dataType === 'boolean' || deviceProperties[this.propertySelection?.name]?.valueLabelMapping != null
    }
  },

  methods: {
    init() {
      if (this.properties.length === 0) {
        // no available properties
        return
      }

      // preselect first property
      this.propertySelection = this.properties[0]
      // initial compareValue
      if (this.propertySelection.dataType === 'boolean') {
        this.compareValue = true
        this.compareOperator = '='
      } else {
        this.compareValue = 0
        this.compareOperator = '>'
      }
      this.updateModel()
    },

    /**
     * returns a translated label for the passed property name
     * @param name
     * @returns {string}
     */
    itemTitle(name) {
      if (this.device?.type === 'meter-mec') {
        return this.$t(deviceProperties[name]?.label.replace('{mec-meter-type}', this.device?.energyDataType))
      } else {
        return this.$t(deviceProperties[name]?.label)
      }
    },

    /**
     * changes compare operator from '<' to '>'
     */
    switchOperator() {
      switch (this.compareOperator) {
        case '>':
          this.compareOperator = '<'
          break
        case '<':
          this.compareOperator = '='
          break
        case '=':
        default:
          this.compareOperator = '>'
      }
      this.updateModel()
    },

    /**
     * returns a translated status text for properties of type boolean
     * @returns {string}
     */
    stateLabel(name, state) {
      switch (state) {
        case true:
          return this.$t(deviceProperties[name]?.activeLabel)
        case false:
          return this.$t(deviceProperties[name]?.inactiveLabel)
      }
    },

    /**
     * updates v-model
     */
    updateModel() {
      let compareValue = this.compareValue;
      if (this.propertySelection.dataType === 'boolean' && this.propertySelection.automationDataType === 'int') {
        compareValue = Number(this.compareValue);
      }

      let data = {
        type: "properties",
        deviceId: this.device.id,
        property: this.propertySelection.name,
        constraint: this.compareOperator,
        value: compareValue,
        metaData: {
          device: this.device
        }
      }

      this.$emit('input', data)
    }
  },

  watch: {
    propertySelection(newValue) {

      this.valueInverted = deviceProperties[newValue.name].inverted;

      // reset to default values on property change
      if (newValue.dataType === 'boolean') {
        this.compareOperator = '='
        this.compareValue = true
      } else if (deviceProperties[newValue.name]?.valueLabelMapping != null) {
        this.compareOperator = '='
        this.compareValue = null
      } else {
        this.compareOperator = '>'
        this.compareValue = 0
      }
      this.updateModel()
    }
  }
}
</script>

<style lang="scss">
.operator-btn {
  height: 56px !important;
  display: block;
}
</style>
