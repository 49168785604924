<template xmlns:slot="http://www.w3.org/1999/html">
  <div style="position: relative">
    <v-overlay v-if="loading" color="navBackground" absolute z-index="4" opacity="0.7">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-overlay>
    <v-slider class="pa-4 pt-8 mode-control-slider"
              :class="hasStep ? 'pt-8' : 'pt-11'"
              hide-details
              v-model="value"
              @change="emitValueChange"
              thumb-label="always"
              :tick-labels="hasStep ? fanModeLabels : []"
              :tick-color="sliderColor"
              :thumb-size="hasStep ? 20 : 28"
              :ticks="hasStep ? 'always' : false"
              tick-size="6"
              :color="sliderColor"
              :track-color="sliderColor + ' lighten-3'"
              :max="max"
              :min="min">
      <template v-slot:thumb-label="item">
        {{ hasStep ? null : item.value }}
      </template>
    </v-slider>
  </div>
</template>


<script>

export default {
  name: "ModeControlSlider",

  props: {
    hasStep: {
      type: Boolean,
      required: true
    },
    FanModes: {
      type: Object,
    },
    ControllerModes: {
      type: Object
    },
    receivedValue: {
      required: true
    },
    irController: {
      type: Object,
      required: false
    },
    loading: {
      type: Boolean
    },
    color: {
      type: String,
      required: false
    }
  },

  data: function () {
    return {
      returnValue: null
    }
  },

  methods: {
    emitValueChange () {
      this.$emit('change-mode-value', this.irController, this.returnValue)
    },
    getReturnValue(newValue) {
      return this.hasStep ? Object.values(this.FanModes)[newValue] : newValue
    }
  },

  computed: {
    min() {
      return this.hasStep ? '0' : '16'
    },
    max() {
      return this.hasStep ? '3' : '30'
    },
    fanModeLabels () {
      return [
        this.$t('ir-controller-card.fan-mode-slider.low'),
        this.$t('ir-controller-card.fan-mode-slider.off'),
        this.$t('ir-controller-card.fan-mode-slider.high'),
        this.$t('ir-controller-card.fan-mode-slider.auto'),
      ]
    },
    value: {
      get () {
        return this.hasStep ? Object.values(this.FanModes).indexOf(this.receivedValue) : this.receivedValue
      },
      set (newValue) {
        this.returnValue = this.getReturnValue(newValue)
      }
    },
    sliderColor () {
      if(this.color) return this.color
      switch (this.irController.mode) {
        case this.ControllerModes.HEAT:
          return 'error'
        case this.ControllerModes.COOL:
          return 'info'
        case this.ControllerModes.FAN_ONLY:
          return 'success'
        default:
          return 'primary'
      }
    }
  },

  mounted() {
    if(this.hasStep) {
      this.value = Object.values(this.FanModes).indexOf(this.receivedValue)
    } else {
      this.value = this.receivedValue
    }
    this.returnValue = this.getReturnValue(this.receivedValue)
  },

  watch: {
    receivedValue () {
      this.value = this.hasStep ? Object.values(this.FanModes).indexOf(this.receivedValue) : this.receivedValue
    }
  }
}
</script>
