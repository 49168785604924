<template>
  <div>
  <v-list color="transparent">
    <v-list-item class="px-0" three-line>
      <v-list-item-icon class="mr-3">
        <v-icon color="error"
                class="material-icons-outlined">
          error_outline
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-04 mb-2 error--text font-weight-bold">
          {{ $t('remove-device-dialog.step-gateway-error.title') }}
        </div>
        <div class="font-size-03">
          {{ $t('remove-device-dialog.step-gateway-error.description') }}
        </div>

      </v-list-item-content>
    </v-list-item>
  </v-list>
  <v-card>
    <v-card-text>
      <v-list class="pa-0">
        <v-list-item class="px-0">
          <v-list-item-icon class="mr-3">
            <v-icon color="primary"
                    class="material-icons-outlined">
              check_circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-size-03">
              {{ $t('add-device-dialog.step-gateway-error.hint1') }} <!-- same hint as for adding -->
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-icon class="mr-3">
            <v-icon color="primary"
                    class="material-icons-outlined">
              check_circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-size-03">
              {{ $t('add-device-dialog.step-gateway-error.hint2') }} <!-- same hint as for adding -->
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-icon class="mr-3">
            <v-icon color="primary"
                    class="material-icons-outlined">
              check_circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-size-03">
              {{ $t('add-device-dialog.step-gateway-error.hint3') }}
            </div>
          </v-list-item-content>
        </v-list-item>
        <remove-device-force-component :device="device" />
      </v-list>
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
import RemoveDeviceForceComponent from "@/templates/dialogs/removeDevice/RemoveDeviceForceComponent.vue";

export default {
  name: "RemoveDeviceStepGatewayError",
  props: ['device'],
  components: {RemoveDeviceForceComponent}
}
</script>

