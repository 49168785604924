<template>
  <v-skeleton-loader v-if="loading"
                     type="article"
                     height="300"/>
  <content-card v-else-if="Object.values(scenes)?.length > 0"
                :title="$t('heating-scenes-card.title').toString()"
                icon="play_circle">
    <template v-slot:content>
      <v-list subheader class="py-0">
        <v-list-item v-for="scene in scenes"
                     :key="scene.id"
                     class="list-item straight px-5">
          <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                             v-text="scene.name"/>
          <v-list-item-action>
            <v-btn small icon
                   @click="deleteHeatingScene(scene)">
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn x-small fab depressed
                   color="primary"
                   class="mr-1"
                   @click.native.stop="play(scene)">
              <v-icon v-if="scene.delay === 0">play_arrow</v-icon>
              <v-icon v-else>timer</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard"
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: "HeatingScenesCard",

  components: {ContentCard},

  data() {
    return {
      timer: null,
      refreshRate: config.dataRefreshRate,
      loading: false,
      scenes: []
    }
  },

  methods: {
    /**
     * gets scenes from the API
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'scene/get-heating-scenes'
      }, (response) => {
        this.scenes = response?.data?.data

        if(!Object.values(this.scenes)?.length > 0) {
          this.$emit('hide-heating-scenes-card', true)
        } else {
          this.$emit('hide-heating-scenes-card', false)
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.$emit('hide-heating-scenes-card', true)
        this.loading = false
      })
    },

    /**
     * plays a scene
     *
     * @param scene
     */
    play(scene) {
      if(!scene.cryptId) {
        this.$root.bisatoast.error({
          message: this.$t('scenes-card.play.failed', {name: scene.name}),
          showCloseBtn: true
        })
        return
      }
      this.$rhRequest.sendGet({
        endpoint: 'scene/' + scene.cryptId + "/play"
      }, (response) => {
        if (response.data.code === 0 && scene.delay > 0) {
          this.$root.bisatoast.success({
            message: this.$t('scenes-card.play-delayed.success', {name: scene.name, delay: scene.delay}),
            showCloseBtn: true
          })
        } else if (response.data.code === 0) {
          this.$root.bisatoast.success({
            message: this.$t('scenes-card.play.success', {name: scene.name}),
            showCloseBtn: true
          })
        } else {
          this.$root.bisatoast.error({
            message: this.$t('scenes-card.play.failed', {name: scene.name}),
            showCloseBtn: true
          })
        }
      }, (error) => {
        this.$root.bisatoast.error({
          message: this.$t('scenes-card.play.error'),
          showCloseBtn: true
        })
        console.error(error)
      })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },

    /**
     * Deletes a heating scene.
     * @param scene
     */
    deleteHeatingScene(scene){
      if(!this.loading){
        this.$root.bisadialog.toggle('confirmation', true, {
          maxWidth: 400,
          title: this.$t('confirmation-dialog.title'),
          text: this.$t(' heating-mode-dialog.delete', {name: scene.name}),
          confirmLabel: this.$t('rules-card.delete-rule.button')
        })
        this.$root.$on('dialogConfirmed', () => {
          this.$rhRequest.sendDelete({
            endpoint: 'scene/delete/'+scene.id
          }, () => {
            this.$root.bisatoast.success({
              message: this.$t('heating-mode-dialog.delete.success', {name: scene.name}),
              showCloseBtn: true
            })
            this.getData(false)
          }, (error) => {
            console.error(error)
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          })
          this.$root.$off('dialogConfirmed')
        })
      }
    }
  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
        () => this.getData(true),
        () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>
