import Vue from "vue";

export default class {
  
  irControllers = null
  
  constructor() {
  }
  
  ControllerModes = {
    OFF: 0,
    HEAT: 1,
    COOL: 2,
    AUTO: 3,
    RESUME: 5,
    FAN_ONLY: 6,
    DRY_AIR: 8
  }
  
  FanModes= {
    LOW: 1,
    OFF: 5,
    HIGH: 3,
    AUTO: 0,
  }
  
  setControllerMode(args, successCallback, errorCallback) {
    if (!args[0]) return
    let irController = args[0]
    let value = args[1]
    
    let options = {
      endpoint: 'devices/update-property',
      data: {
        deviceId: irController.id,
        property: 'ir-thermostat-mode',
        value: value
      }
    }
    
    Vue.prototype.$rhRequest.sendPost(
      options,
      successCallback,
      errorCallback
    )
  }
  
  setModeValue(args, successCallback, errorCallback) {
    
    let propertyName = null
    let controller = args[0]
    let value = args[1]
    
    
    switch (controller.mode) {
      case this.ControllerModes.HEAT:
        propertyName = 'ir-temperature-setpoint-heating'
        break
      case this.ControllerModes.COOL:
        propertyName = 'ir-temperature-setpoint-cooling'
        break
      case this.ControllerModes.FAN_ONLY:
        propertyName = 'ir-thermostat-fan-mode'
        break
      default:
        return
    }
    
    let options = {
      endpoint: 'devices/update-property',
      data: {
        deviceId: controller.id,
        property: propertyName,
        value: value
      }
    }
    
    Vue.prototype.$rhRequest.sendPost(
      options,
      successCallback,
      errorCallback
    )
  }
  
  getActualThermostatMode(deviceId, successCallback, errorCallback) {
    
    let options = {
      endpoint: 'devices/get-active-ir-controller-mode',
      params: {
        deviceId: deviceId
      }
    }
    
    Vue.prototype.$rhRequest.sendGet(
      options,
      successCallback,
      errorCallback
    )
  }
  
}
