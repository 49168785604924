<!--
  Card for the room climate use case.
-->
<template>
  <v-skeleton-loader v-if="loading"
                     type="card"/>
  <content-card v-else
                :title="$t('room-climate-card.title').toString()"
                icon="air">
    <template v-slot:titleBar>
      <v-btn v-if="data?.temperature?.value < temperatureLowThreshold"
             plain small
             class="float-end px-0 mt-1"
             @click="showColdDialog">
        <v-icon class="material-icons-outlined" left :color="temperatureLabelClass">ac_unit</v-icon>
        <span class="mr-1 text-none" v-html="$t('room-climate-card.too-cold')"/>
      </v-btn>
      <v-btn v-else-if="data?.temperature?.value >= temperatureHighThreshold"
             plain small
             class="float-end px-0 mt-1"
             @click="showHeatDialog">
        <v-icon class="material-icons-outlined" left :color="temperatureLabelClass">warning_amber</v-icon>
        <span class="mr-1 text-none" v-html="$t('room-climate-card.too-hot')"/>
      </v-btn>
      <v-btn v-else
             plain small icon
             class="float-end px-0 mt-1"
             @click="showTipsDialog">
        <v-icon class="material-icons-outlined" :color="temperatureLabelClass">info</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>

      <!-- Temperature bar -->
      <div class="room-climate-area pa-5" v-if="data?.temperature">
        <div class="room-climate-area-inner">
          <div class="mr-2">
            <v-icon color="primary">thermostat</v-icon>
          </div>

          <div class="value-bar">
            <div class="indicator-container">
              <div class="indicator"
                   :class="temperatureLabelClass"
                   :style="temperatureIndicatorPosition"></div>
            </div>
            <div class="bar-container">
              <div class="blue bar"></div>
              <div class="green bar"></div>
              <div class="orange bar"></div>
            </div>
          </div>

          <div class="room-climate-tile-label ml-2"
               :class="temperatureLabelClass">
            {{ data?.temperature?.value }} {{ $t('app.unit.celsius') }}
          </div>
        </div>
      </div>

      <!-- Humidity bar -->
      <div class="room-climate-area pa-5" v-if="data?.humidity">
        <div class="room-climate-area-inner">
          <div class="mr-2">
            <v-icon color="primary">water_drop</v-icon>
          </div>

          <div class="value-bar">
            <div class="indicator-container">
              <div class="indicator"
                   :class="humidityLabelClass"
                   :style="humiditiyIndicatorPosition"></div>
            </div>
            <div class="bar-container">
              <div class="blue bar"></div>
              <div class="green bar"></div>
              <div class="orange bar"></div>
            </div>
          </div>

          <div class="room-climate-tile-label ml-2"
               :class="humidityLabelClass">
            {{ data?.humidity?.value }} {{ $t('app.unit.percent') }}
          </div>
        </div>
      </div>

      <!-- CO2 bar -->
      <!-- no device with co2 sensors implemented yet -->
      <!-- TODO discuss design for a co2 indicator -->

    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";
import i18n from "@/i18n";

export default {
  name: "RoomClimateCard",

  components: {ContentCard},

  data: function () {
    return {
      loading: false,
      data: null,
      timer: null,
      temperatureLowThreshold: 18,
      temperatureHighThreshold: 24,
      humidityLowThreshold: 40,
      humidityHighThreshold: 60
    }
  },

  computed: {
    temperatureLabelClass() {
      if (this.data?.temperature?.value < this.temperatureLowThreshold) {
        return 'blue'
      } else if (this.data?.temperature?.value >= this.temperatureHighThreshold) {
        return 'orange'
      } else {
        return 'green'
      }
    },
    temperatureIndicatorPosition() {
      if(i18n.locale !== 'ar') {
        return this.data?.temperature?.indicatorPosition
      } else {
        return this.data?.temperature?.indicatorPosition.replace('left', 'right')
      }
    },
    humidityLabelClass() {
      if (this.data?.humidity?.value <= this.humidityLowThreshold) {
        return 'blue'
      } else if (this.data?.humidity?.value > this.humidityHighThreshold) {
        return 'orange'
      } else {
        return 'green'
      }
    },
    humiditiyIndicatorPosition() {
      if(i18n.locale !== 'ar') {
        return this.data?.humidity?.indicatorPosition
      } else {
        return this.data?.humidity?.indicatorPosition.replace('left', 'right')
      }
    }

  },

  methods: {
    /**
     * get room climate data
     *
     * @param showLoader
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'home-default/get-room-climate-data'
      }, (response) => {
        this.data = response?.data?.data
        this.loading = false
        if (this.data.temperature === null && this.data.humidity === null && this.data.co2 === null) {
          this.$emit('hide-room-climate', true)
        } else {
          this.$emit('hide-room-climate', false)
        }
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, config.updateInterval)
    },

    showColdDialog() {
      this.$root.bisadialog.toggle('infoText', true, {
        title: this.$t('room-climate-card.info-dialog.title'),
        icon: 'ac_unit',
        text: this.$t('room-climate-card.info-dialog.too-cold.text')
      })
    },

    showHeatDialog() {
      this.$root.bisadialog.toggle('infoText', true, {
        title: this.$t('room-climate-card.info-dialog.title'),
        icon: 'warning_amber',
        text: this.$t('room-climate-card.info-dialog.too-hot.text')
      })
    },

    showTipsDialog() {
      this.$root.bisadialog.toggle('infoText', true, {
        title: this.$t('room-climate-card.info-dialog.title'),
        icon: 'info',
        text: this.$t('room-climate-card.info-dialog.info.text')
      })
    }
  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
        () => this.getData(true),
        () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/room-climate-card.scss';
</style>
