import EventBus from '@/plugins/lib/EventBus';

export default class Darkmode {
  
  default = null;
  changeable = true;
  
  constructor(options) {
    if (typeof options?.default === 'string') {
      this.default = options?.default;
    }
    
    if (typeof options?.changeable === 'boolean') {
      this.changeable = options?.changeable;
    }
  }
  
  detect() {
    let platform = window?.cordova?.platformId.toLowerCase();
    switch (platform) {
      case 'ios':
      case 'android':
        var themeDetection = window?.cordova?.plugins?.ThemeDetection;
        if (themeDetection) {
          themeDetection.isDarkModeEnabled(
            (params) => {
              if (params.value) {
                this.change('dark');
              } else {
                this.change('light');
              }
            }
          );
        }
        break;
      default:
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          this.change('dark')
        } else {
          this.change('light')
        }
        break;
    }
  }
  
  change(mode) {
    switch (mode) {
      case 'light':
        EventBus.$emit('change.darkmode', false)
        break;
      case 'dark':
        EventBus.$emit('change.darkmode', true)
        break;
      case 'system':
      default:
        this.detect()
        break;
    }
  }
  
  init() {
    this.change(
      this.get()
    )
  }
  
  set(mode) {
    localStorage.setItem('darkmode', mode)
    this.change(mode)
  }
  
  get() {
    let mode = localStorage.getItem('darkmode')
    
    if (this.default !== null) {
      if (typeof mode === 'undefined' || mode === null) {
        return this.default;
      }
    }
    
    if (typeof mode === 'undefined' || mode === null) {
      return 'system';
    }
    
    return mode;
  }
}
