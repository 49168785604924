<template>
  <v-stepper-content step="3"
                     class=" pb-2 pa-1">

    <div v-html="$t('add-rule-step-timer-type.info-text')"/>

    <content-card :title="$t('add-rule-dialog.select-timer.title').toString()"
                  icon="schedule"
                  class="my-5">
      <template v-slot:content>
        <v-list class="pa-0">
          <v-radio-group v-model="timerSelection"
                         class="pa-0 ma-0"
                         @change="updateModel"
                         hide-details>
            <v-list-item class="list-item straight">
              <v-list-item-content class="overflow-visible">
                <v-list-item-title class="overflow-visible">
                  <v-radio value="SINGULAR"
                           class="font-weight-bold"
                           :label="$t('add-rule-step-timer-type.singular')"/>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="list-item straight">
              <v-list-item-content class="overflow-visible">
                <v-list-item-title class="overflow-visible">
                  <v-radio value="DAILY"
                           class="font-weight-bold"
                           :label="$t('add-rule-step-timer-type.daily')"/>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="list-item straight">
              <v-list-item-content class="overflow-visible">
                <v-list-item-title class="overflow-visible">
                  <v-radio value="WEEKLY"
                           class="font-weight-bold"
                           :label="$t('add-rule-step-timer-type.weekly')"/>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="list-item straight">
              <v-list-item-content class="overflow-visible">
                <v-list-item-title class="overflow-visible">
                  <v-radio value="INTERVAL"
                           class="font-weight-bold"
                           :label="$t('add-rule-step-timer-type.interval')"/>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-radio-group>
        </v-list>
      </template>
    </content-card>

  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";

export default {
  name: 'AddRuleStepTimerType',
  components: {
    ContentCard
  },

  prop: ['value'],

  data: function () {
    return {
      timerSelection: 'SINGULAR'
    }
  },

  methods: {
    updateModel() {
      this.$emit('input', this.timerSelection)
    }
  }
};
</script>
