import CustomLogger from "@/plugins/bluetooth/deviceManager/Logger";
import BltCommand from "@/plugins/bluetooth/deviceManager/Command";

const FLOW_RELEASE_TIME = 0xE1;

export default class {
  constructor(connection) {
    this.logger = new CustomLogger('flow: open');
    this.connection = connection;
    this.connection.onData(this.onData.bind(this));
    this.onFinishCb = null;
    this.onSuccessCb = null;
    this.onErrorCb = null;
    this.callbacks = {
      onFinish: null,
      onSuccess: null,
      onError: null,
    };
  }

  onFinish(callback) {
    this.callbacks.onFinish = callback;
  }

  onSuccess(callback) {
    this.callbacks.onSuccess = callback;
  }

  onError(callback) {
    this.callbacks.onError = callback;
  }

  onData(data) {
    if (data.getType() === 0xFD) {
      if (this.callbacks.onError !== null) {
        this.callbacks.onError();
      }
    } else if (data.getType() === FLOW_RELEASE_TIME) {
      if (this.callbacks.onSuccess !== null) {
        this.callbacks.onSuccess();
      }
    }

    if (this.callbacks.onFinish !== null) {
      this.callbacks.onFinish();
    }
  }

  open() {
    let cmd = new BltCommand(
        FLOW_RELEASE_TIME,
        new Uint8Array([0x0A]) // 10 seconds
    );

    this.connection.send(cmd);
  }

  onConnect() {
    this.open();
  }

  start() {
    let self = this;
    this.connection.isConnected().then(
        self.onConnect.bind(self)
    ).catch(
        self.onError.bind(self)
    );
  }
}
