<template>
  <entry-frame :title="$t('login-page.title').toString()">
    <template v-slot:subheader>
      <div v-html="$t('login-page.subtitle')"/>
      <v-btn text small
             color="primary"
             class="font-weight-bold"
             to="registration">
          {{ $t('login-page.register-link') }}
      </v-btn>
    </template>

    <template v-slot:form>
      <v-form ref="form" v-model="valid"
              lazy-validation
              @submit="login">
        <v-text-field outlined
                      v-model="email"
                      @focus="checkFingerprintAuth"
                      :rules="emailRules"
                      required
                      :label="$t('login-page.e-mail.label')"/>
        <v-text-field outlined
                      v-model="password"
                      :label="$t('login-page.password.label')"
                      :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"/>
        <v-btn depressed x-large block
               color="primary"
               class="font-weight-bold"
               :loading="loadingLogin"
               :disabled="loadingLogin"
               v-html="$t('login-page.login')"
               type="submit"
               @click="login"/>
        <div class="hint mt-4 font-size-02">
          <div v-html="$t('login-page.passwort-reset-hint')"/>
          <v-btn text small
                 color="primary"
                 class="font-weight-bold"
                 to="resetPassword">
              {{ $t('login-page.reset-password') }}
          </v-btn>
        </div>
        <div v-if="showGuestSupportLink"
             class="hint mt-4 font-size-02">
          <v-divider width="50" class="ma-auto mb-4"/>
          <span v-html="$t('login-page.support-text')"/>
        </div>
      </v-form>
    </template>
  </entry-frame>
</template>

<script>
import EntryFrame from "@/templates/components/EntryFrame";
import config from '@/config/config.app.json'

export default {
  name: 'LoginPage',

  components: {
    EntryFrame
  },

  data: () => ({
    showPassword: false,
    email: "",
    password: "",
    loadingLogin: false,
    faceIDAvailable: false,
    valid: true,
    askForBiometricPermission: true
  }),

  computed: {
    emailRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
        v => /.+@.+\..+/.test(v) || this.$t('app.rules.email-invalid')
      ]
    },

    showGuestSupportLink() {
      return config.showGuestSupportLink === 'true'
    }
  },

  methods: {
    checkFingerprintAuth () {
      if (window?.cordova) {
        window.Fingerprint.isAvailable(this.loadSecret, this.loadError, true)
      }
    },
    loadError (error) {
      this.askForBiometricPermission = false
      console.error(error)
    },
    loadSecret () {
      window.Fingerprint.loadBiometricSecret({
        description: `${config.portalKey}-fingerprint`,
      }, this.loadSecretSuccess, (error) => {
        this.faceIDAvailable = false
        console.error(error)}
      )
    },
    loadSecretSuccess (secret) {
      let credentials = JSON.parse(secret)
      this.email = credentials.email
      this.password = credentials.password
      this.faceIDAvailable = true
      this.login()
    },
    setSecret () {
      let self = this
      let faceIDConfig

      if (window?.cordova && !this.faceIDAvailable) {
        faceIDConfig = window.confirm(this.$t('login-page.set-secret.text'))
        if (faceIDConfig) {
          window.Fingerprint.registerBiometricSecret({
            description: `${config.portalKey}-fingerprint`,
            invalidateOnEnrollment: true,
            secret: JSON.stringify({
              email: self.email,
              password: self.password
            })
          }, () => {
            console.log('Password stored successfully')
            this.askForBiometricPermission = false
            localStorage.setItem('askForBiometricPermission', 'false')
          }, (error) => {
            console.error(error)
          })
        }
      }
    },
    login () {
      this.$refs.form.validate() // validate form but execute method regardless of the result
      this.loadingLogin = true
      this.$rhAuth.login(this.email, this.password, (response) => {
        if (response.authenticated) {
          if (this.askForBiometricPermission) {
            this.setSecret()
          }
          this.loadingLogin = false
          // because there are clients without home page, don't route to home, instead take first entry in config.homeTopNavOrder
          this.$router.push({name: config.homeTopNavOrder.split(',')[0]})
        } else if (response.timeout) {
          this.$root.bisatoast.error({
            message: this.$t('app.generic-error'),
            showCloseBtn: true
          })
          this.loadingLogin = false
        } else if (response?.errorCode === -185) {
          this.$root.bisatoast.error({
            message: this.$t('app.login-error.no-valid-site'),
            showCloseBtn: true
          })
          this.loadingLogin = false
        }
        else {
          this.$rhRequest.sendPost(
              {
                endpoint: "user/otp-login",
                data: {
                  'username': this.email,
                  'password': this.password
                }
              }, (response) => {
                if(response.data && response.data.data) {
                  if(response.data.data.otp_token) {
                    this.$root.bisadialog.toggle('newPassword', true, {otp_token: response.data.data.otp_token})
                  } else {
                    this.$root.bisatoast.error({
                      message: this.$t('login-page.login.error'),
                      showCloseBtn: true
                    })
                  }
                } else {
                  this.$root.bisatoast.error({
                    message: this.$t("app.generic-error")
                  })
                }
              }, (e) => {
                console.error(e)
                this.$root.bisatoast.error({
                  message: this.$t("app.generic-error")
                })
              }
          )
          this.loadingLogin = false
        }
      })
    },
    resetPassword: function () {
      this.$router.push({name: 'resetPassword'})
    }
  },

  mounted() {
    if (this.$route.params?.wasUnauthorized === 'true') {
      this.$root.bisatoast.error({message: this.$t("login-page.user-was-unauthorized"), showCloseBtn: true})
    } else if (this.$rhAuth.loggedIn()) {
      this.$router.push('/')
    }

    this.askForBiometricPermission = localStorage.getItem('askForBiometricPermission') === null ? true : JSON.parse(localStorage.getItem('askForBiometricPermission').toLowerCase())
  }
};
</script>
