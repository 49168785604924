<template>
  <div class="page-content">

    <v-skeleton-loader v-if="loading"
                       type="article"
                       height="300"/>

    <content-card v-else-if="!loading && (items && items.length > 0)"
                  :title="$t('inbox-page.title').toString()"
                  icon="forward_to_inbox"
                  color="primary">
      <template v-slot:content>

        <v-list subheader class="py-0">
          <v-list-item-group>
            <v-list-item v-for="item in items"
                         v-bind:key="item.id"
                         class="px-5 list-item"
                         @click="openOverlay(item.id)">
              <v-list-item-title class=" font-size-02"
                                 :class="item.read ? 'marked-as-read-title' : 'primary--text font-weight-bold'"
                                 v-html="item.title"/>
              <v-list-item-action>
                <v-icon v-if="item.read"
                        class="material-icons-outlined">
                  drafts
                </v-icon>
                <v-icon v-else
                        color="primary"
                        class="material-icons-outlined">
                  mail
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </content-card>

    <!-- Empty State -->
    <div v-else>
      <empty-state
        :empty-state-title="$t('inbox-page.emptyState.title').toString()"
        :empty-state-text="$t('inbox-page.emptyState.text').toString()">
        <template v-slot:illustration>
          <inbox-empty-state-illustration
              :fill-primary="'var(--v-primary-base)'"
              :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>
    </div>
  </div>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import config from '@/config/config.app.json'
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import InboxEmptyStateIllustration from "@/templates/components/emptyStates/svg//InboxEmptyStateIllustration";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: "InboxPage",
  components: {EmptyState, InboxEmptyStateIllustration,ContentCard},

  data() {
    return {
      refreshRate: config.dataRefreshRate,
      loading: false,
      items: [],
    }
  },

  methods: {
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }
      let self = this
      this.$rhRequest.sendGet({
        endpoint: 'content-service/get-relevant',
        params: {type: 'NOTICEBOARD'}
      }, function (response) {
        if (response.data.data) {
          self.updateItems(response.data.data)
        }
        self.loading = false
      }, function (error) {
        console.error(error)
        self.loading = false
      })
    },
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },
    openOverlay(messageId) {
      if (typeof messageId === 'undefined' || messageId === null) {
        return
      }
      let messageItem = this.items.find(item => item.id === messageId)
      if (typeof messageItem === 'undefined' || messageItem === null) {
        return
      }

      localStorage.setItem('inbox-' + messageId, 'read')
      this.$forceUpdate()
      this.refreshRead()
      this.$root.bisadialog.toggle("inbox", true, {item: messageItem})
    },
    updateItems(data) {
      data.forEach(item => {
        item.read = localStorage.getItem('inbox-' + item.id) === 'read'
      })
      this.items = data
      this.showRequestedMessage()
    },
    showRequestedMessage() {
      let messageId = this.$route.params?.tapMessageId
      if (this.isRead(messageId)) {
        return
      }
      this.openOverlay(messageId)
    },
    refreshRead () {
      this.items.forEach(item => {
        item.read = localStorage.getItem('inbox-' + item.id) === 'read'
      })
    },
    addGlobalEventListener () {
      this.$root.$on('updateInboxItems', () => {
        this.items.forEach(item => {
          item.read = localStorage.getItem('inbox-' + item.id) === 'read'
        })
      })
    },
    isRead(messageId) {
      return (localStorage.getItem('inbox-' + messageId) === 'read')
    }
  },
  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
        () => this.getData(true),
        () => this.updateData()
    )
    this.addGlobalEventListener()
  },
  beforeDestroy () {
    this.$root.$off('updateInboxItems')
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/inbox.scss';
</style>
