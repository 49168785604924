<template>
  <div id="add-heating-point-dialog">
    <v-card class="my-5">
      <v-list>
        <!-- Name -->
        <v-list-item dense two-line class="ma-0">
          <v-list-item-icon class="my-auto">
            <v-icon class="material-icons-outlined" color="primary">label</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!editName">
            <v-list-item-title class="primary--text font-weight-bold">{{ heatingPointName }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('heating-mode-dialog.add-heating-point.heating-point-name') }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-text-field outlined dense
                          hide-details
                          ref="device-name-input"
                          v-model="heatingPointName"/>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="editName = true" v-if="!editName">
              <v-icon class="material-icons-outlined" color="primary">edit</v-icon>
            </v-btn>
            <v-btn icon @click="editName = false" v-else>
              <v-icon class="material-icons-outlined" color="primary">check</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider/>

        <!-- Time -->
        <v-list-item dense two-line class="ma-0">
          <v-list-item-icon class="my-auto">
            <v-icon class="material-icons-outlined" color="primary">schedule</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-bold">{{ selectedTime }} {{ $t('app.unit.o-clock') }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('heating-mode-dialog.add-heating-point.start-time') }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="editTime = true" v-if="!editTime">
              <v-icon class="material-icons-outlined" color="primary">edit</v-icon>
            </v-btn>
            <v-btn icon @click="editTime = false" v-else>
              <v-icon class="material-icons-outlined" color="primary">check</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item dense v-if="editTime">
          <v-time-picker
              class="elevation-0 mx-auto"
              color="primary"
              v-model="selectedTime"
              format="24hr"
              no-title
          ></v-time-picker>
        </v-list-item>

        <v-divider/>

        <v-list-item dense two-line class="ma-0">
          <v-list-item-icon class="my-auto">
            <v-icon class="material-icons-outlined" color="primary">device_thermostat</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-bold">{{ $t('device.sensor.temperature') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense two-line class="ma-0">
          <v-list-item-content>
            <v-slider class="mt-8"
                      :color="returnTemperatureColor(temperature)"
                      :track-color="returnTemperatureColor(temperature)"
                      :thumb-color="returnTemperatureColor(temperature)"
                      thumb-label="always"
                      append-icon="add_circle_outline"
                      prepend-icon="remove_circle_outline"
                      @click:append="increase"
                      @click:prepend="decrease"
                      min="8"
                      max="28"
                      v-model="temperature"
            >
              <template v-slot:thumb-label="{ value }">
                <span class="font-weight-bold">{{ value }}</span>
                <sup>{{ $t('app.unit.celsius') }}</sup>
              </template>
            </v-slider>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="d-flex px-4 justify-space-between pb-4">
        <div class="text-center" v-for="(temperature, key) in defaultTemperatures" v-bind:key="key">
          <v-chip class="mx-1 justify-center white--text font-size-02 font-weight-bold"
                  :color="returnTemperatureColor(temperature.value)"
                  @click="setDefaultTemperature(temperature.value)"
          >{{ temperature.value }}<sup>{{ $t('app.unit.celsius') }}</sup>
          </v-chip>
          <div class="mt-2">
            <span class="font-size-02">{{ temperature.label }}</span>
          </div>
        </div>
      </div>
    </v-card>

    <v-btn depressed large outlined
           color="primary"
           class="font-weight-bold block-sm mx-auto d-block mt-8 mb-4"
           @click="save">
      <v-icon class="material-icons-outlined" left>add</v-icon>
      {{ $t('heating-mode-dialog.save-heating-point') }}
    </v-btn>

    <v-btn depressed large outlined
           color="primary"
           class="font-weight-bold block-sm mx-auto d-block"
           @click="deleteHeatingPoint">
      <v-icon class="material-icons-outlined" left>delete</v-icon>
      {{ $t('heating-mode-dialog.delete-heating-point') }}
    </v-btn>
  </div>
</template>

<script>

import moment from "moment/moment";

export default {
  name: 'AddHeatingPointDialog',
  components: {},
  props: {
    propTemperature: {
      default: 18,
      type: Number
    },
    propHeatingPointName: {
      default: 'Mein Zeitpunkt',
      type: String
    },
    propSelectedTime: {
      default: "15:00",
      type: String
    },
    propArrIndex: {
      default: false,
    },
  },
  data: function () {
    return {
      title: this.propArrIndex ? this.$t('heating-mode-dialog.add-heating-point.title-edit') : this.$t('heating-mode-dialog.add-heating-point.title-new'),
      color: 'primary',
      editName: false,
      editTime: false,
      temperature: this.propTemperature,
      heatingPointName: this.propHeatingPointName,
      selectedTime: this.propSelectedTime,
      arrIndex: this.propArrIndex,
      defaultTemperatures: [{
        value: 12,
        label: this.$t('heating-mode-dialog.default-temperature.absent')
      }, {
        value: 16,
        label: this.$t('heating-mode-dialog.default-temperature.night')
      }, {
        value: 18,
        label: this.$t('heating-mode-dialog.default-temperature.eco')
      }, {
        value: 22,
        label: this.$t('heating-mode-dialog.default-temperature.comfort')
      }]
    }
  },
  methods: {
    increase() {
      this.temperature++
    },
    decrease() {
      this.temperature--
    },
    setDefaultTemperature(temperature) {
      this.temperature = temperature
    },
    /**
     * Returns a color for a given temperature
     */
    returnTemperatureColor(temperature) {
      let color = 'primary'
      switch (temperature) {
        case 12:
          color = '#8DDBFF'
          break;
        case 16:
          color = '#003953'
          break;
        case 18:
          color = '#67EB9A'
          break;
        case 22:
          color = '#FA9657'
          break;
      }
      return color
    },
    /**
     * Emits an event which removes a given heating point from the list
     */
    deleteHeatingPoint() {
      this.$emit('deleteHeatingPoint', this.arrIndex)
    },
    /**
     * Emits an event which adds or edits the open heating point
     */
    save() {
      let heatingPoint = {
        name: this.heatingPointName,
        hour: this.hour,
        minute: this.minute,
        seconds: 0,
        value: this.temperature
      }

      this.$emit('addHeatingPoint', heatingPoint, this.arrIndex)
    }
  },
  computed: {
    hour () {
      return moment(this.selectedTime, 'HH:mm').format('HH')
    },
    minute () {
        return moment(this.selectedTime, 'HH:mm').format('mm')
    }
  }
};
</script>

