<template>
  <v-app class="app-background"
         :class="[device, platform]">

    <!-- top navigation -->
    <top-navigation v-if="hasTopNavigation"
                    :style="dialogOpen ? blur : ''"
                    :showBackArrow="showBackArrow"
                    :showTopNavTabs="showTopNavTabs"
                    :topNavLogoColor="topNavLogoColor"
                    :topNavLogoFontColor="topNavLogoFontColor"
                    :backRoute="backRoute"
                    @blur="menuOpen = true"
                    @clear="menuOpen = false"/>

    <v-main>
      <!-- show active page -->
      <router-view :style="dialogOpen || menuOpen ? blur : ''"/>
    </v-main>

    <!-- bottom navi -->
    <bottom-navigation v-if="hasBottomNavigation && hasDiscoverPage"
                       :style="dialogOpen || menuOpen ? blur : ''"
                       :bottomNavigationActive="bottomNavigationActive"/>

    <!-- overlay for the menus -->
    <v-overlay :value="menuOpen"/>

    <!-- app snackbar for messages -->
    <bisa-toast ref="bisa-toast"/>

    <!-- app dialogs -->
    <bisa-dialog ref="bisa-dialog" @blur="dialogOpen = true" @close="dialogOpen = false"/>
  </v-app>
</template>

<script>
import BisaToast from "@/templates/components/BisaToast";
import BottomNavigation from "@/templates/components/BottomNavigation";
import BisaDialog from "@/templates/dialogs/BisaDialog";
import TopNavigation from "@/templates/components/TopNavigation";
import EventBus from '@/plugins/lib/EventBus';
import config from '@/config/config.app.json'
import i18n from "@/i18n";

export default {
  name: 'App',

  components: {
    TopNavigation,
    BisaDialog,
    BottomNavigation,
    BisaToast
  },

  data: () => ({
    device: null,
    platform: null,
    dialogOpen: false,
    menuOpen: false,
    blur: 'filter:blur(5px)'
  }),

  methods: {
    //
  },

  computed: {
    hasBottomNavigation() {
      return this.$route.meta.bottomNavigation
    },
    bottomNavigationActive() {
      return this.$route.meta.bottomNavigationActive
    },
    hasTopNavigation() {
      return this.$route.meta.topNavigation
    },
    showTopNavTabs() {
      return this.$route.meta.showTopNavTabs
    },
    showBackArrow() {
      return this.$route.meta.showBackArrow
    },
    backRoute() {
      return this.$route.meta.backRoute ?? 'discover'
    },
    topNavLogoFontColor() {
      return this.$vuetify.theme.currentTheme.logoFontColor ?? this.$vuetify.theme.currentTheme.primary
    },
    topNavLogoColor() {
      // set logo color depending on current path

      if (this.$route.meta.topNavLogoColor === 'secondary') {
        return this.$vuetify.theme.currentTheme.secondary
      } else {
        return this.$vuetify.theme.currentTheme.logoColor ?? this.$vuetify.theme.currentTheme.primary
      }
    },
    hasDiscoverPage() {
      return config.hasDiscoverPage === 'true'
    },
    isRtl() {
      return i18n.locale === 'ar'
    }
  },

  created() {
    EventBus.$on('change.darkmode', (mode) => {
      this.$vuetify.theme.dark = mode;
    })
    this.$darkmode.init();
  },

  mounted() {
    let self = this
    this.$root.bisatoast = this.$refs["bisa-toast"]
    this.$root.bisadialog = this.$refs["bisa-dialog"]
    this.$vuetify.rtl = this.isRtl

    document.addEventListener('deviceready', () => {
      self.platform = window?.cordova?.platformId
      self.device = window?.navigator?.appVersion.includes('iPad') ? 'iPad' : ''
    }, false)
  },

  watch: {
    $route(to) {
      this.$econda.send({content: to.path})
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/app.scss';
@import "fonts";
</style>
