<template>
  <div>
    <v-list color="transparent">
      <v-list-item class="px-0" three-line>
        <v-list-item-icon class="mr-3">
          <v-icon color="error"
                  class="material-icons-outlined">
            error_outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <div class="font-size-04 mb-2 error--text font-weight-bold">
            {{ $t('remove-device-dialog.step-error.title') }}
          </div>
          <div class="font-size-03" v-html="$t('remove-device-dialog.step-error.description')"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card>
      <v-card-text>
        <v-list class="pa-0">
          <v-list-item class="px-0">
            <v-list-item-icon class="mr-3">
              <v-icon color="primary"
                      class="material-icons-outlined">
                check_circle
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-size-03">
                {{ $t('remove-device-dialog.step-error.hint1') }}
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-icon class="mr-3">
              <v-icon color="primary"
                      class="material-icons-outlined">
                check_circle
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-size-03">
                {{ $t('remove-device-dialog.step-error.hint2') }}
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-icon class="mr-3">
              <v-icon color="primary"
                      class="material-icons-outlined">
                check_circle
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-size-03">
                {{ $t('remove-device-dialog.step-error.hint3') }}
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-icon class="mr-3">
              <v-icon color="primary"
                      class="material-icons-outlined">
                check_circle
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-size-03">
                {{ $t('remove-device-dialog.step-error.hint4') }}
              </div>
            </v-list-item-content>
          </v-list-item>
          <remove-device-force-component :device="device"/>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RemoveDeviceForceComponent from "@/templates/dialogs/removeDevice/RemoveDeviceForceComponent.vue";

export default {
  name: "RemoveDeviceStepError",
  props: ['device'],
  components: {RemoveDeviceForceComponent}
}
</script>
