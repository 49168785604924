<template>
  <v-card>

    <v-carousel v-model="carouselIndex"
                class="onboarding-carousel"
                height="600"
                :show-arrows="carouselIndex !== 0"
                progress-color="primary"
                :continuous="false"
                hide-delimiters>
      <v-carousel-item>
        <v-img src="~@/assets/custom/images/backgrounds/welcome.jpg"
               :position="customWelcomeImageZoomPosition + ' center'"
               width="100%" height="100%">
          <div class="background-gradient">
            <v-btn outlined rounded icon
                   color="white"
                   class="close-button"
                   @click="close">
              <v-icon>close</v-icon>
            </v-btn>
            <div class="px-10 pt-16">
              <div class="white--text font-size-08 line-height-11 font-weight-bold"
              v-html="$t('onboarding-dialog.start.title', {firstname: data?.firstName ? ' ' + data.firstName : ''})"/>
              <hr class="separator my-5"/>
              <div class="white--text font-size-03"
                   v-html="$t('onboarding-dialog.start.text')"/>
            </div>

            <v-btn depressed large
                   color="primary"
                   class="onboarding-button mt-10 float-end"
                   @click="carouselIndex++">
              {{ $t('onboarding-dialog.start.button') }}
              <v-icon class="px-2">arrow_forward</v-icon>
            </v-btn>
          </div>
        </v-img>
      </v-carousel-item>

      <v-carousel-item v-if="hasDiscoverPage">
        <onboarding-card :title="$t('onboarding-dialog.discover.title').toString()"
                         :icon="$t('onboarding-dialog.discover.icon').toString()"
                         secondary-color>
          <span v-html="$t('onboarding-dialog.discover.text')"/>
        </onboarding-card>
      </v-carousel-item>

      <v-carousel-item>
        <onboarding-card :title="$t('onboarding-dialog.home.title').toString()"
                         :icon="$t('onboarding-dialog.home.icon').toString()">
          <span v-html="$t('onboarding-dialog.home.text')"/>
        </onboarding-card>
      </v-carousel-item>
      <v-carousel-item>
        <onboarding-card :title="$t('onboarding-dialog.settings.title').toString()"
                         :icon="$t('onboarding-dialog.settings.icon').toString()">
          <span v-html="$t('onboarding-dialog.settings.text', {
                           icon: '<i class=\'v-icon notranslate material-icons theme--light\'>' + $t('onboarding-dialog.settings.icon') + '</i>'
                         })"/>
        </onboarding-card>
        <v-btn depressed large
               v-if="!hasFaq"
               color="primary"
               class="onboarding-button done-button float-end"
               @click="close"
               :to="targetAfterTour">
          {{ $t('app.done') }}
          <v-icon class="px-2" dense small>arrow_forward</v-icon>
        </v-btn>
      </v-carousel-item>
      <v-carousel-item v-if="hasFaq">
        <onboarding-card :title="$t('onboarding-dialog.help.title').toString()"
                         :icon="$t('onboarding-dialog.help.icon').toString()">
          <span v-html="$t('onboarding-dialog.help.text', {faqLinkLabel: $t('profile-page.faq')})"/>
        </onboarding-card>
        <v-btn depressed large
               v-if="hasFaq"
               color="primary"
               class="onboarding-button done-button float-end"
               @click="close"
               :to="targetAfterTour">
          {{ $t('app.done') }}
          <v-icon class="px-2" dense small>arrow_forward</v-icon>
        </v-btn>
      </v-carousel-item>

      <template v-slot:prev="{ on, attrs }">
        <v-btn icon rounded outlined large
               class="mx-4"
               color="primary"
               v-bind="attrs"
               v-on="on">
          <v-icon small>arrow_back</v-icon>
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn icon rounded depressed large
               class="primary mx-4"
               color="white"
               v-bind="attrs"
               v-on="on">
          <v-icon small>arrow_forward</v-icon>
        </v-btn>
        <div/>
      </template>
    </v-carousel>

  </v-card>
</template>

<script>

import OnboardingCard from "@/templates/components/OnboardingCard";
import config from "@/config/config.app.json"

export default {
  name: 'OnboardingDialog',
  components: { OnboardingCard },
  props: ['data'],

  data: () => ({
    carouselIndex: 0
  }),

  computed: {
    hasDiscoverPage () {
      return config.hasDiscoverPage === 'true'
    },
    hasFaq () {
      return config.hasFaq === 'true'
    },
    targetAfterTour () {
      return config.targetAfterTour
    },
    customWelcomeImageZoomPosition () {
      return config.customWelcomeImageZoomPosition ? config.customWelcomeImageZoomPosition : 'center'
    }
  },

  methods: {
    close () {
      localStorage.setItem('firstLaunchDialogSeen', 'true')
      this.$root.bisadialog.toggle('onboarding')
    }
  },

}
</script>

<style lang="scss">
@import '~@/styles/dialogs/onboarding.scss';
</style>