<template>
  <!-- No dense view: Nuki devices cannot be added to favorites. This is covered by AccessesCard.vue -->
  <device-card flat
               :device="device"
               :title="$t('device-card-mec-meter.title').toString()"
               icon="tune">
    <sub-devices>
      <mec-meter :device="device"></mec-meter>
    </sub-devices>
  </device-card>
</template>

<script>
import DeviceCard from "@/templates/components/devices/DeviceCard";
import SubDevices from "@/templates/components/devices/SubDevices";
import MecMeter from "@/templates/components/devices/meter/MecMeter.vue";

export default {
  name: 'DeviceCardMecMeter',

  components: {MecMeter, SubDevices, DeviceCard},

  props: {
    device: Object,
    flat: Boolean
  },
}

</script>
