<template>
  <v-skeleton-loader v-if="loading"
                     type="article"
                     height="300"/>
  <content-card v-else-if="Object.values(schedules)?.length > 0"
                :title="$t('schedules-card.title').toString()"
                icon="update">
    <template v-slot:content>
      <v-list subheader class="py-0">
        <v-list-item v-for="schedule in schedules"
                     :key="schedule.id"
                     class="list-item straight px-5">
          <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                             v-text="schedule.name"/>
          <v-list-item-action>
            <v-btn small icon
                   @click="deleteSchedule(schedule)">
              <v-icon>delete</v-icon>
            </v-btn>
            <v-switch v-model="schedule.enabled"
                      inset hide-details
                      :loading="loadingSwitch[schedule.id]"
                      @change="switchEnabled(schedule)"/>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard"
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: "SchedulesCard",

  components: {ContentCard},

  data() {
    return {
      timer: null,
      refreshRate: config.dataRefreshRate,
      loading: false,
      loadingSwitch: {},
      schedules: []
    }
  },

  methods: {
    /**
     * gets schedules from the API
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'schedule'
      }, (response) => {
        this.schedules = response?.data?.data
        if(!Object.values(this.schedules)?.length > 0) {
          this.$emit('hide-schedules-card', true)
        } else {
          this.$emit('hide-schedules-card', false)
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.$emit('hide-schedules-card', true)
        this.loading = false
      })
    },

    /**
     * en-/disables a schedule
     * @param schedule
     */
    switchEnabled(schedule) {
      this.$set(this.loadingSwitch, schedule.id, true)
      this.$rhRequest.sendPost({
        endpoint: 'schedule/' + schedule.id + "/update",
        data: schedule
      }, (response) => {
        this.$set(this.loadingSwitch, schedule.id, false)
        if (response.data.code === -1) {
          this.$root.bisatoast.error({
            message: this.$t('app.generic-error'),
            showCloseBtn: true
          })
        }
      }, (error) => {
        console.error(error)
        this.$root.bisatoast.error({
          message: this.$t("app.generic-error"),
          showCloseBtn: true
        })
        this.$set(this.loadingSwitch, schedule.id, false)
      })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },

    /**
     * Deletes a time controller.
     * @param schedule
     */
    deleteSchedule(schedule){
      if(!this.loading){
        this.$root.bisadialog.toggle('confirmation', true, {
          maxWidth: 400,
          title: this.$t('confirmation-dialog.title'),
          text: this.$t('rules-card.delete-rule.confirm', {name: schedule.name}),
          confirmLabel: this.$t('rules-card.delete-rule.button')
        })
        this.$root.$on('dialogConfirmed', () => {
          this.$rhRequest.sendDelete({
            endpoint: 'schedule/delete/scheduleOperation/scheduleDelete/scheduleId/' + schedule.id
          }, () => {
            this.$root.bisatoast.success({
              message: this.$t('rules-card.delete-rule.success', {name: schedule.name}),
              showCloseBtn: true
            })
            this.getData(false)
          }, (error) => {
            console.error(error)
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          })
          this.$root.$off('dialogConfirmed')
        })
      }
    }
  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
        () => this.getData(true),
        () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>
