<template>
  <div class="page-content">

    <v-skeleton-loader v-if="loading"
                       type="article"
                       height="300"/>

    <content-card v-else-if="!loading && (items && items.length > 0)"
                  :title="$t('documents-page.title').toString()"
                  icon="folder_open"
                  color="primary">

      <template v-slot:content>

        <v-list subheader class="py-0">
          <v-list-item-group>
            <v-list-item v-for="item in items"
                         v-bind:key="item.id"
                         class="px-5 list-item"
                         @click="download(item.id, item.originalFileName, item.contentType)">
              <v-list-item-icon>
                <v-icon color="primary"
                        :class="isNonFlipIcon(getIcon(item.contentType)) ? 'non-flip material-icons-outlined' : 'material-icons-outlined'">
                  {{ getIcon(item.contentType) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                                 v-html="item.name"/>
              <v-list-item-action class="font-size-02 primary--text text-no-wrap">
                {{ getFileSizeString(item.fileSize) }}
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </content-card>

    <!-- Empty State -->
    <div v-else>
      <empty-state
        :empty-state-title="$t('documents-page.emptyState.title').toString()"
        :empty-state-text="$t('documents-page.emptyState.text').toString()">
        <template v-slot:illustration>
          <documents-empty-state-illustration
            :fill-primary="'var(--v-primary-base)'"
            :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>

    </div>
  </div>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import DocumentsEmptyStateIllustration from "@/templates/components/emptyStates/svg/DocumentsEmptyStateIllustration";
import config from '@/config/config.app.json'
import requestHelper from "@/scripts/requestHelper";
import {isNonFlipIcon} from "@/i18n";

export default {
  name: "DocumentsPage",

  components: {ContentCard, EmptyState, DocumentsEmptyStateIllustration},

  data() {
    return {
      refreshRate: config.dataRefreshRate,
      loading: false,
      items: []
    }
  },

  methods: {
    isNonFlipIcon,
    /**
     * get all documents for this site from the Content Service
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }
      let self = this
      this.$rhRequest.sendGet({
        endpoint: 'content-service/get-documents'
      }, function (resp) {
        self.items = resp?.data?.data?.data?.data
        self.loading = false
      }, function (error) {
        console.error(error)
        self.loading = false
      })
    },

    /**
     * continuously refresh data
     */
    updateInterval() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },

    /**
     * This function returns a matching icon for the passed content type
     * @param contentType Content type
     * @returns {string} Icon string
     */
    getIcon(contentType) {
      let icon = null

      // remove the charset string to determine the content type
      let delimiterIndex = contentType.indexOf(';')
      let type = delimiterIndex !== -1 ? contentType.substr(0, delimiterIndex).trim() : contentType.trim()

      switch (type) {
        case 'image/jpeg':
        case 'image/pjpeg':
        case 'image/png':
          icon = 'photo_library'
          break
        case 'application/pdf':
          icon = 'picture_as_pdf'
          break
        default:
          icon = 'filter_none'
      }
      return icon
    },

    /**
     * returns a file size string including the file size and a unit
     *
     * @param size
     * @returns {string}
     */
    getFileSizeString: function (size) {
      if (size > 1000000) {
        return Math.round(size / 1000000) + " MB"
      } else {
        return Math.round(size / 1000) + " KB"
      }
    },

    /**
     * This function downloads a file using the Content Service
     *
     * @param documentId
     * @param originalFileName
     * @param contentType
     */
    download(documentId, originalFileName, contentType) {
      let self = this
      this.$rhRequest.sendGet({
        endpoint: 'content-service/download-document?documentId=' + documentId,
        responseType: 'arraybuffer'
      }, function (resp) {
        if (window?.cordova?.platformId.toLowerCase() === 'ios') {
          let folder = window.cordova.file.tempDirectory
          self.downloadFileMobile(originalFileName, new Blob([resp.data]), contentType, folder)
        } else if (window?.cordova?.platformId.toLowerCase() === 'android') {
          let folder = window.cordova.file.externalDataDirectory
          self.downloadFileMobile(originalFileName, resp.data, contentType, folder)
        } else {
          self.downloadFileWeb(originalFileName, resp.data)
        }
      }, function (err) {
        console.log(err)
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
      })
    },

    /**
     * This function trigger a download on web browsers.
     * @param originalFileName
     * @param blob
     */
    downloadFileWeb: function (originalFileName, blob) {
      let fileURL = URL.createObjectURL(new Blob([blob]))
      let fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', originalFileName)
      document.body.appendChild(fileLink)
      fileLink.click();
      URL.revokeObjectURL(fileLink.href);
    },

    /**
     * This function uses arraybuffer response to create a file on mobile devices local file system.
     * @param fileName
     * @param blob
     * @param contentType
     * @param folder
     */
    downloadFileMobile: function (fileName, blob, contentType, folder) {
      let self = this

      window.resolveLocalFileSystemURL(folder, function (dirEntry) {
        dirEntry.getFile(fileName, {create: true, exclusive: false}, function (fileEntry) {
          if (window?.cordova?.platformId.toLowerCase() === 'ios') {
            self.writeFileIos(fileEntry, blob, contentType)
          } else {
            self.writeFileAndroid(fileEntry, blob)
          }
        }, function (err) {
          console.warn(err)
        })
      }, function (err) {
        console.warn(err)
        self.$root.bisatoast.error({message: self.$t('app.generic-error')})
      })
    },

    arrayBufferToBase64: function (buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },

    /**
     * This function opens a new created file.
     * @param fileEntry
     * @param dataObj
     * @param contentType
     */
    writeFileIos: function (fileEntry, dataObj, contentType) {
      let delimiterIndex = contentType.indexOf(';')
      let type = delimiterIndex !== -1 ? contentType.substr(0, delimiterIndex).trim() : contentType.trim()

      fileEntry.createWriter(function (fileWriter) {
        fileWriter.onwriteend = function () {
          window?.cordova?.plugins.fileOpener2.open(fileEntry.nativeURL, type,
            {
              error: function (e) {
                console.error('Error status: ' + e.status + ' - Error message: ' + e.message)
              },
              success: function () {
                console.log('file opened successfully')
              }
            })
        }
        fileWriter.onerror = function (error) {
          console.log('Failed file write: ' + error)
        }
        fileWriter.write(dataObj)
      })

    },

    /**
     * This function creates a file and opens it on Android devices.
     * It uses the cordova-plugin-saf-mediastore plugin, which can be used to read and save files via the Storage Access Framework and Mediastore.
     * In addition, it sets the correct permissions for different Android versions.
     * @param fileEntry
     * @param dataObj
     */
    writeFileAndroid: function (fileEntry, dataObj) {
      window.cordova.plugins.safMediastore.writeFile({
        "data": this.arrayBufferToBase64(dataObj),
        "filename": fileEntry.name
      }).then((contentUri) => {
        window.cordova.plugins.safMediastore.openFile(contentUri).then(function () {
          console.log('file opened successfully')
        }).catch(function (e) {
          console.error('Error status: ' + e.status + ' - Error message: ' + e.message)
        })
      })
    }

  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
      () => this.getData(true),
      () => this.updateInterval()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/documents.scss';
</style>
