<template>
  <dialog-frame :title="$t('change-mail-dialog.title').toString()"
                icon="pin"
                :color="dialogColor"
                closable
                @close="abort">
    <template v-slot:content>
      <div class="mb-4" v-text="dialogText"/>

      <v-form v-model="valid" ref="form">
        <v-otp-input class="code-input mx-auto font-size-06"
                     length="6"
                     dense outlined
                     :rules="reqRules"
                     v-model="token"
                     :label="$t('change-mail-dialog.token.label').toString()"
                     single-line/>
        <v-btn plain small
               @click="pasteToken">
          <v-icon small left>content_paste</v-icon>
          {{ $t('app.paste') }}
        </v-btn>
      </v-form>

    </template>

    <template v-slot:actions>
      <v-btn plain block
             :disabled="!valid"
             :color="dialogColor"
             class="font-weight-bold d-block"
             @click="changeMail"
             :loading="loading">
        {{ $t('app.send') }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>

import DialogFrame from "@/templates/dialogs/DialogFrame";

export default {
  name: 'ChangeMailDialog',

  components: {
    DialogFrame
  },

  data: () => ({
    valid: false,
    token: null,
    loading: false,
    dialogColor: 'primary',
    dialogText: null
  }),

  methods: {
    abort() {
      this.$root.bisadialog.toggle('changeMail')
      this.$refs.form.reset()
      this.dialogColor = 'primary'
      this.dialogText = this.$t('change-mail-dialog.text')
    },

    pasteToken() {
      navigator.clipboard.readText().then(text => {
        this.token = text
      })
    },

    changeMail() {
      this.loading = true
      this.$rhRequest.sendPost({
        endpoint: "user/change-email",
        data: {token: this.token}
      }, () => {
        this.$root.bisatoast.success({
          message: this.$t("change-mail-dialog.change-mail.success")
        })
        this.$root.bisadialog.toggle('changeMail')
        this.$refs.form.reset()
        this.loading = false
        this.$router.push({name: 'logout'})
      }, (error) => {
        // return code 403: invalid token
        if (error?.response?.status === 403) {
          this.loading = false
          this.dialogColor = 'error'
          this.dialogText = this.$t('change-mail-dialog.text.invalid-token')
        } else {
          this.$root.bisatoast.error({
            message: this.$t("change-mail-dialog.change-mail.error")
          })
          this.$root.bisadialog.toggle('changeMail')
          this.$refs.form.reset()
        }
        this.loading = false
      })
    }
  },

  mounted() {
    this.dialogColor = 'primary'
    this.dialogText = this.$t('change-mail-dialog.text')
  },

  computed: {
    reqRules() {
      return [
        v => !!v || this.$t('app.rules.required')
      ]
    }
  }
};
</script>

