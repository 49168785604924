<template>
  <v-list class="pa-0">
      <v-list-item inactive
                   :ripple="false">
        <v-list-item-icon>
          <v-icon class="material-icons-outlined">password</v-icon>
        </v-list-item-icon>
        <v-list-item-content v-if="edit.password">
          <v-text-field outlined dense
                        hide-details
                        ref="device-password-input"
                        v-model="password"/>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title class="font-size-03">
            {{ device.isAuthorized ? '******' : $t('device-card-mec-meter.password.unauthorized') }}
          </v-list-item-title>
          <v-list-item-subtitle v-text="$t('device-card-mec-meter.password.label')"/>
        </v-list-item-content>
        <v-list-item-action v-if="!edit.password">
          <v-btn icon
                 @click="changePassword">
            <v-icon>edit</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action v-else>
          <v-btn icon
                 :loading="loading.changePassword"
                 @click="updatePassword">
            <v-icon>check</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item inactive
                   :ripple="false">
        <v-list-item-icon>
          <v-icon class="material-icons-outlined">electric_meter</v-icon>
        </v-list-item-icon>
        <v-list-item-content v-if="!edit.energyDataType">
          <v-list-item-title class="font-size-03"
                             v-text="meterModes.find(x => x.value === this.energyDataType)?.label ?? null"/>
          <v-list-item-subtitle v-text="$t('device-card-mec-meter.mode.label')"/>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-select outlined dense
                    v-model="energyDataType"
                    item-text="label"
                    item-value="value"
                    :label="$t('device-card-mec-meter.mode.label')"
                    ref="device-energyDataType-input"
                    :items="meterModes"
                    hide-details />
        </v-list-item-content>
        <v-list-item-action v-if="!edit.energyDataType">
          <v-btn icon @click="changeEnergyDataType">
            <v-icon>edit</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action v-else>
          <v-btn icon
                 :loading="loading.changeEnergyDataType"
                 @click="updateEnergyDataType">
            <v-icon>check</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
</template>

<script>
import config from '@/config/config.app.json';
export default {
  name: "MecMeter",

  props: {
    device: Object
  },

  data() {
    return {
      mode: null,
      password: null,
      energyDataType: null,
      edit: {
        password: false,
        energyDataType: false
      },
      loading: {
        changePassword: false,
        changeEnergyDataType: false
      }
    }
  },

  computed: {
    isConfigured() {
      return this.device.mecMeterStatus !== 'not-configured'
    },
    meterModes() {

      let modes = [
        {label: this.$t('device-card-mec-meter.mode.photovoltaic-inverter.label'), value: "photovoltaic_inverter"},
        {label: this.$t('device-card-mec-meter.mode.meter.label'), value: "meter"},
        {label: this.$t('device-card-mec-meter.mode.monitoring.label'), value: "monitoring"},
      ];
      if ('superset' in config && JSON.parse(config.superset) ) {
        modes.push({label: this.$t('device-card-mec-meter.mode.superset.label'), value: "superset"})
      }

      return modes;
    },
  },

  methods: {
    changePassword() {
      this.edit.password = true
      this.$nextTick(() => { // wait until refs are defined
        this.$refs['device-password-input'].focus()
      })
    },
    changeEnergyDataType() {
      this.edit.energyDataType = true
      this.$nextTick(() => { // wait until refs are defined
        this.$refs['device-energyDataType-input'].focus()
      })
    },
    updatePassword () {
      this.loading.changePassword = true

      this.$rhRequest.sendPost({
        endpoint: 'device/' + this.device['encryptedId'] + '/update',
        data: {
          mecPassword: this.password
        }
      }, (resp) => {
        let device = resp?.data?.data
        if(device?.mecPassword) {
          this.$root.bisatoast.success({message: this.$t('device-card-mec-meter.set-password.success')})
        } else {
          this.$root.bisatoast.error({message: this.$t('device-card-mec-meter.set-password.error')})
        }
        this.loading.changePassword = false
        this.edit.password = false
      }, () => {
        this.$root.bisatoast.error({message: this.$t('device-card-mec-meter.set-password.error')})
        this.loading.changePassword = false
      })
    },
    updateEnergyDataType () {
      this.loading.changeEnergyDataType = true

      this.$rhRequest.sendPost({
        endpoint: 'device/' + this.device['encryptedId'] + '/update',
        data: {
          energyDataType: this.energyDataType
        }
      }, (resp) => {
        let device = resp?.data?.data
        if(device?.energyDataType) {
          this.$root.bisatoast.success({message: this.$t('device-card-mec-meter.set-mode.success')})
          this.edit.energyDataType = false
        } else {
          this.$root.bisatoast.error({message: this.$t('device-card-mec-meter.set-mode.error')})
        }
        this.loading.changeEnergyDataType = false
      }, () => {
        this.$root.bisatoast.error({message: this.$t('device-card-mec-meter.set-mode.error')})
        this.loading.changeEnergyDataType = false
      })
    }
  },

  mounted() {
    this.energyDataType = this.device.energyDataType
  }
}
</script>
