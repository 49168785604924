<template>
  <v-card v-if="loading" height="185">
    <v-skeleton-loader type="article"/>
  </v-card>
  <v-card v-else>
    <v-card-title class="pa-0">
      <v-list-item class="px-5 py-3">
        <v-list-item-icon class="mr-3">
          <v-icon color="primary">person</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="pending"
                             v-html="$t('invited-user-card.invitation-pending')"/>
          <v-list-item-title v-else
                             class="font-weight-bold primary--text">
            {{ user.firstname }} {{ user.lastname }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="user.role === 'ADMIN'"
                                class="font-size-03"
                                v-html="$t('invited-user-card.role.admin')"/>
          <v-list-item-subtitle v-else-if="user.role === 'USER'"
                                class="font-size-03"
                                v-html="$t('invited-user-card.role.user')"/>
          <v-list-item-subtitle v-else
                                class="font-size-03"
                                v-html="$t('invited-user-card.role.user')"/>
        </v-list-item-content>
        <v-list-item-action v-if="user.role !== 'ADMIN'">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon
                     v-bind="attrs"
                     v-on="on">
                <v-icon>more_horiz</v-icon>
              </v-btn>
            </template>
            <v-list v-if="pending">
              <v-list-item link
                           @click="withdrawInvitation">
                <v-list-item-title v-html="$t('invited-user-card.withdraw-invitation')"/>
              </v-list-item>
            </v-list>
            <v-list v-else>
              <v-list-item link
                           @click="editDevicePermissions">
                <v-list-item-title v-html="$t('invited-user-card.edit-permissions')"/>
              </v-list-item>
              <v-list-item link
                           @click="editReleasePeriod">
                <v-list-item-title v-html="$t('invited-user-card.edit-release-period')"/>
              </v-list-item>
              <v-list-item link
                           @click="removeUser">
                <v-list-item-title v-html="$t('invited-user-card.remove-user')"/>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-card-title>

    <v-card-text class="px-5 pb-5">
      <v-list-item dense class="pa-0">
        <v-list-item-icon class="mr-3">
          <v-icon>mail_outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="font-size-03">
          <a :href="'mailto:' + user.email"
             class="text-decoration-none"
             v-text="user.email"/>
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense class="pa-0">
        <v-list-item-icon class="mr-3">
          <v-icon>pending_actions</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <div v-if="user.valid_from && user.valid_to"
               class="font-size-03">
            {{ format(user.valid_from) }} - {{ format(user.valid_to) }}
          </div>
          <div v-else-if="user.valid_from"
               v-html="$t('invited-user-card.from') + ' ' + format(user.valid_from)"/>
          <div v-else-if="user.valid_to"
               v-html="$t('invited-user-card.to') + ' ' + format(user.valid_to)"/>
          <div v-else
               class="font-size-03"
               v-html="$t('invited-user-card.no-time-restriction')"/>
        </v-list-item-title>
      </v-list-item>

    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'InvitedUserCard',

  props: {
    user: Object,
    pending: Boolean,
    timezone: String
  },

  data: () => ({
    loading: false,
    userInfo: {
      userId: null,
      userValidFrom: null,
      userValidTo: null,
      userFirstname: null,
      userLastname: null,
      userEmail: null
    }
  }),

  methods: {
    /**
     * withdraws a pending invitation
     */
    withdrawInvitation () {
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 350,
        title: this.$t('confirmation-dialog.title'),
        text: this.$t('invited-user-card.withdraw-invitation.confirm'),
        confirmLabel: this.$t('invited-user-card.withdraw-invitation.button')
      })
      this.$root.$on('dialogConfirmed', () => {
        this.loading = true
        this.$rhRequest.sendGet({
          endpoint: 'settings/remove-invitation-token',
          params: {
            email: this.user.email
          }
        }, (resp) => {
          if (resp?.data?.data?.success) {
            this.$emit('update-data')
            this.$root.bisatoast.success({
              message: this.$t('invited-user-card.withdraw-invitation.success'),
              showCloseBtn: true
            })
          } else {
            this.$root.bisatoast.error({
              message: this.$t('invited-user-card.withdraw-invitation.error'),
              showCloseBtn: true
            })
          }
          this.loading = false
        }, (error) => {
          console.error(error)
          this.$root.bisatoast.error({ message: this.$t('app.generic-error') })
          this.loading = false
        })
        this.$root.$off('dialogConfirmed')
      })
    },

    /**
     * opens the editDevicePermissions dialog for an invited user
     */
    editDevicePermissions () {
      this.$root.bisadialog.toggle('editDevicePermissions', true, {
        userId: this.user.id,
        userName: this.user.firstname + ' ' + this.user.lastname
      })
      this.$root.bisadialog.callDialogInit('editDevicePermissions')
    },

    /**
     * sets the period restriction end date for an invited user to 'now'
     */
    revokeAccess () {
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 400,
        title: this.$t('confirmation-dialog.title'),
        text: this.$t('invited-user-card.revoke-access.confirm', { name: this.user.firstname + ' ' + this.user.lastname }),
        confirmLabel: this.$t('invited-user-card.revoke-access.button')
      })
      this.$root.$on('dialogConfirmed', () => {
        this.loading = true
        this.$rhRequest.sendGet({
          endpoint: 'settings/remove-invited-user-permissions',
          params: {
            uid: this.user.id,
            email: this.user.email
          }
        }, () => {
          this.$emit('update-data')
          this.loading = false
        }, (error) => {
          console.error(error)
          this.$root.bisatoast.error({ message: this.$t('app.generic-error') })
          this.loading = false
        })
        this.$root.$off('dialogConfirmed')
      })
    },
    /**
     * function loads UserPermissionDialog
     */
    editReleasePeriod () {
      this.$root.bisadialog.toggle('userPermission', true, {
        maxWidth: 350,
        userId: this.user.id,
        userValidFrom: this.format(this.user.valid_from),
        userValidTo: this.format(this.user.valid_to),
        userFirstname: this.user.firstname,
        userLastname: this.user.lastname,
        userEmail: this.user.email
      })
    },

    /**
     * removes an invited user from the share list
     */
    removeUser () {
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 400,
        title: this.$t('confirmation-dialog.title'),
        text: this.$t('invited-user-card.remove-user.confirm', { name: this.user.firstname + ' ' + this.user.lastname }),
        confirmLabel: this.$t('invited-user-card.remove-user.button')
      })
      this.$root.$on('dialogConfirmed', () => {
        this.loading = true
        this.$rhRequest.sendGet({
          endpoint: 'settings/remove-invited-user',
          params: {
            uid: this.user.id,
            email: this.user.email
          }
        }, () => {
          this.$emit('update-data')
          this.loading = false
        }, (error) => {
          console.error(error)
          this.$root.bisatoast.error({ message: this.$t('app.generic-error') })
          this.loading = false
        })
        this.$root.$off('dialogConfirmed')
      })
    },

    /**
     * parses a date string
     *
     * @param dateString
     * @returns {*}
     */
    format (dateString) {
      if (dateString) {
        return moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').tz(this.timezone).format(this.$t('app.date-time-format.long'))
      }
      return null
    }
  }
}
</script>
