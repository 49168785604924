<template>
  <div>
    <div v-html="$t('app-gateway-already-in-use.description', {gwId: '<code>' + gwId + '</code>'})"/>

    <v-list color="transparent" class="mt-8">
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3">
          <v-icon color="red"
                  class="material-icons-outlined">
            check_circle
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap"
                             v-html="$t('app-gateway-already-in-use.tips.0')"/>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3">
          <v-icon color="red"
                  class="material-icons-outlined">
            check_circle
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap"
                             v-html="$t('app-gateway-already-in-use.tips.1')"/>
          <!-- TODO add link or mailto for support? -->
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

export default {
  name: "AddGatewayAlreadyInUse",

  props: ['gwId']
}
</script>
