<template>
  <content-card class="mb-4"
                :title="$t('no-gateway-card.title')"
                icon="energy_savings_leaf"
                color="white"
                header-img-src="empty-state-smart-home.png">
    <template v-slot:content>
      <div class="pa-5 font-size-03" v-html="$t('no-gateway-card.text')"/>
      <v-list>
        <v-list-item class="px-5">
          <v-list-item-icon class="mr-3">
            <v-icon color="primary"
                    class="material-icons-outlined">
              verified
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-size-03 text-wrap" v-html="$t('no-gateway-card.bulletpoint-1')"/>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-5">
          <v-list-item-icon class="mr-3">
            <v-icon color="primary"
                    class="material-icons-outlined">
              verified
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-size-03 text-wrap" v-html="$t('no-gateway-card.bulletpoint-2')"/>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="pa-5 ma-auto">
        <v-btn large depressed
               class="font-weight-bold block-sm mx-auto d-block"
               color="primary"
               @click="buyGateway">
          {{ $t('no-gateway-card.buy-gateway') }}
        </v-btn>
        <v-btn large plain
               class="font-weight-bold mt-4 block-sm mx-auto d-block"
               color="primary"
               @click="addGateway">
          {{ $t('no-gateway-card.add-gateway') }}
        </v-btn>
      </div>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard"
import config from '@/config/config.app.json'

export default {
  name: "NoGatewayCard",

  components: {ContentCard},

  methods: {
    buyGateway() {
      if (config.clientKey === 'habilio') {
        this.$router.push({name: "energySaving"})
      } else {
        // TODO add route/action for clients without discover
        window.open('https://rockethome-smarthome.de', '_system');
      }
    },

    addGateway() {
      this.$root.bisadialog.toggle('addGateway')
      this.$root.bisadialog.callDialogInit('addGateway')
    }
  }
}
</script>
