<template>
  <div>
    <v-skeleton-loader v-if="loading"
                       type="card"/>
    <content-card :title="$t('timelines-card.title').toString()"
                  :color="theme.props?.timelineHeaderImage ? 'white' : 'primary'"
                  :header-img-src="theme.props?.timelineHeaderImage"
                  icon="view_timeline"
                  v-else-if="!loading && timelineElements?.length > 0"
                  v-for="timelineElement in timelineElements" v-bind:key="timelineElement.name">
      <template v-slot:content>
        <div class="pa-5">
          <div class="font-size-03" v-html="timelineElement.description"/>
          <div class="mt-3 mb-3 font-weight-bold" v-if="timelineElement.linkAddress && timelineElement.linkLabel">
            <a :href="timelineElement.linkAddress" target="_blank">{{ timelineElement.linkLabel }}</a>
          </div>
        </div>
        <v-divider/>
        <div>
          <v-timeline dense>
            <v-timeline-item
                v-for="(section, index)  in timelineElement.sections"
                :key="index"
                class="mb-3"
                :color="getDotColor(section)"
                small>
              <v-row :class="'pt-1 font-weight-bold ' + getActiveStepClass(section)">
                <v-col cols="6">{{ formatDate(section.data) }}</v-col>
                <v-col>{{ section.name }}</v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </div>
      </template>
    </content-card>
  </div>
</template>

<style scoped>
/* Change the dots outer ring color */
.v-timeline-item__dot {
    background: #E2E2E2 !important;
}

/* Change the vertical spacing between timeline steps */
.v-timeline::before {
    height: calc(100% - 50px);
    top: 25px;
}
</style>

<script>
import ContentCard from "@/templates/components/ContentCard";
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";
import moment from "moment";
import theme from "@/config/theme";
import formats from "@/scripts/formats";

export default {
  name: 'TimelinesCard',
  computed: {
    theme() {
      return theme
    }
  },

  components: {
    ContentCard
  },

  data() {
    return {
      timer: null,
      refreshRate: config.dataRefreshRate,
      loading: false,
      timelineElements: []
    }
  },

  methods: {
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'content-service/get-timeline-elements'
      }, (response) => {
        this.timelineElements = response?.data?.data

        if (this.timelineElements) {
          this.timelineElements.forEach((timeline) => {
            // sections will be sorted in-place, so no need to save in a variable
            timeline.sections.sort(this.customSortForSections())
          })
          this.$emit('hide-timelines', false)
        } else {
          this.$emit('hide-timelines', true)
        }

        this.loading = false
      }, (error) => {
        console.error(error)
        this.$emit('hide-timelines', true)
        this.loading = false
      })
    },
    /**
     * This is a sorting function that also takes null and empty values into consideration.
     * The dates of the objects are also checked,
     * to see if they are in the past and to determine the most recent step on the timeline.
     *
     * @returns {(function(*, *): (number|*))|*}
     */
    customSortForSections() {
      return function (section1, section2) {
        // equal items sort to the same position
        if (section1.data === section2.data) {
          return 0
        }

        // empty or null values sort after anything else
        if (!section1.data) {
          return 1
        }
        if (!section2.data) {
          return -1
        }

        // sort ascending by date
        let dateSort = moment(section1.data, "YYYY-MM-DD").diff(moment(section2.data, "YYYY-MM-DD"))

        // Flag both dates if they are in the past with 'isPast=true'.
        // This flag is used for the dots color.
        let date1IsPast = moment(section1.data, "YYYY-MM-DD").isBefore(moment())
        let date2IsPast = moment(section2.data, "YYYY-MM-DD").isBefore(moment())
        section1.isPast = date1IsPast
        section2.isPast = date2IsPast

        // Flag the last step that lies in the past and is closer to today's date.
        // This flag used to highlight the current step text.
        if (date1IsPast && Math.abs(moment(section1.data, "YYYY-MM-DD").diff(moment())) < Math.abs(moment(section2.data, "YYYY-MM-DD").diff(moment()))) {
          section1.isActiveStep = true
          section2.isActiveStep = false
        }

        return dateSort
      }
    },
    getDotColor(section) {
      return section?.isPast ? 'primary' : '#C1C1C1'
    },
    getActiveStepClass(section) {
      if (section?.isActiveStep) {
        return "primary--text"
      } else {
        if (!section?.isPast) {
          return "grey--text text-lighten-1"
        }
      }
    },
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },
    formatDate(date) {
      return formats.formatDateString(date, this.$t('app.date-time-format.date-only'))
    },
  },
  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
      () => this.getData(true),
      () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>
