<template>
  <div>
    <v-btn text small
           class="ma-2"
           @click="showExtendedSettings = !showExtendedSettings">
      {{ $t('extended-timer-definitions.button') }}
      <v-icon v-if="showExtendedSettings">expand_less</v-icon>
      <v-icon v-else>expand_more</v-icon>
    </v-btn>
    <v-expand-transition>
      <div v-show="showExtendedSettings">
        <v-divider class="mb-3"/>

        <v-row class="px-5">
          <v-col>
            <v-menu v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="formattedEndTime"
                              outlined dense hide-details
                              prepend-icon="event"
                              class="my-5"
                              :placeholder="$t('add-rule-step-timer-definition.end-time.label')"
                              readonly
                              v-bind="attrs"
                              v-on="on"/>
              </template>
              <v-date-picker v-model="endTime"
                             no-title
                             scrollable
                             @input="setEndTime"/>
            </v-menu>
          </v-col>

          <v-col>
            <v-text-field v-model="repeatCount"
                          outlined dense hide-details
                          type="number"
                          prepend-icon="repeat"
                          class="my-5"
                          :placeholder="$t('add-rule-step-timer-definition.repeat-count.label')"
                          @change="updateModel"/>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import formats from "@/scripts/formats";

export default {
  name: 'ExtendedTimerDefinition',

  props: ['value'],

  data: function () {
    return {
      dateMenu: false,
      showExtendedSettings: false,
      endTime: null,
      repeatCount: null
    }
  },

  computed: {
    /**
     * returns localized endTime string
     * @returns {null|string}
     */
    formattedEndTime() {
      if (this.endTime) {
        return formats.formatDateString(this.endTime, this.$t('app.date-time-format.date-only'))
      }
      return null
    }
  },

  methods: {
    setEndTime() {
      this.dateMenu = false
      this.updateModel()
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', {
        endTime: this.endTime,
        repeatCount: this.repeatCount
      })
      this.$emit('change')
    }
  }
}
</script>
