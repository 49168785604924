<template>
  <div>
    <v-icon @click="closeOverlay"
            large color="white"
            class="close-button text-shadow">
      close
    </v-icon>

    <!-- header img -->
    <v-img :aspect-ratio="7/3"
           :lazy-src="getImgSrc()"
           :src="getImgSrc()">
      <div class="image-gradient fill-height"/>
      <div class="content-detail-header white--text">
        <div class="header-icon mr-4" v-if="data.iconKey.length > 0">
          <v-icon color="primary"
                  class="material-icons-outlined">
            {{ data.iconKey }}
          </v-icon>
        </div>
        <div class="header-text d-inline-block" style="width: inherit">
          <div class="font-weight-bold font-size-07 text-shadow">
            {{ data.title }}
          </div>
          <div class="text-shadow">
            {{ data.subtitle }}
          </div>
        </div>
      </div>
    </v-img>

    <!-- secondary color section -->
    <v-card flat tile
            color="secondary">
      <v-card-text class="font-weight-bold white--text font-size-04 py-7 px-10"
                   v-text="data.body"/>
    </v-card>

    <!-- CTA button -->
    <div class="text-center">
      <v-btn v-if="data.linkAddress"
             depressed large
             color="primary"
             class="mt-8 font-weight-bold"
             @click="openInapp(data.linkAddress)">
        {{ data.linkLabel ?? data.linkAddress }}
        <v-icon class="ml-1" right>
          arrow_forward
        </v-icon>
      </v-btn>
    </div>

    <div class="px-10 mt-8" v-html="markedFormattedBody"></div>

  </div>
</template>

<script>
import config from "@/config/config.app.json";
import {marked} from "marked";
import DOMPurify from 'dompurify';
import inAppBrowser from "@/scripts/inAppBrowser";

export default {
  name: 'ContentDetailPageDialog',

  props: ['data'],

  computed: {
    markedFormattedBody() {
      return DOMPurify.sanitize(marked.parse(this.data.formattedBody))
    }
  },

  methods: {
    /**
     * returns the v-img source for an image id
     *
     * @returns {string}
     */
    getImgSrc() {
      return config.portalApi + 'content-service/get-image?id=' + this.data.imageId
    },

    /**
     * opens an url in either InAppBrowser (mobile device) or target="_blank"
     * @param url
     */
    openInapp(url) {
      this.$econda.sendDiscover(this.data.title)
      inAppBrowser.openUrl(url)
    },

    /**
     * on dialog close
     */
    closeOverlay() {
      this.$root.bisadialog.toggle('contentDetailPage')
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/dialogs/content-detail-page';

.close-button {
  position: absolute !important;
  right: 20px;
  top: 25px;
  z-index: 999;
}

.text-shadow {
  text-shadow: 0 0 4px #222;
}
</style>
