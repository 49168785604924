export default class {

  /**
   *
   * @param message
   * @param err
   */
  onError(message, err) {
    console.error(message, err);
  }

  /**
   *
   */
  getToken() {
    if (window.usingCordova) {
      window.FirebasePlugin.getAPNSToken(
        null,
        (error) => {
          this.onError("Failed to get APNS token", error);
        });
    }
  }
}
