import PushNotification from "@/plugins/rhPushNotification/pushNotificationPlugin";

export default {
  install(Vue, options) {
    if(window.usingCordova){
      Vue.prototype.$rhPushNotification = new PushNotification(
        options.clientKey,
        options.environment,
        options.channels
      );
    }
  }
}
