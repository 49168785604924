<template>
  <v-card flat
          color="transparent"
          class="flex-box"
          :class="centered ? 'centered' : ''">

    <v-toolbar flat height="140px"
               class="flex-header"
               color="transparent">
      <v-toolbar-title class="font-weight-bold text-break font-size-05 pt-4"
                       :class="color + '--text'">
        <v-icon v-if="isIcon(icon)"
                large left
                :color="color">
          {{ icon }}
        </v-icon>
        <span v-else class="material-symbols-outlined" style="'color': color">
          {{ icon }}
        </span>
        <span v-html="title"/>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="pt-15 pb-11">
        <v-icon large
                v-if="!fullscreen && maximizable"
                class="d-none d-md-block"
                :color="color"
                @click="maximize">
          fullscreen
        </v-icon>
        <v-icon large
                v-if="fullscreen && maximizable"
                class="d-none d-md-block"
                :color="color"
                @click="minimize">
          fullscreen_exit
        </v-icon>
        <v-icon large
                :color="color"
                @click="$emit('close')">
          close
        </v-icon>
      </v-toolbar-items>
    </v-toolbar>

    <v-card-text class="pt-1 d-block font-weight-medium font-size-04 flex-content"
                 :class="removePadding ? 'px-0' : 'px-4'">
      <slot name="content"/>
    </v-card-text>

    <v-card-actions class="mt-2 px-4 flex-footer d-block"
                    :class="isIos ? 'mb-8' : 'mb-3'"
                    v-if="!hideActionsSlot">
      <slot name="actions"/>
    </v-card-actions>
  </v-card>
</template>

<script>

import deviceTypeIcons from "@/config/deviceTypeIcons.json";

export default {
  name: 'FullscreenOverlayFrame',

  props: {
    color: String,
    title: String,
    icon: String,
    closable: Boolean,
    maximizable: Boolean,
    centered: Boolean,
    hideActionsSlot: Boolean,
    removePadding: Boolean
  },

  data: function () {
    return {
      fullscreen: false
    }
  },

  computed: {
    isIos() {
      return window?.cordova?.platformId.toLowerCase() === 'ios'
    }
  },

  methods: {
    /**
     * returns if it's md-icon or md-symbol for a passed device type
     *
     * @param type
     * @returns {boolean}
     */
    isIcon(type) {
      let result = true
      for (const [key, value] of Object.entries(deviceTypeIcons)) {
        if (type === value.icon) {
          result = deviceTypeIcons[key].type === 'md-icon'
          break
        }
      }
      return result
    },
    maximize() {
      this.fullscreen = true
      this.$emit('maximize')
    },

    minimize() {
      this.fullscreen = false
      this.$emit('minimize')
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/dialogs/fullscreen-overlay-frame.scss';
</style>
