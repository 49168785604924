<template>
  <div class="device-icon">
    <v-icon v-if="iconType === 'md_icon'"
            class="material-icons"
            :color="iconColor"
    >{{ iconName }}</v-icon>
    <span v-else-if="iconType === 'md_symbol'"
          class="material-symbols-outlined  v-icon"
          :class="colorizeSymbol(iconColor)"
    >{{ iconName }}</span>
  </div>
</template>

<script>

export default {
  name: 'IconFrame',
  components: {},
  props: {
    iconType: {
      type: String,
      default: 'md_icon'
    },
    iconName: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: 'theme--light',
      required: false
    },

  },
  methods: {
    colorizeSymbol(color) {
      let colorString
      if (color === 'theme--light') {
        colorString = color
      } else {
        colorString = color + '--text'
      }
      return colorString
    }
  }
};
</script>
