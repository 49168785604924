<!--
  Template for generic emptystates
  Provides a slot "illustration" for central illustration.
-->
<template>
  <div class="empty-state flex-column px-14">
    <div class="empty-state-illustration ma-auto">
      <slot name="illustration" />
    </div>
    <div class="empty-state-textarea mt-10 content">
      <div class="empty-state-title font-weight-bold font-size-05 text-center primary--text" v-text="emptyStateTitle" />
      <div class="empty-state-text text-center mt-5 font-size-02" v-html="emptyStateText" />
    </div>

  </div>
</template>

<script>
export default {
  name: "EmptyState",

  props: {
    emptyStateTitle: String,
    emptyStateText: String
  },

}
</script>

<style lang="scss" scoped>
@import '~@/styles/empty-state.scss';
</style>
