<template>
  <fullscreen-overlay-frame :title="$t('connected-accounts.title').toString()"
                            icon="hub"
                            color="primary"
                            centered
                            closable
                            @close="abort">
    <template v-slot:content>

      <v-row>
        <v-col>
          <v-skeleton-loader v-if="hasNoData && loading.data"
                             type="article"
                             height="300"/>
          <div v-else-if="hasNoData">
            <empty-state
                :empty-state-title="$t('connected-accounts.emptyState.title').toString()"
                :empty-state-text="$t('connected-accounts.emptyState.text').toString()">
              <template v-slot:illustration>
                <emobility-empty-state-illustration
                    :fill-primary="'var(--v-primary-base)'"
                    :fill-secondary="'var(--v-secondary-base)'"
                />
              </template>
            </empty-state>
          </div>

          <content-card v-else
                        :title="$t('connected-accounts.list.title').toString()"
                        icon="hub"
                        color="primary">
            <template v-slot:content>
              <v-list subheader class="py-0">
                <v-list-item v-for="(brand, key) in data"
                             :key="key"
                             class="list-item straight px-5"
                             link
                             @click="deleteConnection(brand.vendor, brand.brand)">
                  <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                                     v-text="brand.brand"/>
                  <v-list-item-action>
                    <v-icon color="primary">delete</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </template>
          </content-card>
        </v-col>

      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn large depressed outlined
             v-if="!hasNoData && !loading.data"
             color="primary"
             class="font-weight-bold action-button"
             @click="unlinkUser">
        {{ $t('connected-accounts.button.delete-all') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame"
import ContentCard from "@/templates/components/ContentCard.vue";
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import EmobilityEmptyStateIllustration from "@/templates/components/emptyStates/svg/EmobilityEmptyStateIllustration.vue";

export default {
  name: "ConnectedAccountsDialog",

  components: {
    ContentCard,
    FullscreenOverlayFrame,
    EmptyState,
    EmobilityEmptyStateIllustration
  },

  data: () => ({
    isSiteAdmin: null,
    loading: {
      data: false,
      save: false
    },
    data: {}
  }),
  methods: {
    init() {
      this.isSiteAdmin = this.$rhAuth.isSiteAdmin()
      this.getData()
    },

    deleteConnection(vendor, brand) {
      this.$root.bisadialog.toggle('deleteEnodeConnection', true, {brand: brand})
      this.$root.$on('connectionDelete', () => {
        this.$rhRequest.sendDelete({
          endpoint: "enode/disconnect-vendor",
          params: {
            "vendor": vendor
          }
        }, () => {
          this.getData()
          this.$root.bisatoast.success({message: this.$t('connected-accounts.delete-connection.success')})
        }, (error) => {
          console.error(error)
          this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        })
        this.$root.$off('connectionDelete')
      })
    },

    unlinkUser() {
      this.$root.bisadialog.toggle('unlinkEnodeUser')
      this.$root.$on('unlinkUser', () => {
        this.$rhRequest.sendDelete({
          endpoint: "enode/unlink-user",
        }, () => {
          this.data = {}
          this.$root.bisatoast.success({message: this.$t('connected-accounts.unlink-user.success')})
          this.loading.data = true
          this.getData()
        }, (error) => {
          this.getData()
          console.error(error)
          this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        })
        this.$root.$off('unlinkUser')
      })
    },

    getData() {
      this.loading.data = true
      this.$rhRequest.sendGet({
        endpoint: "enode/get-linked-vendors",
      }, (response) => {
        this.data = response?.data?.data
        this.loading.data = false
      }, (error) => {
        this.loading.data = false
        this.$root.bisadialog.error({message: this.$t('app.generic-error')})
        console.error(error)
      })
    },

    abort() {
      this.$root.bisadialog.toggle('connectedAccounts')
    }
  },
  computed: {
    hasNoData() {
      return Object.keys(this.data).length === 0
    }
  }
}
</script>
