<!-- Use this dialog for generic confirmation prompts. See method comments for further information on events. -->
<template>
  <dialog-frame :title="data?.title"
                icon="question_mark"
                color="primary"
                closable
                @close="abort">
    <template v-slot:content>
      {{ data?.text }}
    </template>

    <template v-slot:actions>
      <v-btn plain block
             color="primary"
             class="font-weight-bold d-block"
             @click="confirm">
        {{ data?.confirmLabel }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>
import DialogFrame from "@/templates/dialogs/DialogFrame";

export default {
  name: 'ConfirmationDialog',

  components: {
    DialogFrame
  },

  props: ['data'],

  methods: {
    /**
     * Emits a global event 'dialogConfirmed' and closes the dialog.
     * - Start listener with this.$root.$on('dialogConfirmed')
     * - Stop listener with this.$root.$off('dialogConfirmed') after reacting to the event.
     */
    confirm() {
      this.$root.$emit('dialogConfirmed', this.data)
      this.$root.bisadialog.toggle('confirmation')
    },

    /**
     * Emits a global event 'dialogAborted' and closes the dialog.
     * - Start listener with this.$root.$on('dialogAborted')
     * - Stop listener with this.$root.$off('dialogAborted') after reacting to the event.
     */
    abort() {
      this.$root.$emit('dialogAborted')
      this.$root.bisadialog.toggle('confirmation')
    }
  }
};
</script>

