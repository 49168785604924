<template>
  <div id="welcome" :style="customWelcomeImageZoomPosition">
    <div class="bglayer">
      <div class="welcome-logo">
        <logo-no-text width="90" :fill-color="topNavLogoColor" :font-color="topNavLogoFontColor" color="white"></logo-no-text>
        <div class="welcome-title mt-12">{{$t('welcome-page.title')}}</div>
      </div>
      <div class="button-container">
        <div class="button-container-inner px-5">
          <v-btn depressed x-large block
                 class="mt-15"
                 color="primary"
                 v-html="$t('login-page.login')"
                 @click="login"/>
          <v-btn depressed x-large block outlined
                 class="register mt-6"
                 color="white"
                 v-html="$t('welcome-page.registration')"
                 @click="registration"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoNoText from "@/assets/custom/images/logo_no_text";
import config from "@/config/config.app.json";

export default {
  name: "WelcomePage",
  components: {LogoNoText},
  methods: {
    login() {
      this.$router.push({ name: 'login' })
    },
    registration() {
      this.$router.push({ name: 'registration' })
    }
  },
  computed: {
    customWelcomeImageZoomPosition() {
      return {
        '--v-zoom-position': config.customWelcomeImageZoomPosition ? config.customWelcomeImageZoomPosition : 'center'
      }
    },
    topNavLogoFontColor() {
      return this.$vuetify.theme.currentTheme.logoFontColor ?? this.$vuetify.theme.currentTheme.primary
    },
    topNavLogoColor() {
      // set logo color depending on current path

      if (this.$route.meta.topNavLogoColor === 'secondary') {
        return this.$vuetify.theme.currentTheme.secondary
      } else {
        return this.$vuetify.theme.currentTheme.logoColor ?? this.$vuetify.theme.currentTheme.primary
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/entry/welcome.scss';
</style>


