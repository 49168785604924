<template>
  <v-stepper-content step="15"
                     class="pb-2 pa-1">

    <div v-html="$t('add-rule-step-notification-definition.info-text')"/>

    <content-card :title="$t('add-rule-step-notification-definition.recipients.title').toString()"
                  icon="contacts"
                  class="my-5">
      <template v-slot:content>
        <div class="mx-5 mt-5">
            <v-text-field v-for="i in recipientCount" v-bind:key="i"
                          v-model="recipients[i-1]"
                          :label="$t('add-rule-step-notification-definition.recipients.title') + ' ' + i"
                          outlined
                          append-icon="delete_forever"
                          @click:append="remove(i-1)"
                          @change="updateModel"/>
        </div>
      </template>

      <template v-slot:titleBar>
        <v-btn @click="recipientCount++"
               class="float-right"
               color="primary"
               depressed fab small>
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </content-card>
  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import {actionTypes} from "@/scripts/automations";

export default {
  name: 'AddRuleStepNotificationDefinition',
  components: {
    ContentCard
  },

  props: {
    /**
     * v-model
     */
    value: {
      type: Object,
      default: null
    },

    type: {
      type: String
    }
  },

  data: function () {
    return {
      notificationType: this.type,
      recipients: [],
      recipientCount: 1
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      return this.recipients.length > 0
    }
  },

  methods: {
    /**
     * initializes step data
     *
     * @param item should be null, when adding a new action
     */
    init(item = null) {
      if (item) {
        this.notificationType = item.notificationType
        this.recipients = item.recipients
        this.recipientCount = this.recipients?.length
      } else {
        this.notificationType = this.type
        this.recipients = []
        this.recipientCount = 1
      }
      this.updateModel()
    },

    /**
     * removes a recipient
     * @param itemIdx
     */
    remove(itemIdx) {
      if (this.recipients.length > 1) {
        this.recipients.splice(itemIdx, 1)
        this.recipientCount = this.recipients.length
      } else {
        this.recipients = []
      }
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', {
        notificationType: this.notificationType,
        recipients: this.recipients,
        type: actionTypes.notifications
      })
    }
  },

  watch: {
    type(newVal) {
      this.notificationType = newVal
    }
  }
};
</script>
