<template>
  <v-list color="transparent">
    <v-list-item class="px-0">
      <v-list-item-icon class="mr-3">
        <v-icon color="primary"
                class="material-icons-outlined">
          hourglass_top
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-size-04 mb-2 primary--text font-weight-bold">
          {{ $t('add-nuki-bridge-dialog.step-authorize.title') }}
        </div>
        <div class="font-size-03">
          {{ $t('add-nuki-bridge-dialog.step-authorize.description') }}
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "AddNukiBrigdeStepAuthorize",
}
</script>

