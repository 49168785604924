<!--
  Shows device event data over time
-->
<template v-slot="{ dense:dense }">
  <div>
    <v-list-item v-if="chartType === 0"
                 class="pa-0 d-block">
      <v-data-table hide-default-footer
                    fixed-header
                    height="240"
                    mobile-breakpoint="350"
                    calculate-widths
                    :loading="loading"
                    :headers="headers"
                    :items="events"
                    sort-by="dateTime"
                    :sort-desc="true"
                    :loading-text="$t('device-event-history.loading-text')"
                    :no-data-text="$t('device-event-history.no-data-text')"
                    :items-per-page="-1">
        <template v-slot:[`item.dateTime`]="{ item }">
          {{ formatTime(item.dateTime) }}
        </template>

        <template v-slot:[`item.value`]="{ item }">
          <span v-html="formatValue(item.value)"/>
        </template>
      </v-data-table>
    </v-list-item>

    <v-list-item class="d-block" v-else>
      <v-skeleton-loader v-if="loading"
                         type="image"
                         height="240"
                         class="mt-4"
                         width="100%"/>
      <device-event-history-chart v-else
                                  class="mt-4 elevation-0"
                                  :date="date"
                                  :event="event"
                                  :events="chartEvents"/>
    </v-list-item>

    <v-list-item class="py-1 list-item">
      <v-btn-toggle v-model="chartType"
                    class="mr-4"
                    dense mandatory borderless>
        <v-btn :disabled="!chartTypeToggleAllowed">
          <v-icon>list</v-icon>
        </v-btn>
        <v-btn :disabled="!chartTypeToggleAllowed">
          <v-icon>bar_chart</v-icon>
        </v-btn>
      </v-btn-toggle>

      <!-- date selection -->
      <v-btn icon
             :disabled="!hasPreviousDay"
             @click="previousDay">
        <v-icon>navigate_before</v-icon>
      </v-btn>
      <span class="mx-1">
        {{ formatDate(date) }}
      </span>
      <v-btn icon
             :disabled="!hasNextDay"
             @click="nextDay">
        <v-icon>navigate_next</v-icon>
      </v-btn>

      <v-spacer/>

      <v-btn icon small
             class="mt-1"
             @click="getEvents">
        <v-icon>refresh</v-icon>
      </v-btn>
    </v-list-item>
  </div>
</template>

<script>
import moment from "moment";
import formats from "@/scripts/formats";
import deviceProperties from "@/config/deviceProperties.json";
import DeviceEventHistoryChart from "@/templates/components/chart/DeviceEventHistoryChart.vue";

export default {
  name: 'DeviceEventHistory',
  components: {DeviceEventHistoryChart},

  props: {
    event: Object,
    deviceId: Number
  },

  data: function () {
    return {
      showEventHistory: false,
      chartType: deviceProperties[this.event?.name]?.chartType?.default ?? 0,
      headers: [
        {text: this.$t('device-event-history.headers.dateTime'), value: 'dateTime'},
        {text: this.$t('device-event-history.headers.value'), value: 'value'}
      ],
      loading: false,
      events: [],
      chartEvents: [],
      datepickerMenu: false,
      date: moment().unix()
    }
  },

  computed: {
    hasNextDay() {
      return moment.unix(this.date).isBefore(moment(), 'day')
    },

    hasPreviousDay() {
      return moment.unix(this.date).isAfter(moment().subtract(7, 'days'), 'day')
    },

    chartTypeToggleAllowed() {
      return deviceProperties[this.event?.name]?.chartType?.allowChange
    }
  },

  methods: {
    getEvents() {
      this.loading = true
      this.$rhRequest.sendGet({
            endpoint: 'device-event/get',
            params: {
              "deviceId": this.deviceId.toString(),
              "propertyId": this.event?.propertyId.toString(),
              "start": moment.unix(this.date).startOf('day').unix().toString(),
              "end": moment.unix(this.date).endOf('day').unix().toString()
            }
          },
          (response) => {
            this.events = response?.data?.data
            this.chartEvents = response?.data?.data.slice()

            // check if last element of next day
            if(!moment.unix(this.date).isSame(moment.unix(this.events[this.events.length -1]?.dateTime), 'day')) {
              this.events.pop()
            }
            if(!moment.unix(this.date).isSame(moment.unix(this.events[0]?.dateTime), 'day')) {
              this.events.shift()
            }

            this.loading = false
          },
          (error) => {
            console.error(error)
            this.$root.bisatoast.error({
              message: this.$t("app.generic-error")
            })
            this.loading = false
          })
    },

    previousDay() {
      this.date = moment.unix(this.date).subtract(1, 'days').unix()
      this.getEvents()
    },

    nextDay() {
      this.date = moment.unix(this.date).add(1, 'days').unix()
      this.getEvents()
    },

    formatTime(timestamp) {
      return formats.formatDate(timestamp, this.$t('app.date-time-format.long-seconds'))
    },

    formatDate(date) {
      return formats.formatDate(date, this.$t('app.date-time-format.date-only'))
    },

    formatValue(value) {
      if (this.event?.type === 'boolean' && value === '1') {
        return this.$t(deviceProperties[this.event?.name]?.activatedText)
      } else if (this.event?.type === 'boolean') {
        return this.$t(deviceProperties[this.event?.name]?.deactivatedText)
      } else if (deviceProperties[this.event?.name]?.eventText) {
        return this.$t(deviceProperties[this.event?.name]?.eventText, {value: value, unit: this.event.unit})
      } else if (deviceProperties[this.event?.name]?.valueLabelMapping) {
        return this.$t(deviceProperties[this.event?.name].valueLabelMapping[value]) ?? value
      } else {
        // Fallback: No definition in deviceProperties.json found for this event name
        return this.$t('device-event.value-changed', {value: value, unit: this.event.unit})
      }
    }
  },

  mounted() {
   this.getEvents()
  }
}
</script>
