export default {

  /**
   * opens an url in either InAppBrowser (mobile device) or target="_blank"
   *
   * @param url
   */
  openUrl(url, target = '_blank', event) {
    if (window.cordova) {
      window.enodeBrowser =  window.cordova.InAppBrowser.open(url, '_blank', 'location=yes,hideurlbar=yes,hidenavigationbuttons=yes,toolbarposition=top,closebuttoncaption=X,closebuttoncolor=#626262,toolbarcolor=#ffffffff')
      if (event) {
        window.enodeBrowser.addEventListener(event.event, event.callback)
      }
    } else {
      window.open(url, target)
    }
  }
}
