<template>
  <v-stepper-content step="2"
                     class=" pb-2 pa-1">

    <div v-html="$t('add-rule-step-condition-type.info-text')"/>

    <content-card :title="$t('add-rule-dialog.select-condition.title').toString()"
                  icon="smart_button"
                  class="my-5">
      <template v-slot:content>
        <v-list class="pa-0">
          <v-radio-group v-model="conditionSelection"
                         @change="updateModel"
                         class="pa-0 ma-0"
                         hide-details>
            <!-- time based condition -->
            <v-list-item class="list-item straight">
              <v-list-item-icon class="mr-2">
                <v-radio value="time"
                         class="pt-2 mr-2"/>
                <v-icon color="primary">schedule</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                                   v-text="$t('add-rule-step-condition-type.time')"/>
              </v-list-item-content>
            </v-list-item>

            <!-- device based condition -->
            <v-list-item class="list-item straight">
              <v-list-item-icon class="mr-2">
                <v-radio value="devices"
                         class="pt-2 mr-2"/>
                <v-icon color="primary">widgets</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                                   v-text="$t('add-rule-step-condition-type.devices')"/>
              </v-list-item-content>
            </v-list-item>
          </v-radio-group>
        </v-list>
      </template>
    </content-card>

  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";

export default {
  name: 'AddRuleStepConditionType',
  components: {
    ContentCard
  },

  props: ['value'],

  data: function () {
    return {
      conditionSelection: this.value ?? 'time'
    }
  },

  mounted() {
    this.updateModel();
  },

  methods: {
    updateModel() {
      this.$emit('input', this.conditionSelection)
    }
  },

  watch: {
    value(newVal) {
      this.conditionSelection = newVal
    }
  }
};
</script>
