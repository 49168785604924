import config from '@/config/config.app.json'
import router from '@/router'
import Vue from 'vue'
import EventBus from '@/plugins/lib/EventBus';

export default class {

  getDefaultOptions(method) {
    return {
      method: method,
      host: config.portalApi,
      endpoint: '',
      responseType: 'json',
      serializer: 'json',
      timeout: Number(config.requestTimeoutSlow),
      logout: true
    };
  }

  sendGet(editedOptions, successCallback, errorCallback) {
    let options = this.getDefaultOptions('GET')
    this.sendRequest(Object.assign(options, editedOptions), successCallback, errorCallback)
  }

  sendPost(editedOptions, successCallback, errorCallback) {
    let options = this.getDefaultOptions('POST')
    this.sendRequest(Object.assign(options, editedOptions), successCallback, errorCallback)
  }

  sendPatch(editedOptions, successCallback, errorCallback) {
    let options = this.getDefaultOptions('PATCH')
    this.sendRequest(Object.assign(options, editedOptions), successCallback, errorCallback)
  }
  
  sendDelete(editedOptions, successCallback, errorCallback) {
    let options = this.getDefaultOptions('DELETE')
    this.sendRequest(Object.assign(options, editedOptions), successCallback, errorCallback)
  }

  sendRequest(options, successCallback, errorCallback = this.defaultErrorCb) {
    if (!errorCallback) {
      errorCallback = this.defaultErrorCb
    }

    window.cordova.plugin.http.setDataSerializer(options.serializer)
    window.cordova.plugin.http.setRequestTimeout(options.timeout)
    document.addEventListener('deviceready', () => {
      this.setHttpPluginRequestHeaders()
      let successCallbackWrapper = this.interceptHttpPluginResponse(successCallback);
      window.cordova.plugin.http.sendRequest(
          options.host + options.endpoint,
          options,
          successCallbackWrapper,
          (response) => this.handleErrorResponse(response, errorCallback, options)
      )
    }, false)
  }

  interceptHttpPluginResponse(successCallback) {
    return (function (originalCallback) {
      return function (response) {
        EventBus.$emit('rhRequest.intercept', response)
        // execute the original callback function
        originalCallback(response)
      }
    })(successCallback)
  }

  setHttpPluginRequestHeaders() {
    window.cordova.plugin.http.setHeader('*', 'App-Version', config.configVersion)
    if (localStorage.user && localStorage.token) {
      window.cordova.plugin.http.setHeader('*', 'Authuser', localStorage.user)
      window.cordova.plugin.http.setHeader('*', 'Authtoken', localStorage.token)
    }
    if (localStorage.activeSite) {
      window.cordova.plugin.http.setHeader('*', 'Selected-Site-Id', localStorage.activeSite)
    }
    if (localStorage.role) {
      window.cordova.plugin.http.setHeader('*', 'User-Role', localStorage.role)
    }
  }

  defaultErrorCb(resp) {
    console.error(resp)
  }

  handleErrorResponse(response, errorCallback, options) {
    errorCallback(response)
    if ((response?.response?.status === 401 || response?.status === 401) && options.logout) {
      console.error('Session invalid - log-out user')
      Vue.prototype.$rhAuth.logout(() => {
        router.push({
          name: 'login',
          params: {wasUnauthorized: 'true'}
        }).then(() => {})
      })
    }
  }
}
