import { render, staticRenderFns } from "./SubDevices.vue?vue&type=template&id=bc394fb4&v-slot=%7B%20dense%20%7D&"
import script from "./SubDevices.vue?vue&type=script&lang=js&"
export * from "./SubDevices.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports