<template>
  <v-form v-model="valid" ref="form" class="pt-1">
    <v-text-field outlined
                  v-model="firstName"
                  :rules="nameRules"
                  required
                  :label="$t('registration-name-page.first-name.label')"/>

    <v-text-field outlined
                  v-model="lastName"
                  :rules="nameRules"
                  required
                  :label="$t('registration-name-page.last-name.label')"/>

    <v-btn depressed x-large block
           class="mt-2"
           color="primary"
           @click="register"
           v-html="$t('app.continue')"/>
    <v-btn depressed block
           class="mt-4"
           color="primary"
           text
           @click="$emit('stepBack')"
           v-html="$t('app.cancel')"/>
  </v-form>
</template>

<script>

export default {
  name: 'RegistrationName',

  components: {
  },

  data: () => ({
    valid: false,
    firstName: '',
    lastName: ''
  }),

  methods: {

    register() {
      if (this.$refs.form.validate()) {
        this.$emit('nextStep', [{'firstName': this.firstName}, {'lastName': this.lastName}])
      }
    }
  },

  computed: {
    nameRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
      ]
    }
  }
};
</script>
