<template>
  <fullscreen-overlay-frame :title="$t('app.change-password').toString()"
                            icon="lock"
                            color="primary"
                            centered
                            closable
                            @close="abort">
    <template v-slot:content>
      <v-form v-model="valid" ref="form">
        <v-text-field class="mt-1"
                      outlined
                      v-model="oldPassword"
                      :rules="rulesOldPassword"
                      :type="oldPasswordVisible ? 'text' : 'password'"
                      required
                      autocomplete="current-password"
                      :label="$t('change-password-dialog.old-password.label')">
          <template v-slot:append>
            <v-icon v-if="oldPasswordVisible" @click="oldPasswordVisible = !oldPasswordVisible">visibility</v-icon>
            <v-icon v-else @click="oldPasswordVisible = !oldPasswordVisible">visibility_off</v-icon>
          </template>
        </v-text-field>
        <v-text-field outlined
                      v-model="newPassword"
                      :rules="rulesNewPassword"
                      :type="newPasswordVisible ? 'text' : 'password'"
                      required
                      autocomplete="new-password"
                      :label="$t('change-password-dialog.new-password.label')">
          <template v-slot:append>
            <v-icon v-if="newPasswordVisible" @click="newPasswordVisible = !newPasswordVisible">visibility</v-icon>
            <v-icon v-else @click="newPasswordVisible = !newPasswordVisible">visibility_off</v-icon>
          </template>
        </v-text-field>
        <v-text-field outlined
                      v-model="confirmPassword"
                      :rules="rulesConfirmPassword"
                      :type="confirmPasswordVisible ? 'text' : 'password'"
                      required
                      autocomplete="new-password"
                      :label="$t('change-password-dialog.confirm-password.label')">
          <template v-slot:append>
            <v-icon v-if="confirmPasswordVisible" @click="confirmPasswordVisible = !confirmPasswordVisible">visibility</v-icon>
            <v-icon v-else @click="confirmPasswordVisible = !confirmPasswordVisible">visibility_off</v-icon>
          </template>
        </v-text-field>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn large depressed
             color="primary"
             class="font-weight-bold action-button"
             @click="save"
             :disabled="!valid">
        {{ $t('app.save') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";

export default {
  name: "ChangePassword",

  components: {
    FullscreenOverlayFrame
  },

  data: () => ({
    valid: false,
    passwordMinLength: 8,
    oldPassword: '',
    oldPasswordVisible: false,
    newPassword: '',
    newPasswordVisible: false,
    confirmPassword: '',
    confirmPasswordVisible: false
  }),
  computed: {
    rulesOldPassword() {
      return [
        v => !!v || this.$t('app.rules.required'),
      ]
    },
    rulesNewPassword() {
      return [
        v => !!v || this.$t('app.rules.required'),
        v => (v && v.length >= this.passwordMinLength) || this.$t('app.rules.password.lengthMessage', {passwordMinLength: this.passwordMinLength}),
        v => /[A-Z]/.test(v) || this.$t('app.rules.password.capitalLetter'),
        v => /[a-z]/.test(v) || this.$t('app.rules.password.smallLetter'),
        v => /[0-9]/.test(v) || this.$t('app.rules.password.number')
      ]
    },
    rulesConfirmPassword() {
      return [
        this.confirmPassword === this.newPassword || this.$t('app.rules.password-not-same')
      ]
    }
  },
  methods: {
    abort() {
      this.oldPassword = ''
      this.oldPasswordVisible = false
      this.newPassword = ''
      this.newPasswordVisible = false
      this.confirmPassword = ''
      this.confirmPasswordVisible = false
      this.valid = false
      this.$refs.form.reset()
      this.$root.bisadialog.toggle('changePassword')
    },
    save() {
      this.$rhRequest.sendPost(
          {
            endpoint: "user/change-password",
            data: {
              'oldPassword': this.oldPassword,
              'newPassword': this.newPassword
            }
          }, (response) => {
            if (response?.data?.data?.result) {
              this.$root.bisatoast.success({
                message: this.$t("change-password-dialog.change-password.success")
              })
            } else {
              this.$root.bisatoast.error({
                message: this.$t("change-password-dialog.change-password.error")
              })
            }

            this.$refs.form.reset()
            this.$root.bisadialog.toggle('changePassword')
          }, (e) => {
            console.error(e)
            this.$root.bisatoast.error({
              message: this.$t("app.generic-error")
            })
          }
      )
    }
  }
}
</script>

<style scoped>

</style>
