<!-- summary card for a user configured HAM action -->

<template>
  <content-card :title="$t('action-summary-card.title').toString()">
    <template v-slot:content>
      <v-list-item @click="editAction">
        <v-list-item-content>
          <v-list-item-title>
            <action-summary :action="action" :action-subject="actionSubject"/>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="ml-0">
          <v-btn icon
                 :disabled="!isSiteAdmin"
                 class="float-right"
                 @click.native.stop="editAction">
            <v-icon color="primary">edit</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard.vue";
import ActionSummary from "@/templates/components/living/rules/ActionSummary.vue";

export default {
  name: 'ActionSummaryCard',
  components: {
    ActionSummary,
    ContentCard
  },

  props: ['action', 'actionSubject'],

  computed: {
    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    }
  },

  methods: {
    editAction() {
      if (!this.isSiteAdmin) {
        return
      }
      this.$emit('edit')
    }
  }
}
</script>
