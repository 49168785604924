<template>
  <fullscreen-overlay-frame :title="$t('change-site-dialog.title').toString()"
                            icon="home"
                            color="primary"
                            centered
                            closable
                            @close="abort">
    <template v-slot:content>
      <v-card-title>{{ $t('change-site-dialog.active-site') }}</v-card-title>
      <v-skeleton-loader type="card" v-if="loading"></v-skeleton-loader>
      <v-alert v-else-if="timeout"
               text
               class="font-size-03"
               border="left"
               color="warning">
        <span>{{ $t('app.generic-error') }}</span>
        <v-btn small icon
               color="warning"
               class="float-right"
               @click="getSiteData">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-alert>
      <content-card v-else :title="activeSite?.name?.toString()"
                    icon="home">
        <template v-slot:content>
          <v-card-text>
            <!-- address -->
            <v-list-item dense
                         v-if="canChangeAddress"
                         class="ma-0 px-0 active-site-address">
              <v-list-item-icon class="mr-2">
                <v-icon class="ma-0 material-icons-outlined" color="primary">place</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span>{{ getSiteAddress(activeSite.street, activeSite.zipcode, activeSite.city) }}</span>
              </v-list-item-content>
            </v-list-item>

            <!-- gateway status -->
            <v-list-item dense class="ma-0 px-0 active-site-gateway-status">
              <v-list-item-icon class="mr-2">
                <v-icon class="ma-0 material-icons-outlined" color="primary">
                  {{ getGatewayIcon(activeSite) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span>{{ getGatewayStatus(activeSite) }}</span>
              </v-list-item-content>
            </v-list-item>

            <!-- role -->
            <v-list-item dense class="active-site-access-rights px-0">
              <v-list-item-icon class="mr-2">
                <v-icon class="ma-0 material-icons-outlined" color="primary">people</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span>{{
                    $t('change-site-dialog.access-rights')
                  }} {{ $t('change-site-dialog.role-' + activeSite?.userRole?.role.toLowerCase()) }}</span>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </template>
      </content-card>

      <v-skeleton-loader v-if="loading"></v-skeleton-loader>
      <div v-else-if="availableSites.length > 0">
        <v-card-title class="mt-8">{{ $t('change-site-dialog.available-admin-site') }}</v-card-title>
        <v-row>
          <v-col cols="12" sm="6" v-for="site in availableSites" v-bind:key="site.siteId">
            <div>
              <content-card :title="site?.name"
                            class="mb-4"
                            icon="home">
                <template v-slot:content>
                  <v-card-text>
                    <!-- address -->
                    <v-list-item dense class="ma-0 px-0 active-site-address">
                      <v-list-item-icon class="mr-2">
                        <v-icon class="ma-0 material-icons-outlined" color="primary">place</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <span>{{ getSiteAddress(site.street, site.zipcode, site.city) }}</span>
                      </v-list-item-content>
                    </v-list-item>

                    <!-- gateway status -->
                    <v-list-item dense class="ma-0 px-0 active-site-gateway-status">
                      <v-list-item-icon class="mr-2">
                        <v-icon class="ma-0 material-icons-outlined" color="primary">
                          {{ getGatewayIcon(site) }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <span>{{ getGatewayStatus(site) }}</span>
                      </v-list-item-content>
                    </v-list-item>

                    <!-- role -->
                    <v-list-item dense class="active-site-access-rights px-0">
                      <v-list-item-icon class="mr-2">
                        <v-icon class="ma-0 material-icons-outlined" color="primary">people</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <span>{{
                            $t('change-site-dialog.access-rights')
                          }} {{ $t('change-site-dialog.role-' + site?.userRole?.role.toLowerCase()) }}</span>
                      </v-list-item-content>
                    </v-list-item>

                  </v-card-text>
                  <div class="px-5 pb-5">
                    <v-btn block depressed
                           color="primary"
                           class="font-weight-bold"
                           @click="changeSite(site.siteId)">
                      {{ $t('change-site-dialog.change-site') }}
                    </v-btn>
                  </div>
                </template>
              </content-card>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="!timeout" class="mt-8">
        <span>{{ $t('change-site-dialog.no-available-sites') }}</span>
      </div>

    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import ContentCard from "@/templates/components/ContentCard";
import config from "@/config/config.app.json";

export default {
  name: "ChangeSiteDialog",

  components: {
    ContentCard,
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: () => ({
    loading: false,
    timeout: false,
    sites: []
  }),

  methods: {
    init() {
      this.getSiteData()
    },
    getSiteData() {
      this.loading = true
      this.$rhRequest.sendGet({
        endpoint: 'multisite/get-related-sites'
      }, (response) => {
        if (response?.data?.data) {
          this.sites = response.data.data
          this.loading = false
        }
      }, (error) => {
        console.error(error)
        if (error.code === 'ECONNABORTED') {
          this.timeout = true
        }
        this.loading = false
      })
    },
    getSiteAddress(address, zip, city) {
      if (zip || city) {
        return address + ', ' + zip + ' ' + city
      } else {
        return address
      }
    },
    close() {
      this.loading = true
      this.sites = []
      this.$root.bisadialog.toggle('changeSite', false)
    },
    abort() {
      this.$root.bisadialog.toggle('changeSite')
    },
    changeSite(siteId) {
      this.loading = true
      this.$rhRequest.sendPost({
        endpoint: 'site/change-site',
        data: {
          siteId: siteId
        }
      }, (response) => {
        this.timeout = false
        if (response?.data?.data?.message?.role) {
          // update the user role for the newly selected site
          localStorage.role = response.data.data.message.role
          // store currently active site
          this.setLocalStorage()
          this.$root.bisatoast.success({
            message: this.$t("change-site-dialog.success")
          })
          this.$root.$emit('close-change-site-dialog')
          this.abort()
        } else {
          this.$root.bisatoast.error({
            message: this.$t("app.generic-error")
          })
        }
      }, (e) => {
        console.error(e)
        if (e.code === 'ECONNABORTED') {
          this.timeout = true
        } else {
          this.$root.bisatoast.error({
            message: this.$t("app.generic-error")
          })
        }
        this.loading = false
      })
    },
    getGatewayStatus(site) {
      if (site.isOnline === null) {
        return this.$t('change-site-dialog.no-gateway')
      } else if (site.isOnline) {
        return this.$t('change-site-dialog.gateway-online')
      } else {
        return this.$t('change-site-dialog.gateway-offline')
      }
    },
    getGatewayIcon(site) {
      switch (site.isOnline) {
        case true:
          return 'podcasts'
        case false:
          return 'wifi_tethering_error'
        default:
          return 'portable_wifi_off'
      }
    },
    setLocalStorage() {
      localStorage.activeSite = this.activeSite.siteId
    }
  },

  computed: {
    /**
     * returns all available sites (without the currently active site)
     * @returns Array
     */
    availableSites() {
      if (this.sites.length === 0) {
        return {name: ''}
      } else {
        return this.sites.filter(site => {
          return site.isActiveSite === false
        })
      }
    },

    /**
     * returns the currently active site
     * @returns {Object}
     */
    activeSite() {
      if (this.sites.length === 0) {
        return {name: ''}
      } else {
        return this.sites.filter(site => {
          return site.isActiveSite
        })[0]
      }
    },

    /**
     * returns true if the user is allowed to enter or change the site address
     * @returns {boolean}
     */
    canChangeAddress() {
      return config.canChangeAddress === 'true';
    }
  }
}
</script>
