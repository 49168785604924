<template>
  <fullscreen-overlay-frame :title="title"
                            icon="control_point_duplicate"
                            :color="color"
                            centered
                            closable
                            @close="close">
    <template v-slot:content>

      <v-card v-if="!data.gatewayAdded"
              :disabled="data.gatewayAdded || !isSiteAdmin"
              class="mb-4"
              @click="addGateway">
        <v-card-title class="pb-0">
          <v-icon class="material-icons-outlined" color="primary" left>broadcast_on_personal</v-icon>
          <span class="primary--text font-weight-bold font-size-04"
                v-text="$t('no-gateway-card.add-gateway')"/>
          <v-spacer/>
          <v-icon color="primary" class="material-icons-outlined">
            chevron_right
          </v-icon>
        </v-card-title>
        <v-card-text class="pl-12 font-size-02"
          v-text="$t('add-smarthome-component-dialog.gateway.info')"/>
      </v-card>

      <v-card :disabled="!data.gatewayAdded || !isSiteAdmin"
              class="mb-4"
              @click="addDevice">
        <v-card-title class="pb-0">
          <v-icon class="material-icons-outlined" color="primary" left>widgets</v-icon>
          <span class="primary--text font-weight-bold font-size-04"
                v-text="$t('device-list.add-device')"/>
          <v-spacer/>
          <v-icon color="primary" class="material-icons-outlined">
            chevron_right
          </v-icon>
        </v-card-title>
        <v-card-text class="pl-12 font-size-02"
                     v-text="$t('add-smarthome-component-dialog.device.info')"/>
      </v-card>

      <v-card v-if="hasAutomationV2"
              :disabled="!data.gatewayAdded || !isSiteAdmin"
              class="mb-4"
              @click="addScene">
        <v-card-title class="pb-0">
          <v-icon class="material-icons-outlined" color="primary" left>play_circle</v-icon>
          <span class="primary--text font-weight-bold font-size-04"
                v-text="$t('add-smarthome-component-dialog.add-scene')"/>
          <v-spacer/>
          <v-icon color="primary" class="material-icons-outlined">
            chevron_right
          </v-icon>
        </v-card-title>
        <v-card-text class="pl-12 font-size-02"
                     v-text="$t('add-smarthome-component-dialog.scene.info')"/>
      </v-card>

      <v-card v-if="hasAutomationV2"
              :disabled="!data.gatewayAdded || !isSiteAdmin"
              class="mb-4"
              @click="addRule">
        <v-card-title class="pb-0">
          <v-icon class="material-icons-outlined" color="primary" left>auto_awesome</v-icon>
          <span class="primary--text font-weight-bold font-size-04"
                v-text="$t('add-smarthome-component-dialog.add-rule')"/>
          <v-spacer/>
          <v-icon color="primary" class="material-icons-outlined">
            chevron_right
          </v-icon>
        </v-card-title>
        <v-card-text class="pl-12 font-size-02"
                     v-text="$t('add-smarthome-component-dialog.rule.info')"/>
      </v-card>

      <v-card :disabled="!data.gatewayAdded || !isSiteAdmin"
              class="mb-4"
              @click="addHeatingMode">
        <v-card-title class="pb-0">
          <v-icon class="material-icons-outlined" color="primary" left>thermostat_auto</v-icon>
          <span class="primary--text font-weight-bold font-size-04"
                v-text="$t('add-heating-mode-dialog.title')"/>
          <v-spacer/>
          <v-icon color="primary" class="material-icons-outlined">
            chevron_right
          </v-icon>
        </v-card-title>
        <v-card-text class="pl-12 font-size-02"
                     v-text="$t('add-smarthome-component-dialog.heating-mode.info')"/>
      </v-card>

      <v-card :disabled="!data.gatewayAdded || !isSiteAdmin"
              class="mb-4"
              @click="addNukiBridge">
        <v-card-title class="pb-0">
          <v-icon class="material-icons-outlined" color="primary" left>key</v-icon>
          <span class="primary--text font-weight-bold font-size-04"
                v-text="$t('add-nuki-bridge-dialog.title')"/>
          <v-spacer/>
          <v-icon color="primary" class="material-icons-outlined">
            chevron_right
          </v-icon>
        </v-card-title>
        <v-card-text class="pl-12 font-size-02"
                     v-text="$t('add-smarthome-component-dialog.nuki-bridge.info')"/>
      </v-card>

    </template>

  </fullscreen-overlay-frame>
</template>

<script>
import config from "@/config/config.app.json";
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";

export default {
  name: 'AddSmarthomeComponentDialog',
  components: {
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: function () {
    return {
      title: this.$t('add-smarthome-component-dialog.title'),
      color: 'primary'
    }
  },

  computed: {
    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    },
    hasAutomationV2() {
      return (/true/).test(config.hasAutomationV2)
    }
  },

  methods: {
    /**
     * open gateway dialog
     */
    addGateway() {
      this.close()
      this.$root.bisadialog.toggle('addGateway')
      this.$root.bisadialog.callDialogInit('addGateway')
    },

    /**
     * open add scene dialog
     */
    addScene() {
      this.close()
      this.$root.bisadialog.toggle('addScene', true, {})
    },

    /**
     * open add rule dialog
     */
    addRule() {
      this.close()
      this.$root.bisadialog.toggle('addRule', true, {})
    },

    /**
     * open add heating mode dialog
     */
    addHeatingMode() {
      this.close()
      this.$root.bisadialog.toggle('addHeatingMode', true, {gatewayMac: this.data.gatewayMac})
      this.$root.bisadialog.callDialogInit('addHeatingMode')
    },

    /**
     * open add device dialog
     */
    addDevice() {
      this.close()
      this.$root.bisadialog.toggle('addDevice', true, {})
      this.$root.bisadialog.callDialogInit('addDevice')
    },

    /**
     * open add nuki bridge dialog
     */
    addNukiBridge() {
      this.close()
      this.$root.bisadialog.toggle('addNukiBridge', true, {})
      this.$root.bisadialog.callDialogInit('addNukiBridge')
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('addSmarthomeComponent')
    }
  }
};
</script>

