<template>
  <div>
    <div class="font-size-03" v-html="$t('add-gateway-step1.gw-id-info')"/>

    <div class="mt-8 mb-4 primary--text font-weight-bold" v-html="$t('add-gateway-step1.gw-id-headline')"/>

    <v-row>
      <v-col cols="6">
        <v-img src="~@/assets/images/gw_help_diehl.png"
               height="150" width="150"
               class="mx-auto"/>
        <div class="text-center font-size-02 primary--text font-weight-bold"
             v-html="$t('add-gateway-step1.type-a')"/>
      </v-col>
      <v-col cols="6">
        <v-img src="~@/assets/images/gw_help_devolo.png"
               height="150" width="150"
               class="mx-auto"/>
        <div class="text-center font-size-02 primary--text font-weight-bold"
             v-html="$t('add-gateway-step1.type-b')"/>
      </v-col>
    </v-row>

    <ul class="mt-6 font-size-03" v-html="$t('add-gateway-step1.gw-id-locations')"/>
  </div>
</template>

<script>

export default {
  name: "AddGatewayStep1"
}
</script>
