<template>
  <v-app-bar app
             bottom
             color="transparent"
             elevation="0"
             :class="(displayDiscover ? '' : 'no-discover ') + 'bottom-navigation mt-4 pa-0'">

      <!-- home button -->
      <v-btn x-large tile
             to="home"
             color="navBackground"
             elevation="0"
             :ripple="false"
             class="button-left mt-0">
        <v-icon :color="bottomNavigationActive === 'home' ? 'primary' : ''"
                :class="(displayDiscover ? 'mr-8 ' : '') + activeClass('home')">
          business
        </v-icon>
        <div class="button-text font-size-00" :class="bottomNavigationActive === 'home' ? 'primary--text font-weight-bold' : ''">
          <span :class="(displayDiscover ? 'mr-8' : '')"
                v-html="$t('bottom-navigation.home')"/>
        </div>
      </v-btn>

      <!-- discover button -->
      <v-fab-transition class="ma-0 pa-0" v-if="displayDiscover">
        <v-btn
            class="discover-button"
            :color="this.bottomNavigationActive === 'discover' ? 'secondary' : 'navBackground'"
            bottom
            to="discover"
            elevation="2"
            absolute
            fab
            :ripple="false"
            large >
          <v-icon :class="activeClass('discover') + ' material-icons-outlined'"
                  large>
            explore
          </v-icon>
        </v-btn>
      </v-fab-transition>
      <div class="discover-label font-size-00 text-center v-btn text-none"
           :class="bottomNavigationActive === 'discover' ? 'secondary--text font-weight-bold' : ''"
           v-html="$t('bottom-navigation.discover')"/>

      <!-- profile button -->
      <v-btn :ripple="false"
          to="profile"
          x-large tile
          color="navBackground"
          elevation="0"
          class="button-right ma-0 pa-0">
        <v-icon :color="bottomNavigationActive === 'profile' ? 'primary' : ''"
                :class="(displayDiscover ? 'ml-8 ' : '') + activeClass('profile')">
          person_outline
        </v-icon>
        <div class="button-text font-size-00" :class="bottomNavigationActive === 'profile' ? 'primary--text font-weight-bold' : ''">
          <span :class="(displayDiscover ? 'ml-8' : '')"
                v-html="$t('bottom-navigation.profile')"/>
        </div>
      </v-btn>
  </v-app-bar>
</template>

<script>

  import config from '@/config/config.app.json'

  export default {
    name: 'BottomNavigation',
    props: ['bottomNavigationActive'],
    data: function () {
      return {
        //
      }
    },

    methods: {
      activeClass: function (routeName) {
        return routeName === this.bottomNavigationActive ? 'active' : 'inactive'
      }
    },

    computed: {
      displayDiscover: function () {
        return config.hasDiscoverPage === 'true'
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/styles/bottom-navigation.scss';
</style>
