<template>
  <div class="page-content">
    <v-speed-dial v-if="hasEnode"
                  class="pulse"
                  :right="true"
                  :bottom="true">
      <template v-slot:activator>
        <v-btn class="speed-dial-button"
               fab fixed bottom right
               color="primary"
               @click="openAddDialog">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>

    <v-skeleton-loader v-if="loading"
                       type="article"
                       height="300"/>

    <div v-else-if="!loading && (enodeConnections.length > 0 || (items && Object.values(items).length > 0))">
      <v-row v-if="(items && Object.values(items).length > 0)">
        <v-col cols="12" sm="6" lg="4" v-for="chargingStation in Object.values(items)"
               v-bind:key="chargingStation[0].attributes.chargingStation.id">
          <content-card

              :title="chargingStation[0].attributes.chargingStation.name"
              class="mb-6"
              icon="ev_station"
              color="primary">
            <template v-slot:content>
              <chargingstation-card :charging-points="chargingStation"
                                    :enabled-connection-ids="enabledConnectionIds"
                                    :chargingstation-id="chargingStation[0].attributes.chargingStation.id"
                                    :compleo-connection-id="compleoConnectionId"/>
            </template>
          </content-card>
        </v-col>
      </v-row>

      <v-row v-if="enodeConnections.length > 0">
        <v-col cols="12" sm="6" lg="4" v-for="(enodeConnection, index) in enodeConnections"
               v-bind:key="index">
          <enode-connection-card @reloadConnectionDetails="getEnodeConnections(false)" :connectionDetails="enodeConnection"/>
        </v-col>
      </v-row>

    </div>

    <!-- Empty State -->
    <div v-else>
      <empty-state
        :empty-state-title="$t('emobility-page.emptyState.title').toString()"
        :empty-state-text="$t('emobility-page.emptyState.text').toString()">
        <template v-slot:illustration>
          <emobility-empty-state-illustration
            :fill-primary="'var(--v-primary-base)'"
            :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>
    </div>

  </div>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import EmobilityEmptyStateIllustration from "@/templates/components/emptyStates/svg/EmobilityEmptyStateIllustration.vue";
import config from '@/config/config.app.json'
import requestHelper from "@/scripts/requestHelper";
import ChargingstationCard from "@/templates/components/emobility/ChargingstationCard.vue";
import EnodeConnectionCard from "@/templates/components/emobility/EnodeConnectionCard.vue";

export default {
  name: "EMobilityPage",

  components: {EnodeConnectionCard, ChargingstationCard, ContentCard, EmptyState, EmobilityEmptyStateIllustration},

  data() {
    return {
      refreshRate: config.updateInterval,
      loading: false,
      chargingPoints: [],
      compleoConnectionId: null,
      items: [],
      timer: null,
      enabledConnectionIds: null,
      enodeConnections: []
    }
  },

  methods: {
    openAddDialog() {
      this.$root.bisadialog.toggle('addEmobilityConnection', true)
    },
    /**
     * calls all get connection requests
     * @param showLoader
     */
    getVehicles(showLoader) {
      this.getCompleoConnections(showLoader)
      this.getEnodeConnections(showLoader)
    },
    /**
     * get all compleoConnections
     */
    getCompleoConnections(showLoader) {
      if (showLoader) {
        this.loading = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'chargingstations/compleoConnections',
        params: {
          include: "chargingPoints",
        }
      }, (resp) => {
        if (resp?.data?.data?.included) {
          this.compleoConnectionId = resp.data.data.data[0].id
          let included = resp.data.data.included

          this.enabledConnectionIds = resp.data.data?.currentlyEnabled

          // group (reduce) included chargingpoints to their respective chargingstation. reason for this: on emobility page, every charginstation
          // has its own card with its chargingpoints. included data is not grouped, therefore has to be done here
          this.items = included.reduce((acc, d) => {
            if (Object.keys(acc).includes(d.attributes.chargingStation.id)) return acc;
            acc[d.attributes.chargingStation.id] = included.filter(g => g.attributes.chargingStation.id === d.attributes.chargingStation.id);
            return acc;
          }, {})
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },
    /**
     * get all enodeConnections
     */
    getEnodeConnections (showLoader) {
      if (showLoader) {
        this.loading = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'enode/get-vehicles',
      }, (resp) => {
        if (resp?.data?.data) {
          this.enodeConnections = resp.data.data
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },

    /**
     * continuously refresh data
     */
    updateInterval() {
      this.timer = setInterval(() => {
        this.getVehicles(false)
      }, this.refreshRate)
    },
    checkForRedirect() {
      if (this.$route.path === '/enodeRedirect') {
        setTimeout(() => {
          if (Object.keys(this.$route.query).length === 0) {
            this.$root.bisatoast.success({
              message: this.$t("enode.toast.success"),
              showCloseBtn: true
            })
          } else if (Object.keys(this.$route.query).length > 0) {
            this.$root.bisatoast.error({
              message: this.$t('app.generic-error'),
              showCloseBtn: true
            })
          }
          this.$router.push('emobility')
        }, 500);
      }
    }
  },
  computed: {
    hasEnode() {
      return Object.hasOwn(config, 'enodeRedirect')
    }
  },
  mounted() {
    this.loading = true
    this.checkForRedirect()
    requestHelper.startDelayedRequest(
      () => this.getVehicles(true),
      () => this.updateInterval()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/documents.scss';
</style>
