<template>
  <fullscreen-overlay-frame :title="title"
                            icon="control_point_duplicate"
                            :color="color"
                            centered
                            closable
                            @close="close">
    <template v-slot:content>

      <v-card :disabled="!isSiteAdmin"
              class="mb-4"
              @click="addEnodeConnection">
        <v-card-title class="pb-0">
          <v-icon class="material-icons-outlined" color="primary" left>electric_car</v-icon>
          <span class="primary--text font-weight-bold font-size-04"
                v-text="$t('add-enode-connection.title')"/>
          <v-spacer/>
          <v-icon color="primary" class="material-icons-outlined">
            chevron_right
          </v-icon>
        </v-card-title>
        <v-card-text class="pl-12 font-size-02"
                     v-text="$t('add-enode-connection.info')"/>
      </v-card>

    </template>

  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import inAppBrowser from "@/scripts/inAppBrowser";
import config from "@/config/config.app.json";

export default {
  name: 'AddEmobilityConnectionDialog',
  components: {
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: function () {
    return {
      title: this.$t('add-emobility-connection-dialog.title'),
      color: 'primary'
    }
  },

  computed: {
    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    }
  },

  methods: {
    /**
     * open add nuki bridge dialog
     */
    addEnodeConnection() {
      this.close()

      this.$rhRequest.sendPost({
        endpoint: 'enode/link-user',
        data: {
          "redirectUri": config.enodeRedirect,
        }
      }, (resp) => {
        if (resp?.data?.data?.linkUrl) {
          let event = {
            event: 'loadstart',
            callback: (params) => {
              let [hash, query] = params.url.split('#')[1].split('?')

              if (hash === "/enodeRedirect") {
                if (query) {
                  let params = Object.fromEntries(new URLSearchParams(query))

                  this.$root.bisatoast.error({
                    message: this.$t('app.generic-error'),
                    showCloseBtn: true
                  })

                  console.error(hash, params?.error)
                  console.error(hash, params?.error_description)
                } else {
                  this.$root.bisatoast.success({
                    message: this.$t("enode.toast.success"),
                    showCloseBtn: true
                  })
                }

                window.enodeBrowser.close()
              }
            }
          }
          inAppBrowser.openUrl(resp.data.data.linkUrl, '_blank', event)
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('addEmobilityConnection')
    }
  }
};
</script>

