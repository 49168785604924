<template>
  <device-card flat
               :title="$t('device-card-ir-controller-configuration.title').toString()"
               icon="tune">
    <v-overlay v-if="loadCode" color="navBackground" absolute z-index="4" opacity="0.7">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-overlay>
    <div class="pa-5">
      <v-list class="pb-3">
        <v-list-item inactive
                     :ripple="false">
          <v-list-item-icon>
            <span class="material-symbols-outlined v-icon ma-0" :class="$vuetify.theme.isDark ? 'theme--dark' : 'theme--light'">manufacturing</span>
          </v-list-item-icon>
          <v-list-item-content v-if="!edit.manufacturer">
            <v-list-item-title class="font-size-03"
                               v-text="$t('device-card-ir-controller.manufacturer-select.label')"/>
            <v-list-item-subtitle v-text="selectedBrand ?? null"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-select :items="manufacturerList"
                      v-model="selectedBrand"
                      clearable
                      outlined dense
                      hide-details
                      :label="$t('device-card-ir-controller.manufacturer-select.label')"
                      :item-text="item => item?.brand"
                      :item-value="item => item?.brand"
            />
          </v-list-item-content>
          <v-list-item-action v-if="!edit.manufacturer">
            <v-btn icon
                   @click="updateManufacturer()">
              <v-icon>edit</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item inactive
                     :ripple="false">
          <v-list-item-icon>
            <v-icon class="material-icons-outlined">123</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!edit.code">
            <v-list-item-title class="font-size-03"
                               v-text="$t('device-card-ir-controller.code-select.label')"/>
            <v-list-item-subtitle v-text="selectedCode ?? null"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-select :items="codeList"
                      :disabled="selectedBrand === null"
                      v-model="selectedCode"
                      clearable
                      outlined dense
                      hide-details
                      :label="$t('device-card-ir-controller.code-select.label')"
                      :item-text="item => item?.codeNum"
                      :item-value="item => item?.codeNum"
            />
          </v-list-item-content>
          <v-list-item-action v-if="!edit.code">
            <v-btn icon
                   @click="edit.code = true">
              <v-icon>edit</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div class="d-flex flex-row-reverse">
        <v-btn large depressed
               :disabled="selectedCode === null"
               :loading="loading"
               color="primary"
               @click="sendCode()">
        {{ $t('app.save') }}
      </v-btn>
      </div>
    </div>


  </device-card>
</template>

<script>
import DeviceCard from "@/templates/components/devices/DeviceCard.vue";
import irManufacturerCodes from "@/config/irManufacturerCodes.json";


export default {
  name: 'DeviceCardIRControllerConfiguration',

  components: {DeviceCard},

  data: function () {
    return {
      selectedBrand: null,
      selectedCode: null,
      irManufacturerCodes: irManufacturerCodes,
      loading: false,
      loadCode: false,
      edit: {
        manufacturer: false,
        code: false
      },
    }
  },

  props: ['device'],

  methods: {
    init() {
      this.selectedCode = null
      this.selectedBrand = null
      this.getIrControllerCode()
    },
    updateManufacturer() {
      this.edit.manufacturer = true
      this.edit.code = true
      this.selectedCode = null
    },
    onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    },
    getIrControllerCode() {
      this.loadCode = true
      this.$rhRequest.sendGet({
        endpoint: "devices/get-ir-controller-code",
        params: {
          "deviceId": this.device.id.toString(),
        }
      }, (resp) => {
        this.$nextTick(()=>{
          let data = resp?.data.data
          if(!data) return
          this.loadCode = false
          this.selectedBrand = data.manufacturerName
          this.selectedCode = data.value
        })
      }, () => {
        this.loadCode = false
        this.$root.bisatoast.error({message: this.$t('device-card-ir-controller.get-zwave-configuration.error')})
      })
    },
    sendCode() {
      this.loading = true
      this.$rhRequest.sendPost({
        endpoint: 'devices/set-ir-controller-code',
        data: {
          deviceId: this.device.id,
          code: this.selectedCode,
          manufacturerName: this.selectedBrand
        }
      }, () => {
        this.loading = false
        this.edit.manufacturer = false
        this.edit.code = false
        this.$root.bisatoast.success({message: this.$t('device-card-ir-controller.zwave-configuration.success')})
      }, (err) => {
        console.error(err)
        this.loading = false
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
      })
    }
  },

  mounted() {
    this.getIrControllerCode()
  },

  computed: {
    manufacturerList() {
      return this.irManufacturerCodes.filter(this.onlyUnique)
    },
    codeList() {
      return this.manufacturerList.filter((item) => item.brand === this.selectedBrand)
    }
  }
}


</script>
