<template>
  <entry-frame :title="$t('password-reset.title').toString()">
    <template v-slot:subheader>
      <div v-html="$t('password-reset.subtitle')"/>
    </template>

    <template v-slot:form>
      <v-form v-model="valid" ref="form">
        <v-text-field outlined
                      v-model="email"
                      :rules="emailRules"
                      required
                      :label="$t('login-page.e-mail.label')"/>

        <v-btn depressed x-large block
               class="mt-2 font-weight-bold"
               color="primary"
               :loading="loading"
               :disabled="loading"
               v-html="$t('app.send')"
               @click="resetPassword"/>
        <v-btn depressed text block
               color="primary"
               class="mt-4"
               :loading="loading"
               :disabled="loading"
               v-html="$t('app.cancel')"
               to="login"/>
      </v-form>
    </template>
  </entry-frame>
</template>

<script>
import EntryFrame from "@/templates/components/EntryFrame";

export default {
  name: 'ResetPassword',

  components: {
    EntryFrame: EntryFrame
  },

  data: () => ({
    email: "",
    loading: false,
    valid: false
  }),

  methods: {
    close() {
      this.email = ""
      this.loading = false
      this.valid = false
      this.$router.push('/home')
    },
    resetPassword: function () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$rhRequest.sendPost({
          endpoint: "user/forgotten-password-otp",
          data: {email: this.email}
        }, (response) => {
          if(response.data.data && response.data.data.mailSent === true) {
            this.$root.bisatoast.success({
              message: this.$t('reset-password.success'),
              showCloseBtn: true
            })
            this.close()
          } else {
            this.$root.bisatoast.error({
              message: this.$t('reset-password.error'),
              showCloseBtn: true
            })
          }
          this.loading = false
        }, (e) => {
          console.error(e)
          this.$root.bisatoast.error({
            message: this.$t('reset-password.error'),
            showCloseBtn: true
          })
          this.loading = false
        })
      }
    }
  },

  computed: {
    emailRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
        v => /.+@.+\..+/.test(v) || this.$t('app.rules.email-invalid')
      ]
    }
  }
};
</script>
