<template>
  <v-app-bar app
             top
             class="top-navigation"
             fixed
             color="navBackground"
             height="89"
             :elevation="showTopNavTabs ? '5' : '0'">

    <template v-slot:img>
      <v-img :width="logoWidth"
             :style="logoTopPadding"
             class="logo mx-auto mt-6">
        <main-logo :fill-color="topNavLogoColor" :font-color="topNavLogoFontColor" v-if="showMainLogo"></main-logo>
      </v-img>
    </template>

    <v-btn class="back-button" v-if="showBackArrow" icon :to="backRoute">
      <v-icon color="primary">arrow_back</v-icon>
    </v-btn>

    <template v-slot:extension v-if="showTopNavTabs">
      <v-tabs center-active
              icons-and-text
              hide-slider
              active-class="top-nav-active"
              centered
      >
        <v-tab :ripple="false" v-for="tab in topNavElementsInOrder" :key="tab" :to="tab">
          <span class="mt-1 text-none font-size-00 overflow-hidden">{{ $t(navTabsObject[tab].name) }}</span>
          <v-icon class="material-icons-outlined">{{ navTabsObject[tab].icon }}</v-icon>
        </v-tab>
      </v-tabs>
    </template>

    <v-spacer/>

    <!-- legal button ONLY for client key Habilio -->
    <v-menu offset-y
            @input="onMenuToggle"
            v-if="config.clientKey === 'habilio'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text rounded small
               class="primary--text font-weight-bold font-size-06 px-0"
               v-bind="attrs" v-on="on">
          §
        </v-btn>
      </template>

      <v-list>
        <v-list-item link
                     :href="config.privacyLink"
                     target="_blank">
          <v-list-item-icon>
            <v-icon color="primary" class="material-icons-outlined">phonelink_lock</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-html="$t('app.privacy')"/>
        </v-list-item>
        <v-list-item link
                     :href="config.tosLink"
                     target="_blank">
          <v-list-item-icon>
            <v-icon color="primary" class="material-icons-outlined">perm_device_information</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-html="$t('profile-page.tos')"/>
        </v-list-item>
        <v-list-item link
                     :href="config.imprintLink"
                     target="_blank">
          <v-list-item-icon>
            <v-icon color="primary" class="material-icons-outlined">description</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-html="$t('app.imprint')"/>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- user profile ONLY for large view ports -->
    <v-btn plain
           class="d-none d-md-block text-lowercase pt-2"
           color="primary"
           to="profile">
      <v-icon left
              color="primary"
              class="material-icons-outlined font-size-08">
        person_outline
      </v-icon>
      {{ userName }}
    </v-btn>
    <v-btn plain small icon
           v-if="!hasDiscoverPage"
           class="d-md-none mr-4"
           color="primary"
           to="profile">
      <v-icon class="material-icons-outlined ">manage_accounts</v-icon>
    </v-btn>

  </v-app-bar>
</template>

<script>

import config from '@/config/config.app.json'
import MainLogo from "@/assets/custom/images/mainLogo"
import theme from "@/config/theme"

export default {
  name: "TopNavigation",
  components: {MainLogo},
  props: ['showTopNavTabs', 'topNavLogoColor', 'topNavLogoFontColor', 'showBackArrow', 'backRoute'],

  data: function () {
    return {
      config: config,
      showTabs: true,
      navTabsObject: {
        // [key how given in config} : { name: [translation key], icon : [icon name]}
        'home' : {name: 'home-page.topNav.home.title', icon: 'home'},
        'homeEnergy' : {name: 'home-page.topNav.home-energy.title', icon: 'solar_power'},
        'energy' : {name: 'home-page.topNav.energy.title', icon: 'thermostat'},
        'watercontrol' : {name: 'home-page.topNav.water-control.title', icon: 'water_drop'},
        'emobility' : {name: 'home-page.topNav.emobility.title', icon: 'ev_station'},
        'inbox' : {name: 'home-page.topNav.inbox.title', icon: 'forward_to_inbox'},
        'documents' : {name: 'home-page.topNav.documents.title', icon: 'folder_open'},
        'support' : {name: 'home-page.topNav.support.title', icon: 'support_agent'},
        'smarthome': {name: 'home-page.topNav.smarthome.title', icon: 'broadcast_on_personal'},
        'offers': {name: 'home-page.topNav.offers.title', icon: 'storefront'}
      }
    }
  },

  computed: {
    topNavElementsInOrder() {
      let self = this
      let topNavElements = []
      // make sure only elements which are given in topNavObject get returned. Otherwise, error
      // will prevent whole navigation from showing
      config.homeTopNavOrder.split(',').forEach(function (item){
        if(Object.keys(self.navTabsObject).includes(item)) {
          topNavElements.push(item)
        }
      })
      return topNavElements
    },

    userName() {
      return localStorage.user
    },

    hasDiscoverPage() {
      return config.hasDiscoverPage === 'true';
    },

    /**
     * get the header logo width from the theme - defaults to 102px
     *
     * @returns {number}
     */
    logoWidth() {
      return theme.props?.headerLogoWidth ?? 102
    },

    /**
     * get the header logo extra top padding from the theme - defaults to 0px
     *
     * @returns {string}
     */
    logoTopPadding() {
      if (theme.props?.headerLogoTopPadding > 0) {
        return "padding-top: " + theme.props.headerLogoTopPadding + "px;"
      }
      return ''
    },

    /**
     * Show the main logo on all pages, except the profile and user management pages
     * @returns {boolean}
     */
    showMainLogo() {
      return this.$route.name !== 'profile' && this.$route.name !== 'userManagement';
    }
  },

  methods: {
    /**
     * Used for the Habilio-only legal menu.
     * Will blur the background when the menu is opened.
     */
    onMenuToggle(state) {
      if (state) {
        this.$emit('blur')
      } else {
        this.$emit('clear')
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/styles/top-navigation.scss';
</style>
