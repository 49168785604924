import RHPushNotification from '@/plugins/rhPushNotification/pushNotification'
import EventBus from '@/plugins/lib/EventBus';
import router from '@/router/index'
import config from '@/config/config.app.json'
import Vue from "vue";

export default class RHPushNotificationPlugin {

  /**
   * These constants are necessary to identify which type of id is used in the notification topic.
   * c - cluster
   * b - building
   * s - site
   *
   * Examples:
   * my-development-marketplace-c1-de (cluster id 1)
   * my-development-marketplace-b2-de (building id 2)
   * my-development-marketplace-s3-de (site id 3)
   */
  static CLUSTER_ID_PREFIX = 'c'
  static BUILDING_ID_PREFIX = 'b'
  static SITE_ID_PREFIX = 's'
  static ALL_ID = 'all'

  static CHANNEL_KEY_SERVICE = "service"

  static LSKEY_SUBSCRIPED_TOPICS = 'subscribedTopics';
  static LSKEY_CLUSTER_ID = 'clusterId';
  static LSKEY_BUILDING_ID = 'buildingId';
  static LSKEY_ACTIVE_SITE = 'activeSite';
  static LSKEY_LANG_SETTING = 'langSetting';

  rhPushNotification = null;
  clientKey = null;
  environment = null;
  channels = null;
  requested = [];

  /**
   *
   * @param clientKey
   * @param environment
   * @param channels
   */
  constructor(clientKey, environment, channels) {
    this.rhPushNotification = new RHPushNotification();
    this.rhPushNotification.onMessage = this.onMessage.bind(this);

    EventBus.$on('rhRequest.intercept', this.handleRequestResponse.bind(this));
    this.clientKey = clientKey;
    this.environment = environment;
    this.channels = channels;
  }

  onMessage(message) {
    if (message.messageType === "notification") {
      if (message.tap) {
        this.onMessageTapped(message);
      }
    }
  }

  onMessageTapped(message) {
    let notificationRoute = message.route
    if (notificationRoute) {
      let vueRoute = {
        name: 'home'
      };
      switch (notificationRoute) {
        case 'messages':
          vueRoute.name = 'inbox';
          vueRoute.params = {tapMessageId: message.navTargetId}
          break
        case 'documents':
          vueRoute.name = 'documents';
          break
        case 'offers':
          vueRoute.name = 'offers';
          break
        case 'device':
          vueRoute.name = 'smarthome';
          vueRoute.params = {deviceId: message.deviceId}
          break;
        default:
          vueRoute.name = 'home';

      }
      router.push(vueRoute)
    }
  }

  /**
   *
   * @param resp
   */
  handleRequestResponse(resp) {
    this.checkFaultyStorageReset()
    this.handle(
      resp.headers['cluster-id'],
      resp.headers['building-id'],
      resp.headers['selected-site-id']
    );
  }

  /**
   *
   * @param topic
   */
  subscribe(topic) {
    if (this.requested.indexOf(topic) !== -1) {
      console.error(`Cannot subscribe '${topic}' because it is currently requested to subscribe`);
      return;
    }

    this.addRequestedTopic(topic);

    this.rhPushNotification.subscribe(topic).then((t) => {
      this.addSubscribedTopic(t);
      this.deleteRequestedTopic(t);
    }).catch((t) => {
      this.deleteRequestedTopic(t)
    });
  }

  /**
   *
   * @param topic
   */
  unsubscribe(topic) {
    if (this.requested.indexOf(topic) !== -1) {
      console.error(`Cannot unsubscribe '${topic}' because it is currently requested to subscribe`);
      return;
    }

    this.rhPushNotification.unsubscribe(topic).then((t) => {
      this.deleteStoredTopic(t);
      this.deleteRequestedTopic(t);
    }).catch((t) => {
      this.deleteRequestedTopic(t);
    });
  }

  /**
   *
   * @param clusterId
   * @param buildingId
   * @param siteId
   */
  handle(clusterId, buildingId, siteId) {
    if (window.usingCordova && Vue.prototype.$rhAuth.loggedIn()) {
      
      let localStorageTopics = this.getSubscribedTopics();
      let localStorageClusterId = localStorage.getItem(RHPushNotificationPlugin.LSKEY_CLUSTER_ID);
      let localStorageBuildingId = localStorage.getItem(RHPushNotificationPlugin.LSKEY_BUILDING_ID)
      let localStorageSiteId = localStorage.getItem(RHPushNotificationPlugin.LSKEY_ACTIVE_SITE)
      let localStorageLang = localStorage.getItem(RHPushNotificationPlugin.LSKEY_LANG_SETTING)

      if (localStorageLang === null || typeof localStorageLang === 'undefined') {
        localStorageLang = config?.availableLanguages?.split(',').shift() ?? 'de';
      }

      if (this.channels === null || this.channels.length <= 0) return;

      // iterate the configured channels for the current client (custom/<client>/config.base.json)
      this.channels.forEach((channel) => {

        // subscribe to the cluster topic
        this.handleIdType(RHPushNotificationPlugin.LSKEY_CLUSTER_ID,
          localStorageClusterId,
          RHPushNotificationPlugin.CLUSTER_ID_PREFIX,
          clusterId,
          channel,
          localStorageLang,
          localStorageTopics);

        // subscribe to the building topic
        this.handleIdType(RHPushNotificationPlugin.LSKEY_BUILDING_ID,
          localStorageBuildingId,
          RHPushNotificationPlugin.BUILDING_ID_PREFIX,
          buildingId,
          channel,
          localStorageLang,
          localStorageTopics);


        // subscribe to the site topic
        this.handleIdType(null,
          localStorageSiteId,
          RHPushNotificationPlugin.SITE_ID_PREFIX,
          siteId,
          channel,
          localStorageLang,
          localStorageTopics);

        // subscribe to the topic "all"
        this.handleAllType(channel, localStorageLang, localStorageTopics);
      })
    }
  }

  /**
   *
   * @param localStorageKey - customer specific key for localStorage
   * @param localStorageId - id which is stored in localStorage for the site, cluster or building
   * @param idPrefix - defines if it's a site a cluster or building
   * @param currentId - currentId for the site, cluster or building
   * @param channel - topic like "marketplace", "message", etc.
   * @param localStorageLang - language which is saved in localStorage
   * @param localStorageTopics - all topics which are stored in localStorage
   */
  handleIdType(localStorageKey, localStorageId, idPrefix, currentId, channel, localStorageLang, localStorageTopics) {
    if (typeof currentId === 'undefined' || currentId === null) {
      console.warn('currentId for the site, cluster or building is undefined, the response headers are not set ' +
        '- aborting the un-/subscribing for push notifications .')
      return
    }

    // No subscriptions to other than site topics for the "service" channel
    if (channel === RHPushNotificationPlugin.CHANNEL_KEY_SERVICE && idPrefix !== RHPushNotificationPlugin.SITE_ID_PREFIX) {
      return
    }

    let localStorageTopic = localStorageId ? this.clientKey + '-' + this.environment + '-' + channel + '-' + idPrefix + localStorageId + '-' + localStorageLang : null
    let currentTopic = this.clientKey + '-' + this.environment + '-' + channel + '-' + idPrefix + currentId + '-' + localStorageLang
    
    if (localStorageTopics.indexOf(currentTopic) === -1) {
      // unsubscribe the topic in the local storage and subscribe to the new one
      // only unsubscribe if different because here is a timing problem with topics of the same name
      if (localStorageTopic !== null && localStorageTopic != currentTopic) {
        this.unsubscribe(localStorageTopic);
      }

      this.subscribe(currentTopic);
    }

    // handle local storage changes - only required for cluster and building id, site id changes are handled elsewhere
    if (currentId !== localStorageId) {
      // if a local storage key was passed to the function, set the new id in the local storage
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, currentId);
      }
    }
  }

  /**
   *
   * @param channel
   * @param langLocal
   * @param topicsArray
   */
  handleAllType(channel, langLocal, topicsArray) {
    // No subscription to the "all" topic for the "service" channel
    if (channel === RHPushNotificationPlugin.CHANNEL_KEY_SERVICE) {
      return
    }

    let allTopic = this.clientKey + '-' + this.environment + '-' + channel + '-' + RHPushNotificationPlugin.ALL_ID + '-' + langLocal
    if (topicsArray === null || topicsArray.indexOf(allTopic) === -1) {
      this.subscribe(allTopic);
    }
  }

  /**
   *
   */
  unsubscribeAllTopics() {
    if (this.getSubscribedTopics().length > 0) {
      let storedTopics = this.getSubscribedTopics()
      storedTopics.forEach((topic) => {
        this.unsubscribe(topic);
      })
    }
    delete localStorage[RHPushNotificationPlugin.LSKEY_SUBSCRIPED_TOPICS]
    delete localStorage[RHPushNotificationPlugin.LSKEY_CLUSTER_ID]
    delete localStorage[RHPushNotificationPlugin.LSKEY_BUILDING_ID]
  }

  /**
   * Unregister device from firebase to avoid getting push notifications for old topics.
   * This deletes the firebase token for the device.
   */
  unregisterFromFirebase() {
    this.rhPushNotification.unregister();
  }

  /**
   *
   * @param topic
   */
  addSubscribedTopic(topic) {
    if (this.getSubscribedTopics().indexOf(topic) === -1) {
      let storedTopics = this.getSubscribedTopics()
      storedTopics.push(topic)
      this.storeSubscribedTopics(storedTopics)
    }
  }

  /**
   *
   * @param topic
   */
  deleteStoredTopic(topic) {
    let topicIndex = this.getSubscribedTopics().length > 0 ? this.getSubscribedTopics().indexOf(topic) : -1
    if (topicIndex >= 0) {
      let storedTopics = this.getSubscribedTopics()
      storedTopics.splice(topicIndex, 1)
      this.storeSubscribedTopics(storedTopics)
    }
  }

  /**
   *
   * @param topic
   */
  addRequestedTopic(topic) {
    if (this.requested.indexOf(topic) === -1) {
      this.requested.push(topic)
    }
  }

  /**
   *
   * @param topic
   */
  deleteRequestedTopic(topic) {
    let topicIndex = this.requested.length > 0 ? this.requested.indexOf(topic) : -1
    if (topicIndex >= 0) {
      this.requested.splice(topicIndex, 1)
    }
  }

  /**
   *
   * @returns []
   */
  getSubscribedTopics() {
    let topics = localStorage.getItem(RHPushNotificationPlugin.LSKEY_SUBSCRIPED_TOPICS);
    if (typeof topics === 'undefined' || topics === null) {
      return JSON.parse('[]');
    }

    return JSON.parse(topics);
  }

  /**
   *
   * @param storedTopics
   */
  storeSubscribedTopics(storedTopics) {
    localStorage[RHPushNotificationPlugin.LSKEY_SUBSCRIPED_TOPICS] = JSON.stringify(storedTopics)
  }

  /**
   * This function resets the faulty push subscriptions that were created by a previous incorrect implementation.
   * The resetting only takes place the first time this function is called up.
   */
  checkFaultyStorageReset() {
    // Check if it's the first time opening the app getting the 'pushStorageFixed' entry
    let pushStorageFixed = window.localStorage.getItem('pushStorageFixed');

    // Reset the faulty subscriptions and storage
    if (!pushStorageFixed) {
      // Clear and unsubscribe the topics
      this.unsubscribeAllTopics()

      // Unregister device from firebase
      this.unregisterFromFirebase()

      // Set the flag indicating that the app has been opened at least once and the storage was cleared
      window.localStorage.setItem('pushStorageFixed', 'true');
    }
  }
}

